import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    min-height: 100%;
    flex-direction: column;
    //padding-bottom: 200px;
    padding: 0px 20px;
`;

export const Cupom = styled.div`
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    background: white; 
    border-radius: 10px; 
    padding: 30px;
`


export const BoxInforInitial = styled.div`
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px ;
    margin-bottom: 32px ;
`;

export const BoxInforInitialLeft = styled.div`
    display: flex;
    //background-color: #808080;
    flex-direction: column ;
    flex: 1;

    label{

    }
    label:nth-child(1){
        font-weight: 600;
        font-size: 28px;
        color: #5E6278;
    }

    label:nth-child(2){
        font-weight: 400;
        font-size: 16px;
        color: #5E6278;
    }
`;

export const BoxInforInitialRight = styled.div`
    display: flex;
    
    width: 582px;
    align-items: center ;
    justify-content: center ;
    
    label{
        background-color: #FFC400;
        display: flex ;
        padding: 8px ;
        border-radius: 4px ;
        font-size: 12px ;
        font-weight: 400;
        font-size: 14px;
        color: #2b2b2b;
        cursor: pointer;
    }

`;
