import { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { BadgeCheck, BoxStar, Container, Name, Text } from './styled'
import ImgCard from "../../../../assets/imgs/icon-oculos.svg";
import { HiBadgeCheck } from "react-icons/hi";
import { getTranslation } from "../../../../translations";

const CardDropi = (props) => {

    const [icon, setIcon] = useState({ size: 20, color: "orange" })
    const config = useSelector(state => state.reducerControlConfig)

    useEffect(() => {
        setIcon({ ...icon, colorActive: config.star_color_primary, colorInative: config.star_color_secondary })
    }, [config])

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
      console.log("teste config", config)
    }, [config])
    
    useEffect(() => {
        console.log("teste props ", props)
      }, [props])
      

    return (
        <Container update={props.update} background={config.background_color_secondary} borderRadius={config.border_card_radius} borderSize={config.border_card_size} borderColor={config.border_card_color}>
            <img style={{ borderRadius: "10px" }} src={ImgCard} width="100" height="140" />
            <Name fontColor={config.font_color_primary} >
                {props?.item?.name[1] + "***" + props?.item?.name[props?.item?.name?.length - 1]}
                <BadgeCheck>
                    {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "10px" }} size={20} color={"#5cb85c"} />}
                    <label>{translation.thema.cardReviewDropiPreview.compraVerificada}</label>
                </BadgeCheck>

            </Name>
            <BoxStar position={config.position_star_review} fontColor={config.font_color_primary} >
                <span>
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? icon.colorActive : icon.colorInative} />
                </span>
                <label style={{ visibility: config.visible_date_review == "1" ? "visible" : "hidden" }} >17/03/2023</label>
            </BoxStar>
            <Text fontColor={config.font_color_primary} >{props.item.text}</Text>
        </Container>
    )
}

export default CardDropi