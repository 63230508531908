import styled from "styled-components";

export const Container = styled.div`
    background-color: white ;
    height: 200px ;
    border-radius: 10px ;
    display: flex ;
    flex-direction: column ;
    padding: 20px ;
    justify-content: space-between ;
    border: 1px solid #f2f2f2;
`

export const Title = styled.label`
    font-size: 18px;
`

export const SubTitle = styled.label`
    font-size: 12px;
    //margin-top: -40px ;
`

export const Search = styled.div`
    //background-color: yellow;
    height: 40px ;
    display: flex ;
    justify-content: space-between ;

    button{
        width: 40px ;
        height: 40px ;
        margin-left: 20px ;
        background-color: #FFC400 ; //#FBF9F6 ;
        border: none ;
        border-radius: 5px ;
        cursor: pointer;
        display: flex ;
        justify-content: center ;
        align-items: center ;
    }

    input{
        display: flex ;
        width: 100px ;
        flex: 1 ;
        background-color: #f9fafb ;
        border: none ;
        outline: none ;
        border-radius: 5px ;
        padding: 0px 5px ;
    }

`