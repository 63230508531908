import { Container, ItemOption, Options, Title } from "./styled";
import LilyImg from "../../../../assets/imgs/Girrafe.svg";
import "./style.css";
import { useHistory } from "react-router-dom";

const Code = () => {

    const history = useHistory()

    const data = [
        { name: "Brasil", img: "https://app.sak.com.br/image/paises/brazil.png", action: () => { localStorage.setItem("depoimentos@paismercadoshop", "BR") } },
        { name: "Argentina", img: "https://app.sak.com.br/image/paises/argentina.png", action: () => { localStorage.setItem("depoimentos@paismercadoshop", "AR") } },
        { name: "Chile", img: "https://app.sak.com.br/image/paises/chile.png", action: () => { localStorage.setItem("depoimentos@paismercadoshop", "CL") } },
        { name: "Colômbia", img: "https://app.sak.com.br/image/paises/colombia.png", action: () => { localStorage.setItem("depoimentos@paismercadoshop", "CO") } },
        { name: "México", img: "https://app.sak.com.br/image/paises/mexico.png", action: () => { localStorage.setItem("depoimentos@paismercadoshop", "MX") } },
    ]

    return (

        <Container>
            <Title>
                <div id="logos" className="d-flex justify-content-center">
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <img src={LilyImg} />
                        <label style={{ marginLeft: "10px", fontSize: "32px", fontWeight: "600", color: "#A85E0A" }}>Lily Reviews</label>
                    </span>
                    <img src="https://empreender.nyc3.digitaloceanspaces.com/landingpage/uploads/landingpage/3kw9lm034647.png" />
                </div>
            </Title>
            <Options>
                {data.map((item) => {
                    return (
                        <ItemOption onClick={() => { item.action(); history.push("/integracoes/mercadoshop") }}>
                            <img src={item.img} />
                            <label>{item.name}</label>
                        </ItemOption>
                    )
                })}
            </Options>
        </Container>
    )
}

export default Code
