
const INITIAL_STATE = {}

const ControlConfigMural = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONFIG_CONTROL_MURAL_SET':
            return {...state, ...action.payload}
        default:
            return state;
    }
}

export default ControlConfigMural