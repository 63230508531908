import React from 'react'
import { Container, Content, Infor, Title } from './styled'
import { getTranslation } from '../../translations';
import PlugIcon from "../../assets/imgs/icons-custom-tailwind/plug.svg";
import { useHistory } from "react-router-dom";

const PageIntegration = () => {

  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
  const history = useHistory()

  return (
    <Container>
      <Content>
        <img style={{ width: "48px" }} src={PlugIcon} />
        <Title>{translation.pageIntegration.title}</Title>
        <Infor>{translation.pageIntegration.infor}</Infor>
        <button
          onClick={() => { history.push("/integracoes") }}
          type="button"
          className="rounded-md bg-[#FFC400] px-3.5 py-2.5 text-sm font-semibold text-gray-600 hover:bg-[#FFC40099] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FFC40099]"
        >
          {translation.pageIntegration.button}
        </button>
      </Content>
    </Container>
  )
}

export default PageIntegration