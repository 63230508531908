
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Scrollbar, A11y } from "swiper/modules";
import Modal from "react-modal";
import "./styles.css";
import { ZiggeoPlayer } from 'react-ziggeo'
import { useEffect } from "react";
import { useState } from "react";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        with: "500px"

    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99"
    }
};


const ModalReviews = (props) => {


    const [player, setPlayer] = useState(null);
    useEffect(() => {
        if (player) {
            // DO stuff here
            player.on("attached", function (embedding) { }, player);
        }
    }, [player]);

    const handlePlaying = (embedding) => {
        
    };

    const handlePaused = (embedding) => {
       
    };

    const iframe_container = {
        left: 0,
        width: "250px",
        height: "500px",
        position: "relative"
    }

    const iframe = {
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        position: "absolute",
        border: 0
    }

    const getModalMedia = () => {

        switch (props.data[0]?.type) {
            case "tiktok":
                return (
                    <iframe
                        src={`https://tiktok.com/embed/${props.data[0]?.url}`}
                        style={{ width: "241px", height: "500px" }}
                        allowfullscreen
                        scrolling="no"
                        allow="encrypted-media;"
                    />
                )
            case "instagram":
                return (
                    <iframe
                        id="frame"
                        width="320"
                        height="568"
                        scrolling="no"
                        src={`https://www.instagram.com/p/${props.data[0]?.url}/embed`}
                        frameborder="0" />
                )
            case "youtube":
                return (
                    <iframe
                        id="frame"
                        width="560"
                        height="315"
                        scrolling="no"
                        src={`https://www.youtube.com/embed/${props.data[0]?.url}`}
                        frameborder="0"
                    />
                )
            default:
                return (
                    <div style={{ width: "600px", height: "400px" }}>
                        <Swiper
                            pagination={{
                                type: "fraction",
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {
                                props.data.map((item) => (
                                   
                                    <SwiperSlide key={item.id} >
                                        {
                                            item.type == "video" ?
                                                <ZiggeoPlayer
                                                    apiKey={process.env.REACT_APP_ZIGGEO_APP_TOKEN}
                                                    video={item.url}
                                                    theme={'modern'}
                                                    themecolor={'yellow'}
                                                    skipinitial={false}
                                                    onPlaying={handlePlaying}
                                                    onPaused={handlePaused}
                                                    onRef={ref => (setPlayer(ref))}
                                                    popup={true}
                                                    popup-height={400}
                                                /> :
                                                <img src={item.url} />
                                        }
                                    </SwiperSlide>
                                ))
                            }

                        </Swiper>
                    </div>
                )
        }
    }


    return (
        <Modal
            isOpen={props.visibleModal}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props.setVisibleModal(false)}
        >
            {getModalMedia()}

        </Modal>
    );
};

export default ModalReviews;
