import { BoxCupom, BoxForm, BoxInfor, BoxMedias, BoxMessage, BoxWhats, ButtomItemBoxMedia, ButtonClose, ButtonCustom, Container, ControlScreen, InputCustomForm, InputCustomWhatsappForm, ItemBoxMedia, ItemOption, Main, Steps, TermoUso, Title } from "./styled";
import imgBackground from "../../../../assets/imgs/preview-background.png";
import { AiFillStar } from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";
import { MdOutlineContentCopy } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { FaCloudUploadAlt, FaTiktok } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation, Scrollbar, A11y, EffectCards, EffectFade, EffectCube, EffectCoverflow, EffectFlip, EffectCreative } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./styles.css";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTranslation } from "../../../../translations";

const PreviewAvaliar = (props) => {

  let icon = { size: "24", colorActive: "orange", colorInative: "#80808022" }

  const configControl = useSelector(state => state.reducerControlConfig)

  const [hoverOption, setHoverOption] = useState(null)

  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);





  return (
    <Container id={"main-swiper-avaliar"} img={imgBackground}>

      <Swiper
        id='swiper-avaliar'
        modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
        slidesPerView={1}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        navigation
        pagination={{ clickable: true, type: 'fraction', }}
        effect={"slide"}
      >

        <SwiperSlide>
          <Main backgroundColor={configControl.screen_review_color_primary}>
            <Title color={configControl.screen_review_color_font}>
              {configControl.screen_review_config_title_star}
            </Title>
            <ItemOption onMouseOver={() => setHoverOption(5)} onMouseOut={() => setHoverOption(null)} colorHover={configControl.screen_review_color_font_secondary} color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
              <AiFillStar size={icon.size} color={hoverOption == 5 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 5 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 5 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 5 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 5 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <label>ÓTIMO</label>
            </ItemOption>

            <ItemOption onMouseOver={() => setHoverOption(4)} onMouseOut={() => setHoverOption(null)} colorHover={configControl.screen_review_color_font_secondary} color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
              <AiFillStar size={icon.size} color={hoverOption == 4 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 4 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 4 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 4 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <label>MUITO BOM</label>
            </ItemOption>

            <ItemOption onMouseOver={() => setHoverOption(3)} onMouseOut={() => setHoverOption(null)} colorHover={configControl.screen_review_color_font_secondary} color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
              <AiFillStar size={icon.size} color={hoverOption == 3 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 3 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 3 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <label>BOM</label>
            </ItemOption>

            <ItemOption onMouseOver={() => setHoverOption(2)} onMouseOut={() => setHoverOption(null)} colorHover={configControl.screen_review_color_font_secondary} color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
              <AiFillStar size={icon.size} color={hoverOption == 2 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={hoverOption == 2 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <label>RAZOÁVEL</label>
            </ItemOption>

            <ItemOption onMouseOver={() => setHoverOption(1)} onMouseOut={() => setHoverOption(null)} colorHover={configControl.screen_review_color_font_secondary} color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
              <AiFillStar size={icon.size} color={hoverOption == 1 ? configControl.screen_review_color_font_secondary : configControl.screen_review_color_star} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <AiFillStar size={icon.size} color={icon.colorInative} />
              <label>RUIM</label>
            </ItemOption>
          </Main>
        </SwiperSlide>

        <SwiperSlide>
          <Main backgroundColor={configControl.screen_review_color_primary}>
            <Title color={configControl.screen_review_color_font}>
              {configControl.screen_review_config_title_comment}
            </Title>

            <BoxMessage>
              <textarea
                value={" "}
                rows="8"
                maxLength={255}
                placeholder={"Conte-nos sua experiência..."}
                style={{ fontSize: "14px", color: configControl.screen_review_color_font, borderColor: configControl.screen_review_color_secondary, backgroundColor: "transparent", display: "flex", minWidth: "100%", padding: "20px", borderRadius: "8px" }}
              />
              <ButtonCustom colorHover={configControl.screen_review_color_font_secondary} color={configControl.screen_review_color_font} borderColor={configControl.screen_review_color_secondary} >
              {translation.thema.previewCreateReview.proximo}
              </ButtonCustom>
            </BoxMessage>

            <ControlScreen color={configControl.screen_review_color_font}>
              <label>{translation.thema.previewCreateReview.voltar}</label>
              <Steps backgroundColor={configControl.screen_review_color_secondary} >
                <span />
                <div />
                <span />
                <div />
                <span style={{ backgroundColor: "transparent", border: `1px solid ${configControl.screen_review_color_secondary}` }} />
                <div />
                <span style={{ backgroundColor: "transparent", border: `1px solid ${configControl.screen_review_color_secondary}` }} />
              </Steps>
              <label style={{ visibility: "hidden" }}>Avançar</label>
            </ControlScreen>
          </Main>
        </SwiperSlide>

        {
          configControl.screen_review_config_visible_media_tiktok == 1 ||
            configControl.screen_review_config_visible_media_upload == 1 ||
            configControl.screen_review_config_visible_media_cam == 1 ?

            <SwiperSlide>
              <Main backgroundColor={configControl.screen_review_color_primary}>
                <Title color={configControl.screen_review_color_font}>
                  {configControl.screen_review_config_title_medias}
                </Title>

                <BoxMedias>
                  {
                    configControl.screen_review_config_visible_media_cam == 1 &&
                    <ItemBoxMedia color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
                      <BsFillCameraVideoFill size={50} color={configControl.screen_review_color_secondary} />
                      <label>Ao vivo</label>
                      <ButtomItemBoxMedia colorHover={configControl.screen_review_color_font_secondary} backgroundColor={configControl.screen_review_color_secondary} color={configControl.screen_review_color_font}>
                        Gravar
                      </ButtomItemBoxMedia>
                    </ItemBoxMedia>
                  }

                  {
                    configControl.screen_review_config_visible_media_upload == 1 &&
                    <ItemBoxMedia color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
                      <FaCloudUploadAlt size={50} color={configControl.screen_review_color_secondary} />
                      <label>Suas imagens e vídeos</label>
                      <ButtomItemBoxMedia colorHover={configControl.screen_review_color_font_secondary} backgroundColor={configControl.screen_review_color_secondary} color={configControl.screen_review_color_font}>
                        Upload
                      </ButtomItemBoxMedia>
                    </ItemBoxMedia>
                  }


                  {
                    configControl.screen_review_config_visible_media_tiktok == 1 &&
                    <ItemBoxMedia color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
                      <FaTiktok size={50} color={configControl.screen_review_color_secondary} />
                      <label>TikTok</label>
                      <ButtomItemBoxMedia colorHover={configControl.screen_review_color_font_secondary} backgroundColor={configControl.screen_review_color_secondary} color={configControl.screen_review_color_font}>
                        Enviar
                      </ButtomItemBoxMedia>
                    </ItemBoxMedia>
                  }

                </BoxMedias>

                <ControlScreen color={configControl.screen_review_color_font}>
                  <label>{translation.thema.previewCreateReview.voltar}</label>
                  <Steps backgroundColor={configControl.screen_review_color_secondary}>
                    <span />
                    <div />
                    <span />
                    <div />
                    <span />
                    <div />
                    <span style={{ backgroundColor: "transparent", border: `1px solid ${configControl.screen_review_color_secondary}` }} />
                  </Steps>
                  <label>Pular</label>
                </ControlScreen>
              </Main>
            </SwiperSlide> : null
        }


        <SwiperSlide>
          <Main backgroundColor={configControl.screen_review_color_primary}>
            <Title color={configControl.screen_review_color_font}>
              {configControl.screen_review_config_title_data}
            </Title>

            <BoxForm>
              <InputCustomForm color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
                <input type="text" value={"Roberta Oliveira"} placeholder="Nome:" />
              </InputCustomForm>

              {
                configControl.screen_review_config_visible_data_whatsapp == 1 &&
                <BoxWhats color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
                  <PhoneInput
                    countryCodeEditable={false}
                    containerStyle={{ marginBottom: "0px" }}
                    inputStyle={{
                      borderRadius: "0px",
                      width: "100%",
                      backgroundColor: 'transparent',
                      outline: "none",
                      height: "45px",
                      paddingLeft: "50px",
                      border: `1px solid ${configControl.screen_review_color_secondary}`,
                      color: `${configControl.screen_review_color_font}`
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                      marginLeft: "0px",
                      padding: "5px",
                      width: "65px",
                    }}
                    country={"br"}
                    value={"5511999999999"}

                  />
                  <span style={{ position: "absolute", top: 8, right: 10 }}>
                    <RiWhatsappFill size={20} color={configControl.screen_review_color_font} />
                  </span>

                </BoxWhats>
              }


              <InputCustomForm color={configControl.screen_review_color_font} colorBorder={configControl.screen_review_color_secondary}>
                <input type="text" value={"robertaoliveira@email.com.br"} placeholder="E-mail:" />
              </InputCustomForm>

              <TermoUso color={configControl.screen_review_color_font}>
                {translation.thema.previewCreateReview.privacidade}
              </TermoUso>
              <ButtonCustom colorHover={configControl.screen_review_color_font_secondary} color={configControl.screen_review_color_font} borderColor={configControl.screen_review_color_secondary} >
                Enviar
              </ButtonCustom>
            </BoxForm>

            <ControlScreen color={configControl.screen_review_color_font}>
              <label>{translation.thema.previewCreateReview.voltar}</label>
              <Steps backgroundColor={configControl.screen_review_color_secondary}>
                <span />
                <div />
                <span />
                <div />
                <span />
                <div />
                <span />
              </Steps>
              <label style={{ visibility: "hidden" }}>Avançar</label>
            </ControlScreen>
          </Main>
        </SwiperSlide>

        <SwiperSlide>
          <Main backgroundColor={configControl.screen_review_color_primary} >
            <Title color={configControl.screen_review_color_font}>
              {configControl.screen_review_config_title_end}
            </Title>

            <BoxInfor color={configControl.screen_review_color_font} backgroundColor={configControl.screen_review_color_secondary}>
              <p>Obrigado por enviar seu feedback</p>
              <label>Válido apenas para produtos vendidos pela nossa loja. Lojas parceiras não serão aceitas.</label>
              <BoxCupom colorBorder={configControl.screen_review_color_secondary} color={configControl.screen_review_color_font}>
                <label>OFF10</label>
                <MdOutlineContentCopy size={24} color={configControl.screen_review_color_font} />
              </BoxCupom>
            </BoxInfor>

            <div style={{ display: "flex", flex: "1", backgroundColor: "transparent" }} />

            <ButtonClose colorHover={configControl.screen_review_color_font_secondary} colorBorder={configControl.screen_review_color_secondary} color={configControl.screen_review_color_font}>
              Fechar
              <IoIosArrowForward size={24} color={configControl.screen_review_color_font_secondary} />
            </ButtonClose>

          </Main>
        </SwiperSlide>

      </Swiper>


    </Container>
  )
}

export default PreviewAvaliar