import { Tooltip, Container, BoxContents, BoxContact, BoxOptions, BoxContentsLeft, SubTitle, Status, Img, ButtonAnswered, ButtonHidden, ButtonDelete, Box, BoxCheck, BoxActions, ButtonCustom, LabelReply, Badget } from "./styled"
import { AiFillStar, AiOutlineQuestionCircle, AiOutlineInfoCircle, AiFillEdit, AiOutlineMail, AiOutlineCheckCircle, AiFillClockCircle, AiFillCloseCircle, AiOutlineCheck, AiFillEye, AiFillEyeInvisible, AiOutlineQuestion, AiOutlineDelete } from "react-icons/ai";
import { interval } from "../../../../utils/date";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useState } from "react";
import { FaEdit, FaInfo, FaWhatsapp } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { MdHeadsetMic } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { getTranslation } from "../../../../translations";
import { Back } from "iconsax-react";


const CardQuestions = (props) => {

    const dispatch = useDispatch()
    const statusUpdateQuestion = useSelector(state => state.reducerUpdateQuestion)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [selectCard, setSelectCard] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const updateQuestion = (status, id) => {
        dispatch({
            type: "SAGA_UPDATE_QUESTIONS",
            payload: {
                status: status,
                id: id
            }
        })
    }


    const handleStatusCard = (checked) => {
        // if (checked) {
        //     dispatch({
        //         type: "QUESTION_CONTROL_SELECT_SET",
        //         payload: { id: props.item.id, status: props.item.reply_message == null || props.item.reply_message == "" ? "pending" : "answered" }
        //     })
        // } else {
        //     dispatch({
        //         type: "QUESTION_CONTROL_SELECT_REMOVE",
        //         payload: { id: props.item.id, status: props.item.reply_message == null || props.item.reply_message == "" ? "pending" : "answered" }
        //     })
        // }
        // setSelectCard(checked)
    }

    useEffect(() => { handleStatusCard(props.checkedAll) }, [props.checkedAll])


    const updateItem = () => {
        dispatch({
            type: "SAGA_UPDATE_QUESTIONS",
            payload: {
                active: 1,
                id: props.item.id
            }
        })
    }

    return (
        <Container>



            {/* {
                props?.noVisibleCheck ?
                    <></> :
                    <BoxCheck>
                        <input type="checkbox" checked={selectCard} onChange={(e) => { handleStatusCard(e.target.checked) }} />
                    </BoxCheck>
            } */}

            <Box>
                <BoxContents
                //color={colorBorder(props.item.status)}
                >
                    <BoxContentsLeft>
                        <p style={{ marginBottom: "15px" }}>
                            {props.item.name} {translation.questions.card.sobre} <a target="_blank" href={props.item.canonical_url}>{props.item.product_name}</a> <span><AiFillClockCircle size={15} color={"gray"} /> <label > {interval(props.item.created_at)}{translation.questions.card.atras}</label></span>
                        </p>
                        <div style={{ marginBottom: "10px" }}>
                            <RiQuestionnaireFill size={25} color={"gray"} />
                            <p>
                                {props.item.message}
                            </p>
                        </div>

                        {props.item?.reply_message?.length > 1 &&
                            <div>
                                <MdHeadsetMic size={25} color={"gray"} />
                                <LabelReply>
                                    {props.item.reply_message}
                                </LabelReply>
                            </div>}

                    </BoxContentsLeft>
                    <BoxActions>
                        <span>
                            <Back size={20} color={"#808080"} onClick={() => { updateItem() }} />
                            <label>{"Deseja restaurar essa pergunta?"}</label>
                        </span>
                    </BoxActions>
                </BoxContents>
            </Box>
        </Container >
    )
}

export default CardQuestions
