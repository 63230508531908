import styled from "styled-components";

export const ContainerNew = styled.div`
    display: flex;
    //background-color: yellow;
    width: 100% !important;
    //overflow: hidden;
    flex: 1;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
    background-size: 60%;
    //justify-content: center;
    flex-direction: column;
    padding: 40px;
    max-width: calc(100vw - 450px);
`

export const BodyNew = styled.div`
    display: flex;
    background-color: ${props => props.backgroundColor};
    overflow: hidden;
    padding-bottom: 50px;
`

export const TitleNew = styled.div`
    display: flex;
    background: ${props => props.backgroundColor};
    justify-content: ${props => props.align};
    margin-top: 0px;
    width: 100%;
    align-items: center;
    padding: 20px 20px;
    font-family: ${props => props.font};

    label{
        font-size: ${props => props.fontsize};
        font-weight: 500;
        color: ${props => props.color};
    }

`