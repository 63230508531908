import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { GrFormClose } from "react-icons/gr";
import { AiFillTag } from "react-icons/ai";

import { CheckCustom, Container, Error, Title, FormCustom, Close, ButtonCustom, InputCustomText, InputCustomDate, BoxInput, TextAreaCustom } from "./styled";
import { CounterItemsCircle } from '../../../thema/components/box-config-email/styled';
import { getTranslation } from '../../../../translations';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalAdd = (props) => {

    const statusAddCupom = useSelector(state => state.reducerAddCupom)
    const dispatch = useDispatch()
    const [activeCupom, setActiveCupom] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (!(statusAddCupom.loading == false && !statusAddCupom.error == false && statusAddCupom.data.length == 0)) {
            const id = "statusAddCupom"
            if (statusAddCupom.loading) {
                toast.loading(translation.cupons.modalAdd.aguarde, { toastId: id })
            } else {
                if (statusAddCupom.error) toast.update(id, { render: translation.cupons.modalAdd.errorSalvar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.cupons.modalAdd.salvoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.setVisibleModal(false)
                    formik.resetForm();
                    setActiveCupom(false)
                }
            }
        }
    }, [statusAddCupom])

    const formik = useFormik({
        initialValues: { code: '', message: translation.cupons.modalAdd.compartilheAmigos, description: '', expiration_date: addDays(new Date(), 30).toISOString().slice(0, 10) },
        validationSchema: Yup.object({
            code: Yup.string().required(translation.cupons.modalAdd.formik.code.validacao1).max(20, translation.cupons.modalAdd.formik.code.validacao2).min(3, translation.cupons.modalAdd.formik.code.validacao3),
            message: Yup.string().required(translation.cupons.modalAdd.formik.message.validacao1).max(100, translation.cupons.modalAdd.formik.message.validacao2).min(3, translation.cupons.modalAdd.formik.message.validacao3),
            expiration_date: Yup.string().required(translation.cupons.modalAdd.formik.expirationDate.validacao1),
        }),
        onSubmit: (values) => {
            dispatch({ type: "SAGA_ADD_CUPOM", payload: { ...values, active: activeCupom } })
        },
    });

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { formik.resetForm(); props.setVisibleModal(false) }}
        >
            <Container onSubmit={formik.handleSubmit}>
                <Close onClick={() => { props.setVisibleModal(false) }}><GrFormClose size={25} color={"#5e6278"} /></Close>
                <Title><strong>{translation.cupons.modalAdd.cadastrarCupom}</strong></Title>
                <FormCustom>
                    <BoxInput>
                        <label>{translation.cupons.modalAdd.cupom}</label>
                        <InputCustomText>
                            <span>
                                <AiFillTag size={20} color={"gray"} />
                            </span>
                            <input type={"text"} placeholder={"Ex: OFF10"} name={"code"} value={formik.values.code} onChange={formik.handleChange} />
                        </InputCustomText>
                        {formik.errors.code && formik.touched.code && <Error>{formik.errors.code}</Error>}
                    </BoxInput>
                    <BoxInput>
                        <label>{translation.cupons.modalAdd.expira}</label>
                        <InputCustomDate type={"date"} name={"expiration_date"} value={formik.values.expiration_date} onChange={formik.handleChange} lang="fr-CA" />
                        {formik.errors.expiration_date && formik.touched.expiration_date && <Error>{formik.errors.expiration_date}</Error>}
                    </BoxInput>
                    {/* <BoxInput style={{ position: "relative" }}>
                        <label>{translation.cupons.modalAdd.descricao}</label>
                        <TextAreaCustom maxLength="255" rows={3} placeholder={"Ex: 10% de desconto"} name={"description"} value={formik.values.description} onChange={formik.handleChange} />
                        {formik.errors.description && formik.touched.description && <Error>{formik.errors.description}</Error>}
                        <CounterItemsCircle porc={`${Math.round(formik.values.description.length / 255 * 100)}%`}>
                            <label>{255 - formik.values.description.length}</label>
                        </CounterItemsCircle>
                    </BoxInput> */}
                    <BoxInput style={{ position: "relative" }}>
                        <label>{translation.cupons.modalAdd.mensagem}</label>
                        <TextAreaCustom maxLength="255" rows={3} name={"message"} value={formik.values.message} onChange={formik.handleChange} />
                        {formik.errors.message && formik.touched.message && <Error>{formik.errors.message}</Error>}
                        <CounterItemsCircle porc={`${Math.round(formik.values?.message?.length / 255 * 100)}%`}>
                            <label>{255 - formik.values?.message.length}</label>
                        </CounterItemsCircle>
                    </BoxInput>
                    <CheckCustom>
                        <input checked={activeCupom ? true : false} type="checkbox" id="ativo" name="status" value={activeCupom} onChange={(e) => { setActiveCupom(!activeCupom) }} />
                        <label for="ativo">{translation.cupons.modalAdd.ativo}</label>
                    </CheckCustom>
                </FormCustom>
                <ButtonCustom type="submit">{translation.cupons.modalAdd.salvar}</ButtonCustom>
            </Container>
        </Modal>
    )
}

export default ModalAdd
