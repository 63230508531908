import React, { useEffect, useState } from "react";
import { BadgeCheck, BoxInfor, BoxMedia, BoxText, CardInfo, CardPlay, CardStar, Container, TitleName, } from "./styled";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { getVideoTiktok } from "./functionsTiktok";
import { FaPlay } from "react-icons/fa";
import { FiCameraOff } from "react-icons/fi";
import ImgCard from "../../../../assets/imgs/icon-bolsa.svg";
import { HiBadgeCheck } from "react-icons/hi";
import { getTranslation } from "../../../../translations";

const CardInsta = (props) => {




  const [icon, setIcon] = useState(
    {
      size: 20,
      colorActive: "orange",
      colorInative: "gray",
    }
  )
  const config = useSelector(state => state.reducerControlConfig)

  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  useEffect(() => {
    
    setIcon({ ...icon, colorActive: config.starColorPrimary, colorInative: config.starColorSecondary })
  }, [config])


  const [isClicked, setIsClicked] = useState(false);
  const [ziggeoApi, setZiggeoApi] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  const getThumbnail = (token) => {
    let response = ziggeoApi.videos.get(token);

    response.success(function (data) {
      setThumbnail(data.original_stream.embed_image_url);
    })
  }
  const getMedia = (media, type) => {
    if (media?.type == "tiktok") {
    }

    type = "insta"
    
    switch (type) {
      case "image":
        return (
          <CardPlay id='abrir'>
            <img src={ImgCard} id='abrir' />
          </CardPlay>
        )
      case "video":
        return (
          ziggeoApi && getThumbnail(media?.url),
          <CardPlay id='abrir'>
            {thumbnail ?
              <img src={thumbnail} id='abrir' />
              :
              <FaPlay color="gray" size={35} id='abrir' />
            }
          </CardPlay>
        )
      case "tiktok":
        return (
          <CardPlay>
            {/* <FaPlay color="gray" size={35} /> */}
            {/* {getVideoTiktok(7114045612609260806)} */}
            <iframe
              src={`https://tiktok.com/embed/7114045612609260806`}
              style={{ width: '-webkit-fill-available', height: '-webkit-fill-available', margin: '0px auto', borderRadius: '0px', }}
              allowfullscreen
              scrolling="no"
              allow="encrypted-media;"
            />
          </CardPlay>
        )

      case "insta":
        return (
          <CardPlay card={"insta"} style={{ border: "none" }}>
            <iframe
              id="frame"
              width="320"
              height="568"
              scrolling="no"
              src="https://www.instagram.com/p/CunPs9ov0RX/embed"
              frameborder="0" />
          </CardPlay>
        )

      case "youtube":
        return (
          <CardPlay style={{ border: "none" }}>
            <iframe
              id="frame"
              width="560"
              height="315"
              scrolling="no"
              src="https://www.youtube.com/embed/AAitCE51W40?si=urEroUGLVgOelin8"
              frameborder="0"
            />
          </CardPlay>
        )
      default:
        return (
          <CardPlay id='abrir'>
            <FiCameraOff size={50} color={"gray"} id='abrir' />
          </CardPlay>
        )
    }
  }


  return (
    <Container
      key={props.review?.id}
      mediaType={props.review?.media[0]?.type}
    >
      <BoxMedia>
        <CardStar backgroundColor={config.background_color_secondary} onClick={() => { setIsClicked(!isClicked) }} isClicked={isClicked}>
          <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? config.star_color_primary : config.star_color_secondary} />
          <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? config.star_color_primary : config.star_color_secondary} />
          <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? config.star_color_primary : config.star_color_secondary} />
          <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? config.star_color_primary : config.star_color_secondary} />
          <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? config.star_color_primary : config.star_color_secondary} />
          <CardInfo backgroundColor={config.background_color_secondary} isClicked={isClicked}>
            <div className="client-name-lily">
              <span style={{ display: "flex", justifyContent: "center", color: `${config.fontColorPrimary}` }}>
                {props.item.name}
                <BadgeCheck>
                  {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={20} color={"#5cb85c"} />}
                  <label>{translation.thema.cardReviewTiktokPreview.compraVerificada}</label>
                </BadgeCheck>

              </span>
              <label style={{ color: `${config.fontColorPrimary}`, textAlign: "center", fontSize: "12px", fontWeight: "initial" }}>
                {config.visible_date_review == "1" && new Date("2022-01-05").toLocaleDateString('pt-BR')}
              </label>
            </div>
            <BoxText>
              <label style={{ color: `${config.fontColorPrimary}` }}>{props.item.text}</label>
            </BoxText>
          </CardInfo>
        </CardStar>
        {getMedia(props.item.medias[0], props.item.type)}
      </BoxMedia>

    </Container>
  );
};

export default CardInsta;
