import { BoxCheck, Container, Section, SectionLeft, BoxSvg, NameMobile, } from "./styled"
import { AiFillStar, } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { RiQuestionnaireFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { FiCameraOff } from "react-icons/fi";
import { getTranslation } from "../../../../translations";


const CardImportProduct = (props) => {


    const dispatch = useDispatch()
    const history = useHistory()

    const [visibleSubmenu, setVisibleSubmenu] = useState(false)

    const [selectCard, setSelectCard] = useState(false)


    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    //useEffect(() => { handleStatusCard(props.checkedAll) }, [props.checkedAll])

    return (
        <Container onClick={() => { props.setProductImportSelected(props.item); props.goSearchReviewsImportProduct(props.item) }}>
            <Section>
                <SectionLeft>

                    {props.item.image_url == null || props?.item?.image_url.length == 0 ?
                        <BoxSvg>
                            <FiCameraOff color={"gray"} />
                        </BoxSvg> :
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={props?.item?.image_url} />
                            <NameMobile onClick={() => { window.open(props.item?.canonical_url) }}>{props.item?.name}</NameMobile>
                        </div>
                    }
                    <span>
                        <label style={{ display: "flex", alignItems: "center" }}>
                            {props.item?.name} <AiFillStar style={{ margin: "0px 5px" }} color="ORANGE" size={20} /> {props.item.avg_review.toFixed(1)}
                        </label>
                        <div>
                            <label>
                                <AiFillStar color="ORANGE" size={20} /> {`${props.item.total_review_all} ${translation.products.card.avaliacoes}`}
                            </label>
                            <label>
                                <RiQuestionnaireFill size={20} /> {props.item.total_ask} {translation.products.card.perguntas}
                            </label>

                        </div>
                        <label>{new Date(props.item.created_at).toLocaleDateString('pt-BR')}</label>
                    </span>
                </SectionLeft>

            </Section>
        </Container >
    )
}

export default CardImportProduct
