import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 300px;
    width: 300px;
    background-color: ${props => props.background};
    flex-direction: column;
    padding: 20px;
    border: ${props => props.sizeBorder +" solid " + props.colorBorder}; 
    border-radius: ${props => props.radiusBorder} ;
`

export const Starts = styled.div`
    display: flex;
    height: 60px;
    //background-color: yellow;
    align-items: center;
    justify-content: center;

    span{
        display: flex;
        gap: 2px;
    }
`

export const Text = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    


    label{
        font-size: 14px;
        font-family: ${props => props.theme.homereviews_font_title};
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${props => props.fontColor};
        text-align: center;
        //background-color: red;
        width: 100%;
    }
`

export const Infor = styled.div`
    display: flex;
    height: 71px;
    //background-color: green;
    //flex-direction: column;
    //gap: 2px;
    //justify-content: center;
    gap: 10px;

    span{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: ${props => `${props.background}22`};
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 22px;
        font-family: ${props => props.theme.homereviews_font_title};
        color: ${props => props.fontColor};
        border: 1px solid ${props => props.fontColor}22;
    }

    div{
        display: flex;
        flex-direction: column;
        justify-content: left;
        //background-color: red;

        p{
            margin: 0px;
            padding: 0px;
            font-size: 16px;
            font-family: ${props => props.theme.homereviews_font_title};
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
            color: ${props => props.fontColor};
            text-align: left;
        }

        label{
            font-size: 9px;
            opacity: 0.6;
            font-family: ${props => props.theme.homereviews_font_title};
            margin-top: -2px;
            color: ${props => props.fontColor};
            text-align: left;
        }
    }

    
`