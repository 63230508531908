import styled from "styled-components";

export const Container = styled.div`
    
    display: flex;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
    background-size: 60%;
    width: 100%;
    height: 100% ;
    border-radius: 8px;
    //margin-right: 20px;
    align-items: center;
    justify-content: center;

    label{
        font-size: 28px;
        font-weight: 500;
    }

`;