import styled from "styled-components";

export const Container = styled.div`

  display: flex ;
  flex: 100% ;
  //margin-left: 10px ;
  flex-direction:  column;
  padding: 40px;
  border-radius: 5px;
  /* margin-left: 350px ; */
  font-family: ${props => props.font} ; 

  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
  background-size: 60%;
  margin: 0px 20px 0px 10px;
  height: 100% ;

  //background-color: yellow ;
  //width: calc(100vw - 30px) ;
  width: 100% !important;
  //overflow: scroll;
 

  @media(max-width: 1100px) {
     //background-color: red ;
  }


`

export const BoxCards = styled.div`
  column-count: 2;
  

  @media (min-width: 1400px) {
    column-count: 3;
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};
  padding: 40px;
  border-radius: 8px;
`

export const Title = styled.label`
  display: flex;
  //background-color: red;
  padding: 0px 0px;
  margin-bottom: 40px;
  font-size: ${props => props.size};
  justify-content: ${props => props.position};
  color: ${props => props.color};
  font-family: ${props => props.font};
`

