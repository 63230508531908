import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const items = [
    { id: 1, title: 'Back End Developer', department: 'Engineering', type: 'Full-time', location: 'Remote' },
    { id: 2, title: 'Front End Developer', department: 'Engineering', type: 'Full-time', location: 'Remote' },
    { id: 3, title: 'User Interface Designer', department: 'Design', type: 'Full-time', location: 'Remote' },
]

export default function Pagination(props) {

    const reviews = useSelector(state => state.reducerReviews)

    useEffect(() => {
        console.log("revies pag", reviews)
    }, [reviews])


    const classActive = "relative z-10 inline-flex items-center bg-gray-900 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
    const classInative = "relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"


    const getPaginationType = () => {

        if (reviews?.data?.meta?.last_page < 7) {
            return (
                <>
                    <a
                        onClick={() => { props.setPage(1) }}
                        href="#"
                        aria-current="page"
                        className={reviews?.data?.meta?.current_page == 1 ? classActive : classInative}
                    >
                        1
                    </a>

                    {reviews?.data?.meta?.last_page >= 2 &&
                        <a
                            onClick={() => { props.setPage(2) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 2 ? classActive : classInative}
                        >
                            2
                        </a>}

                    {reviews?.data?.meta?.last_page >= 3 &&
                        <a
                            onClick={() => { props.setPage(3) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 3 ? classActive : classInative}
                        >
                            3
                        </a>}
                    {reviews?.data?.meta?.last_page >= 4 &&
                        <a
                            onClick={() => { props.setPage(4) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 4 ? classActive : classInative}
                        >
                            4
                        </a>}
                    {reviews?.data?.meta?.last_page >= 5 &&
                        <a
                            onClick={() => { props.setPage(5) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 5 ? classActive : classInative}
                        >
                            5
                        </a>}
                    {reviews?.data?.meta?.last_page >= 6 &&
                        <a
                            onClick={() => { props.setPage(6) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 6 ? classActive : classInative}
                        >
                            6
                        </a>}

                </>
            )
        } else {
            if (
                reviews?.data?.meta?.current_page == reviews?.data?.meta?.last_page ||
                reviews?.data?.meta?.current_page == reviews?.data?.meta?.last_page - 1 ||
                reviews?.data?.meta?.current_page == reviews?.data?.meta?.last_page - 2 ||
                reviews?.data?.meta?.current_page == 1 ||
                reviews?.data?.meta?.current_page == 2 ||
                reviews?.data?.meta?.current_page == 3
            ) {
                return (
                    <>
                        <a
                            onClick={() => { props.setPage(1) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 1 ? classActive : classInative}
                        >
                            1
                        </a>

                        <a
                            onClick={() => { props.setPage(2) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 2 ? classActive : classInative}
                        >
                            2
                        </a>

                        <a
                            onClick={() => { props.setPage(3) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 3 ? classActive : classInative}
                        >
                            3
                        </a>
                        <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                            ...
                        </span>
                        <a
                            onClick={() => { props.setPage(reviews?.data?.meta?.last_page - 2) }}
                            href="#"
                            className={reviews?.data?.meta?.last_page - 2 == reviews?.data?.meta?.current_page ? classActive : classInative}
                        >
                            {reviews?.data?.meta?.last_page - 2}
                        </a>
                        <a
                            onClick={() => { props.setPage(reviews?.data?.meta?.last_page - 1) }}
                            href="#"
                            className={reviews?.data?.meta?.last_page - 1 == reviews?.data?.meta?.current_page ? classActive : classInative}
                        >
                            {reviews?.data?.meta?.last_page - 1}
                        </a>
                        <a
                            onClick={() => { props.setPage(reviews?.data?.meta?.last_page) }}
                            href="#"
                            className={reviews?.data?.meta?.last_page == reviews?.data?.meta?.current_page ? classActive : classInative}
                        >
                            {reviews?.data?.meta?.last_page}
                        </a>
                    </>
                )
            } else {
                return (
                    <>
                        <a
                            onClick={() => { props.setPage(1) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 1 ? classActive : classInative}
                        >
                            1
                        </a>

                        <a
                            onClick={() => { props.setPage(2) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 2 ? classActive : classInative}
                        >
                            2
                        </a>

                        <a
                            onClick={() => { props.setPage(3) }}
                            href="#"
                            aria-current="page"
                            className={reviews?.data?.meta?.current_page == 3 ? classActive : classInative}
                        >
                            3
                        </a>
                        <a
                            href="#"
                            className={classActive}
                        >
                            {reviews?.data?.meta?.current_page}
                        </a>
                        <a
                            onClick={() => { props.setPage(reviews?.data?.meta?.last_page - 2) }}
                            href="#"
                            className={reviews?.data?.meta?.last_page - 2 == reviews?.data?.meta?.current_page ? classActive : classInative}
                        >
                            {reviews?.data?.meta?.last_page - 2}
                        </a>
                        <a
                            onClick={() => { props.setPage(reviews?.data?.meta?.last_page - 1) }}
                            href="#"
                            className={reviews?.data?.meta?.last_page - 1 == reviews?.data?.meta?.current_page ? classActive : classInative}
                        >
                            {reviews?.data?.meta?.last_page - 1}
                        </a>
                        <a
                            onClick={() => { props.setPage(reviews?.data?.meta?.last_page) }}
                            href="#"
                            className={reviews?.data?.meta?.last_page == reviews?.data?.meta?.current_page ? classActive : classInative}
                        >
                            {reviews?.data?.meta?.last_page}
                        </a>
                    </>
                )
            }

        }
    }

    return (
        <div className="flex items-center justify-between border-t border-gray-200 mt-3 px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <a
                    style={{ visibility: reviews?.data?.meta?.current_page > 1 ? "initial" : "hidden" }}
                    onClick={() => { reviews?.data?.meta?.current_page > 1 && props.setPage(reviews?.data?.meta?.current_page - 1) }}
                    href="#"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Anterior
                </a>
                <a
                    style={{ visibility: reviews?.data?.meta?.last_page > reviews?.data?.meta?.current_page ? "initial" : "hidden" }}
                    onClick={() => { reviews?.data?.meta?.last_page > reviews?.data?.meta?.current_page && props.setPage(reviews?.data?.meta?.current_page + 1) }}
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Proximo
                </a>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Mostrando <span className="font-medium">{(reviews?.data?.meta?.per_page * reviews?.data?.meta?.current_page) - (reviews?.data?.meta?.per_page - 1)}</span> a <span className="font-medium">{reviews?.data?.meta?.current_page == reviews?.data?.meta?.last_page ? reviews?.data?.meta?.total : (reviews?.data?.meta?.per_page * reviews?.data?.meta?.current_page)}</span> de{' '}
                        <span className="font-medium">{reviews?.data?.meta?.total}</span> resultados
                    </p>
                </div>
                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md" aria-label="Pagination">

                        <a
                            onClick={() => {
                                //alert("tste voltar")
                                reviews?.data?.meta?.current_page > 1 && props.setPage(reviews?.data?.meta?.current_page - 1)
                            }}
                            href="#"
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </a>

                        {getPaginationType()}

                        <a
                            onClick={() => {
                                //alert("teste passar")
                                reviews?.data?.meta?.last_page > reviews?.data?.meta?.current_page && props.setPage(reviews?.data?.meta?.current_page + 1)
                                //props.setPage(reviews?.data?.meta?.current_page + 1) 
                            }}
                            href="#"
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    )
}
