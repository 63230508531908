
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from "react-icons/io";

import { Container, Title, FormCustom, Close, ButtonCustom, InputCustomText, BoxInput } from "./styled";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getTranslation } from '../../../../translations';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalEditEmail = (props) => {


    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    // const language = useSelector((state) => state.reducerLanguage);
    const reducerUpdateEmailOrders = useSelector((state) => state.reducerUpdateEmailOrders);

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);



    useEffect(() => {
        //console.log('reducerUpdateEmailOrders', reducerUpdateEmailOrders)
        if (reducerUpdateEmailOrders.loading == false && reducerUpdateEmailOrders.error == false && reducerUpdateEmailOrders.data.length == 0) {

        } else {
            const id = "reducerUpdateEmailOrders"
            if (reducerUpdateEmailOrders.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (reducerUpdateEmailOrders.error) toast.update(id, { render: translation.orders.ErroEdit, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.orders.atualizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.setVisibleModal(false)
                    setEmail("")
                    props.goSearch()
                }
            }
        }
    }, [reducerUpdateEmailOrders])

    const saveEmail = () => {
        dispatch({ type: "SAGA_UPDATE_EMAIL_ORDERS", payload: { id: props.item.id, email: email }, });
    }

    useEffect(() => {
        if (props.visible) {
            setEmail(props.item.email)
        }
        //console.log("props order", props)
    }, [props.visible])

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <Title><strong>{translation.orders.editarEmail}</strong></Title>
                <FormCustom>
                    <BoxInput>
                        <label>{translation.orders.email}:</label>
                        <InputCustomText>
                            <input style={{ padding: "0px 10px" }} type={"text"} placeholder={"E-mail"} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </InputCustomText>
                    </BoxInput>
                </FormCustom>
                <ButtonCustom onClick={() => { saveEmail() }}>{translation.orders.salvar}</ButtonCustom>
            </Container>
        </Modal>
    )
}

export default ModalEditEmail
