import { EllipsisVerticalIcon, EnvelopeIcon } from "@heroicons/react/24/outline"
import { BoxIcon, Submenu } from "../../../../cupons/components/tailwind/item-table/styled"
import { useEffect, useState } from "react"
import ModalEditEmail from "../../modal-edit-email"
import { useDispatch } from "react-redux"
import { FaWhatsapp } from "react-icons/fa"
import { BoxImage } from "../../../../reviews/components/tailwind/item-table/styled"
import { getTranslation } from "../../../../../translations"
import ModalMenuItem from "../../modal-menu-item"
import { TextQtd } from "../../../../products/components/tailwind/item-table/styled"
import { useHistory } from "react-router-dom"


const ItemTable = (props) => {

    const [visibleMenu, setvisibleMenu] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const [visibleModalMenu, setVisibleModalMenu] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const closeMenu = () => { setvisibleMenu(false) }
    useEffect(() => {
        if (visibleMenu) {
            window.addEventListener('mouseup', closeMenu);
            return () => { window.removeEventListener('mouseup', closeMenu); };
        }


    }, [visibleMenu])

    const sendEmail = () => {
        dispatch({
            type: "SAGA_RESEND_EMAIL_ORDERS",
            payload: { order: [props.item] },
        });
        props.setSelectedOrders([])
    }

    const sendWhats = () => {
        dispatch({
            type: "SAGA_RESEND_WHATS_ORDERS",
            payload: { order: [props.item] },
        });
        props.setSelectedOrders([])
    }

    return (
        <>
            <ModalMenuItem
                //setVisibleModalEdit={setVisibleModal}
                item={props.item}
                visibleModalMenu={visibleModalMenu}
                setVisibleModalMenu={setVisibleModalMenu}
                setVisibleModal={setVisibleModal}
                sendEmail={sendEmail}
                sendWhats={sendWhats}
            //deleteReview={deleteReview}
            //setVisibleModalEditReviews={setVisibleModalEditReviews}
            //copyItem={copyItem}
            />
            <ModalEditEmail goSearch={props.goSearch} item={props.item} visible={visibleModal} setVisibleModal={setVisibleModal} />
            <tr key={"person.email"} className={props.selectedOrders.includes(props.item) ? 'bg-gray-50 even:bg-gray-50' : "even:bg-gray-50"}>
                <td className="relative px-7 sm:w-12 sm:px-6">
                    {props.selectedOrders.includes(props.item) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-green-600" />
                    )}
                    <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={props.item.email}
                        checked={props.selectedOrders.includes(props.item)}
                        onChange={(e) =>
                            props.setSelectedOrders(
                                e.target.checked
                                    ? [...props.selectedOrders, props.item]
                                    : props.selectedOrders.filter((p) => p !== props.item),
                            )
                        }
                    />
                </td>
                <td
                    className={props.classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                        props.selectedOrders.includes(props.item) ? 'text-gray-900' : 'text-gray-900',
                    )}
                >
                    {new Date(props.item.created_at).toLocaleString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    #{props.item.order_number != null ? props.item.order_number : props.item.order_id}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {props.item.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {props.item.email}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <TextQtd onClick={() => {history.push(`/pedidos/${props.item.id}/produtos`) }} style={{ width: "100%", textAlign: "center" }}>
                        {props.item.order_items?.length}
                    </TextQtd>
                    {/* <BoxImage>
                        <span>
                            <img src={props.item.order_items[0]?.product?.image_url} style={{ width: "32px", height: "32px", objectFit: "cover", borderRadius: "4px" }} />
                            {props.item.order_items?.length > 1 && <label>+{props.item.order_items?.length - 1}</label>}
                        </span>
                    </BoxImage> */}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <label style={{ width: "200px" }} className={props.item.is_email_send == 1 ? "bg-green-50 text-green-700 p-2 rounded-1 flex justify-center" : "bg-yellow-50 text-yellow-700 p-2 rounded-1 flex justify-center"} >
                        {props.item.is_email_send == 1 ? new Date(props.item.updated_at).toLocaleString() : props.item.dataSent == null ? "Aguardando" : new Date(props.item.dataSent).toLocaleString()}
                    </label>
                </td>
                {JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationSak != null &&
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <label style={{ width: "200px" }} className={props.item.is_sak_send == 1 ? "bg-green-50 text-green-700 p-2 rounded-1 flex justify-center" : "bg-yellow-50 text-yellow-700 p-2 rounded-1 flex justify-center"} >
                            {props.item.is_sak_send == 1 ? new Date(props.item.updated_at).toLocaleString() : props.item.dataSent == null ? "Aguardando" : new Date(props.item.dataSent).toLocaleString()}
                        </label>
                    </td>}
                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <span style={{ position: "relative" }}>
                        <EllipsisVerticalIcon onClick={() => { setVisibleModalMenu(true) }} style={{ height: "32px", cursor: "pointer" }} />
                        {/* <Submenu isVisible={visibleMenu}>
                            <div onClick={() => { setVisibleModal(true) }}>
                                <BoxIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                    </svg>
                                </BoxIcon>
                                <span>
                                    <p> {translation.orders.editarEmail}</p>
                                    <label> {translation.orders.atualizarEmail}</label>
                                </span>
                            </div>
                            <div>
                                <BoxIcon>
                                    <EnvelopeIcon style={{ width: "20px" }} />
                                </BoxIcon>
                                <span onClick={() => { sendEmail() }}>
                                    <p> {translation.orders.reenviarEmail}</p>
                                    <label> {translation.orders.envieReenvieEmail}</label>
                                </span>
                            </div>
                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationSak != null &&
                                <div>
                                    <BoxIcon>
                                        <FaWhatsapp size={20} />
                                    </BoxIcon>
                                    <span onClick={() => { sendWhats() }}>
                                        <p> {translation.orders.reenviarWhatsapp}</p>
                                        <label>{translation.orders.envieReenvieWhatsapp}</label>
                                    </span>
                                </div>}
                        </Submenu> */}
                    </span>
                </td>
            </tr>
        </>

    )
}

export default ItemTable