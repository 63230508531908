import Modal from 'react-modal';
import { Container, ListCard, Paginate, PaginateInfor, PaginateNav } from "./styled";
import CardLoadingReview from "../../../reviews/components/card-loading";
import CardReview from "../../../reviews/components/card";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { IoIosClose } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { getTranslation } from '../../../../translations';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalListReviews = (props) => {

    const dispatch = useDispatch()
    const controlModalReviews = useSelector(state => state.ControlModalReviews)
    const reviews = useSelector(state => state.reducerReviews)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const statusUpdateReview = useSelector(state => state.reducerUpdateReview)
    const statusDeleteReview = useSelector(state => state.reducerDeleteReview)

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    // useEffect(() => {
    //     if (!(statusDeleteReview.loading == false && !statusDeleteReview.error == false && statusDeleteReview.data.length == 0)) {
    //         const id = "statusDeleteReview"
    //         if (statusDeleteReview.loading) {
    //             toast.loading("Aguarde...", { toastId: id })
    //         } else {
    //           
    //             if (statusDeleteReview.error) toast.update(id, { render: "Erro ao tentar excluir.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //             else toast.update(id, { render: "Excluído com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
    //         }
    //     }
    // }, [statusDeleteReview])

    // useEffect(() => {
    //     let control = null
    //     statusUpdateReview.loading ? control = 1 : control = 0
    //     if(!statusUpdateReview.loading && control == 0){
    //        
    //         control = null
    //     }

    // }, [statusUpdateReview])

    useEffect(() => {
        if (props.visible) {
            dispatch({
                type: "SAGA_LIST_REVIEWS", payload: {
                    rating: "",
                    status: "all",
                    search: props?.search,
                    perPage: perPage,
                    page: page,
                    medias: "",
                    log: "props.visible 1",
                    productId: props?.id

                }
            })
        }

    }, [props.visible])

    const [control, setcontrol] = useState(0)

    // useEffect(() => {
    //     dispatch({
    //         type: "SAGA_LIST_REVIEWS", payload: {
    //             rating: "",
    //             status: "all",
    //             search: props?.search,
    //             perPage: 20,
    //             page: 1,
    //             medias: "",
    //             log: "props.visible 2"
    //         }
    //     })
    // }, [statusUpdateReview])

    useEffect(() => {
        if (props.visible) {
            dispatch({
                type: "SAGA_LIST_REVIEWS", payload: {
                    rating: "",
                    status: "all",
                    search: props?.search,
                    perPage: perPage,
                    page: page,
                    medias: "",
                    log: "props.visible 3",
                    productId: props?.id
                }
            })
        }
    }, [statusDeleteReview, statusUpdateReview, page])


    const clear = () => {

        props.setVisibleModal(false)
        //props.setControlUpdate(true)
    }

    useEffect(() => {
        if (reviews.data?.data?.length == 0 && props.visible) {
            props.setControlUpdate(true)
        }
    }, [reviews])

    useEffect(() => {

    }, [])

    const countItens = () => {
       
        let result = 0
        if (page == reviews?.data?.meta?.last_page) result = reviews?.data?.meta?.total
        else result = page * perPage
        return result
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => {
                props.setVisibleModal(false);
                props?.setControlUpdate(true)
            }}
        >
            <IoIosClose
                onClick={() => {
                    props.setVisibleModal(false);
                    props?.setControlUpdate(true)
                }}
                style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} size={25} color={"gray"} />
            <Container>
                <ListCard>
                    {reviews.loading ?
                        <>
                            <div style={{ marginBottom: "20px" }} />
                            {Array(3).fill(<CardLoadingReview />)}
                        </>
                        :
                        reviews.data?.data?.length == 0 ?
                            clear() :
                            reviews.data.data?.map((item) => {
                                return (<CardReview noVisibleCheck={true} key={item.id} item={item} />)
                            })


                    }

                    {reviews.data?.data?.length > 0 && reviews.loading == false ?
                        <Paginate>
                            <PaginateNav>
                                <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                    <MdKeyboardArrowLeft size={35} color={reviews.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                </span>
                                <label>{reviews.data?.meta?.current_page}</label>
                                <span onClick={() => { if (page < reviews.data?.meta?.last_page) setPage(page + 1) }}>
                                    <MdKeyboardArrowRight size={35} color={reviews.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                </span>
                            </PaginateNav>
                            <PaginateInfor>
                                <label>{translation.reviews.total} {countItens()} - {reviews.data?.meta?.total}</label>
                            </PaginateInfor>
                        </Paginate> : null}
                </ListCard>


                {/* {products.loading && <div />}
                        {products.loading ?
                            Array(5).fill(<CardLoadingReview />) :
                            products.data.data?.map((item) => { return <CardReview key={item.id} checkedAll={checkedAll} item={item} /> })} */}

                {/* {products.data.data?.length > 0 &&
                        <Paginate>
                            <PaginateNav>
                                <span onClick={() => {
                                    if (page != 1) setPage(page - 1)
                                }}>
                                    <MdKeyboardArrowLeft size={35} color={products.data.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                </span>
                                <label>{products.data?.meta?.current_page}</label>
                                <span onClick={() => {
                                    if (page < products.data?.meta?.last_page) setPage(page + 1)
                                }}>
                                    <MdKeyboardArrowRight size={35} color={products.data.meta?.last_page == page ? "gray" : "#19B674"} />
                                </span>
                            </PaginateNav>
                            <PaginateInfor>
                                <label>Total {countItens()} - {products.data?.meta?.total}</label>
                            </PaginateInfor>
                        </Paginate>} */}
            </Container>
        </Modal >
    )
}

export default ModalListReviews
