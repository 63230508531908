import styled from "styled-components";


export const Container = styled.div`
    background-color: white ;
    display: flex ;
    //height: 300px;
    width: calc(50% - 17.5px) ;
    border-radius: 10px ;
    padding: 0px ;
    flex-direction: column ;
    border: 1px solid #f2f2f2;

    @media screen and (max-width: 1200px) {
        width: 100% ;
    }

    @media screen and (max-width: 768px) {
        //background-color: yellow ;
        width: calc(100% - 0px);
    }
`;

export const Title = styled.div`
    display: flex ;
    height: 74px ;
    align-items: center ;
    //color: green ;
    padding: 20px ;
    border-bottom: 1px solid #F1F1F1 ;
`

export const Contents = styled.div`
    display: flex ;
    //background-color:  #c1c1c1;
    padding: 20px ;
    height: 100% ;
    align-items: center ;
    justify-content: center ;
`

export const Infor = styled.div`
    display: flex ;

    span{
        //background-color: #FBF9F6 ;
        height: 150px ;
        width: 150px ;
        border-radius: 50%;
        display: flex ;
        align-items: center ;
        justify-content: center ;
        border: 1px solid #f1f1f1 ;
        background: conic-gradient(#F13F6C 0% ${props => props.red}, #f0ad4e 0% ${props => props.yellow} , #20D489 0% ${props => props.green}, #0275d8 0% 100%);

        label{
            background-color: white;
            width: 25px ;
            height: 25px ;
            border-radius:  50%;
            display: flex ;
            justify-content: center ;
            align-items: center ;
            font-size: 12px ;
        }
    }
`

export const Legends = styled.div`
    display: flex ;
    //background-color: yellow ;
    flex-direction: column ;
    flex: 1 ;
    align-items: center ;
    justify-content: space-evenly ;
    height: 100% ;
`

export const Item = styled.div`
    //background-color: blue ;
    display: flex ;
    align-items: center ;
    width: 100% ;
    margin-left: 150px ;

    @media screen and (max-width: 500px) {
        margin-left: 20px ;
    }

    span{
        background-color: ${props => props.backgroundLegend} ;
        width: 10px ;
        height: 10px ;
        border-radius: 50% ;
        margin-right: 10px ;
        @media screen and (max-width: 765px) {
            margin-bottom: 5px ;
        }
    }

    label{

        @media screen and (max-width: 765px) {
            font-size: 9px ;
            margin-bottom: 5px ;
        }
    }
`