
import React, { useState } from 'react'

import { Container } from './styled';
import imgBackground from "../../../../assets/imgs/preview-background.png";
import { getTranslation } from '../../../../translations';


const FormMenu = () => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    

    return (
        <Container img={imgBackground}>
            <label>{translation.thema.escolhaLado}</label>
        </Container>
    )
}

export default FormMenu