import { useSelector } from "react-redux";
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE, DELETE_CUSTOM } from "../../../../services/api";

export function* listOrders(action) {


    yield put({ type: "ORDERS_REQUEST", })
    const result = yield call(GET, { endpoint: `/order/pagination?perPage=${action.payload.perPage}&page=${action.payload.page}&userToken=${action.payload.user_token}&search=${action.payload.search}&ini_date=${action.payload?.iniDate}&fin_date=${action.payload?.finDate}` })
    if (result === false) {
        yield put({ type: "ORDERS_ERROR" })
    } else {
        yield put({ type: "ORDERS_SUCCESS", payload: result.data.data })
    }
}

export function* listOrderProducts(action) {
    yield put({ type: "ORDER_PRODUCTS_REQUEST", })
    //yield delay(5000)
    const result = yield call(GET, { endpoint: `/order/${action.payload}` })
    if (result === false) {
        yield put({ type: "ORDER_PRODUCTS_ERROR" })
    } else {
        yield put({ type: "ORDER_PRODUCTS_SUCCESS", payload: result.data.data })
    }
}

// //
export function* reSendWhatsOrders(action) {
    yield put({ type: "WHATS_REQUEST" });
    const result = yield call(POST, { endpoint: `/resendsak`, data: action.payload.order, });
    if (result === false) {
        yield put({ type: "WHATS_ERROR" });
    } else {
        yield put({ type: "WHATS_SUCCESS" });
    }
}

export function* reSendEmailOrders(action) {
    yield put({ type: "EMAIL_REQUEST" });
    const result = yield call(POST, { endpoint: `/resendemails`, data: action.payload.order, });
    if (result === false) {
        yield put({ type: "EMAIL_ERROR" });
    } else {
        yield put({ type: "EMAIL_SUCCESS" });
    }
}

export function* updateEmailOrders(action) {

    yield put({ type: "UPDATE_EMAIL_ORDERS_REQUEST" });
    const result = yield call(PUT, { endpoint: `/order`, data: action.payload });
    if (result === false) {
      yield put({ type: "UPDATE_EMAIL_ORDERS_ERROR" });
    } else {
      yield put({ type: "UPDATE_EMAIL_ORDERS_SUCCESS", payload: result.data });
      yield put({ type: "UPDATE_EMAIL_ORDERS_RESET", payload: [] });
    }
    
  
  }