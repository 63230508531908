import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemTable from '../item-table'
import Pagination from "../pagination";
import { ArrowPathIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { getTranslation } from '../../../../../translations';
import { ContainerLoading } from '../../../../reviews/pages/home-new/styled';
import LoadingLogoLilyNew from '../../../../../components/Loading-logo-lily-new'
import { BarFilters, BarFiltersLeft, Search } from '../../../../reviews/components/tailwind/table/styled';



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableCupons(props) {
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedCupons, setSelectedCupons] = useState([])
    const [dataCupons, setDataCupons] = useState([])
    const reducerDeleteSelectCupons = useSelector(state => state.reducerDeleteSelectCupons)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const dispatch = useDispatch()

    const cupons = useSelector(state => state.reducerCupons)
    const [page, setpage] = useState(1)

    useEffect(() => {
        console.log("cupons", cupons?.data?.data)
        cupons?.data?.data?.length > 0 && setDataCupons(cupons.data.data)
        cupons?.data?.data == undefined && setDataCupons([])
    }, [cupons])

    const handleClick = () => {
        if (checkbox.current) {
            checkbox.current.click();
        }
    };


    useLayoutEffect(() => {
        const isIndeterminate = selectedCupons.length > 0 && selectedCupons.length < dataCupons.length
        setChecked(dataCupons.length == 0 ? false : selectedCupons.length === dataCupons.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedCupons])

    function toggleAll() {
        setSelectedCupons(checked || indeterminate ? [] : dataCupons)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    const deleteAll = () => {
        const dataDelete = selectedCupons.map((item) => item.id)
        dispatch({ type: "SAGA_DELETE_SELECT_CUPONS", payload: { itens: dataDelete } })
        setTimeout(() => { handleClick() }, 100);
    }

    useEffect(() => {
        if (reducerDeleteSelectCupons.loading == false && reducerDeleteSelectCupons.error == false && reducerDeleteSelectCupons.data.length == 0) {
        } else {
            const id = "reducerDeleteSelectCupons"
            if (reducerDeleteSelectCupons.loading) {
                toast.loading(translation.reviews.aguarde, { toastId: id })
            } else {
                if (reducerDeleteSelectCupons.error) {
                    toast.update(id, { render: translation.reviews.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.reviews.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.goSearch()
                    //setTimeout(() => { handleClick() }, 1000);

                }
            }
        }
    }, [reducerDeleteSelectCupons])

    return (
        cupons.loading ?
            <ContainerLoading>
                <LoadingLogoLilyNew />
            </ContainerLoading> :
            <>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{translation.sidebar.cupons}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.cupom}
                            </p>
                        </div>
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            {/* <button
                            onClick={() => props.setVisibleModal(true)}
                            type="button"
                            className="block rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Adicionar
                        </button> */}
                            <button
                                onClick={() => props.setVisibleModal(true)}
                                type="button"
                                className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                {translation.title.adicionar}
                            </button>
                        </div>
                    </div>
                    <BarFilters style={{ paddingRight: "20px" }} >
                        <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <BarFiltersLeft>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && props.goSearch() }} type='text' placeholder={"Pesquisar por cupom"} value={props.search} onChange={(e) => { props.setSearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>
                        </span>
                    </BarFilters>
                    <div className="mt-0 flow-root">
                        <div id={"table-cupons-scroll"} className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {selectedCupons.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            <button
                                                onClick={() => { deleteAll() }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? translation.reviews.excluir : "Excluír selecionados"}
                                            </button>
                                        </div>
                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={toggleAll}
                                                    />
                                                </th>
                                                <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {translation.cupons.card.expira}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.sidebar.cupons}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.cupons.card.mensagem}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", justifyContent: "center" }}>{translation.cupons.card.ativo}</label>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">

                                            {dataCupons.map((item, index) => {
                                                return (
                                                    <ItemTable index={index} classNames={classNames} setSelectedCupons={setSelectedCupons} selectedCupons={selectedCupons} item={item} />
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination setPage={setpage} />
            </>
    )
}
