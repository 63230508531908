import styled from "styled-components";


export const Container = styled.form`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    //padding: 25px;
    border-radius: 5px;
    overflow: auto;
    //height: calc(500%) !important;
    margin-left: 0px ;
    flex:1 ;
    align-items: center ;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
    background-size: 60%;
    justify-content: center;

    @media screen and (max-width: 450px) {
        width: 300px;
    }
`;



export const BoxInfor = styled.div`
    display: flex;
    background-color: #11668822;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;

    label{
        display: flex;
        text-align: center;
    }
`

export const Close = styled.span`
    display: flex;
    position: absolute;
    right: -25px;
    top: 5px;
    margin-right: 25px;
    cursor: pointer;
    z-index: 9999;
    //background-color: red;
    z-index: 999999999999;
`;

export const Title = styled.div`
    display: flex;
    position: relative;
    color: #5e6278;
    margin-bottom: 10px;
`;

export const Carrossel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    span{
        display: flex;
        //background-color: red;
        align-items: center;
        justify-content: space-between;

        img{
            margin-right: 10px;
        }
        
    }

`

export const ItemActive = styled.div`
    display: flex;
    width: 30px;
    height: 20px;
    background-color: ${props => props.active ? "#00803B" : "gray"};
    align-items: center;
    padding: 0px 5px;
    border-radius: 5px;
    justify-content: ${props => props.active ? "right" : "left"};
    cursor: pointer;

    span{
        display: flex;
        height: 10px;
        width: 10px;
        background-color: white;
        border-radius: 2px;
    }
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
    padding: 10px;
    border-radius: 8px;
    gap: 10px;
`
export const ListItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #fff;
    padding: 10px;

    opacity:  ${props => props.opacity ? "0.6" : "1"} ;
    animation: ${props => props.active ? "animateCard 2s infinite alternate" : "initial"} ;

    @keyframes animateCard {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.1);
        }
    }
`

export const TitleList = styled.div`
    display: flex;
    gap: 10px;
`

export const TitleCircle = styled.div`
    display: flex;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background-color: #f3f3f3;
    border: 1px solid #dcdcdc;
`

export const TitleInfor = styled.div`
    display: flex;
    flex-direction: column;
    p{
        margin: 0px;
        padding: 0px;
        font-size: 12px;
        font-weight: 500;
        color: #808080;
    }
    label{
        font-size: 9px;
    }
`

export const BodyItem = styled.div`
    display: flex;
    flex-direction: column;

    p{
        margin: 0px;
        padding: 0px;
        font-size: 12px;
        font-weight: 500;
        color: #1a0dab;
    }
    label{
        font-size: 9px;
        word-break: break-word;
    }
`

export const ItemReview = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`