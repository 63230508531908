import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { POST_ERROR } from '../../../../services/api';
import { getTranslation } from '../../../../translations';
import { formatTimestamp } from '../../../../utils/date';
import { Container } from './styled'

const cores_protesto = ['', '#ffebeb', '#ebebeb', '#ebebeb'];

const TablePagamentos = ({ faturasInitialValue }) => {
    const tableElement = useRef(null)
    const container = useRef(null)

    useEffect(() => {
        if (tableElement.current.hasChildNodes()) {
            container.current.style.minHeight = '-webkit-fill-available'
        } else {
            container.current.style.minHeight = '150px'
        }
    }, [])

    const dispatch = useDispatch();
    const history = useHistory();
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    function acoesFatura(fatura) {

        if (fatura.protest === 0) {
            return (
                <>
                    <td>
                        <span>{translation.assinatura.tablePagamentos.pago}</span>
                    </td>
                    <td>-</td>
                </>
            );
        }

        if (fatura.protest === 1) {

            async function repetirCobranca(transaction_id) {
                const id = 0
                toast.loading(translation.assinatura.tablePagamentos.aguarde, { toastId: id });
                let { data } = await POST_ERROR({
                    endpoint: "/payments/regularize-protest",
                    data: { transacao: transaction_id }
                });

                toast.update(id, { render: data.description ? data.description : (data.error ? translation.assinatura.tablePagamentos.erroInesperado : 'Sucesso'), type: data.error ? "error" : "success", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });

                if (data?.redirect) {
                    let delay = data?.redirect?.delay ? data.redirect.delay : 50;
                    setTimeout(() => {
                        if (data?.redirect.to === 'self') {
                            dispatch({
                                type: 'SAGA_LIST_PAYMENTS'
                            })
                        } else {
                            history.push(data.redirect.to);
                        }
                    }, delay)
                }
            }

            return (
                <>
                    <td>
                        <span>
                            {translation.assinatura.tablePagamentos.protestado}
                        </span>
                    </td>
                    <td>
                        <button onClick={() => { repetirCobranca(fatura.transaction_id) }} type="button">
                            {translation.assinatura.tablePagamentos.repetirCobranca}
                        </button>
                    </td>
                </>
            );
        }

        if (fatura.protest === 2) {
            return (
                <>
                    <td>
                        <span>{translation.assinatura.tablePagamentos.faturaProtesto}</span>
                    </td>
                    <td>-</td>
                </>
            );
        }

        if (fatura.protest === 3) {
            return (
                <>
                    <td>
                        <span>{translation.assinatura.tablePagamentos.pagamentoEstornado}</span>
                    </td>
                    <td>-</td>
                </>
            );
        }

    }

    return (
        <Container ref={container}>
            <h3>{translation.assinatura.tablePagamentos.pagamentos}</h3>
            <table>
                <thead>
                    <tr>
                        <th>{translation.assinatura.tablePagamentos.data}</th>
                        <th>{translation.assinatura.tablePagamentos.planoContratado}</th>
                        <th>{translation.assinatura.tablePagamentos.total}</th>
                        <th>{translation.assinatura.tablePagamentos.desconto}</th>
                        <th>{translation.assinatura.tablePagamentos.validade}</th>
                        <th>{translation.assinatura.tablePagamentos.quantidade}</th>
                        <th>{translation.assinatura.tablePagamentos.notaFiscal}</th>
                        <th>{translation.assinatura.tablePagamentos.status}</th>
                        <th>{translation.assinatura.tablePagamentos.acoes}</th>
                    </tr>
                </thead>
                <tbody ref={tableElement}>
                    {faturasInitialValue.map((fatura, index) => (
                        <tr key={index} style={cores_protesto[fatura.protest] !== '' ? { backgroundColor: cores_protesto[fatura.protest] } : {}}>
                            <td>{fatura.date ? formatTimestamp(fatura.date, "dd/MM/yyyy") : '-'}</td>
                            <td>{fatura.description}</td>
                            <td>{fatura.value}</td>
                            <td>{fatura.discount}</td>
                            <td>{fatura.months} {translation.assinatura.tablePagamentos.mes}</td>
                            <td>{fatura.quantity}</td>
                            <td>
                                {fatura?.invoice_url ?
                                    <a rel="noreferrer" href={fatura.invoice_url} target='_blank'>{translation.assinatura.tablePagamentos.download}</a>
                                    :
                                    <span>{translation.assinatura.tablePagamentos.aguarde2}</span>
                                }
                            </td>

                            {acoesFatura(fatura)}

                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    );
}

export default TablePagamentos