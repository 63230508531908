import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemTable from '../item-table'
import Pagination from "../pagination";
import { getTranslation } from '../../../../../translations';
import LoadingLogoLilyNew from '../../../../../components/Loading-logo-lily-new';
import { ContainerLoading } from '../../../../reviews/pages/home-new/styled';
import { BarFilters, BarFiltersIcon, BarFiltersLeft, Search } from '../../../../reviews/components/tailwind/table/styled';
import { CodeBracketIcon, MagnifyingGlassIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import ButtonSinc from '../../button-sinc';
import { toast } from 'react-toastify';
import Navbar from '../navbar';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableProducts(props) {
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const dispatch = useDispatch()
    //const [page, setpage] = useState(1)
    //const [perPage, setperPage] = useState(10)
    //const [search, setSearch] = useState("")

    const [dataProducts, setDataProducts] = useState([])

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const statusDeleteSelectProducts = useSelector(state => state.reducerDeleteSelectProducts)
    const products = useSelector(state => state.reducerProducts)

    const statusUpdateProduct = useSelector(state => state.reducerUpdateProduct)
    const statusDeleteProduct = useSelector(state => state.reducerDeleteProduct)
    const reducerSincSelectedProduct = useSelector(state => state.reducerSincSelectedProduct)

    useEffect(() => {
        console.log("products", products?.data?.data)
        products?.data?.data?.length > 0 && setDataProducts(products.data.data)
        products?.data?.data == undefined && setDataProducts([])
    }, [products])

    useLayoutEffect(() => {
        const isIndeterminate = selectedProducts.length > 0 && selectedProducts.length < dataProducts.length
        setChecked(dataProducts.length == 0 ? false : selectedProducts.length === dataProducts.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedProducts])

    const handleClick = () => {
        if (checkbox.current) {
            checkbox.current.click();
        }
    };

    function toggleAll() {
        setSelectedProducts(checked || indeterminate ? [] : dataProducts)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    // const goSearch = () => {
    // props.goSearch()
    // dispatch({
    //     type: "SAGA_LIST_PRODUCTS", payload: {
    //         search: search,
    //         perPage: perPage,
    //         page: page
    //     }
    // })
    //}

    //useEffect(() => { goSearch() }, [page,])

    const sincMulti = () => {
        const dataId = selectedProducts.map((item) => (item.id))
        dispatch({
            type: "SAGA_SEARCH_PLATFORM_SELECTED_PRODUCTS", payload: {
                data: dataId,
                platform: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform
            }
        })
        setTimeout(() => { handleClick() }, 100);
    }

    const deleteAll = () => {
        console.log("selectedProducts", selectedProducts)
        dispatch({
            type: "SAGA_DELETE_SELECT_PRODUCTS",
            payload: {
                itens: selectedProducts.map((item) => (item.id))
            }
        })
        setTimeout(() => { handleClick() }, 100);

    }

    useEffect(() => {
        if (!statusDeleteSelectProducts.loading && !statusDeleteSelectProducts.error && statusDeleteSelectProducts.data.length == 0) {
        } else {
            const id = "statusDeleteSelectReviews"
            if (statusDeleteSelectProducts.loading) {
                toast.loading(translation.products.aguarde, { toastId: id })
            } else {
                if (statusDeleteSelectProducts.error) {
                    toast.update(id, { render: translation.products.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.products.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.goSearch()
                }
            }
        }
    }, [statusDeleteSelectProducts])

    useEffect(() => {
        if (statusUpdateProduct.loading == false && statusUpdateProduct.error == false && statusUpdateProduct.data.length == 0) {
        } else {
            const id = "statusUpdateProduct"
            if (statusUpdateProduct.loading) {
                toast.loading(translation.products.card.aguarde, { toastId: id })
            } else {
                if (statusUpdateProduct.error) toast.update(id, { render: translation.products.card.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else toast.update(id, { render: translation.products.card.atualizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
            }
        }
    }, [statusUpdateProduct])

    useEffect(() => {
        if (statusDeleteProduct.loading == false && statusDeleteProduct.error == false && statusDeleteProduct.data.length == 0) {
        } else {
            const id = "statusDeleteProduct"
            if (statusDeleteProduct.loading) {
                toast.loading(translation.products.card.aguarde, { toastId: id })
            } else {
                if (statusDeleteProduct.error) toast.update(id, { render: translation.products.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else toast.update(id, { render: translation.products.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
            }
        }
    }, [statusDeleteProduct])

    useEffect(() => {
        if (reducerSincSelectedProduct.loading == false && reducerSincSelectedProduct.error == false && reducerSincSelectedProduct.data.length == 0) {
        } else {
            const id = "reducerSincSelectedProduct"
            if (reducerSincSelectedProduct.loading) {
                toast.loading(translation.products.card.aguarde, { toastId: id })
            } else {
                if (reducerSincSelectedProduct.error) toast.update(id, { render: translation.products.card.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.products.card.atualizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.goSearch()

                }
            }
        }
    }, [reducerSincSelectedProduct])

    useEffect(() => {
        console.log("props table", props)
    }, [props])

    const changePage = (page) => {
        console.log("changePage: ", page)
        props.setPage(page)
    }

    return (
        products.loading ?
            <ContainerLoading>
                <LoadingLogoLilyNew />
            </ContainerLoading> :
            <>


                <div className="px-4 sm:px-6 lg:px-8">

                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{translation.sidebar.meusProdutos}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.produtos}
                            </p>
                        </div>
                        {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <button
                                    onClick={() => { props.setVisibleModalAdd(true) }}
                                    type="button"
                                    className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                    {translation.products.cadastrar}
                                </button>
                            </div> : <></>
                        }
                    </div>




                    <BarFilters style={{ paddingRight: "20px" }}>
                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                            <BarFiltersLeft style={{ marginRight: "20px" }}>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && props.goSearch() }} type='text' placeholder={translation.products.pesquisarProduto} value={props.search} onChange={(e) => { props.setSearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>
                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                                <button
                                    onClick={() => { props.setVisibleModalCodeHome(true) }}
                                    type="button"
                                    className="inline-flex items-center gap-x-1.5 rounded-md bg-green-50 px-2.5 py-1.5 text-sm font-semibold text-green-700 hover:bg-green-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-100"
                                >
                                    <CodeBracketIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                    Homepage
                                </button> :
                                <ButtonSinc />}
                        </span>
                    </BarFilters>

                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {selectedProducts.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ? <></> :
                                                <button
                                                    onClick={() => { sincMulti() }}
                                                    type="button"
                                                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                                >
                                                    {"Sincronizar"}
                                                </button>}

                                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                                                <button
                                                    onClick={() => { deleteAll() }}
                                                    type="button"
                                                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                                >
                                                    {translation.avaliacoes.excluir}
                                                </button> : <></>}
                                        </div>
                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={toggleAll}
                                                    />
                                                </th>
                                                <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.data}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.produto}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {translation.sidebar.avaliacoes}
                                                    </label>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {translation.sidebar.perguntas}
                                                    </label>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {dataProducts?.map((item) => (
                                                <ItemTable
                                                    visibleModalImport={props.visibleModalImport}
                                                    setVisibleModalImport={props.setVisibleModalImport}
                                                    setImportShopeeProductSelected={props.setImportShopeeProductSelected}
                                                    setControlUpdate={props.setControlUpdate}
                                                    goSearch={props.goSearch}
                                                    classNames={classNames}
                                                    setSelectedProducts={setSelectedProducts}
                                                    selectedProducts={selectedProducts}
                                                    item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination setPage={changePage} />
            </>
    )
}
