import React from 'react'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../translations'
import { Container, Contents, Infor, Item, Legends, Title } from './styled'

const TotalReviews = () => {

    const dashboard = useSelector(state => state.reducerDashboard)



    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language)

    return (
        <Container>
            <Title>{translation.dashboard.totalReview.avaliacoes}</Title>
            <Contents>
                <Infor
                    red={`${Math.round(dashboard.data?.newReview / dashboard.data?.totalReview * 100)}%`}
                    yellow={`${Math.round(dashboard.data?.pendingReview / dashboard.data?.totalReview * 100) + Math.round(dashboard.data?.newReview / dashboard.data?.totalReview * 100)}%`}
                    green={`${Math.round(dashboard.data?.pendingReview / dashboard.data?.totalReview * 100) + Math.round(dashboard.data?.newReview / dashboard.data?.totalReview * 100) + Math.round(dashboard.data?.approvedReview / dashboard.data?.totalReview * 100)}%`}
                >
                    <span>
                        <label> <strong>{dashboard.data?.totalReview}</strong></label>
                    </span>
                </Infor>
                <Legends>
                    <Item backgroundLegend={"#F13F6C"}>
                        <span />
                        <label>{translation.dashboard.totalReview.novos} <strong>{dashboard.data?.newReview}</strong></label>
                    </Item>
                    <Item backgroundLegend={"#f0ad4e"}>
                        <span />
                        <label>{translation.dashboard.totalReview.pendentes} <strong>{dashboard.data?.pendingReview}</strong></label>
                    </Item>
                    <Item backgroundLegend={"#20D489"}>
                        <span />
                        <label>{translation.dashboard.totalReview.aprovados} <strong>{dashboard.data?.approvedReview}</strong></label>
                    </Item>
                    <Item backgroundLegend={"#0275d8"}>
                        <span />
                        <label>{translation.dashboard.totalReview.escondidos} <strong>{dashboard.data?.hiddenReview}</strong></label>
                    </Item>
                </Legends>
            </Contents>
        </Container>
    )
}

export default TotalReviews