
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE, PUT_CUSTOM, POST_CUSTOM } from "../../../../services/api";



// export function* updateConfig(action) {
//     yield put({ type: "CONFIG_UPDATE_REQUEST", })
//     //yield delay(200);
//     const result = yield call(PUT, { endpoint: `/configurations/${action.payload.id}`, data: action.payload })

//     if (result === false) {
//         yield put({ type: "CONFIG_UPDATE_ERROR" })
//     } else {
//         yield put({ type: "CONFIG_UPDATE_SUCCESS", payload: result.data })
//     }
// }

export function* updateConfig(action) {


    yield put({ type: "CONFIG_UPDATE_REQUEST", })
    //yield delay(5000);
    const result = yield call(PUT_CUSTOM, { endpoint: `/configurations/${action.payload.id}`, data: action.payload })

    if (result === false) {
        yield put({ type: "CONFIG_UPDATE_ERROR" })
    } else {
        yield put({ type: "CONFIG_UPDATE_SUCCESS", payload: result.data })

    }
}

export function* updateConfigHome(action) {

    yield put({ type: "CONFIG_UPDATE_HOME_REQUEST", })
    //yield delay(5000);
    const result = yield call(PUT, { endpoint: `/configurations/editor/${action.payload.id}`, data: action.payload })

    if (result === false) {
        yield put({ type: "CONFIG_UPDATE_HOME_ERROR" })
    } else {
        yield put({ type: "CONFIG_UPDATE_HOME_SUCCESS", payload: result.data })

        const result2 = yield call(GET, { endpoint: `/configurations` })
        if (result2 === false) {
            yield put({ type: "CONFIG_GET_ERROR" })
        } else {
            yield put({ type: "CONFIG_GET_SUCCESS", payload: result2.data.data })
        }
    }
}

export function* updateConfigMural(action) {



    yield put({ type: "CONFIG_UPDATE_MURAL_REQUEST", })

    const result = yield call(PUT, { endpoint: `/murals/${action.payload.id}`, data: action.payload })
    if (result === false) {
        yield put({ type: "CONFIG_UPDATE_MURAL_ERROR" })
    } else {
        yield put({ type: "CONFIG_UPDATE_MURAL_SUCCESS", payload: result.data })

        // yield put({ type: "CONFIG_GET_MURAL_REQUEST", })
        // const result2 = yield call(GET, { endpoint: `/murals` })
        // if (result2 === false) {
        //     yield put({ type: "CONFIG_GET_MURAL_ERROR" })
        // } else {
        //     yield put({ type: "CONFIG_GET_MURAL_SUCCESS", payload: result2.data.data })
        //     yield put({ type: "CONFIG_CONTROL_MURAL_SET", payload: JSON.parse(result2.data.data[0].data_config) })
        // }
    }
}

export function* updateConfigHomePosition(action) {


    yield put({ type: "CONFIG_UPDATE_HOME_POSITION_REQUEST", })
    //yield delay(5000);
    const result = yield call(PUT, { endpoint: `/configurations/xpath/home`, data: action.payload })
    if (result === false) {
        yield put({ type: "CONFIG_UPDATE_HOME_POSITION_ERROR" })
    } else {
        yield put({ type: "CONFIG_UPDATE_HOME_POSITION_SUCCESS", payload: result.data })
    }
}

export function* scriptIntegration(action) {

    yield put({ type: "SCRIPT_INTEGRATION_REQUEST", })
    //yield delay(5000);
    const result = yield call(PUT, { endpoint: `/integrations/xpath/${action.payload.location}`, data: { xpath: action.payload.xpath } })
    if (result === false) {
        yield put({ type: "SCRIPT_INTEGRATION_ERROR" })
    } else {
        yield put({ type: "SCRIPT_INTEGRATION_SUCCESS", payload: result.data })
    }
}

export function* getConfig(action) {

    yield put({ type: "CONFIG_GET_REQUEST", })
    //yield delay(5000)
    const result = yield call(GET, { endpoint: `/configurations` })
    if (result === false) {
        yield put({ type: "CONFIG_GET_ERROR" })
    } else {
        yield put({ type: "CONFIG_GET_SUCCESS", payload: result.data.data })

        yield put({ type: "CONFIG_CONTROL_SET", payload: result.data.data })

        yield put({ type: "CONFIG_CONTROL_SAVE_HOME_COLOR_SET", payload: true })

        yield put({ type: "CONFIG_CONTROL_SAVE_HOME_CONFIG_SET", payload: true })

        yield put({ type: "CONFIG_CONTROL_SAVE_EMAIL_COLOR_SET", payload: true })

        yield put({ type: "CONFIG_CONTROL_SAVE_EMAIL_CONFIG_SET", payload: true })

        yield put({ type: "CONFIG_CONTROL_SAVE_PRODUCT_COLOR_SET", payload: true })

        yield put({ type: "CONFIG_CONTROL_SAVE_PRODUCT_CONFIG_SET", payload: true })
    }
}

export function* getConfigMural(action) {

    yield put({ type: "CONFIG_GET_MURAL_REQUEST", })
    //yield delay(5000)
    const result = yield call(GET, { endpoint: `/murals` })
    if (result === false) {
        yield put({ type: "CONFIG_GET_MURAL_ERROR" })
    } else {
        yield put({ type: "CONFIG_GET_MURAL_SUCCESS", payload: result.data.data })
        yield put({ type: "CONFIG_CONTROL_MURAL_SET", payload: JSON.parse(result.data.data[0].data_config) })
    }
}

export function* sendEmail(action) {

    if (typeof action.payload.email_logourl != "string") {
        action.payload.image = action.payload.email_logourl
        action.payload.email_logourl = ""
    }

    yield put({ type: "SEND_EMAIL_REQUEST", })
    yield delay(200);
    const result = yield call(POST_CUSTOM, { endpoint: `/emailpreview`, data: action.payload })

    if (result === false) {
        yield put({ type: "SEND_EMAIL_ERROR" })
    } else {
        yield put({ type: "SEND_EMAIL_SUCCESS", payload: result.data })
    }
}

export function* changeStatusGoogle(action) {
    yield put({ type: "CHANGE_STATUS_GOOGLE_REQUEST" });
    //yield delay(2000)
    const result = yield call(PUT, { endpoint: `integrations/scriptgoogle`, data: action.payload });
    if (result == false) {
        yield put({ type: "CHANGE_STATUS_GOOGLE_ERROR" });
    } else {
        yield put({ type: "CHANGE_STATUS_GOOGLE_SUCCESS", payload: result.data });
    }
}