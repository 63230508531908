import logo from "../../assets/animation/lily-loading-100x100.gif";
import React from 'react'
import {ContainerLoading, Loader01 } from './styled'

const LoadingLogoLilyNew = () => {
    return (
        <ContainerLoading>
            <Loader01 />
            <img src={logo} />
            <span />
        </ContainerLoading>
    )
}

export default LoadingLogoLilyNew