import { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { BadgeCheck, Container, Description, SectionLeft, SectionRigth, Stars, Title, } from './styled'
import { HiBadgeCheck } from "react-icons/hi";
import { getTranslation } from "../../../../translations";
import ImgCard from "../../../../assets/imgs/icon-sapato.svg";

const Card02LilyCarousel = (props) => {

    const [icon, setIcon] = useState({ size: 20, color: "orange" })
    const config = useSelector(state => state.reducerControlConfig)

    useEffect(() => {
        setIcon({ ...icon, colorActive: config?.homereviews_color_star_card, colorInative: "#dcdcdc" })
    }, [config])


    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Container
            background={config.homereviews_background_color_card}
            colorBorder={config?.homereviews_color_border_card}
            sizeBorder={config?.homereviews_size_border_card}
            radiusBorder={config?.homereviews_radius_border_card}
        >
            <SectionLeft>
                <img src={ImgCard} />
            </SectionLeft>
            <SectionRigth>
                <Title fontColor={config.homereviews_font_color_card}>
                    <label>
                        Fulano da Silva
                        {config.homereviews_visible_verified_card == "1" &&
                            <BadgeCheck>
                                {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={15} color={config?.homereviews_color_verified_card} />}
                                <label>{translation.thema.cardReviewLilyPreview.compraVerificada}</label>
                            </BadgeCheck>}
                        <span>
                            {config.visible_date_review == "1" && translation.thema.cardReviewLilyPreview.haMinutos}
                        </span>
                    </label>
                </Title>
                <Description fontColor={config.homereviews_font_color_card}>
                    <label>{props.item.text}</label>
                </Description>
                <Stars position={config.position_star_review}>
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? icon.colorActive : icon.colorInative} />
                </Stars>
            </SectionRigth>
        </Container>
    )
}

export default Card02LilyCarousel