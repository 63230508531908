import styled from "styled-components";

export const Container = styled.div`
    background-color: white ;
    display: flex ;
    //height: 450px;
    width: calc(50% - 17.5px) ;
    border-radius: 10px ;
    //padding: 20px ;
    flex-direction: column ;
    border: 1px solid #f2f2f2;

    @media screen and (max-width: 1200px) {
        width: 100% ;
    }

`
export const Contents = styled.div`
    display: flex ;
    flex-direction: column ;
`
export const Row = styled.div`
    display: flex ;
    height: 39px ;
    //background-color: #F1F1F1 ;
    border-top: 1px solid #F1F1F1 ;
    align-items: center ;
    padding: 0px 20px ;
    font-size: 12px ;

    label{
        margin-left: 20px ;
        cursor: pointer;
        display: flex ;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        //background-color: red ;
        max-width: 50ch;

        :hover{
            text-decoration: underline ;
            strong{
                text-decoration: none ;
            }
        }

        strong{
            display: flex ;
            flex: 1 ;
            //background-color: red ;
            justify-content: flex-end ;
            text-decoration: none !important ;
        }
    }

    :nth-child(10){
        border-bottom: 1px solid #F1F1F1 ;
        height: 48px ;
    }
    strong:nth-child(2){
        //background-color: red ;
        width: 60px;
        margin-left: 20px ;
        text-align: end ;
    }
`

export const Title = styled.div`
    display: flex ;
    height: 74px ;
    align-items: center ;
    //color: green ;
    padding: 0px 20px ;
    //border-bottom: 1px solid gray ;
`



export const RowCustom = styled.div`
    display: flex ;
    height: 39px ;
    //background-color: #F1F1F1 ;
    border-top: 1px solid #F1F1F1 ;
    align-items: center ;
    padding: 0px 20px ;
    font-size: 12px ;

    label{
        margin-left: 20px ;
        display: flex ;
        flex: 1;
        

        
        strong{
            display: flex ;
            flex: 1 ;
            //background-color: red ;
            justify-content: flex-end ;
            text-decoration: none !important ;
        }
    }

    :nth-child(10){
        border-bottom: 1px solid #F1F1F1 ;
        height: 48px ;
    }

    label:nth-child(1){
        //background-color: red;
        flex: 1 ;
        //max-width: 45ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    strong:nth-child(2){
        //background-color: yellow;
        min-width: 60px ;
        margin-left: 20px
    }

    label:nth-child(2){
        //background-color: yellow;
        max-width: 60px ;
    }

`