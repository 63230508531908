import { useEffect } from "react";
import LoadingLogo from "../../../../components/Loading-logo";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getTranslation } from "../../../../translations";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";

const PositionHomeScript = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory()
  const dispatch = useDispatch()
  const statusUpdateConfig = useSelector(state => state.reducerUpdateConfigHomePosition)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


  useEffect(() => {

    if (urlParams.get("xpath_homereviews")) {
      dispatch({ type: "SAGA_UPDATE_CONFIG_HOME_POSITION", payload: { xpath_homereviews: urlParams.get("xpath_homereviews") } })
    } else {
      toast.error("problemas")
      //history.push("/personalizar/home")
    }
  }, [])



  useEffect(() => {
    if (statusUpdateConfig.loading == false && statusUpdateConfig.error == false && statusUpdateConfig.data.length == 0) {
    } else {
      if (statusUpdateConfig.loading == false) {
        if (statusUpdateConfig.error) {
          toast.error("Error")
          history.push("/personalizar/home")
        }
        else {
          toast.success("Posicionado")
          history.push("/personalizar/home")
        }
      }

    }
    //         const id = "statusUpdateConfig"
    //         if (!(statusUpdateConfig.loading == false && !statusUpdateConfig.error == false && statusUpdateConfig.data.length == 0)) {
    //             if (statusUpdateConfig.loading) {
    //                 toast.loading(translation.thema.aguarde, { toastId: id })
    //             } else {
    //                 if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //                 else {
    //                     toast.update(id, { render: translation.thema.podeLevar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
    //                     history.push("/personalizar/home")
    //                 }
    //             }
    //         } else if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
  }, [statusUpdateConfig])


  return (
    <div style={{ display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <LoadingLogoLily />
    </div>
  )
}

export default PositionHomeScript