import { Avatar, Box, Container, IconType, Stars, Text, Title, } from "./styled"
import { getTranslation } from "../../../../translations";
import { useSelector } from "react-redux";
import { AiFillStar } from "react-icons/ai";

const CardMural02 = (props) => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const configControl = useSelector(state => state.reducerControlConfigMural)

    const getIconType = () => {
        switch ("google") {
            case "google":
                return (
                    <IconType>
                        <img src={"https://imagepng.org/wp-content/uploads/2019/08/google-icon-1.png"} />
                    </IconType>
                )

            default:
                return null
        }
    }

    return (
        <Container>
            <Box
                backgrounColor={configControl.mural_background_color_card}
                borderColor={configControl.mural_border_color_card}
                borderSize={configControl.mural_border_size_card}
                borderRadius={configControl.mural_border_radius_card}
            >

                {getIconType()}
                <Avatar backgrounColor={configControl.mural_background_color_card}>
                    <img src={props.item.img} />
                </Avatar>
                <Title>
                    <label style={{fontFamily: `${configControl.mural_title_font_widget}`,color: `${configControl.mural_font_color_card}`}}>{props.item.name}</label>
                    <span style={{fontFamily: `${configControl.mural_title_font_widget}`,fontSize: "12px", color: `${configControl.mural_font_color_card}88`}}>01/01/2024</span>
                </Title>
                <Text style={{fontFamily: `${configControl.mural_title_font_widget}`,color: `${configControl.mural_font_color_card}`}}>
                    {props.item.msg}
                </Text>
                <Stars>
                    <AiFillStar size={15} color={"orange"} />
                    <AiFillStar size={15} color={4 > 1 ? "orange" : "gray"} />
                    <AiFillStar size={15} color={4 > 2 ? "orange" : "gray"} />
                    <AiFillStar size={15} color={4 > 3 ? "orange" : "gray"} />
                    <AiFillStar size={15} color={5 > 4 ? "orange" : "gray"} />
                </Stars>
            </Box>

        </Container >
    )
}

export default CardMural02
