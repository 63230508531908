import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    margin: 0px 0px;
    background-color: transparent;
    min-width: 300px;
    break-inside: avoid;
    margin-bottom: 20px;
`;

export const Box = styled.label`
    display: flex ;
    background-color: yellow;
    position: relative;
    gap: 20px;
    min-width: 300px;
    width: 100%;
    background-color: ${props => props.backgrounColor};
    border: ${props => props.borderSize} solid ${props => props.borderColor};
    border-radius: ${props => props.borderRadius};
    break-inside: avoid;
    flex-direction: column; 
    margin-top: 55px;
    padding: 70px 20px 20px 20px;
`;

export const Title = styled.label`
    display: flex ;
    text-align: center;
    justify-content: center;
    flex-direction: column;

    label{
        font-size: 16px;
    }

    span{
        color: #80808088;
        font-size: 12px
    }
`;

export const Avatar = styled.div`
    display: flex ;
    //background-color: ${props => props.backgrounColor};
    //border: 1px solid orange;
    border-radius: 50%;
    height: 110px;
    width: 110px;
    
    position: absolute;
    top: -55px;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    left: calc(50% - 55px);

    img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
    }
`;

export const Stars = styled.div`
    display: flex ;
    justify-content: center;
    
`;
export const Text = styled.label`
    display: flex ;
    text-align: center;
    justify-content: center;
    font-size: 12px;
    width: 100%;
`;


export const IconType = styled.div`
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;

    img{
        width: 20px;
        height: 20px;
        object-fit: cover;
    }

`