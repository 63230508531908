
import { useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { parse } from "date-fns";
import { Container, ItemOption, Options, Title, UlCustom } from "./styled";
import LilyImg from "../../../../assets/imgs/Girrafe.svg";

import "./style.css";

const Code = () => {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()
    const route = useRouteMatch()

    const controlIntegrationSteps = useSelector(state => state.reducerControlIntegrationSteps)

    useEffect(() => {
        console.log("route", route)
    }, [route])




    useEffect(() => {

        //if (route.params.type == "mercadoshop") return null


        console.log("location :: ", location)

        let shop = location.search.substring(location.search.indexOf("shop=") + 5)
        let url = location.search.substring(location.search.indexOf("url=") + 4)
        let clientId = location.search.substring(location.search.indexOf("client_id=") + 10).split("&")[0]
        let clientSecret = location.search.substring(location.search.indexOf("client_secret=") + 14).split("&")[0]
        let basic = location.search.substring(location.search.indexOf("basic=") + 6).split("&")[0]
        let store = location.search.substring(location.search.indexOf("store=") + 6).split("&")[0]
        let token = location.search.substring(location.search.indexOf("token=") + 6).split("&")[0]
        let chaveLojaIntegrada = location.search.substring(location.search.indexOf("chaveLojaIntegrada=") + 19).split("&")[0]
        let tokenCartpanda = location.search.substring(location.search.indexOf("tokenCartpanda=") + 15).split("&")[0]

        let state = location.search.substring(location.search.indexOf("state=") + 6).split("&")[0]
        let instanceId = location.search.substring(location.search.indexOf("instanceId=") + 11).split("&")[0]
        let code = location.search.substring(location.search.indexOf("code=") + 5).split("&")[0]

        const getLinkMercadolivre = () => {
            switch (localStorage.getItem("depoimentos@paismercadoshop") ? localStorage.getItem("depoimentos@paismercadoshop") : JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country) {
                case "BR":
                    return `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
                case "AR":
                    return `https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
                case "MX":
                    return `https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
                case "CL":
                    return `https://auth.mercadolibre.cl/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
                case "CO":
                    return `https://auth.mercadolibre.co/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
                default:
                    return `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`

            }
        }

        const urlParams = new URLSearchParams(window.location.search);

        if (route.params.type == "wix") {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.more_user_data == instanceId) {
                dispatch({ type: "CONTROL_PLATFORM_SUCCESS", payload: { typeIntegration: "error", } })
                toast.info("Loja já integrada.")
                history.push('/integracoes');
            } else {
                localStorage.setItem("wix@integration", JSON.stringify({ instanceId: instanceId, code: code }))
                dispatch({
                    type: "CONTROL_PLATFORM_SUCCESS", payload: {
                        typeIntegration: route.params.type,
                        instanceId: instanceId,
                        codeWix: code
                    }
                })
                //alert("teste:", JSON.parse(localStorage.getItem("isIntegrationSteps")))
                if (JSON.parse(localStorage.getItem("isIntegrationSteps")) == true) {
                    localStorage.setItem("isIntegrationSteps", false);
                    history.push('/passo-a-passo');
                }
                else history.push('/integracoes');
            }

            // if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.more_user_data == instanceId) {
            //     toast.info("Loja já integrada.")
            //     dispatch({ type: "CONTROL_PLATFORM_RESET" })
            //     history.push('/integracoes');
            // } else {
            //     localStorage.setItem("wix@integration", { instanceId: instanceId, code: code })
            // }

        }
        //  else if (route.params.type == "mercadoshop") {

        //     dispatch({
        //         type: "CONTROL_PLATFORM_SUCCESS", payload: {
        //             typeIntegration: "mercadoshop",
        //             code: urlParams.get("code"),
        //         }
        //     })
        //     history.push('/integracoes');
        // }

        else if (route.params.type == "woocommerce") {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get("success") == 1) {
                dispatch({
                    type: "CONTROL_PLATFORM_SUCCESS", payload: {
                        typeIntegration: "woocommerce",
                        url: urlParams.get("url"),
                    }
                })
                history.push('/integracoes');
            } else {
                toast.error("Problemas na integração.")
                history.push('/integracoes');
            }
        } else if (route.params.type == "sak") {
            const urlParams = new URLSearchParams(window.location.search);
            dispatch({
                type: "CONTROL_PLATFORM_SUCCESS", payload: {
                    typeIntegration: "sak",
                    token: urlParams.get("tokenSak"),
                    webhook: urlParams.get("webhookSak"),
                }
            })
            history.push('/integracoes');
        }
        else if (route.params.type == "mercadoshop") {

            //localStorage.removeItem("mercadoshop@integration")
            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.get("code")) {
                console.log("if => com code")
                dispatch({
                    type: "CONTROL_PLATFORM_SUCCESS", payload: {
                        typeIntegration: "mercadoshop",
                        code: urlParams.get("code"),
                    }
                })
                localStorage.removeItem("mercadoshop@integration")
                history.push('/integracoes');
            } else {
                //return null
                localStorage.removeItem("mercadoshop@integration")
                window.location = getLinkMercadolivre()

            }

            //history.push('/integracoes');
        }
        else if (route.params.type == "tray") {
            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.get("code")) {
                console.log("if => com code")
                dispatch({
                    type: "CONTROL_PLATFORM_SUCCESS", payload: {
                        typeIntegration: "tray",
                        url: urlParams.get("store_host") ? urlParams.get("store_host") : urlParams.get("url"),
                        code: urlParams.get("code"),
                    }
                })
            } else {
                console.log("else => sem code")
                window.location = `${urlParams.get("url")}/auth.php?response_type=code&consumer_key=c97019143e06fa48e90db88d7f1558d675c378ed0221c442fc8cfe1e11e4aeda&callback=https://app.lily.com.br/integracoes/tray`
            }

            console.log("teste dados", {
                typeIntegration: "tray",
                url: urlParams.get("store_host") ? urlParams.get("store_host") : urlParams.get("url"),
                code: urlParams.get("code"),
            })
            history.push('/integracoes');
        } else if (route.params.type == "bagy") {
            const urlParams = new URLSearchParams(window.location.search);
            dispatch({
                type: "CONTROL_PLATFORM_SUCCESS", payload: {
                    typeIntegration: "bagy",
                    access_token: urlParams.get("bagyToken"),
                }
            })
            history.push('/integracoes');
        } else if (route.params.type == "shopify") {
            dispatch({
                type: "CONTROL_PLATFORM_SUCCESS", payload: {
                    typeIntegration: "shopify",
                    store: urlParams.get("urlShopify"),
                    token: urlParams.get("tokenShopify"),
                    apiKey: urlParams.get("keyShopify"),
                    secretKey: urlParams.get("keySecretShopify"),
                }
            })
            history.push('/integracoes');
        } else if (route.params.type == "montink") {
            dispatch({
                type: "CONTROL_PLATFORM_SUCCESS", payload: {
                    typeIntegration: "montink",
                    token: urlParams.get("tokenMontink"),
                }
            })
            history.push('/integracoes');
        } else if (route.params.type == "nuvemshop") {
            if (localStorage.getItem("nuvemshop@code") && localStorage.getItem("nuvemshop@code") != null) {
                let data = JSON.parse(localStorage.getItem("nuvemshop@code"))
                data.typeIntegration = "nuvemshop"
                dispatch({
                    type: "CONTROL_PLATFORM_SUCCESS", payload: data
                })
            } else {
                dispatch({
                    type: "CONTROL_PLATFORM_SUCCESS", payload: {
                        typeIntegration: "nuvemshop",
                        code: location.search.substring(6, location.search.length),
                    }
                })
            }
            history.push('/integracoes');
        } else {
            dispatch({
                type: "CONTROL_PLATFORM_SUCCESS", payload: {
                    typeIntegration: route.params.type,
                    code: route.params.type == "loja_integrada" ? chaveLojaIntegrada.split("?")[0] : location.search.substring(6, location.search.length),
                    shop: shop.split("&")[0],
                    url: url.replace(/^https?:\/\//, ''),
                    client_id: clientId,
                    client_secret: clientSecret,
                    basic: basic,
                    store: store,
                    token: tokenCartpanda.length !== 0 ? tokenCartpanda.split("?")[0] : token,
                    state: state,
                    instanceId: instanceId,
                    codeWix: code,

                }
            })
            //alert("teste:", JSON.parse(localStorage.getItem("isIntegrationSteps")))
            if (JSON.parse(localStorage.getItem("isIntegrationSteps")) == true) {
                localStorage.setItem("isIntegrationSteps", false);
                history.push('/passo-a-passo');
            }
            else history.push('/integracoes');
        }

    }, [])

    const data = [
        { name: "Brasil", img: "https://app.sak.com.br/image/paises/brazil.png", link: `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop` },
        { name: "Argentina", img: "https://app.sak.com.br/image/paises/argentina.png", link: `https://auth.mercadolibre.ar/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop` },
        { name: "Chile", img: "https://app.sak.com.br/image/paises/chile.png", link: `https://auth.mercadolibre.ch/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop` },
        { name: "Colômbia", img: "https://app.sak.com.br/image/paises/colombia.png", link: `https://auth.mercadolibre.co/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop` },
        { name: "México", img: "https://app.sak.com.br/image/paises/mexico.png", link: `https://auth.mercadolibre.mx/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop` },
    ]

    return (
        ""
        // route.params.type == "mercadoshop" ?
        //     <Container>
        //         <Title>
        //             <div id="logos" className="d-flex justify-content-center">
        //                 <span style={{ display: "flex", alignItems: "center" }}>
        //                     <img src={LilyImg} />
        //                     <label style={{ marginLeft: "10px", fontSize: "32px", fontWeight: "600", color: "#A85E0A" }}>Lily Reviews</label>
        //                 </span>
        //                 <img src="https://empreender.nyc3.digitaloceanspaces.com/landingpage/uploads/landingpage/3kw9lm034647.png" />
        //             </div>
        //         </Title>
        //         <Options>
        //             {data.map((item) => {
        //                 return (
        //                     <ItemOption onClick={() => { window.location = item.link }}>
        //                         <img src={item.img} />
        //                         <label>{item.name}</label>
        //                     </ItemOption>
        //                 )
        //             })}
        //         </Options>
        //     </Container> : null
    )
}

export default Code
