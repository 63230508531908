import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    height: calc(100% - 40px);
    gap: 1rem;
    flex-wrap: wrap;
    flex: 1 !important;
    //background-color: red ;
    //justify-content: space-evenly;
    flex-direction: column;
    padding: 0px 20px;
    
`;

export const Filters = styled.div`
    //background-color: yellow ;
    display: flex ;
    //height: 50px;
    width: calc(100%) ;
    margin-bottom: 20px ;
    align-items: center ;
    flex-wrap: wrap ;
`;
export const FilterOption = styled.label`
    display: flex ;
    background-color: ${props => props.active ? "#EFEFEF" : "transparent"} ;
    height: 35px ;
    align-items: center ;
    justify-content: center ;
    margin-right: 10px ;
    padding: 0px 15px ;
    border-radius: 17px ;
    cursor: pointer;
    font-size: 13px ;
    border: ${props => props.active ? "none" : "1px solid #EFEFEF"} ;
    margin-bottom: 10px;
`;

export const BoxCards = styled.div`
    display: flex ;
    flex-wrap: wrap ;
    gap: 2rem;
    //background-color: green ;
    

    @media screen and (max-width: 768px) {
        justify-content: center ;
    } 

`;
   /* @media screen and (max-width: 768px) {
        flex-direction: column ;
        gap: 20px;
    } */

export const TitleCard = styled.label`
    //background-color: gray ;
    margin-bottom: 7px;
    font-size: 18px ;
    font-weight: bold ;
`;

export const Line = styled.div`
    display:  flex;
    flex-direction: column ;
    margin-bottom: 35px ;
    //background-color: red ;

    :nth-last-child(1){
        //background-color: purple;
        margin-bottom: 0px ;
    }
`;

export const SectionLeft = styled.div`
    display: flex;
    margin: 10px 0px;
`;

export const SectionRight = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
`;

export const Search = styled.div`
    background-color: #FBF9F6;
    display: flex;
    height: 40px;
    //border: 1px solid gray;
    border-radius: 20px;
    padding: 10px;
    align-items: center;

    input{
        display: flex;
        height: 100%;
        width: 250px;
        outline: none;
        border: none;
        background-color: transparent;
    }
`;

export const Check = styled.div`
    display: flex;
    margin-right: 20px;
    align-items: center;
    color: gray;
    span{
        display: flex;
        width: 20px;
        height: 20px;
        background-color: #FBF9F6;
        justify-content: center;
        align-items: center;
        border: 2px solid gray;
        margin-right: 5px ;
        border-radius: 4px;
    }
`;

export const DropDown = styled.div`
    display: flex;
    background-color: orange;

    span{
        display: none;
        flex-direction: column;
    }
`;

export const BoxLoading = styled.div`

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100% ;

    span{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;