import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE, PUT_CUSTOM, POST_CUSTOM, DELETE_CUSTOM } from "../../../../services/api";





export function* listCollects(action) {
    yield put({ type: "COLLECT_GET_REQUEST", })
    const result = yield call(GET, { endpoint: `/reviewcollection?search=${action.payload.search}&perPage=${action.payload.perPage}&page=${action.payload.page}&active=${typeof action.payload.active == "undefined" ? 1 : action.payload.active}` })
    if (result === false) {
        yield put({ type: "COLLECT_GET_ERROR" })
    } else {
        yield put({ type: "COLLECT_GET_SUCCESS", payload: result.data.data })
        //yield put({ type: "COLLECT_GET_RESET", payload: [] })
    }
}

export function* listBusinessCache(action) {
    yield put({ type: "COLLECT_GET_BUSINESS_REF_REQUEST", })
    const result = yield call(GET, { endpoint: `/businessref` })
    if (result === false) {
        yield put({ type: "COLLECT_GET_BUSINESS_REF_ERROR" })
    } else {
        yield put({ type: "COLLECT_GET_BUSINESS_REF_SUCCESS", payload: result.data.data })
        //yield put({ type: "COLLECT_GET_RESET", payload: [] })
    }
}

export function* updateCollect(action) {

    yield put({ type: "COLLECT_UPDATE_REQUEST", })
    const result = yield call(PUT, { endpoint: `/reviewcollection`, data: action.payload })
    if (result === false) {
        yield put({ type: "COLLECT_UPDATE_ERROR" })
    } else {
        yield put({ type: "COLLECT_UPDATE_SUCCESS", payload: result.data })
    }
}

export function* deleteCollect(action) {




    yield put({ type: "COLLECT_DELETE_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE_CUSTOM, { endpoint: `/reviewcollection`, data: action.payload })
    if (result === false) {
        yield put({ type: "COLLECT_DELETE_ERROR" })
    } else {
        yield put({ type: "COLLECT_DELETE_SUCCESS", payload: result.data })

    }


    // yield put({ type: "COLLECT_DELETE_REQUEST", })
    // const result = yield call(DELETE, { endpoint: `/reviewcollection`, data: action.payload })
    // if (result === false) {
    //     yield put({ type: "COLLECT_DELETE_ERROR" })
    // } else {
    //     yield put({ type: "COLLECT_DELETE_SUCCESS", payload: result.data })
    // }
}


export function* addCollect(action) {
    yield put({ type: "COLLECT_ADD_REQUEST", })
    //yield delay(200);
    const result = yield call(POST, { endpoint: `/reviewcollection`, data: action.payload })
    if (result === false) {
        yield put({ type: "COLLECT_ADD_ERROR" })
    } else {
        yield put({ type: "COLLECT_ADD_SUCCESS", payload: result.data })
    }
}

export function* CollectGoogle(action) {
    yield put({ type: "COLLECT_GOOGLE_REQUEST", })
    const result = yield call(GET, { endpoint: `/reviewcollection/googlereviews?data_id=${action.payload.data_id}&next_page_token=${action.payload.paginate}` })
    if (result === false) {
        yield put({ type: "COLLECT_GOOGLE_ERROR" })
    } else {
        yield put({ type: "COLLECT_GOOGLE_SUCCESS", payload: result.data.data })
        //yield put({ type: "COLLECT_GOOGLE_RESET", payload: result.data.data })
    }
}

export function* CollectGooglePaginate(action) {
    yield put({ type: "COLLECT_GOOGLE_PAGINATE_REQUEST", })
    //const result = yield call(GET, { endpoint: `/reviewcollection/googlereviews?data_id=${action.payload.data_id}` })
    const result = false
    if (result === false) {
        yield put({ type: "COLLECT_GOOGLE_PAGINATE_ERROR" })
    } else {
        yield put({ type: "COLLECT_GOOGLE_PAGINATE_SUCCESS", payload: result.data.data })
        //yield put({ type: "COLLECT_GOOGLE_RESET", payload: result.data.data })
    }
}

export function* searchBusinessGoogle(action) {

    yield put({ type: "SEARCH_BUSINESS_GOOGLE_REQUEST", })
    const result = yield call(GET, { endpoint: `/reviewcollection/googlestore?search=${action.payload.search}` })

    if (result === false) {
        yield put({ type: "SEARCH_BUSINESS_GOOGLE_ERROR" })
    } else {
        yield put({ type: "SEARCH_BUSINESS_GOOGLE_SUCCESS", payload: result.data.data })
    }
}