import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../translations'
import { Answer, Container, Infor, Question } from './styled'

const CardQuestion = (props) => {

    const config = useSelector(state => state.reducerControlConfig)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Container background={config.background_color_secondary} borderRadius={config.border_card_radius} borderSize={config.border_card_size} borderColor={config.border_card_color}>
            <Question fontColor={config.font_color_primary}>
                <label>{props.item.ask}</label>
                <label style={{ visibility: config.visible_date_review == "1" ? "visible" : "hidden" }}>11/04/2022</label>
            </Question>
            <Infor fontColor={config.font_color_primary} >por {props.item.name}</Infor>
            <Answer backgroundColor={config.background_color_primary} primaryColor={config.primary_color} fontColor={config.font_color_primary} clean={props.item.answer == "" ? true : false}>
                <span>
                    <label>{props.item.answer == "" ? translation.thema.cardQuestionPreview.aguardando : props.item.answer}</label>
                </span>
            </Answer>
        </Container>
    )
}

export default CardQuestion