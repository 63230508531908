
const INITIAL_STATE = {
    error: false,
    loading: false,
    data: []
}

const GetConfigMural = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONFIG_GET_MURAL_REQUEST':
            return { loading: true, error: false, data: [] }
        case 'CONFIG_GET_MURAL_ERROR':
            return { loading: false, error: true, data: [] };
        case 'CONFIG_GET_MURAL_SUCCESS':
            return { loading: false, error: false, data: action.payload };
        case 'CONFIG_GET_MURAL_RESET':
            return { loading: false, error: false, data: [] };
        default:
            return state;
    }
}

export default GetConfigMural