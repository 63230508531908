import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    //background-color: blue ;
    flex: 1;
    position: relative;
`;

export const BoxControlMobile = styled.div`
    display: flex;

    height: 100%;
    //flex: 1;
    
    @media(max-width: 768px) {
        flex-direction: ${props => props.active ? "column" : "row"};
    }


   
`;

export const NewBarLeft = styled.div`
    //background-color: yellow;
    display: flex;
    width: 340px;
    //background-color: purple;
    flex-direction: row;
    padding: 20px 20px;
    gap: 20px;
    flex-wrap: wrap;
    //justify-content: center;

`
export const NewBarLeftTitle = styled.label`
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    border-radius: 8px;

    font-size: 18px;
    color: #1E1E1E ;
    font-weight: 400;
    font-family:"poppins";
    

        
`
export const BarLeft = styled.div`
    display: flex;
    min-height: 100%;
    flex-direction: column;
    background-color: #fff ;
    //width: ${props => props.visibleBarConfig ? "340px" : "max-content"} ;
    width: ${props => props.isFull ? "340px" : "initial"};
    gap: 10px;
    align-items: center;
    padding-top: 0px !important;
    
    //background-color: green;

    @media(max-width: 900px) {
        width: auto;
        //padding: 10px 10px ;
    }
`;



export const BoxLoading = styled.div`

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;

    span{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;




export const Tooltip = styled.div`
    position: relative;  
 
    :hover div{
        visibility: visible;
        opacity: 0.9;
        top: 30px;
        z-index: 999;
    }

    div{
        display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important;
        width: max-content;
        padding: 5px 10px;

        label{
            font-size: 10px;
            color: white;
        }
  

        :after{
            content: '';
            position: absolute;
            bottom: 100%;
            left: 10px;
            border-bottom: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
`;




export const ItemBarLeft = styled.div`
    //background-color: yellow;
    display: flex;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    width: 140px;
    height: 140px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
    border: 1px solid ${props => props.active ? "#FFC400" : "transparent"};

    :hover{
        border: 1px solid #FFC400;
    }

    img{
        width: 40px;
        height: 40px;
    }

    label{
        display: flex;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
        height: 60px;
        //background-color: red;
    }
`




export const EmBreve = styled.label`
    display: flex;
    background: #FFCC00;
    position: absolute;
    bottom: 0px;
    border-radius: 0px 0px 8px 8px;
    width: 140px;
    max-height: 30px;
    font-size:12px;
    align-items: center;
    justify-content: center;
    color: #5e0a07;;
`;

export const ListCards = styled.label`
    display: flex;
    flex-direction: column;
    background-color: transparent;
    gap: 20px;
    padding: 20px;
    width: 100%;
    height: 100%;
    //background-color: red;
    //justify-content: space-between;
`;
