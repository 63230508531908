import React, { useEffect, useState } from 'react'
import { BoxActions, BoxListItems, BoxLoading, Close, Container, Form, IconSuccess, InputCustom, ItemBoxAction, ItemResult, Logo, LogoToPlatform, More, Results, Screen, TextEnd, Title } from './styled'
import Modal from 'react-modal'
import { getTranslation } from '../../../../translations';

import LogoLily from "../../../../assets/imgs/Girrafe.svg";
import CardGoogle from '../card-google';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AiFillStar, AiOutlineLoading } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { Star } from 'iconsax-react';
import { ButtonMoreReviews } from '../../../products/components/modal-import-shopee-new/styled';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        width: "500px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalCollectGoogle = (props) => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const [searchGoogle, setSearchGoogle] = useState("")
    const [screenActive, setScreenActive] = useState("")

    const [paginate, setPaginate] = useState("")

    const searchBusinessGoogle = useSelector(state => state.reducerSearchBusinessGoogle)
    const collectGoogle = useSelector(state => state.reducerCollectGoogle)
    const reducerCollectGooglePaginate = useSelector(state => state.reducerCollectGooglePaginate)
    const reducerAddCollect = useSelector(state => state.reducerAddCollect)

    const [listBusiness, setlistBusiness] = useState([])
    const [listReviewsGoogle, setListReviewsGoogle] = useState([])
    const [selectedBusiness, setselectedBusiness] = useState("")
    const dispatch = useDispatch()


    const closeModal = () => {
        props.setVisibleModal(false)
        setSearchGoogle("")
        setScreenActive("")
        setlistBusiness([])
        setListReviewsGoogle([])
    }

    useEffect(() => {
        let componentList = document.getElementById("box-list")
        if (componentList) {
            if (componentList.scrollHeight > 500) {
                componentList.style.paddingRight = "30px"
            } else {
                componentList.style.paddingRight = "0px"
            }
        }

    })


    useEffect(() => {

        if (searchGoogle != "") {
            if (searchBusinessGoogle?.data?.local_results) {
                setlistBusiness(searchBusinessGoogle?.data?.local_results)
            } else if (searchBusinessGoogle?.data?.place_results) {
                setlistBusiness([searchBusinessGoogle?.data?.place_results])
            }
        }

    }, [searchBusinessGoogle])

    useEffect(() => {

        if (props.businessUpdate != null) {
            //setScreenActive("list")
            goSearchReviewsGoogle(props.businessUpdate.token)
            setselectedBusiness(JSON.parse(props.businessUpdate.meta_data))
        }
    }, [props.businessUpdate])


    useEffect(() => {
        if (collectGoogle.loading == false && collectGoogle.error == false && collectGoogle?.data?.length == 0) {
        } else {
            if (collectGoogle.loading == true) {

            } else {
                if (collectGoogle.error == true) {

                } else {
                    if (screenActive == "loading") setScreenActive("list")
                    setListReviewsGoogle([...listReviewsGoogle, ...collectGoogle.data.reviews])
                    setPaginate(collectGoogle.data.nextPageToken)
                }
            }

        }
    }, [collectGoogle])



    useEffect(() => {
        if (reducerAddCollect.loading == false && reducerAddCollect.error == false && reducerAddCollect?.data?.length == 0) {
        } else {
            if (reducerAddCollect.loading == true) {

            } else {
                if (reducerAddCollect.error == true) {

                } else {
                    setScreenActive("end")
                    props.goSearch()
                }
            }

        }
    }, [reducerAddCollect])





    useEffect(() => {
        //alert(screenActive)
        if (screenActive == "list" && listReviewsGoogle?.length < 1) {
            setScreenActive("")
        }
    }, [screenActive])


    const goSearchBusinessGoogle = () => {
        dispatch({ type: "SAGA_SEARCH_BUSINESS_GOOGLE", payload: { search: searchGoogle, } })
    }

    const goSearchReviewsGoogle = (id) => {
        if (screenActive != "list") setScreenActive("loading")
        dispatch({ type: "SAGA_COLLECT_GOOGLE", payload: { data_id: id, paginate: paginate } })
    }

    const saveReviewsGoogle = () => {
        setScreenActive("save")
        dispatch({ type: "SAGA_ADD_COLLECT", payload: { dataReviews: listReviewsGoogle, platform: "google", dataBusiness: selectedBusiness } })
    }

    const removeReviewsGoogle = (remove) => {

        let newArray = listReviewsGoogle.filter(item => (item.review_id != remove.review_id));
        setListReviewsGoogle(newArray)
        if (newArray.length == 0) setScreenActive("")

    }

    const getMore = () => {
        dispatch({ type: "SAGA_COLLECT_PAGINATE_GOOGLE", payload: { paginate: paginate, } })
    }


    useEffect(() => {
        if (reducerCollectGooglePaginate.loading == false && reducerCollectGooglePaginate.error == false && reducerCollectGooglePaginate?.data?.length == 0) {
        } else {
            if (reducerCollectGooglePaginate.loading == true) {

            } else {
                if (reducerCollectGooglePaginate.error == true) {
                    toast.error("Problemas na busca.")
                } else {
                    //updateListDepo(reducerCollectGooglePaginate.data)
                    //setListReviewsGoogle(reducerCollectGooglePaginate.data)
                }
            }

        }
    }, [reducerCollectGooglePaginate])

    return (
        <Modal
            isOpen={props.visibleModal}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => closeModal()}
        >
            {screenActive == "" &&
                <Screen>
                    <LogoToPlatform>
                        <div>
                            <img src={"https://imagepng.org/wp-content/uploads/2019/08/google-icon-1.png"} />
                        </div>

                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                <g filter="url(#filter0_d_1866_671)">
                                    <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                    </filter>
                                </defs>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                <g filter="url(#filter0_d_1866_671)">
                                    <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </span>
                        <div>
                            <img src={LogoLily} />
                        </div>

                    </LogoToPlatform>

                    <Title style={{ fontSize: "12px", color: "#808080", textAlign: "center", lineHeight: "initial", backgroundColor: "#FFF6D9", padding: "20px", borderRadius: "8px" }}>
                        {/* {translation.MeusProdutos.modalImport.preenchaCampos} */}
                        {translation.depoimentos.alert}
                    </Title>

                    <Form>
                        <InputCustom>
                            <input onChange={(e) => { setSearchGoogle(e.target.value) }} value={searchGoogle} type='text' placeholder={translation.depoimentos.empresaEndereco} />
                            {searchBusinessGoogle.loading ?
                                <AiOutlineLoading size={18} color={"#20d489"} /> :
                                <img src={"https://imagepng.org/wp-content/uploads/2019/08/google-icon-1.png"} />}

                        </InputCustom>
                        <Results style={{ maxHeight: "400px", overflow: "auto" }}>
                            {/* {Array(5).fill(
                                <ItemResult>
                                    <div>
                                        <label>Nome</label>
                                        <label>Reviews <strong>50</strong></label>
                                    </div>
                                    <label>
                                        rua tal, bairro tal, numero tal
                                    </label>
                                </ItemResult>
                            )} */}
                            {listBusiness.map((item) => {
                                return (
                                    <ItemResult onClick={() => {
                                        if (item?.reviews) {
                                            setselectedBusiness(item);
                                            goSearchReviewsGoogle(item?.data_id)
                                        } else {
                                            toast.info(translation.depoimentos.naoEncontramos)
                                        }
                                    }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <label style={{ fontWeight: "bold", display: "flex", alignItems: "center" }} >{item?.title}
                                                <span style={{ marginLeft: "5px", display: "flex", gap: "5px", alignItems: "center", }} >
                                                    {/* <Star size="20" color="orange" variant="Bold" />   */}
                                                    <AiFillStar size={15} color={"orange"} />
                                                    <span style={{ display: "flex", alignItems: "center", marginTop: "2px" }}>{item?.reviews}</span>
                                                </span>
                                            </label>
                                            {/* {item?.reviews && <label>Reviews <strong>{item?.reviews}</strong></label>} */}
                                        </div>
                                        <label>
                                            {item?.address}
                                        </label>
                                    </ItemResult>
                                )
                            })}
                        </Results>
                    </Form>
                    <BoxActions>
                        <ItemBoxAction onClick={() => { closeModal() }} fontColor={"#344054"} backgroundColor={"transparent"} borderColor={"#D0D5DD"} >
                            {"Cancelar"}
                        </ItemBoxAction>

                        <ItemBoxAction onClick={() => { goSearchBusinessGoogle() }} fontColor={"#ffffff"} backgroundColor={"#19b674"} borderColor={"#19b674"}>
                            Buscar
                        </ItemBoxAction>
                    </BoxActions>
                </Screen>}

            {screenActive == "loading" &&
                <Screen>
                    <LogoToPlatform>
                        <div>
                            <img src={"https://imagepng.org/wp-content/uploads/2019/08/google-icon-1.png"} />
                        </div>

                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                <g filter="url(#filter0_d_1866_671)">
                                    <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                    </filter>
                                </defs>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                <g filter="url(#filter0_d_1866_671)">
                                    <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </span>
                        <div>
                            <img src={LogoLily} />
                        </div>
                    </LogoToPlatform>

                    <BoxLoading>
                        <svg xmlns="http://www.w3.org/2000/svg" width="196" height="196" viewBox="0 0 196 196" fill="none">
                            <path d="M196 98C196 152.124 152.124 196 98 196C43.8761 196 0 152.124 0 98C0 43.8761 43.8761 0 98 0C152.124 0 196 43.8761 196 98ZM11.6006 98C11.6006 145.717 50.2829 184.399 98 184.399C145.717 184.399 184.399 145.717 184.399 98C184.399 50.2829 145.717 11.6006 98 11.6006C50.2829 11.6006 11.6006 50.2829 11.6006 98Z" fill="url(#paint0_linear_1828_636)" fill-opacity="0.2" />
                            <defs>
                                <linearGradient id="paint0_linear_1828_636" x1="98" y1="0" x2="-4.5" y2="166.5" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FFC400" />
                                    <stop offset="1" stop-color="#FFC400" stop-opacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>

                        <label>
                            {"Buscando..."}
                        </label>
                    </BoxLoading>

                    <BoxActions>
                        <ItemBoxAction onClick={() => { closeModal() }} fontColor={"#ffffff"} backgroundColor={"#19b674"} borderColor={"#19b674"}>
                            Cancelar
                        </ItemBoxAction>
                    </BoxActions>
                </Screen>}

            {screenActive == "list" &&
                <Screen style={{ position: "relative" }}>

                    {/* <ConfirmAlert confirm={deleteAllImageReviewShopee} setVisible={setVisibleConfirm} visible={visibleConfirm} title={translation.MeusProdutos.modalImport.desejaExcluirImagens} text={translation.MeusProdutos.modalImport.naoReverter} />

                        <ConfirmAlertDeleteReview id={reviewIdSelected} confirm={deleteReviewShopee} setVisible={setVisibleConfirmDeleteReview} visible={visibleConfirmDeleteReview} title={translation.MeusProdutos.modalImport.desejaExcluirAvaliacao} text={translation.MeusProdutos.modalImport.naoReverter} /> */}


                    <BoxListItems id='box-list'>

                        {/* {Array(5).fill(
                            <CardGoogle />
                        )} */}
                        {
                            listReviewsGoogle?.map((item) => {
                                return (
                                    <CardGoogle item={item} removeReviewsGoogle={removeReviewsGoogle} />
                                )
                            })
                        }

                        {reducerCollectGooglePaginate.loading ?
                            <More>
                                <label>Buscando...</label>
                            </More> :
                            paginate == null ?
                                "" :
                                <More onClick={() => {
                                    goSearchReviewsGoogle(selectedBusiness.data_id)
                                }}>
                                    <label>Buscar mais</label>
                                </More>}


                    </BoxListItems>


                    {/* <ButtonMoreReviews >
                        <label style={{ fontSize: "12px" }}>
                            Fim
                        </label>
                    </ButtonMoreReviews> : */}
                    {/* <ButtonMoreReviews onClick={() => {
                        setpageImportReviews(pageImportReviews + 1)
                    }}> */}
                    {/* <label style={{ fontSize: "12px" }}>
                            {"statusImportShopeeMore.loading" ?
                                "Buscando..." :
                                `Buscar mais ${"numberReviews"} ${translation.products.avaliacoes}`}
                        </label> */}
                    {/* <label>Buscar mais</label>
                    </ButtonMoreReviews> */}


                    <BoxActions>
                        <ItemBoxAction
                            onClick={() => { setScreenActive(""); }}
                            fontColor={"#344054"} backgroundColor={"transparent"} borderColor={"#D0D5DD"} >
                            {translation.MeusProdutos.modalImport.voltar}
                        </ItemBoxAction>

                        <ItemBoxAction
                            onClick={() => { saveReviewsGoogle() }}
                            fontColor={"#ffffff"} backgroundColor={"#19b674"} borderColor={"#19b674"}>
                            Enviar
                        </ItemBoxAction>
                    </BoxActions>
                </Screen>}

            {screenActive == "save" &&
                <Screen style={{ overflow: "hidden" }} onClick={() => { }} >
                    <LogoToPlatform>
                        <div>
                            <img src={"https://imagepng.org/wp-content/uploads/2019/08/google-icon-1.png"} />
                        </div>

                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                <g filter="url(#filter0_d_1866_671)">
                                    <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                    </filter>
                                </defs>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                <g filter="url(#filter0_d_1866_671)">
                                    <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </span>
                        <div>
                            <img src={LogoLily} />
                        </div>

                    </LogoToPlatform>

                    <Title>
                        {translation.MeusProdutos.modalImport.enviandoPagina}
                    </Title>

                    <BoxLoading>
                        <svg xmlns="http://www.w3.org/2000/svg" width="196" height="196" viewBox="0 0 196 196" fill="none">
                            <path d="M196 98C196 152.124 152.124 196 98 196C43.8761 196 0 152.124 0 98C0 43.8761 43.8761 0 98 0C152.124 0 196 43.8761 196 98ZM11.6006 98C11.6006 145.717 50.2829 184.399 98 184.399C145.717 184.399 184.399 145.717 184.399 98C184.399 50.2829 145.717 11.6006 98 11.6006C50.2829 11.6006 11.6006 50.2829 11.6006 98Z" fill="url(#paint0_linear_1828_636)" fill-opacity="0.2" />
                            <defs>
                                <linearGradient id="paint0_linear_1828_636" x1="98" y1="0" x2="-4.5" y2="166.5" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FFC400" />
                                    <stop offset="1" stop-color="#FFC400" stop-opacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>
                        {/* {
                            statusControlSaveImportShopee.qtdTotal != 0 &&
                            <label>{statusControlSaveImportShopee.qtdSuccess} de {statusControlSaveImportShopee.qtdTotal}</label>
                        } */}
                        <label> {translation.depoimentos.aguarde}</label>

                    </BoxLoading>
                </Screen>}

            {screenActive == "end" &&
                <Screen>
                    <Logo>
                        <img src={LogoLily} />
                        <IconSuccess>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                <path d="M13.3828 6.51515V7.09375C13.382 8.44994 12.9429 9.76954 12.1309 10.8558C11.3188 11.942 10.1775 12.7366 8.87692 13.1211C7.57639 13.5057 6.1864 13.4595 4.91426 12.9895C3.64211 12.5195 2.55597 11.6509 1.81783 10.5132C1.07969 9.37544 0.729087 8.0296 0.81832 6.67635C0.907553 5.3231 1.43184 4.03495 2.31298 3.00402C3.19412 1.97308 4.38492 1.25459 5.70776 0.955715C7.0306 0.656836 8.41462 0.793578 9.6534 1.34555M13.3828 2.0625L7.09375 8.35785L5.20703 6.47113" stroke="#039855" stroke-width="1.25781" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </IconSuccess>
                    </Logo>

                    <Title>
                        {translation.depoimentos.coletados}
                    </Title>

                    <TextEnd>
                        {""}
                    </TextEnd>

                    <Close onClick={() => { closeModal() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M1.66675 12.3334L12.3334 1.66669M1.66675 1.66669L12.3334 12.3334" stroke="#767676" stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Close>
                </Screen>}


        </Modal>

    )
}

export default ModalCollectGoogle