import React, { useState } from 'react'
import { BoxButtons, BoxOptions, ButtonHelp, ButtonReset, ButtonSave, Container, SelectCustom, Title } from './styled'
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useDispatch, useSelector } from 'react-redux';
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';
import { IoIosArrowBack } from "react-icons/io";
import { MenuOption, ItemOption, Main, } from '../bar-config-product-cores/styled';
import { useHistory, useLocation } from 'react-router';
import { Carrossel, ItemActive } from '../bar-config-home-color/styled';
import { Posicao, Titulo } from '../bar-config-avaliar-config/styled';
import { EstiloWidget } from '../bar-config-home-config/styled';

import IconEstiloWidget from "../../../../assets/imgs/icon-estilo-widget.svg";
import IconText from "../../../../assets/imgs/icon-text.svg";
import IconSlider from "../../../../assets/imgs/icon-slider-home.svg";
import IconLink from "../../../../assets/imgs/icon-link.svg";
import { useEffect } from 'react';

const BarConfigMuralAjustes = () => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const history = useHistory()
    const dispatch = useDispatch()
    const config = useSelector(state => state.reducerGetConfigMural)
    const configControl = useSelector(state => state.reducerControlConfigMural)
    const [is_visibility, set_is_visibility] = useState(false)

    const selectedMenu = useLocation();

    useEffect(() => {
        set_is_visibility(config?.data[0]?.is_visibility == 1 ? true : false)
    }, [config])



    const saveTheme = () => {

        dispatch({
            //type: "SAGA_UPDATE_CONFIG_MURAL", payload: {...config.data[0], data_config: JSON.stringify(configControl) }
            type: "SAGA_UPDATE_CONFIG_MURAL", payload: {
                id: config.data[0]?.id,
                data_config: JSON.stringify(configControl)
            }
        })

    }

    const updateActive = () => {
        let status = is_visibility
        set_is_visibility(!is_visibility)
        dispatch({
            type: "SAGA_UPDATE_CONFIG_MURAL", payload: {
                id: config.data[0]?.id,
                is_visibility: status ? 0 : 1
            }
        })

    }


    const getDefaultTheme = () => {
        const themaDefault = {
            mural_background_color_widget: configControl.mural_background_color_widget,
            mural_background_color_card: configControl.mural_background_color_card,
            mural_font_color_widget: configControl.mural_font_color_widget,
            mural_font_color_card: configControl.mural_font_color_card,
            mural_border_color_card: configControl.mural_border_color_card,



            mural_title_text_widget: "Depoimentos",
            mural_title_font_widget: "Poppins",
            mural_title_size_widget: "32px",
            mural_title_position_widget: "center",

            mural_model_widget: "modelo01",

            mural_model_card: "modelo01",
            mural_border_size_card: "1px",
            mural_border_radius_card: "4px",
        }
        dispatch({
            type: "SAGA_UPDATE_CONFIG_MURAL", payload: {
                id: config.data[0]?.id,
                data_config: JSON.stringify(themaDefault)
            }
        })
    }

    const isSaveMuralConfig = () => {

        const data = JSON.parse(config.data[0]?.data_config)

        if (
            configControl.mural_title_text_widget == data.mural_title_text_widget &&
            configControl.mural_title_font_widget == data.mural_title_font_widget &&
            configControl.mural_title_size_widget == data.mural_title_size_widget &&
            configControl.mural_title_position_widget == data.mural_title_position_widget &&
            configControl.mural_model_widget == data.mural_model_widget &&

            configControl.mural_model_card == data.mural_model_card &&
            configControl.mural_border_size_card == data.mural_border_size_card &&
            configControl.mural_border_radius_card == data.mural_border_radius_card
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_MURAL_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_MURAL_COLOR_SET", payload: false })
            return false
        }
    }


    const getLanguage = () => {
        if (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es") {
            return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language + "-mx"
        } else {
            return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
        }
    }

    return (
        <Main>
            <Container>
                <Title>
                    <span onClick={() => { history.push("/personalizar") }}>
                        <IoIosArrowBack />
                    </span>
                    <label > {"Mural"} </label>
                </Title>

                <MenuOption>
                    <ItemOption active={false} onClick={() => {
                        isSaveMuralConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "mural/cores" })
                    }}>
                        {translation.thema.boxConfigTema.cores}
                    </ItemOption>
                    <ItemOption active={true} onClick={() => {
                        dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "mural/config" })
                    }}>
                        {translation.thema.boxConfigTema.avancado}
                    </ItemOption>
                </MenuOption>

                <BoxOptions>
                    <Carrossel>
                        <span>
                            <div>
                                <img width={20} src={IconSlider} />
                                <label>{"WIDGET MURAL:"}</label>
                            </div>
                            <ItemActive active={is_visibility} onClick={() => { updateActive() }}>
                                <span />
                            </ItemActive>
                        </span>
                        {/* <label>{translation.thema.boxConfigHome.ativarDesc}</label> */}
                    </Carrossel>
                </BoxOptions>

                <BoxOptions>
                    <Posicao>
                        <div>
                            <div
                                onClick={() => {
                                    if (JSON.parse(localStorage.getItem("depoimentos@login")).integration?.url != null)
                                        window.open(`https://${JSON.parse(localStorage.getItem("depoimentos@login")).integration.url}?editor_lily_mural=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}&language=${getLanguage()}`)
                                }}
                            >
                                <label onClick={() => { }} >
                                    {translation.thema.boxConfigHome.btnEditor}
                                </label>
                                <img onClick={() => { }} src={IconLink} />
                            </div>
                        </div>
                        <label style={{ textAlign: "center", fontSize: "9px" }}>
                            {translation.thema.configs.definaEspacoDepoimentos}
                        </label>
                    </Posicao>
                </BoxOptions>

                <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <img width={20} src={IconText} />
                                <label> {translation.thema.boxConfigHome.titulo.toUpperCase()}</label>
                            </div>
                        </span>
                        <input
                            type={"text"}
                            value={configControl.mural_title_text_widget == null || configControl.mural_title_text_widget == "null" ? "" : configControl.mural_title_text_widget}
                            placeholder={"Título do widget"}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_title_text_widget: e.target.value } }) }}
                        />
                    </Titulo>

                    <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_title_font_widget: e.target.value } }) }}>
                        <option value={"Poppins"} selected={configControl.mural_title_font_widget == "Poppins" ? true : false}>Poppins</option>
                        <option value={"Times New Roman"} selected={configControl.mural_title_font_widget == "Times New Roman" ? true : false}>Times New Roman</option>
                        <option value={"Arial"} selected={configControl.mural_title_font_widget == "Arial" ? true : false}>Arial</option>
                        <option value={"Courier New"} selected={configControl.mural_title_font_widget == "Courier New" ? true : false}>Courier New</option>
                        <option value={"Georgia"} selected={configControl.mural_title_font_widget == "Georgia" ? true : false}>Georgia</option>
                        <option value={"Oswald"} selected={configControl.mural_title_font_widget == "Oswald" ? true : false}>Oswald</option>
                        <option value={"Montserrat"} selected={configControl.mural_title_font_widget == "Montserrat" ? true : false}>Montserrat</option>
                        <option value="Roboto" selected={configControl.font == "Roboto" ? true : false} >Roboto</option>
                    </SelectCustom>

                    <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_title_size_widget: e.target.value } }) }}>
                        <option value={"16px"} selected={configControl.mural_title_size_widget == "16px" ? true : false}>16px</option>
                        <option value={"18px"} selected={configControl.mural_title_size_widget == "18px" ? true : false}>18px</option>
                        <option value={"20px"} selected={configControl.mural_title_size_widget == "20px" ? true : false}>20px</option>
                        <option value={"22px"} selected={configControl.mural_title_size_widget == "22px" ? true : false}>22px</option>
                        <option value={"24px"} selected={configControl.mural_title_size_widget == "24px" ? true : false}>24px</option>
                        <option value={"26px"} selected={configControl.mural_title_size_widget == "26px" ? true : false}>26px</option>
                        <option value={"28px"} selected={configControl.mural_title_size_widget == "28px" ? true : false}>28px</option>
                        <option value={"30px"} selected={configControl.mural_title_size_widget == "30px" ? true : false}>30px</option>
                        <option value={"32px"} selected={configControl.mural_title_size_widget == "32px" ? true : false}>32px</option>
                        <option value={"34px"} selected={configControl.mural_title_size_widget == "34px" ? true : false}>34px</option>
                        <option value={"36px"} selected={configControl.mural_title_size_widget == "36px" ? true : false}>36px</option>
                        <option value={"38px"} selected={configControl.mural_title_size_widget == "38px" ? true : false}>38px</option>
                        <option value={"40px"} selected={configControl.mural_title_size_widget == "40px" ? true : false}>40px</option>
                        <option value={"42px"} selected={configControl.mural_title_size_widget == "42px" ? true : false}>42px</option>
                    </SelectCustom>

                    <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_title_position_widget: e.target.value } }) }}>
                        <option value={"center"} selected={configControl?.mural_title_position_widget == "center" ? true : false}>{translation.thema.configs.centralizado}</option>
                        <option value={"left"} selected={configControl?.mural_title_position_widget == "left" ? true : false}>{translation.thema.configs.alinhadoEsquerda}</option>
                    </SelectCustom>
                </BoxOptions>

                <BoxOptions>
                    <EstiloWidget>
                        <span>
                            <div>
                                <img src={IconEstiloWidget} />
                                <label> {translation.thema.boxConfigHome.estiloWidget}</label>
                            </div>
                        </span>

                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_model_widget: e.target.value } }) }}>
                            <option value={"modelo01"} selected={configControl.mural_model_widget == "modelo01" ? true : false}>Modelo 01</option>
                            <option value={"modelo02"} selected={configControl.mural_model_widget == "modelo02" ? true : false}>Modelo 02</option>
                        </SelectCustom>


                    </EstiloWidget>
                </BoxOptions>

                <BoxOptions>
                    <EstiloWidget>
                        <span>
                            <div>
                                <img src={IconEstiloWidget} />
                                <label> {translation.thema.configs.card}</label>
                            </div>
                        </span>


                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_model_card: e.target.value } }) }}>
                            <option value="modelo01" selected={configControl.mural_model_card == "modelo01" ? true : false}>Modelo 01</option>
                            <option value="modelo02" selected={configControl.mural_model_card == "modelo02" ? true : false}>Modelo 02</option>
                        </SelectCustom>


                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_border_size_card: e.target.value } }) }}>
                            <option value="0px" selected={configControl.mural_border_size_card == "0px" ? true : false}>{translation.thema.configs.borda} 0px</option>
                            <option value="1px" selected={configControl.mural_border_size_card == "1px" ? true : false}>{translation.thema.configs.borda} 1px</option>
                            <option value="2px" selected={configControl.mural_border_size_card == "2px" ? true : false}>{translation.thema.configs.borda} 2px</option>
                            <option value="3px" selected={configControl.mural_border_size_card == "3px" ? true : false}>{translation.thema.configs.borda} 3px</option>
                            <option value="4px" selected={configControl.mural_border_size_card == "4px" ? true : false}>{translation.thema.configs.borda} 4px</option>
                            <option value="5px" selected={configControl.mural_border_size_card == "5px" ? true : false}>{translation.thema.configs.borda} 5px</option>
                        </SelectCustom>

                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_border_radius_card: e.target.value } }) }}>
                            <option value="0px" selected={configControl.mural_border_radius_card == "0px" ? true : false}>{translation.thema.configs.arredondamento} 0px</option>
                            <option value="1px" selected={configControl.mural_border_radius_card == "1px" ? true : false}>{translation.thema.configs.arredondamento} 1px</option>
                            <option value="2px" selected={configControl.mural_border_radius_card == "2px" ? true : false}>{translation.thema.configs.arredondamento} 2px</option>
                            <option value="4px" selected={configControl.mural_border_radius_card == "4px" ? true : false}>{translation.thema.configs.arredondamento} 4px</option>
                            <option value="6px" selected={configControl.mural_border_radius_card == "6px" ? true : false}>{translation.thema.configs.arredondamento} 6px</option>
                            <option value="8px" selected={configControl.mural_border_radius_card == "8px" ? true : false}>{translation.thema.configs.arredondamento} 8px</option>
                            <option value="10px" selected={configControl.mural_border_radius_card == "10px" ? true : false}>{translation.thema.configs.arredondamento} 10px</option>
                            <option value="12px" selected={configControl.mural_border_radius_card == "12px" ? true : false}>{translation.thema.configs.arredondamento} 12px</option>
                            <option value="14px" selected={configControl.mural_border_radius_card == "14px" ? true : false}>{translation.thema.configs.arredondamento} 14px</option>
                            <option value="16px" selected={configControl.mural_border_radius_card == "16px" ? true : false}>{translation.thema.configs.arredondamento} 16px</option>
                            <option value="18px" selected={configControl.mural_border_radius_card == "18px" ? true : false}>{translation.thema.configs.arredondamento} 18px</option>
                            <option value="20px" selected={configControl.mural_border_radius_card == "20px" ? true : false}>{translation.thema.configs.arredondamento} 20px</option>
                        </SelectCustom>


                    </EstiloWidget>
                </BoxOptions>

                <ButtonHelp>
                    <img src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons style={{ marginBottom: "10px" }}>
                    <ButtonReset onClick={() => getDefaultTheme()}>
                        <label>{translation.thema.boxConfigTema.restaurar}</label>
                    </ButtonReset>
                    <ButtonSave onClick={() => saveTheme()}>
                        {translation.thema.boxConfigTema.salvar}
                    </ButtonSave>
                </BoxButtons>
            </Container>
        </Main>

    )
}

export default BarConfigMuralAjustes