import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Contents from "../../../../components/Contents";
import Card from '../../components/Card/index'
import { Container, ContainerCards } from './styled'
import { isVisibleScript } from "../../../../utils/displayScript";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTranslation } from "../../../../translations";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import ModalInforVideo from "../../../../components/modal-infor-video";
import HelpClick from "../../../../components/HelpClick";
import Layout from "../../../../components/tailwind/layout";
import LayoutNew from "../../../../components/tailwind/layout-new";

const HomeNew = () => {

    useEffect(() => {

        const script2 = document.getElementById("script-support");
        if (script2 == null) {

            if (window.sakw_token) delete window.sakw_token;
            const script = document.createElement('script');
            script.src = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/63d2dcb4bdc15.js" : "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/619678452e2db.js"
            script.id = "script-support";
            script.async = true;
            document.body.appendChild(script);

        }


    }, [])

    const [query, setQuery] = useState("")
    const queryHelp = useSelector(state => state.reducerQueryHelp)
    const dispatch = useDispatch()

    const [visibleModalInforHelp, setVisibleModalInforHelp] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    useEffect(() => {
        setQuery(queryHelp)
        dispatch({ type: "SET_QUERY_HELP", payload: "" })

        //modifyScriptSupport()
    }, [])

    const modifyScriptSupport = () => {
        let script = document.getElementById("script-support")
        script.src = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/63d2dcb4bdc15.js" : "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/619678452e2db.js"
        //script.async = true;
        //document.body.appendChild(script);
        // return () => {
        //     document.body.removeChild(script);
        // }
    }




    return (
        <LayoutNew>

            <ModalInforVideo visible={visibleModalInforHelp} setVisibleModal={setVisibleModalInforHelp}
                video={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/WcLVkFT2clA" : `https://www.youtube.com/embed/w5ChDgUEkuQ`}
            />

            <Container>
                <iframe scrolling="auto" allow="clipboard-write" src={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? `https://lily-latam.sak.com.br/faq?q=${query}` : `https://lilyreviews.sak.com.br/faq?q=${query}`} title="lilly-faq" style={{ border: 'none', width: '100%', height: '120vh' }} frameBorder={0}></iframe>
                <ContainerCards>
                    <Card background="#20D489" color="white" headerText={translation.ajuda.atendimento} mainText={translation.ajuda.suporteWhatsapp} btnText={translation.ajuda.whatsapp} btnAction="javascript:sak_toggle_widget()" />
                    <Card background="#ffcc00" color="#5e0a07" headerText={translation.ajuda.forum} mainText={translation.ajuda.suporteForum} btnText={translation.ajuda.forum} btnAction="https://forum.empreender.com.br/t/geral" />
                </ContainerCards>
            </Container>

            <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                <div onClick={() => { setVisibleModalInforHelp(true) }}>
                    <HelpClick text={translation.alert.ajuda} />
                </div>
            </div>

            {isVisibleScript(true)}
        </LayoutNew>
    );
}

export default HomeNew