import { AcademicCapIcon, ChatBubbleBottomCenterIcon, ChatBubbleLeftRightIcon, CreditCardIcon, FolderIcon, HomeIcon, PaintBrushIcon, ShoppingBagIcon, ShoppingCartIcon, Square3Stack3DIcon, StarIcon, TagIcon, UserIcon } from '@heroicons/react/24/outline'
import Logo from "../../../assets/imgs/Girrafe.svg";
import { TruckRemove } from 'iconsax-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const navigation = [
    { name: 'Dashboard', href: '#', route: "/", icon: HomeIcon, current: window.location.pathname == "/" ? true : false },
    { name: 'Avaliações', href: '#', route: "/avaliacoes", icon: StarIcon, count: '99+', current: window.location.pathname == "/avaliacoes" ? true : false },
    { name: 'Perguntas', href: '#', route: "/perguntas", icon: ChatBubbleLeftRightIcon, count: '99+', current: window.location.pathname == "/perguntas" ? true : false },
    { name: 'Cupons', href: '#', route: "/cupons", icon: TagIcon, count: '2', current: window.location.pathname == "/cupons" ? true : false },
    { name: 'Produtos', href: '#', route: "/produtos", icon: ShoppingBagIcon, count: '99+', current: window.location.pathname == "/produtos" ? true : false },
    { name: 'Pedidos', href: '#', route: "/pedidos", icon: ShoppingCartIcon, count: '99+', current: window.location.pathname == "/pedidos" ? true : false },
    { name: 'Depoimentos', href: '#', route: "/depoimentos", icon: ChatBubbleBottomCenterIcon, count: '57', current: window.location.pathname == "/depoimentos" ? true : false },
    { name: 'Passo a passo', href: '#', route: "/passo-a-passo", icon: AcademicCapIcon, current: window.location.pathname == "/passo-a-passo" ? true : false },
    { name: 'Ajuda', href: '#', route: "/ajuda", icon: UserIcon, current: window.location.pathname == "/ajuda" ? true : false },
]
const configs = [
    { name: 'Personalizar', href: '#', route: "/personalizar", icon: PaintBrushIcon, current: window.location.pathname == "/personalizar" ? true : false },
    { name: 'Integrações', href: '#', route: "/integracoes", icon: Square3Stack3DIcon, current: window.location.pathname == "/integracoes" ? true : false },
    { name: 'Pagamentos', href: '#', route: "/pagamentos", icon: CreditCardIcon, current: window.location.pathname == "/pagamentos" ? true : false },

]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Sidebar() {


    const history = useHistory()

    const classItemActive = "bg-[#ffc400] text-gray-600"
    const classItemInactive = "text-gray-700 hover:bg-[#ffc400] hover:text-gray-600"
    const classItem = "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"

    const classItemActiveIcon = "text-gray-600"
    const classItemInactiveIcon = "text-gray-400 group-hover:text-gray-600"
    const classItemIcon = "h-6 w-6 shrink-0"

    useEffect(() => {
        console.log(window.location.pathname)
    }, [])

    return (
        <>
            <div style={{ width: "300px", height: "100vh", backgroundColor: '#FFF' }}></div>
            <div style={{ width: "300px", backgroundColor: '#fff' }} className="h-screen fixed z-50 flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 px-6">
                <div style={{ gap: "14px", color: "#ca8a04" }} className="flex h-16 shrink-0 items-center font-semibold">
                    <img
                        style={{ width: "24px" }}
                        src={Logo}
                        alt="Lily Reviews"
                    />
                    <label style={{ fontSize: "16px" }}>Lily Reviews</label>
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1">
                                <li key={"Dashboard"} onClick={() => { history.push("/") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/" ? classItemActive : classItemInactive, classItem)}                                >
                                        <HomeIcon className={classNames(window.location.pathname == "/" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Dashboard"}
                                    </a>
                                </li>
                                <li key={"Avaliações"} onClick={() => { history.push("/avaliacoes") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/avaliacoes" ? classItemActive : classItemInactive, classItem)}                                >
                                        <StarIcon className={classNames(window.location.pathname == "/avaliacoes" ? classItemActiveIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Avaliações"}
                                    </a>
                                </li>
                                <li key={"Perguntas"} onClick={() => { history.push("/perguntas") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/perguntas" ? classItemActive : classItemInactive, classItem)}                                >
                                        <ChatBubbleLeftRightIcon className={classNames(window.location.pathname == "/perguntas" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Perguntas"}
                                    </a>
                                </li>
                                <li key={"Cupons"} onClick={() => { history.push("/cupons") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/cupons" ? classItemActive : classItemInactive, classItem)}                                >
                                        <TagIcon className={classNames(window.location.pathname == "/cupons" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Cupons"}
                                    </a>
                                </li>
                                <li key={"Produtos"} onClick={() => { history.push("/produtos") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/produtos" ? classItemActive : classItemInactive, classItem)}                                >
                                        <ShoppingBagIcon className={classNames(window.location.pathname == "/produtos" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Produtos"}
                                    </a>
                                </li>
                                <li key={"Pedidos"} onClick={() => { history.push("/pedidos") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/pedidos" ? classItemActive : classItemInactive, classItem)}                                >
                                        <ShoppingCartIcon className={classNames(window.location.pathname == "/pedidos" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Pedidos"}
                                    </a>
                                </li>
                                <li key={"Depoimentos"} onClick={() => { history.push("/depoimentos") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/depoimentos" ? classItemActive : classItemInactive, classItem)}                                >
                                        <ChatBubbleBottomCenterIcon className={classNames(window.location.pathname == "/depoimentos" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Depoimentos"}
                                    </a>
                                </li>
                                <li key={"Passo a Passo"} onClick={() => { history.push("/passo-a-passo") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/passo-a-passo" ? classItemActive : classItemInactive, classItem)}                                >
                                        <AcademicCapIcon className={classNames(window.location.pathname == "/passo-a-passo" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Passo a Passo"}
                                    </a>
                                </li>
                                <li key={"Ajuda"} onClick={() => { history.push("/ajuda") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/ajuda" ? classItemActive : classItemInactive, classItem)}                                >
                                        <UserIcon className={classNames(window.location.pathname == "/ajuda" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Ajuda"}
                                    </a>
                                </li>


                                {/* {navigation.map((item) => (
                                <li key={item.name} onClick={() => { history.push(item.route) }}>
                                    <a
                                        href={item.href}
                                        className={classNames(
                                            item.current
                                                ? classItemActive
                                                : classItemInactive,
                                            classItem
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current ? classItemIcon : classItemInactiveIcon,
                                                classItemIcon
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                        {item.count ? (
                                            <span
                                                className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                                                aria-hidden="true"
                                            >
                                                {item.count}
                                            </span>
                                        ) : null}
                                    </a>
                                </li>
                            ))} */}
                            </ul>
                        </li>
                        <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">Configurações</div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                <li key={"Personalizar"} onClick={() => { history.push("/personalizar") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/personalizar" ? classItemActive : classItemInactive, classItem)}                                >
                                        <PaintBrushIcon className={classNames(window.location.pathname == "/personalizar" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Personalizar"}
                                    </a>
                                </li>
                                <li key={"Integrações"} onClick={() => { history.push("/integracoes") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/integracoes" ? classItemActive : classItemInactive, classItem)}                                >
                                        <Square3Stack3DIcon className={classNames(window.location.pathname == "/integracoes" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Integrações"}
                                    </a>
                                </li>
                                <li key={"Pagamentos"} onClick={() => { history.push("/pagamentos") }}>
                                    <a href={"#"} className={classNames(window.location.pathname == "/pagamentos" ? classItemActive : classItemInactive, classItem)}                                >
                                        <CreditCardIcon className={classNames(window.location.pathname == "/pagamentos" ? classItemIcon : classItemInactiveIcon, classItemIcon)} aria-hidden="true" />
                                        {"Pagamentos"}
                                    </a>
                                </li>
                                {/* {configs.map((item) => (
                                <li key={item.name}>
                                    <a
                                        href={item.href}
                                        className={classNames(
                                            item.current
                                                ? classItemActive
                                                : classItemInactive,
                                            classItem
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current ? classItemIcon : classItemActiveIcon,
                                                classItemIcon
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                        {item.count ? (
                                            <span
                                                className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                                                aria-hidden="true"
                                            >
                                                {item.count}
                                            </span>
                                        ) : null}
                                    </a>
                                </li>
                            ))} */}
                            </ul>
                        </li>
                        {/* <li className="-mx-6 mt-auto">
                        <a
                            href="#"
                            className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                        >
                            <img
                                className="h-8 w-8 rounded-full bg-gray-50"
                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                            />
                            <span className="sr-only">Your profile</span>
                            <span aria-hidden="true">Tom Cook</span>
                        </a>
                    </li> */}
                    </ul>
                </nav>
            </div>
        </>
    )
}
