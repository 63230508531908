
const INITIAL_STATE = {isEnd: false}

const ControlImportShopeeMore = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUCTS_CONTROL_IMPORT_SHOPEE_MORE':
            return state = action.payload
        default:
            return state;
    }
}

export default ControlImportShopeeMore