import styled from "styled-components";

export const Main = styled.div`
    //background-color: blue ;
    display: flex ;
    margin: 0px !important;
    width: 100%;
    min-width: 340px;
    //margin-bottom: 20px;
    
`
export const Container = styled.div`
    //background-color: yellow ;
    display: flex ;
    width: 100% ;
    flex-direction: column ;
    padding: 0px 20px;
    gap: 10px;
`
export const Title = styled.label`
    //background-color: blueviolet ;
    margin: 20px 0px;
    display: flex ;
    align-items: center ;

    label{
        display: flex ;
        flex: 1 ;
        //background-color: green ;
        justify-content: center ;
        font-size: 18px;
        color: #1E1E1E ;
        margin-left: 5px;
        //cursor: pointer;
        //margin: 20px 0px 10px 0px;
        font-weight: 400;
        font-family:"poppins";
    }

    span{
        //background-color: red;
        cursor: pointer;
    }
`

export const BarSubmenu = styled.div`
    display: flex;
    //width: 69px;
    //background-color: red;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px 0px;
    border-right: 1px solid #D3DAE3;
    border-bottom: ${props => props.isFull ? "1px solid #D3DAE3" : "none"};
    //height: ${props => !props.favorite ? "100%" : "calc(100% - 46px)"};
    height: calc(100% - 46px);
    //${props => props.visible != true ? `height: calc(100%);` : `height: calc(100% - 46px);`}
    align-items: center !important;
`;

export const BarSubmenuItem = styled.div`

    background-color: ${props => props.active ? " #ffc400" : "transparent"};
    border-radius: 19px;
    padding: 5px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0px 10px;

    :hover{
        background-color: ${props => !props.active ? "rgba(255, 204, 0, 0.3)" : "#ffc400"};
        
        p{
            display: flex;
        }

    }
    
`;

export const BarMenu = styled.div`
    //background-color: yellow ;
    display: flex ;

`



export const Subtitle = styled.div`
    //background-color: red ;
    font-size: 10px;
`

export const MenuOption = styled.div`
    //background-color: red ;
    display: flex;
    margin-bottom: 10px;
    gap: 10px;

`

export const ItemOption = styled.label`
    font-size: 14px;
    display: flex;
    flex: 1;
    justify-content: center;
    border-bottom: ${props => props.active ? "3px solid #FFCC00" : "none"};
    padding-bottom: 5px;
    color: ${props => props.active ? "#050505" : "inicial"};
    cursor: pointer;
    font-weight: ${props => props.active ? "500" : "inicial"};
    height: ${props => props.active ? "30px" : "33px"};
`

export const BoxTema = styled.div`
    flex-direction: column ;
    display: flex ;
    gap: 5px;
    border: 1px solid #D3DAE3;
    padding: 10px ;
    border-radius: 4px;

    label{
        font-size: 10px ;
    }
`

export const Item = styled.div`
    height: 30px ;
    display: flex ;
    align-items: center ;
    gap: 20px;
    //padding: 10px ;
    justify-content: space-between ;
    //background-color: red ;

    label{
        font-size: 12px;
        color: #000 ;
    }

    span{
        display: flex ;
        //background-color: yellow ;
        min-width: 100px !important;
        height: 30px ;
        display: flex ;
        align-items: center ;
        border: 1px solid #D3DAE3;
        border-radius: 6px;
        padding: 0px 10px ;
        gap: 10px;

        label{
            text-transform: uppercase;
        }

        div{
            width: 20px ;
            height: 20px ;
            background-color: black ;
            border-radius: 4px ;
        }

    input[type=color] {
        width: 20px;
        height: 20px; 
        border-radius: 5px;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        border: none;
        cursor: pointer;
        font-size: 11px ;   
        border: 1px solid #f1f1f144;
    }

    input[type=color]::-webkit-color-swatch {
        border: none;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        font-size: 11px ;
    }

    input[type=color]::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        font-size: 11px ;
    }
    }
`

export const BoxColors = styled.div`
    //background-color: red ;
    flex-direction: column ;
    display: flex ;
    gap: 5px;
    border: 1px solid #D3DAE3;
    padding: 10px ;
    border-radius: 4px;

    label{
        font-size: 10px ;
    }
`

export const ButtonHelp = styled.div`
    background-color: #121212 ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 48px ;
    border-radius: 4px ;
    justify-content: center ;
    //border: 1px dashed #0D6EFD;
    cursor: pointer;


    label{
        font-size: 12px ;
        cursor: pointer;
        color: #fff ;
    }

    img{
        width: 44px ;
        height: 44px ;
        cursor: pointer;
    }
`

export const ButtonSave = styled.div`
    background-color: #00803B  ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    font-size: 12px ;
    color: #fff ;
    cursor: pointer;
    flex: 1;
`

export const ButtonReset = styled.div`
    //background-color: red ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    border: 1px solid #D3DAE3;
    cursor: pointer;
    flex: 1;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #000 ;
    }

    img{
        width: 14px ;
        height: 16px ;
    }

`
export const BoxButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`

export const SelectCustom = styled.select`
    display: flex;
    background-color: #F5F3F0;
    height: 34px;
    width: 100% !important;
    font-weight: 600;
    color: #353535;
    border-width: 1px 1px 1px 0px;
    border-color: #EFF2F5;
    border-style: solid;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 10px 0px 5px;
    outline: none;
    font-size: 12px;
    cursor: pointer;

    @media(min-width: 480px) {
        width: 280px;
    }
`