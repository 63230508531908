import styled from 'styled-components'

export const Container =  styled.div`
    display: flex;
    gap: 30px;
    padding: 0px 20px;

    @media(max-width: 1100px) {
        flex-direction: column-reverse;
    }
`

export const ContainerCards = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;

    @media(max-width: 1100px) {
        flex-direction: row;
    }
`