import Modal from "react-modal";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { BoxInput, ButtonCustom, ButtonCustomClose, Close, Container, FormCustom, InputCustomDate, InputCustomText, SelectCustom, Stars, TextAreaCustom, Title } from "./styled";
import { GrFormClose } from "react-icons/gr";
import { AiFillStar, AiFillTag } from "react-icons/ai";
import { CounterItemsCircle } from "../../../thema/components/box-config-email/styled";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../../../translations";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        with: "500px"

    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999999999999999"
    }
};


const ModalEditReviews = (props) => {

    const dispatch = useDispatch()

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);




    const [name, setName] = useState(props?.data?.name)
    const [email, setEmail] = useState(props?.data?.email)
    const [whatsapp, setWhatsapp] = useState(props?.data?.whatsapp)
    const [message, setMessage] = useState(props?.data?.message)

    const [status, setStatus] = useState(props?.data?.status)
    const [verified, setVerified] = useState(props?.data?.verified)
    const [favorite, setFavorite] = useState(props?.data?.favorite)

    const [dateCreate, setDateCreate] = useState(props?.data?.created_at == null ? "25-08-2023" : props?.data?.created_at.slice(0, 10))

    const [qtdStar, setQtdStar] = useState(props?.data?.rating)



    const save = () => {
        dispatch({
            type: "SAGA_UPDATE_REVIEWS",
            payload: {
                id: props.data.id,
                name: name,
                email: email,
                whatsapp: whatsapp,
                message: message,
                status: status,
                verified: verified,
                created_at: dateCreate,
                rating: qtdStar,
                favorite: favorite
            }
        })
    }

    return (
        <Modal
            isOpen={props.visibleModal}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props.setVisibleModal(false)}
        >

            {/* <Close onClick={() => { props.setVisibleModal(false) }}>
                <GrFormClose size={25} color={"#5e6278"} />
            </Close> */}
            <Container>

                <Title>
                    <strong>{translation.reviews.modalEdit.editarAvaliacao}</strong>
                </Title>
                <FormCustom>
                    {/* <Stars>
                        <AiFillStar onClick={() => setQtdStar(1)} onMouseOver={() => hoverStar(1)} onMouseOut={() => hoverStarExit()} id="1star" size={30} color={qtdStar > 0 ? "orange" : "gray"} />
                        <AiFillStar onMouseOver={() => hoverStar(2)} onMouseOut={() => hoverStarExit()} id="2star" size={30} color={qtdStar > 1 ? "orange" : "gray"} />
                        <AiFillStar onMouseOver={() => hoverStar(3)} onMouseOut={() => hoverStarExit()} id="3star" size={30} color={qtdStar > 2 ? "orange" : "gray"} />
                        <AiFillStar onMouseOver={() => hoverStar(4)} onMouseOut={() => hoverStarExit()} id="4star" size={30} color={qtdStar > 3 ? "orange" : "gray"} />
                        <AiFillStar onMouseOver={() => hoverStar(5)} onMouseOut={() => hoverStarExit()} id="5star" size={30} color={qtdStar > 4 ? "orange" : "gray"} />
                    </Stars> */}
                    <BoxInput>
                        <label>{translation.reviews.modalEdit.estrelas}:</label>
                        <SelectCustom onChange={(e) => { setQtdStar(e.target.value) }}>
                            <option value={"1"} selected={qtdStar == "1" ? true : false}>1 {translation.reviews.modalEdit.estrela}</option>
                            <option value={"2"} selected={qtdStar == "2" ? true : false}>2 {translation.reviews.modalEdit.estrelas}</option>
                            <option value={"3"} selected={qtdStar == "3" ? true : false}>3 {translation.reviews.modalEdit.estrelas}</option>
                            <option value={"4"} selected={qtdStar == "4" ? true : false}>4 {translation.reviews.modalEdit.estrelas}</option>
                            <option value={"5"} selected={qtdStar == "5" ? true : false}>5 {translation.reviews.modalEdit.estrelas}</option>
                        </SelectCustom>
                    </BoxInput>
                    <BoxInput>
                        <label>{translation.reviews.modalEdit.nome}:</label>
                        <InputCustomText>
                            <input type={"text"} placeholder={"Nome"} value={name} onChange={(e) => { setName(e.target.value) }} />
                        </InputCustomText>
                    </BoxInput>
                    <BoxInput>
                        <label>E-mail:</label>
                        <InputCustomText>
                            <input type={"text"} placeholder={"E-mail"} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </InputCustomText>
                    </BoxInput>
                    <BoxInput>
                        <label>Whatsapp:</label>
                        <InputCustomText>
                            <input type={"text"} placeholder={"Whatsapp"} value={whatsapp} onChange={(e) => { setWhatsapp(e.target.value) }} />
                        </InputCustomText>
                    </BoxInput>
                    <BoxInput style={{ position: "relative" }}>
                        <label>{translation.reviews.modalEdit.mensagem}:</label>
                        <TextAreaCustom maxLength="255" rows={3} value={message} onChange={(e) => { setMessage(e.target.value) }} />
                        <CounterItemsCircle porc={`${Math.round(message.length / 255 * 100)}%`}>
                            <label>{255 - message.length}</label>
                        </CounterItemsCircle>
                    </BoxInput>
                    <BoxInput>
                        <label>{translation.avaliacoes.data}:</label>
                        <InputCustomDate type={"date"} value={dateCreate} lang="fr-CA" onChange={(e) => { setDateCreate(e.target.value) }} />
                    </BoxInput>
                    <BoxInput>
                        <label>Status:</label>
                        <SelectCustom onChange={(e) => { setStatus(e.target.value) }}>
                            <option value={"approved"} selected={status == "approved" ? true : false}>{translation.reviews.modalEdit.aprovado}</option>
                            <option value={"hidden"} selected={status == "hidden" ? true : false}>{translation.reviews.modalEdit.escondido}</option>
                            <option value={"pending"} selected={status == "pending" ? true : false}>{"Pendente"}</option>
                        </SelectCustom>
                    </BoxInput>
                    <BoxInput>
                        <label>Compra verificada:</label>
                        <SelectCustom onChange={(e) => { setVerified(e.target.value) }}>
                            <option value={"1"} selected={verified == "1" ? true : false}>{translation.reviews.modalEdit.sim}</option>
                            <option value={"0"} selected={verified == "0" ? true : false}>{translation.reviews.modalEdit.nao}</option>
                        </SelectCustom>
                    </BoxInput>
                    <BoxInput>
                        <label>Favorita:</label>
                        <SelectCustom onChange={(e) => { setFavorite(e.target.value) }}>
                            <option value={"1"} selected={favorite == "1" ? true : false}>{translation.reviews.modalEdit.sim}</option>
                            <option value={"0"} selected={favorite == "0" ? true : false}>{translation.reviews.modalEdit.nao}</option>
                        </SelectCustom>
                    </BoxInput>
                </FormCustom>
                <ButtonCustom onClick={() => { save() }}>{translation.reviews.modalEdit.salvar}</ButtonCustom>
                <ButtonCustomClose onClick={() => { props.setVisibleModal(false) }}>{translation.reviews.modalEdit.cancelar}</ButtonCustomClose>
            </Container>

        </Modal>
    );
};

export default ModalEditReviews;
