import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Contents from "../../../../components/Contents";
import { Container } from './styled'
import { isVisibleScript } from "../../../../utils/displayScript";
import { useDispatch,  } from "react-redux";
import { getTranslation } from "../../../../translations";

const Email = () => {

    return (
        <Body>
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1", minWidth: '0%' }} >
                <Header />
                <Contents>
                    <Container>
                        <iframe width={"100%"} height={"100%"} src={`${process.env.REACT_APP_URL_EDITOR_EMAIL}/editor?name=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country == "BR" ? "review" : "review_es"}&app=lily&user_id_ref=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id}&token=${process.env.REACT_APP_TOKEN_EDITOR_EMAIL}`} />
                    </Container>
                </Contents>
            </div>
            {isVisibleScript(false)}
        </Body>
    );
}

export default Email