
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AlertSave, Box, BoxActions, BoxContentStep, BoxForm, BoxHelp, BoxInfor, BoxLoadingCustom, BoxOptions, BoxStepsConfig, Btn, BtnFaq, ButtonDefault, ButtonIr, ButtonOpenEditor, ButtonSave, Container, Description, DescriptionSteps, ErrorMessage, FormCustom, InputCustomItem, InputCustomItemColor, InputFileCustom, InputTextCustom, ItemOption, SectionCardsIntegration, SectionContents, SelectCustom, StepCircle, TextAreaCustom, TextHelp, TextSupport, Title, TitleSteps, Tooltips } from './styled';
import { FaRegQuestionCircle, FaUpload } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { AiFillCheckCircle, AiFillPlayCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { useHistory } from 'react-router-dom';
import IconBossSave from '../../../../assets/imgs/boss-save.svg';

import ModalInforVideoFavorite from "../../../../components/modal-infor-video";
import ModalInforVideoEditor from "../../../../components/modal-infor-video";
import ModalInforVideoVisibleBox from "../../../../components/modal-infor-video";
import { getTranslation } from "../../../../translations";

import ImgBossLupa from "../../../../assets/imgs/boss/boss-lupa.svg";
import IconSetaButton from "../../../../assets/imgs/seta-icon-button.svg";
import { BsArrowRight } from "react-icons/bs";
import PreviewEmail from '../preview-email';
import PreviewHome from '../preview-home';
import { EditCustom } from '../form-email/styled';
import IconEdit from '../../../../assets/imgs/icon-edit.svg';
import PreviewHomeCarrossel from '../preview-home-carrossel';
import ModalAlertSave from '../modal-alert-save';
import PreviewHomeCarrosselMulti from '../preview-home-carrossel-multi';
import PreviewAvaliar from '../preview-avaliar';

const FormAvaliar = () => {

    const [menuActive, setmenuActive] = useState("favorite")

    const config = useSelector(state => state.reducerGetConfig)
    const dispatch = useDispatch()
    const history = useHistory()
    const tooltip = { color: "#3F4254", size: 12 }

    const [enableEditor, setEnableEditor] = useState(config.data.editor)
    const [enableEditorControl, setEnableEditorControl] = useState(config.data.editor)
    const [visibleHome, setVisibleHome] = useState(config.data.homereviews)

    const statusUpdateConfigHome = useSelector(state => state.reducerUpdateConfigHome)

    const [visibleModalInforVideoFavorite, setVisibleModalInforVideoFavorite] = useState(false)
    const [visibleModalInforVideoEditor, setVisibleModalInforVideoEditor] = useState(false)
    const [visibleModalInforVideoVisibleBox, setVisibleModalInforVideoVisibleBox] = useState(false)

    const reviewsFavorites = useSelector(state => state.reducerReviews)
    const controlSaveHomeColor = useSelector(state => state.reducerControlSaveHomeColor)
    const controlSaveHomeConfig = useSelector(state => state.reducerControlSaveHomeConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        dispatch({
            type: "SAGA_LIST_REVIEWS", payload: {
                rating: "",
                status: "all",
                search: "",
                perPage: 10,
                page: 1,
                medias: "",
                favorite: true
            }
        })
    }, [])


    useEffect(() => {
        const id = "statusUpdateConfigHome"
        if (!(statusUpdateConfigHome.loading == false && !statusUpdateConfigHome.error == false && statusUpdateConfigHome.data.length == 0)) {
            if (statusUpdateConfigHome.loading) {
                toast.loading(translation.thema.formHome.aguarde, { toastId: id })
            } else {
                if (statusUpdateConfigHome.error) toast.update(id, { render: translation.thema.formHome.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.thema.formHome.podeLevar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
                    //dispatch({ type: "CONFIG_UPDATE_HOME_REQUEST", })
              
                }
            }
        } else if (statusUpdateConfigHome.error) toast.update(id, { render: translation.thema.formHome.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    }, [statusUpdateConfigHome])

    const save = () => {
        //alert("teste", {enableEditor: enableEditor})
        dispatch({
            type: "SAGA_UPDATE_CONFIG_HOME",
            payload: {
                id: config.data.id,
                editor: enableEditor == 1 ? true : false,
                homereviews: visibleHome == 1 ? true : false
            }
        })
    }

    useEffect(() => {
        setEnableEditorControl(config.data.editor)
    }, [config])






    const getWidget = () => {
        switch (configControl.homereviews_widget_style) {
            case "modelo1":
                return <PreviewAvaliar />
            default:
                return <PreviewAvaliar />
        }
    }

    return (
        <>
            {getWidget()}

            {/* {!controlSaveHomeColor &&
                <ModalAlertSave>
                    <span>
                        <AlertSave>
                            <img src={IconBossSave} />
                            <span>
                                <label>{translation.thema.alertText}</label>
                                <div>
                                    <label onClick={() => {
                                        dispatch({ type: "CONFIG_CONTROL_SET", payload: config.data })
                                        dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_COLOR_SET", payload: true })
                                    }} >
                                        {translation.thema.alertDescartar}
                                    </label>
                                    <span onClick={() => {
                                        dispatch({
                                            type: "SAGA_UPDATE_CONFIG",
                                            payload: {
                                                id: config.data?.id,
                                                homereviews_background_color: configControl.homereviews_background_color,
                                                homereviews_arrow_color: configControl.homereviews_arrow_color,
                                                //homereviews_step_color: configControl.homereviews_arrow_color,
                                                homereviews_color_title: configControl.homereviews_color_title,
                                                homereviews_background_color_card: configControl.homereviews_background_color_card,
                                                homereviews_font_color_card: configControl.homereviews_font_color_card,
                                                homereviews_color_verified_card: configControl.homereviews_color_verified_card,
                                                homereviews_color_star_card: configControl.homereviews_color_star_card,
                                                homereviews_color_border_card: configControl.homereviews_color_border_card,
                                                homereviews_pagination_color: configControl.homereviews_pagination_color
                                            }
                                        })
                                    }}
                                    >
                                        {translation.thema.alertSalvar}
                                    </span>
                                </div>
                            </span>
                        </AlertSave>
                    </span>
                </ModalAlertSave >} */}

            {/* {!controlSaveHomeConfig &&
                <ModalAlertSave>
                    <span>
                        <AlertSave>
                            <img src={IconBossSave} />
                            <span>
                                <label>{translation.thema.alertText}</label>
                                <div>
                                    <label onClick={() => { dispatch({ type: "SAGA_GET_CONFIG" }) }} >
                                        {translation.thema.alertDescartar}
                                    </label>
                                    <span onClick={() => {
                                       dispatch({
                                            type: "SAGA_UPDATE_CONFIG",
                                            payload: {
                                                id: config.data?.id,
                                                homereviews: configControl.homereviews ? 1 : 0,
                                                editor: configControl.editor ? 1 : 0,

                                                homereviews_title: configControl.homereviews_title,
                                                homereviews_widget_style: configControl.homereviews_widget_style,
                                                homereviews_animation: configControl.homereviews_animation,
                                                homereviews_font_title: configControl.homereviews_font_title,
                                                homereviews_font_size_title: configControl.homereviews_font_size_title,
                                                homereviews_align_title: configControl.homereviews_align_title,
                                                homereviews_card: configControl.homereviews_card,
                                                homereviews_radius_border_card: configControl.homereviews_radius_border_card,
                                                homereviews_size_border_card: configControl.homereviews_size_border_card,
                                                homereviews_visible_verified_card: configControl.homereviews_visible_verified_card,

                                                homereviews_navigation: configControl.homereviews_navigation,
                                                homereviews_progress: configControl.homereviews_progress,
                                                homereviews_pagination: configControl.homereviews_pagination,
                                                homereviews_loop: configControl.homereviews_loop,
                                            }
                                        })
                                    }}
                                    >
                                        {translation.thema.alertSalvar}
                                    </span>
                                </div>
                            </span>
                       </AlertSave>
                    </span>
                </ModalAlertSave>} */}
        </>
    )
}

export default FormAvaliar