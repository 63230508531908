import React from 'react'
import { BoxStars, Container, DateInfor, Name, TextReview } from './styled'
import { AiFillStar } from "react-icons/ai";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { BadgeCheck } from '../card-01-dropi-carousel/styled';
import { HiBadgeCheck } from 'react-icons/hi';
import { getTranslation } from '../../../../translations';

const Card1Home = (props) => {

    const config = useSelector(state => state.reducerControlConfig)
    const [icon, setIcon] = useState({ size: 20, color: "orange" })

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    useEffect(() => {
        setIcon({ ...icon, colorActive: config?.homereviews_color_star_card, colorInative: "#dcdcdc" })
    }, [config])



    return (
        <Container
            background={config.homereviews_background_color_card}
            colorBorder={config?.homereviews_color_border_card}
            sizeBorder={config?.homereviews_size_border_card}
            radiusBorder={config?.homereviews_radius_border_card}
        >
            <TextReview fontColor={config.homereviews_font_color_card}>
                "{props.item.text}"
            </TextReview>
            <BoxStars>
                <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? icon.colorActive : icon.colorInative} />
                <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? icon.colorActive : icon.colorInative} />
                <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? icon.colorActive : icon.colorInative} />
                <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? icon.colorActive : icon.colorInative} />
                <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? icon.colorActive : icon.colorInative} />
            </BoxStars>
            <Name fontColor={config.homereviews_font_color_card}>
                {props.item?.name} Silva
                {config.homereviews_visible_verified_card == "1" &&
                    <BadgeCheck style={{alignItems: "center", display: "flex"}}>
                        {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={20} color={config?.homereviews_color_verified_card} />}
                        <label>{translation.thema.cardReviewDropiPreview.compraVerificada}</label>
                    </BadgeCheck>}
            </Name>
            <DateInfor fontColor={config.homereviews_font_color_card}>
                {config.visible_date_review == "1" && "01/12/2023"}
            </DateInfor>
        </Container>
    )
}

export default Card1Home