import { BoxSubMenulabel, BoxMenu, BoxSubMenu, Container, SectionLogo, TitleMenu, ItemMenu, SectionMenu, ItemMenuChaveEmpreender, ItemMenuOptions, TooltipItem } from "./styled";
import { RiChatPollFill, RiDashboardFill } from "react-icons/ri";
import { AiFillStar, AiOutlineLogout, AiOutlineCopy, AiFillTag } from "react-icons/ai";
import { RiQuestionnaireFill, RiAccountBoxFill, RiKeyFill } from "react-icons/ri";
import { FaPiggyBank, FaLayerGroup, FaBoxOpen } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { MdAttachMoney } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useBurgerMenu } from '../../hooks/useBurgerMenu'
import Logo from "../../assets/imgs/Girrafe.svg";
import { toast } from "react-toastify";
import { IoStorefrontSharp } from "react-icons/io5";
import { FcDataConfiguration } from "react-icons/fc";

import animation from "../../assets/animation/lily.webm";
import { BsFillGearFill } from "react-icons/bs";

import { IoIosArrowDown, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { TiStarOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";

import iconDashboard from "./../../assets/imgs/icons-sidebar/dashboard.svg";
import iconDashboardAtivo from "./../../assets/imgs/icons-sidebar/dashboard-ativo.svg";
import iconReviews from "./../../assets/imgs/icons-sidebar/reviews.svg";
import iconReviewsAtivo from "./../../assets/imgs/icons-sidebar/reviews-ativo.svg";
import iconQuestions from "./../../assets/imgs/icons-sidebar/questions.svg";
import iconQuestionsAtivo from "./../../assets/imgs/icons-sidebar/questions-ativo.svg";
import iconCupons from "./../../assets/imgs/icons-sidebar/cupons.svg";
import iconCuponsAtivo from "./../../assets/imgs/icons-sidebar/cupons-ativo.svg";
import iconProducts from "./../../assets/imgs/icons-sidebar/products.svg";
import iconProductsAtivo from "./../../assets/imgs/icons-sidebar/products-ativo.svg";
import iconIntegrations from "./../../assets/imgs/icons-sidebar/integrations.svg";
import iconIntegrationsAtivo from "./../../assets/imgs/icons-sidebar/integrations-ativo.svg";
import iconConfig from "./../../assets/imgs/icons-sidebar/config.svg";
import iconConfigAtivo from "./../../assets/imgs/icons-sidebar/config-ativo.svg";
import iconSteps from "./../../assets/imgs/icons-sidebar/steps.svg";
import iconStepsAtivo from "./../../assets/imgs/icons-sidebar/steps-ativo.svg";
import iconCores from "./../../assets/imgs/icons-sidebar/submenu-cores.svg";
import iconCoresAtivo from "./../../assets/imgs/icons-sidebar/submenu-cores-ativo.svg";
import iconAjustes from "./../../assets/imgs/icons-sidebar/submenu-ajustes.svg";
import iconAjustesAtivo from "./../../assets/imgs/icons-sidebar/submenu-ajustes-ativo.svg";
import iconAssinatura from "./../../assets/imgs/icons-sidebar/assinatura-inativo.svg";
import iconAssinaturaAtivo from "./../../assets/imgs/icons-sidebar/assinatura-ativo.svg";
import iconAjuda from "./../../assets/imgs/icons-sidebar/ajuda-inativo.svg";
import iconAjudaAtivo from "./../../assets/imgs/icons-sidebar/ajuda-ativo.svg";
import iconPersonalizar from "./../../assets/imgs/icons-sidebar/personalizar-menu-icon.svg";
import { getTranslation } from "../../translations";
import { NoteText, Sms, Trash } from "iconsax-react";

const SideBar = () => {

    const { burgerMenu, setBurgerMenu } = useBurgerMenu()

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)

    //let translation = getTranslation(localStorage?.getItem("translation@lily") == undefined ? "ptBr" : localStorage.getItem("translation@lily"))

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language)

    const icon = { size: 25, color: "#A1A5B7" }
    const listNotify = useSelector(state => state.reducerListNotify)
    const menuOpen = useSelector(state => state.reducerControlMenu)

    const selectedMenu = useLocation();
    const history = useHistory();
    const [visibleSubMenuConfig, setVisibleSubMenuConfig] = useState(true)

    const edit = useSelector(state => state.reducerControlEdit)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch({ type: "HEADER_CONTROL_MENU", payload: { isOpen: localStorage.getItem("lily@sidebar_is_open") == "false" ? false : true } })
    }, [])


    useEffect(() => {
        if (selectedMenu.pathname == "/personalizar/tema" || selectedMenu.pathname == "/personalizar" || selectedMenu.pathname == "/personalizar/email") {
            setVisibleSubMenuConfig(true)
        } else {
            setVisibleSubMenuConfig(false)
        }
    }, [selectedMenu.pathname])

    useEffect(() => {

        // const animation = document.getElementById("animation")
        // animation.addEventListener("mouseenter", function (e) {
        //     this.play()
        // }, false)


        // function handleResize() {
        //     window.innerWidth <= 768 ? setBurgerMenu(true) : setBurgerMenu(false)
        // }

        // window.addEventListener('resize', handleResize);
        // return () => { window.removeEventListener('resize', handleResize) };
    }, [])

    function handleClick(path) {
        window.innerWidth <= 768 ? setBurgerMenu(true) : setBurgerMenu(false)
        history.push(path)
    }

    // useEffect(() => {
    //     function handleClickOutside(e) {
    //         if (window.innerWidth > 768) return
    //         let id = e.target?.id
    //         if (id === '') {
    //             id = e.target?.parentElement?.id
    //         }

    //         if (id !== 'burger__id' && !document.getElementById('container__sidemenu')?.contains(e.target)) {
    //             setBurgerMenu(true)
    //         }
    //     }

    //     document.addEventListener('click', handleClickOutside);

    //     return () => { document.removeEventListener('click', handleClickOutside) }
    // }, [setBurgerMenu])





    const isSaveHomeConfig = () => {

        if (
            configControl.homereviews == config.data.homereviews &&
            configControl.homereviews_title == config.data.homereviews_title &&
            configControl.homereviews_animation == config.data.homereviews_animation &&
            configControl.homereviews_widget_style == config.data.homereviews_widget_style &&

            configControl.homereviews_align_title == config.data.homereviews_align_title &&
            configControl.homereviews_font_size_title == config.data.homereviews_font_size_title &&
            configControl.homereviews_font_title == config.data.homereviews_font_title &&

            configControl.homereviews_radius_border_card == config.data.homereviews_radius_border_card &&
            configControl.homereviews_visible_verified_card == config.data.homereviews_visible_verified_card &&
            configControl.homereviews_size_border_card == config.data.homereviews_size_border_card
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_CONFIG_SET", payload: false })
            return false
        }
    }

    const isSaveHomeColor = () => {

        if (
            configControl.homereviews_background_color == config.data.homereviews_background_color &&
            // configControl.homereviews_arrow_color == config.data.homereviews_arrow_color &&
            // configControl.homereviews_step_color == config.data.homereviews_step_color &&
            configControl.homereviews_color_title == config.data.homereviews_color_title &&
            configControl.homereviews_font_color_card == config.data.homereviews_font_color_card &&
            configControl.homereviews_background_color_card == config.data.homereviews_background_color_card &&
            configControl.homereviews_color_verified_card == config.data.homereviews_color_verified_card &&
            configControl.homereviews_color_star_card == config.data.homereviews_color_star_card &&
            configControl.homereviews_color_border_card == config.data.homereviews_color_border_card
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_COLOR_SET", payload: false })
            return false
        }
    }

    const isSaveEmailColor = () => {

        if (
            configControl.color_text_button == config.data.color_text_button &&
            configControl.background_button == config.data.background_button &&
            configControl.color_text == config.data.color_text &&
            configControl.color_border == config.data.color_border &&
            configControl.background_body == config.data.background_body
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_COLOR_SET", payload: false })
            return false
        }
    }

    const isSaveEmailConfig = () => {

        if (
            config.data.font_email == configControl.font_email &&
            config.data.request_email_logourl == configControl.request_email_logourl &&
            config.data.request_email_content == configControl.request_email_content &&
            config.data.request_email_subject == configControl.request_email_subject &&
            config.data.request_email_buttontext == configControl.request_email_buttontext &&
            config.data.request_email == configControl.request_email &&
            config.data.request_email_date == configControl.request_email_date
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_CONFIG_SET", payload: false })
            return false
        }
    }

    const isSaveProductColor = () => {

        if (
            config.data.star_color_primary == configControl.star_color_primary &&
            config.data.star_color_secondary == configControl.star_color_secondary &&
            config.data.font_color_primary == configControl.font_color_primary &&
            config.data.font_color_secondary == configControl.font_color_secondary &&
            config.data.primary_color == configControl.primary_color &&
            config.data.background_color_primary == configControl.background_color_primary &&
            config.data.background_color_secondary == configControl.background_color_secondary
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_COLOR_SET", payload: false })
            return false
        }
    }

    const isSaveProductConfig = () => {
     
        if (
            configControl.no_review_star == config.data.no_review_star &&
            configControl.visible_button_review == config.data.visible_button_review &&
            configControl.visible_button_ask == config.data.visible_button_ask &&
            configControl.visible_date_review == config.data.visible_date_review &&
            configControl.itens_per_page == config.data.itens_per_page &&
            configControl.layout == config.data.layout &&
            configControl.layout_question == config.data.layout_question &&
            configControl.layout_form == config.data.layout_form &&
            configControl.layout_form_ask == config.data.layout_form_ask &&
            configControl.terms_use == config.data.terms_use &&
            configControl.font == config.data.font &&
            configControl.order_reviews == config.data.order_reviews &&
            configControl.order_asks == config.data.order_asks &&
            configControl.layout_stars == config.data.layout_stars &&
            configControl.resume_reviews == config.data.resume_reviews &&
            configControl.layout_resume == config.data.layout_resume &&
            configControl.auto_approve == config.data.auto_approve
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_CONFIG_SET", payload: false })
            return false
        }
    }

    const verifiSave = (action) => {



        if (edit == "home/cores") {
            isSaveHomeColor() && handleClick(action)
        } else if (edit == "home/config") {
            isSaveHomeConfig() && handleClick(action)
        } else if (edit == "email/cores") {
            isSaveEmailColor() && handleClick(action)
        } else if (edit == "email/config") {
            isSaveEmailConfig() && handleClick(action)
        } else if (edit == "produto/cores" || edit == "") {
            isSaveProductColor() && handleClick(action)
        } else if (edit == "produto/config") {
            isSaveProductConfig() && handleClick(action)
        } else {
            handleClick(action)
        }
    }


    return (

        <Container menuOpen={menuOpen.isOpen}>
            <SectionLogo menuOpen={menuOpen.isOpen}>
                <img style={{ cursor: "pointer", cursor: "pointer" }} src={Logo} onClick={() => { history.push("/") }} />
                {/* <video style={{ cursor: "pointer" }} id="animation" width="75" muted onClick={() => verifiSave("/")} >
                    <source src={animation} type="video/webm" />
                </video> */}
            </SectionLogo>
            <SectionMenu>

                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/" ? true : false}>
                    <img onClick={() => { verifiSave("/") }}
                        //src={selectedMenu.pathname == "/" ? iconDashboardAtivo : iconDashboard}
                        src={iconDashboard}
                    />
                    <label onClick={() => { verifiSave("/") }} > {translation.sidebar.dashboard}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/" ? true : false}>
                            {translation.sidebar.dashboard}
                        </TooltipItem>
                    </span>
                </ItemMenu >
                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/avaliacoes" ? true : false}>
                    <img onClick={() => { verifiSave("/avaliacoes") }}
                        //src={selectedMenu.pathname === "/avaliacoes" ? iconReviewsAtivo : iconReviews}
                        src={iconReviews}
                    />
                    <label onClick={() => { verifiSave("/avaliacoes") }}> {translation.sidebar.avaliacoes}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/avaliacoes" ? true : false}>
                            {translation.sidebar.avaliacoes}
                        </TooltipItem>
                    </span>
                </ItemMenu>
                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/perguntas" ? true : false}>
                    <img onClick={() => { verifiSave("/perguntas") }}
                        //src={selectedMenu.pathname === "/perguntas" ? iconQuestionsAtivo : iconQuestions}
                        src={iconQuestions}
                    />
                    <label onClick={() => { verifiSave("/perguntas") }} > {translation.sidebar.perguntas}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/perguntas" ? true : false}>
                            {translation.sidebar.perguntas}
                        </TooltipItem>
                    </span>
                </ItemMenu>

                {/* <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/email" ? true : false}>
                    <Sms onClick={() => { verifiSave("/email") }}  size="24" color="gray" />
                    <label onClick={() => { verifiSave("/email") }} > {"E-mail"}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/email" ? true : false}>
                            {"E-mail"}
                        </TooltipItem>
                    </span>
                </ItemMenu> */}

                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/cupons" ? true : false}>
                    <img onClick={() => { verifiSave("/cupons") }}
                        //src={selectedMenu.pathname === "/cupons" ? iconCuponsAtivo : iconCupons}
                        src={iconCupons}
                    />
                    <label onClick={() => { verifiSave("/cupons") }}> {translation.sidebar.cupons}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/cupons" ? true : false}>
                            {translation.sidebar.cupons}
                        </TooltipItem>
                    </span>
                </ItemMenu>
                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/produtos" ? true : false}>
                    <img onClick={() => { verifiSave("/produtos") }}
                        //src={selectedMenu.pathname === "/produtos" ? iconProductsAtivo : iconProducts}
                        src={iconProducts}
                    />
                    <label onClick={() => { verifiSave("/produtos") }}> {translation.sidebar.meusProdutos}</label>
                    <span>
                        <label>.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/produtos" ? true : false}>
                            {translation.sidebar.meusProdutos}
                        </TooltipItem>
                    </span>
                </ItemMenu>

                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/pedidos" ? true : false}>
                    {/* <img onClick={() => { verifiSave("/emails") }}
                  
                        src={iconProducts}
                    /> */}
                    <svg onClick={() => { verifiSave("/pedidos") }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2H3.74C4.82 2 5.67 2.93 5.58 4L4.75 13.96C4.71759 14.3459 4.76569 14.7342 4.89123 15.1005C5.01678 15.4669 5.21705 15.8031 5.47934 16.0879C5.74163 16.3728 6.06023 16.6001 6.41495 16.7553C6.76967 16.9106 7.15278 16.9905 7.54 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82M9 8H21M16.25 22C16.5815 22 16.8995 21.8683 17.1339 21.6339C17.3683 21.3995 17.5 21.0815 17.5 20.75C17.5 20.4185 17.3683 20.1005 17.1339 19.8661C16.8995 19.6317 16.5815 19.5 16.25 19.5C15.9185 19.5 15.6005 19.6317 15.3661 19.8661C15.1317 20.1005 15 20.4185 15 20.75C15 21.0815 15.1317 21.3995 15.3661 21.6339C15.6005 21.8683 15.9185 22 16.25 22ZM8.25 22C8.58152 22 8.89946 21.8683 9.13388 21.6339C9.3683 21.3995 9.5 21.0815 9.5 20.75C9.5 20.4185 9.3683 20.1005 9.13388 19.8661C8.89946 19.6317 8.58152 19.5 8.25 19.5C7.91848 19.5 7.60054 19.6317 7.36612 19.8661C7.1317 20.1005 7 20.4185 7 20.75C7 21.0815 7.1317 21.3995 7.36612 21.6339C7.60054 21.8683 7.91848 22 8.25 22Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <label onClick={() => { verifiSave("/pedidos") }}> {translation.sidebar.pedidos}</label>
                    <span>
                        <label>.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/pedidos" ? true : false}>
                            {translation.sidebar.pedidos}
                        </TooltipItem>
                    </span>
                </ItemMenu>

                {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "nuvemshop" ||
                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "shopify" ||
                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "bagy" ||
                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "cartpanda" ||
                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "loja_integrada" ?
                    <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/depoimentos" ? true : false}>
                        <NoteText onClick={() => { verifiSave("/depoimentos") }} size="24" color="#00000088" />
                        <label onClick={() => { verifiSave("/depoimentos") }}> {translation.sidebar.depoimentos}</label>
                        <span>
                            <label>.</label>
                            <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/depoimentos" ? true : false}>
                                {translation.sidebar.depoimentos}
                            </TooltipItem>
                        </span>
                    </ItemMenu> : null}


                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/integracoes" ? true : false}>
                    <img onClick={() => { verifiSave("/integracoes") }}
                        //src={selectedMenu.pathname === "/integracoes" ? iconIntegrationsAtivo : iconIntegrations}
                        src={iconIntegrations}
                    />
                    <label onClick={() => { verifiSave("/integracoes") }}> {translation.sidebar.integracoes}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/integracoes" ? true : false}>
                            {translation.sidebar.integracoes}
                        </TooltipItem>
                    </span>
                </ItemMenu>
                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/personalizar" || selectedMenu.pathname === "/personalizar/tema" || selectedMenu.pathname === "/personalizar/email" || selectedMenu.pathname === "/personalizar/home" ? true : false}>
                    <img onClick={() => { verifiSave("/personalizar") }}
                        //src={selectedMenu.pathname === "/personalizar" ? iconConfigAtivo : iconConfig}
                        src={iconPersonalizar}
                    />
                    <label onClick={() => { verifiSave("/personalizar") }}> {translation.sidebar.personalizar}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/personalizar" ? true : false}>
                            {translation.sidebar.personalizar}
                        </TooltipItem>
                    </span>
                </ItemMenu>
                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/pagamentos" ? true : false}>
                    <img onClick={() => { verifiSave("/pagamentos") }}
                        //src={selectedMenu.pathname === "/pagamentos" ? iconAssinaturaAtivo : iconAssinatura}
                        src={iconAssinatura}
                    />
                    <label onClick={() => { verifiSave("/pagamentos") }}> {translation.sidebar.assinatura}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/pagamentos" ? true : false}>
                            {translation.sidebar.assinatura}
                        </TooltipItem>
                    </span>
                </ItemMenu>
                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/passo-a-passo" ? true : false}>
                    <img onClick={() => { verifiSave("/passo-a-passo") }}
                        //src={selectedMenu.pathname === "/passo-a-passo" ? iconStepsAtivo : iconSteps}
                        src={iconSteps}
                    />
                    <label onClick={() => { verifiSave("/passo-a-passo") }}> {translation.sidebar.passoapasso}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/passo-a-passo" ? true : false} >
                            {translation.sidebar.passoapasso}
                        </TooltipItem>
                    </span>
                </ItemMenu>

                {/* <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/lixeira" ? true : false}>
                    <Trash onClick={() => { verifiSave("/lixeira") }} size="24" color="#00000088" />
                    <label onClick={() => { verifiSave("/lixeira") }}> {"Lixeira"}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/lixeira" ? true : false} >
                            {"Lixeira"}
                        </TooltipItem>
                    </span>
                </ItemMenu> */}

                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/ajuda" ? true : false}>
                    <img onClick={() => { verifiSave("/ajuda") }}
                        //src={selectedMenu.pathname === "/ajuda" ? iconAjudaAtivo : iconAjuda} 
                        src={iconAjuda}
                    />
                    <label onClick={() => { verifiSave("/ajuda") }}> {translation.sidebar.ajuda}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/ajuda" ? true : false} >
                            {translation.sidebar.ajuda}
                        </TooltipItem>
                    </span>
                </ItemMenu>
            </SectionMenu>
        </Container >




















        // <Container id="container__sidemenu" style={burgerMenu ? { transform: 'translateX(-250px)' } : { transform: 'translateX(0px)' }}>
        //     <SectionLogo>
        //         <span id="box-animation">
        //             {/* <img style={{ cursor: "pointer" }} src={Logo} onClick={() => { history.push("/") }} /> */}
        //             <video style={{ cursor: "pointer" }} id="animation" width="75" muted onClick={() => handleClick("/")} >
        //                 <source src={animation} type="video/webm" />
        //             </video>
        //         </span>
        //     </SectionLogo>
        //     <SectionMenu>
        //         <TitleMenu>MENU</TitleMenu>
        //         <ItemMenu onClick={() => { handleClick("/") }} selectedMenu={selectedMenu.pathname === "/" ? true : false}>
        //             <RiDashboardFill size={icon.size} color={icon.color} />
        //             <label>Dashboard</label>
        //         </ItemMenu>
        //         <ItemMenu onClick={() => { handleClick('/avaliacoes') }} selectedMenu={selectedMenu.pathname === "/avaliacoes" ? true : false}>
        //             <AiFillStar size={icon.size} color={icon.color} />
        //             <label>Avaliações</label>
        //             {listNotify.data?.data?.newReview > 0 &&
        //                 <span>
        //                     <label>{listNotify.data?.data?.newReview}</label>
        //                 </span>}
        //         </ItemMenu>
        //         <ItemMenu onClick={() => { handleClick("/perguntas") }} selectedMenu={selectedMenu.pathname === "/perguntas" ? true : false}>
        //             <RiQuestionnaireFill size={icon.size} color={icon.color} />
        //             <label>Perguntas</label>
        //             {listNotify.data?.data?.newAsk > 0 &&
        //                 <span>
        //                     <label>{listNotify.data?.data?.newAsk}</label>
        //                 </span>}
        //         </ItemMenu>

        //         <ItemMenu onClick={() => { handleClick("/produtos") }} selectedMenu={selectedMenu.pathname === "/produtos" ? true : false}>
        //             <FaBoxOpen size={icon.size} color={icon.color} />
        //             <label>Meus Produtos</label>
        //         </ItemMenu>

        //         <ItemMenu onClick={() => { handleClick("/cupons") }} selectedMenu={selectedMenu.pathname === "/cupons" ? true : false}>
        //             <AiFillTag size={icon.size} color={icon.color} />
        //             <label>Cupons</label>
        //         </ItemMenu>

        //         {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url != null &&
        //             <ItemMenu
        //                 onClick={() => {
        //                     if (JSON.parse(localStorage.getItem("depoimentos@login")).integration?.url != null)
        //                         JSON.parse(localStorage.getItem("depoimentos@login")).integration.url.includes("http") ?
        //                             window.open(`${JSON.parse(localStorage.getItem("depoimentos@login")).integration.url}`) :
        //                             window.open(`https://${JSON.parse(localStorage.getItem("depoimentos@login")).integration.url}`)
        //                 }}
        //             >
        //                 <IoStorefrontSharp size={icon.size} color={icon.color} />
        //                <label>Minha Loja</label>
        //             </ItemMenu>}

        //     </SectionMenu>

        //     <SectionMenu> 
        //         <TitleMenu>CONFIGURAÇÕES</TitleMenu>
        //         <ItemMenu onClick={() => { handleClick("/integracoes") }} selectedMenu={selectedMenu.pathname === "/integracoes" ? true : false}>
        //             <FaLayerGroup size={icon.size} color={icon.color} />
        //             <label>Integrações</label>
        //         </ItemMenu>
        //         <ItemMenuOptions>
        //             <BoxMenu visible={visibleSubMenuConfig} onClick={() => { setVisibleSubMenuConfig(!visibleSubMenuConfig) }}>
        //                 <BsFillGearFill size={icon.size} color={icon.color} />
        //                 <label>Personalizar</label>
        //                 <span>
        //                     {visibleSubMenuConfig ? <IoIosArrowUp size={18} color={icon.color} /> : <IoIosArrowDown size={18} color={icon.color} />}
        //                 </span>
        //             </BoxMenu>
        //             <BoxSubMenu visible={visibleSubMenuConfig} selectedMenu={selectedMenu.pathname}>
        //                 <BoxSubMenulabel onClick={() => { handleClick("/personalizar/email") }} selectedMenu={selectedMenu.pathname === "/personalizar/email" ? true : false} >E-mail</BoxSubMenulabel>
        //                 <BoxSubMenulabel onClick={() => { handleClick("/personalizar/tema") }} selectedMenu={selectedMenu.pathname === "/personalizar" || selectedMenu.pathname === "/personalizar/tema" ? true : false} >Página produto</BoxSubMenulabel>
        //                 {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "nuvemshop" && <BoxSubMenulabel onClick={() => { handleClick("/personalizar/home") }} selectedMenu={selectedMenu.pathname === "/personalizar/home" ? true : false} >Página Inicial</BoxSubMenulabel>}

        //             </BoxSubMenu>
        //         </ItemMenuOptions>

        //         <ItemMenu onClick={() => { handleClick("/pagamentos") }} selectedMenu={selectedMenu.pathname === "/pagamentos" ? true : false}>
        //             <FaPiggyBank size={icon.size} color={icon.color} />
        //             <label>Assinatura</label>
        //         </ItemMenu>

        //         <ItemMenu onClick={() => { handleClick("/passo-a-passo") }} selectedMenu={selectedMenu.pathname === "/passo-a-passo" ? true : false}>
        //             <TiStarOutline size={icon.size} color={icon.color} />
        //             <label>Passo a Passo</label>
        //         </ItemMenu>

        //         <ItemMenu onClick={() => { handleClick("/ajuda") }} selectedMenu={selectedMenu.pathname === "/ajuda" ? true : false}>
        //             <BiSupport size={icon.size} color={icon.color} />
        //             <label>Ajuda</label>
        //         </ItemMenu>
        //     </SectionMenu>
        // </Container>
    )
}

export default SideBar
