import { Container } from './styled'
import { reviews } from "../../pages/home/dataPreview";
import CardDropi from "../card-review-dropi-preview";
import CardLily from "../card-review-lily-preview";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import CardTiktok from '../card-review-tiktok-preview';
import Card2Lily from '../card2-review-lily-preview';
import Card3Lily from '../card3-review-lily-preview';
import Card2Dropi from '../card2-review-dropi-preview';
import Card3Dropi from '../card3-review-dropi-preview';
import Card1Home from '../card1-home';
import CardLilyLarge from '../card-large-review-lily-preview';
import CardInsta from '../card-review-insta-preview';
import CardYoutube from '../card-review-youtube-preview';

const BoxReviewsUpdate = () => {

    const config = useSelector(state => state.reducerControlConfig)




    const getCard = (card, item) => {
        switch (card) {

            case "dropi":
                return <CardDropi update={true} item={item} />
            case "dropi2":
                return <Card2Dropi update={true} item={item} />
            case "dropi3":
                return <Card3Dropi update={true} item={item} />
            default:
                return <CardDropi update={true} item={item} />
        }
    }


    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <Container style={{ paddingTop: config.layout == "insta" ? "50px" : "0px" }}  >
                {reviews.map((item) => {
                    return (getCard(config.layout, item))
                    //return <Card3Dropi update={true} item={item} />
                })}
            </Container>
        </div>

    )

}

export default BoxReviewsUpdate


