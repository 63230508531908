import styled from "styled-components";


export const Container = styled.form`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    //padding: 25px;
    border-radius: 5px;
    overflow: auto;
    //height: calc(500%) !important;
    margin-left: 0px ;
    flex:1 ;
    align-items: center ;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
    background-size: 60%;
    justify-content: center;

    @media screen and (max-width: 450px) {
        width: 300px;
    }
`;



export const BoxInfor = styled.div`
    display: flex;
    background-color: #11668822;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;

    label{
        display: flex;
        text-align: center;
    }
`

export const Close = styled.span`
    display: flex;
    position: absolute;
    right: -25px;
    top: 5px;
    margin-right: 25px;
    cursor: pointer;
    z-index: 9999;
    //background-color: red;
    z-index: 999999999999;
`;

export const Title = styled.div`
    display: flex;
    position: relative;
    color: #5e6278;
    margin-bottom: 10px;
`;