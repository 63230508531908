import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 120px ;
    margin: 0px 0px;
    position: relative;
    //background-color: green ;
    flex-direction: row ;
    border-radius: 10px ;
    //border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;

    &:hover{
        cursor: pointer;
        opacity: 0.7;
    }

    :nth-last-child(){
        //border-bottom: 1px solid #dcdcdc;
        //background-color: red;
    }

    @media(max-width: 768px) {
        height: max-content ;
        //padding: 10px ;
    }

`;




export const BoxCheck = styled.div`
    //background-color: purple ;
    width: 30px;
    display: flex ;
    justify-content: center;
    padding-top: 5px ;
    //background-color: red ;
    //border-top: 1px solid #C1C1C1 ;
    //height: 130px ;
    align-items: flex-start ;


`;

export const Section = styled.div`
    background-color: white ;
    display: flex ;
    flex: 1;
    //height: 130px ;
    padding: 0px 20px ;
    //border-top: 1px solid #C1C1C1;
    align-items: center ;
    border-radius: 10px ;

    //background-color: purple ;
    
    @media(max-width: 768px) {
        flex-direction:  column ;
        padding: 0px ;
    }

`

export const NameMobile = styled.label`
    display: none ;
    //align-items: center;
    //background-color: red;

    @media(max-width: 768px) {
        display: flex ;
    }
`

export const SectionLeft = styled.div`
    //background-color: red ;
    display: flex ;
    flex: 1 ;

    @media(max-width: 768px) {
        flex-direction:  column ;
        //background-color: red ;
        width: 100% ;
    }
    

    span{
        display: flex ;
        flex-direction: column ;
        

        label{
            font-size: 14px ;
            font-weight: 600 ;
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 1; /** número de linhas que você quer exibir */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            //background-color: red;

            @media(max-width: 768px) {
                display: none ;
            }

            :hover{
                text-decoration: underline ;
            }
        }

        div{
            display: flex ;
            margin: 10px 0px;

            @media(max-width: 768px) {
                flex-direction:  column ;
                justify-content: left ;
                //background-color: yellow ;
                gap: 10px;
            }
    

            label{
                display: flex;
                //background-color: purple ;
                margin-right: 30px ;
                font-size: 12px ;
                align-items: center ;
                font-weight: normal ;
                cursor: pointer;

                :hover{
                    text-decoration: underline ;
                }

                svg{
                    margin: 0px 5px ;
                }
            }
        }

        label:nth-child(3){
            font-size: 12px;
            opacity: 0.6 ;
            font-weight: normal ;
            text-decoration: none ;
            cursor: default;
        }
    }


    img{
        width: 60px ;
        height: 60px ;
        object-fit: cover;
        margin-right: 20px ;
        border-radius: 10px ;
        cursor: pointer;
    }


`

export const BoxSvg = styled.div`
    svg{
        width: 50px ;
        height: 50px ;
        margin-right: 25px ;
        margin-left: 5px ;
    }
`