const INITIAL_STATE = {
  error: false,
  loading: false,
  data: []
};

const UpdateEmailOrders = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_EMAIL_ORDERS_REQUEST":
      return { loading: true, error: false };
    case "UPDATE_EMAIL_ORDERS_ERROR":
      return { loading: false, error: true };
    case "UPDATE_EMAIL_ORDERS_SUCCESS":
      return { loading: false, error: false, data: action.payload };
    case "UPDATE_EMAIL_ORDERS_RESET":
      return { loading: false, error: false, data: [] };
    default:
      return state;
  }
};

export default UpdateEmailOrders;
