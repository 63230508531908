const INITIAL_STATE = {
  error: false,
  loading: false,
  data: []
};

const Whats = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "WHATS_REQUEST":
      return { loading: true, error: false };
    case "WHATS_ERROR":
      return { loading: false, error: true };
    case "WHATS_SUCCESS":
      return { loading: false, error: false, data: "[]" };
    default:
      return state;
  }
};

export default Whats;
