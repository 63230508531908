import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"


const Affiliate = () => {

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (typeof urlParams.get("ref") == "string" && urlParams.get("ref") != "") {

      localStorage.setItem('depoimentos@affiliate', urlParams.get("ref"))
      if (typeof urlParams.get("url") == "string" && urlParams.get("url") != "") {
        //localStorage.setItem('depoimentos@backurl', urlParams.get("url"))
        window.location.href = urlParams.get("url");
        //window.open(urlParams.get("url"))
      } else {
        history.push("/cadastro")
      }
    } else {
      history.push("/cadastro")
    }

  }, [])

  return (
    <p></p>
  )
}

export default Affiliate
