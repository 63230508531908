import React from 'react'
import { Container, Infor, Starts, Text } from './styled'
import { useEffect } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { BadgeCheck } from '../card-01-dropi-carousel/styled'
import { HiBadgeCheck } from 'react-icons/hi'
import { getTranslation } from '../../../../translations'
// import { interval } from 'utils/date'

const Card01Corousel = (props) => {

    const config = useSelector(state => state.reducerControlConfig)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);






    return (
        <Container
            background={config.homereviews_background_color_card}
            colorBorder={config?.homereviews_color_border_card}
            sizeBorder={config?.homereviews_size_border_card}
            radiusBorder={config?.homereviews_radius_border_card}
        >
            <Starts>
                <span>
                    <AiFillStar size={24} color={props.item?.qtdStars > 0 ? config?.homereviews_color_star_card : "#dcdcdc"} />
                    <AiFillStar size={24} color={props.item?.qtdStars > 1 ? config?.homereviews_color_star_card : "#dcdcdc"} />
                    <AiFillStar size={24} color={props.item?.qtdStars > 2 ? config?.homereviews_color_star_card : "#dcdcdc"} />
                    <AiFillStar size={24} color={props.item?.qtdStars > 3 ? config?.homereviews_color_star_card : "#dcdcdc"} />
                    <AiFillStar size={24} color={props.item?.qtdStars > 4 ? config?.homereviews_color_star_card : "#dcdcdc"} />
                </span>
            </Starts>
            <Text fontColor={config.homereviews_font_color_card} >
                <label>{`" ${props?.item?.text} "`}</label>
            </Text>
            <Infor background={config.homereviews_background_color_card} fontColor={config.homereviews_font_color_card}>
                <span>
                    {/* {props?.item?.name[0]} */}
                    A
                </span>
                <div>
                    <p style={{display: "flex"}}>
                        {props?.item?.name}
                        {config.homereviews_visible_verified_card == "1" &&
                            <BadgeCheck style={{ alignItems: "center", display: "flex" }}>
                                {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={20} color={config?.homereviews_color_verified_card} />}
                                <label>{translation.thema.cardReviewDropiPreview.compraVerificada}</label>
                            </BadgeCheck>}
                    </p>
                    <label>{"10 dias atrás"}</label>
                </div>

            </Infor>
        </Container>
    )
}

export default Card01Corousel