import React, { useState } from 'react'
import { BoxButtons, BoxColors, BoxOptions, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Container, Item, ItemSelect, SelectCustom, Subtitle } from './styled'

import IconReset from "../../../../assets/imgs/icon-reset.svg";
import IconCamera from "../../../../assets/imgs/icon-camera.svg";
import IconSeta from "../../../../assets/imgs/seta-back.svg";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useDispatch, useSelector } from 'react-redux';
import { TextAreaCustom } from '../form-email/styled';
import { CounterItemsCircle } from '../box-config-tema/styled';
import { BoxLoadingCustom, InputFileCustomTeste, InputTextAreaCustom, InputTextCustom } from '../box-config-email/styled';
import { useEffect } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { FaUpload } from 'react-icons/fa';
import imageCompression from 'browser-image-compression';

import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';

import { IoIosArrowBack } from "react-icons/io";
import { BarSubmenu, BarSubmenuItem, ItemOption, Main, MenuOption, Title } from '../bar-config-product-cores/styled';
import { toast } from 'react-toastify';

import IconImgProduct from "../../../../assets/imgs/icons-sidebar/submenu-product.svg";
import IconImgProductInativo from "../../../../assets/imgs/icons-sidebar/submenu-product-inativo.svg";
import IconImgEmail from "../../../../assets/imgs/icons-sidebar/submenu-email-inativo.svg";
import IconImgEmailAtivo from "../../../../assets/imgs/icons-sidebar/submenu-email-ativo.svg";
import IconImgHome from "../../../../assets/imgs/icon-homepage.svg";
import IconImgHomeAtivo from "../../../../assets/imgs/icon-homepage-active.svg";
import IconImgTeste from "../../../../assets/imgs/teste-00.svg";
import { useHistory, useLocation } from 'react-router';
import { Tooltip } from '../bar-config-product-ajustes/styled';

const BarConfigEmailAjustes = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const selectedMenu = useLocation();

    //const [logo, setLogo] = useState(config.data.request_email_logourl ? config.data.request_email_logourl : "")
    const [uploading, setUploading] = useState(false)
    const [email, setEmail] = useState(JSON.parse(localStorage.getItem("depoimentos@login"))?.user.email)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    const statusSendEmail = useSelector(state => state.reducerSendEmail)

    useEffect(() => {
        const id = "statusSendEmail"
        if (!(statusSendEmail.loading == false && !statusSendEmail.error == false && statusSendEmail.data.length == 0)) {
            if (statusSendEmail.loading) {
                toast.loading(translation.thema.modalPreviewEmail.aguarde, { toastId: id })
            } else {
                if (statusSendEmail.error) toast.update(id, { render: translation.thema.modalPreviewEmail.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.thema.modalPreviewEmail.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                }
            }
        } else if (statusSendEmail.error) toast.update(id, { render: translation.thema.modalPreviewEmail.erroEnviar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    }, [statusSendEmail])

    const saveTheme = () => {
        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                request_email_date: configControl.request_email_date,
            }
        })

        // if (typeof configControl.request_email_logourl == "string") {
        //     dispatch({
        //         type: "SAGA_UPDATE_CONFIG", payload: {
        //             id: config.data?.id,
        //             font_email: configControl.font_email,
        //             request_email_buttontext: configControl.request_email_buttontext,
        //             request_email_date: configControl.request_email_date,
        //             request_email_subject: configControl.request_email_subject,
        //             request_email_content: configControl.request_email_content,
        //         }
        //     })
        // } else {
        //     dispatch({
        //         type: "SAGA_UPDATE_CONFIG", payload: {
        //             id: config.data?.id,
        //             font_email: configControl.font_email,
        //             request_email_buttontext: configControl.request_email_buttontext,
        //             request_email_date: configControl.request_email_date,
        //             image: configControl.request_email_logourl,
        //             request_email_subject: configControl.request_email_subject,
        //             request_email_content: configControl.request_email_content,
        //         }
        //     })
        // }

    }

    const getDefaultTheme = () => {
        const themaDefault = {
            id: config.data.id,
            //font_email: "Arial",
            //request_email_logourl: "",
            //request_email_content: translation.thema.formEmail.requestEmailContent,
            //request_email_subject: translation.thema.formEmail.requestEmailSubject,
            //request_email_buttontext: translation.thema.formEmail.requestEmailButtontext,
            //request_email: 1,
            request_email_date: 20,

        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })
    }


    const isSaveEmailConfig = () => {
        if (
            // config.data.font_email == configControl.font_email &&
            // config.data.request_email_logourl == configControl.request_email_logourl &&
            // config.data.request_email_content == configControl.request_email_content &&
            // config.data.request_email_subject == configControl.request_email_subject &&
            // config.data.request_email_buttontext == configControl.request_email_buttontext &&
            // config.data.request_email == configControl.request_email &&
            config.data.request_email_date == configControl.request_email_date
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_CONFIG_SET", payload: false })
            return false
        }
    }



    const senEmail = () => {
        //alert("teste")
        dispatch({
            type: "SAGA_SEND_EMAIL",
            payload: {
                email_logourl: configControl.request_email_logourl,
                email_content: configControl.request_email_content,
                email_subject: configControl.request_email_subject,
                product_name: "Nome do produto",
                email_buttontext: configControl.request_email_buttontext,
                email: email,
                color_text_button: configControl.color_text_button,
                background_button: configControl.background_button,
                color_text: configControl.color_text,
                background_body: configControl.background_body,
                color_border: configControl.color_border,
                font_email: configControl.font_email,
                product_image_url: IconCamera
            }
        })
    }

    async function handleImageUpload(imageFile) {
        setUploading(true)

        const options = {
            maxSizeMB: 0.6,
            maxWidthOrHeight: 1280,
            useWebWorker: true
        }

        try {
            const compressedFile = await imageCompression(imageFile, options);
            //setLogo(compressedFile)
            dispatch({ type: "CONFIG_CONTROL_SET", payload: { request_email_logourl: compressedFile } })
            setUploading(false)
        } catch (error) {
            setUploading(false)

        }
    }


    return (
        <Main>
            {/* <BarSubmenu>
                <BarSubmenuItem
                    active={false}
                    style={{position: "relative"}}
                    onClick={() => { history.push("/personalizar/tema") }}
                >
                    {selectedMenu.pathname.slice(13) == "/" || selectedMenu.pathname.slice(13) == "" || selectedMenu.pathname.slice(13) == "/tema" ?
                        <img width={24} src={IconImgProduct} /> :
                        <img width={24} src={IconImgProductInativo} />}

                    <Tooltip>
                        Página do produto
                    </Tooltip>

                </BarSubmenuItem>
                <BarSubmenuItem
                    active={true}
                    style={{position: "relative"}}
                    onClick={() => { history.push("/personalizar/email") }}
                >
                    {selectedMenu.pathname.slice(13) == "/email" ?
                        <img width={24} src={IconImgEmailAtivo} /> :
                        <img width={24} src={IconImgEmail} />}

                    <Tooltip>
                        E-mail
                    </Tooltip>

                </BarSubmenuItem>

                <BarSubmenuItem
                    active={false}
                    style={{position: "relative"}}
                    onClick={() => { history.push("/personalizar/home") }}
                >
                    {selectedMenu.pathname.slice(13) == "/home" ?
                        <img width={24} src={IconImgHomeAtivo} /> :
                        <img width={24} src={IconImgHome} />}

                    <Tooltip>
                        Página do inicial
                    </Tooltip>
                </BarSubmenuItem>


            </BarSubmenu> */}

            {/* <Container>
                <Title>
                    <span onClick={() => { isSaveEmailConfig() && history.push("/personalizar") }}>
                        <IoIosArrowBack />
                    </span>

                    <label> {translation.thema.boxConfigEmail.title}</label>
                </Title> */}

                {/* <MenuOption>
                    <ItemOption active={false} onClick={() => {
                        isSaveEmailConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "email/cores" })
                    }}>
                        {translation.thema.boxConfigEmail.cores}
                    </ItemOption>
                    <ItemOption active={true} onClick={() => {
                        isSaveEmailConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "email/config" })
                    }}>
                        {translation.thema.boxConfigEmail.avancado}
                    </ItemOption>
                </MenuOption> */}

                {/* <BoxOptions>
                    <ItemSelect>
                        <label>{translation.thema.boxConfigEmail.tempoEnvio}:</label>
                        <SelectCustom name="select_layout_form" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { request_email_date: e.target.value } }) }}                        >
                            <option value="0" selected={configControl.request_email_date == 0 ? true : false}>Nunca</option>
                            <option value="1" selected={configControl.request_email_date == 1 ? true : false} >01 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="2" selected={configControl.request_email_date == 2 ? true : false} >02 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="3" selected={configControl.request_email_date == 3 ? true : false} >03 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="4" selected={configControl.request_email_date == 4 ? true : false} >04 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="5" selected={configControl.request_email_date == 5 ? true : false} >05 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="6" selected={configControl.request_email_date == 6 ? true : false} >06 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="7" selected={configControl.request_email_date == 7 ? true : false} >07 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="8" selected={configControl.request_email_date == 8 ? true : false} >08 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="9" selected={configControl.request_email_date == 9 ? true : false} >09 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="10" selected={configControl.request_email_date == 10 ? true : false} >10 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="11" selected={configControl.request_email_date == 11 ? true : false} > 11 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="12" selected={configControl.request_email_date == 12 ? true : false} >12 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="13" selected={configControl.request_email_date == 13 ? true : false} >13 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="14" selected={configControl.request_email_date == 14 ? true : false} >14 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="15" selected={configControl.request_email_date == 15 ? true : false} >15 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="16" selected={configControl.request_email_date == 16 ? true : false} >16 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="17" selected={configControl.request_email_date == 17 ? true : false} >17 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="18" selected={configControl.request_email_date == 18 ? true : false} >18 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="19" selected={configControl.request_email_date == 19 ? true : false} >19 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="20" selected={configControl.request_email_date == 20 ? true : false} >20 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="21" selected={configControl.request_email_date == 21 ? true : false} >21 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="28" selected={configControl.request_email_date == 28 ? true : false} >28 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="30" selected={configControl.request_email_date == 30 ? true : false} >30 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="35" selected={configControl.request_email_date == 35 ? true : false} >35 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="42" selected={configControl.request_email_date == 42 ? true : false} >42 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="49" selected={configControl.request_email_date == 49 ? true : false} >49 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="56" selected={configControl.request_email_date == 56 ? true : false} >56 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="63" selected={configControl.request_email_date == 63 ? true : false} >63 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="70" selected={configControl.request_email_date == 70 ? true : false} >70 {translation.thema.boxConfigEmail.diasCompra}</option> </SelectCustom>
                    </ItemSelect>


                </BoxOptions> */}

                {/* <BoxOptions>
                    <ItemSelect>
                        <label>{translation.thema.boxConfigEmail.fonte}</label>
                        <SelectCustom name="select_theme_reviews" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_email: e.target.value } }) }}                        >
                            <option value="Arial" selected={configControl.font_email == "Arial" ? true : false}>Arial</option>
                            <option value="Courier New" selected={configControl.font_email == "Courier New" ? true : false}>Courier New</option>
                            <option value="Georgia" selected={configControl.font_email == "Georgia" ? true : false}>Georgia</option>
                            <option value="Helvetica" selected={configControl.font_email == "Helvetica" ? true : false}>Helvetica</option>
                            <option value="Lucida Sans" selected={configControl.font_email == "Lucida Sans" ? true : false}>Lucida Sans</option>
                            <option value="Tahoma" selected={configControl.font_email == "Tahoma" ? true : false}>Tahoma</option>
                            <option value="Times New Roman" selected={configControl.font_email == "Times New Roman" ? true : false}>Times New Roman</option>
                            <option value="Trebuchet MS" selected={configControl.font_email == "Trebuchet MS" ? true : false}>Trebuchet MS</option>
                        </SelectCustom>
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.boxConfigEmail.logomarca}:</label>
                        <InputFileCustomTeste>
                            <input style={{ display: "none" }} id="request_email_logourl" type={"file"} onChange={async (event) => { await handleImageUpload(event.currentTarget.files[0]) }} />
                            <input type={"text"} value={typeof configControl.request_email_logourl == "string" ? configControl.request_email_logourl : configControl.request_email_logourl.name} />
                            <label for="request_email_logourl">
                                {uploading ?
                                    <BoxLoadingCustom >
                                        <div>
                                            <AiOutlineLoading style={{ margin: "0px" }} size={12} color={"black"} />
                                        </div>
                                    </BoxLoadingCustom> :
                                    <FaUpload style={{ margin: "0px", cursor: "pointer" }} size={15} />}
                            </label>
                        </InputFileCustomTeste>
                    </ItemSelect>

                    <ItemSelect style={{ position: "relative" }}>
                        <label>{translation.thema.boxConfigEmail.assuntoEmail}:</label>
                        <InputTextAreaCustom
                            value={configControl.request_email_subject}
                            rows={6}
                            maxLength="255"
                            placeholder={translation.thema.boxConfigEmail.assuntoDe}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { request_email_subject: e.target.value } }) }}
                        />
                        <CounterItemsCircle porc={`${Math.round(configControl.request_email_subject?.length / 255 * 100)}%`} colorBorder={configControl.request_email_subject?.length < 255 ? "green" : "red"}>
                            <label>{255 - configControl.request_email_subject?.length}</label>
                        </CounterItemsCircle>
                    </ItemSelect>

                    <ItemSelect style={{ position: "relative" }}>
                        <label>{translation.thema.boxConfigEmail.conteudoEmail}:</label>
                        <InputTextAreaCustom
                            value={configControl.request_email_content}
                            rows={12}
                            maxLength="255"
                            placeholder={translation.thema.boxConfigEmail.conteudoEmail}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { request_email_content: e.target.value } }) }}
                        />
                        <CounterItemsCircle porc={`${Math.round(configControl.request_email_content?.length / 255 * 100)}%`} colorBorder={configControl.request_email_content?.length < 255 ? "green" : "red"}>
                            <label>{255 - configControl.request_email_content?.length}</label>
                        </CounterItemsCircle>

                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.boxConfigEmail.textoBotao}:</label>
                        <InputTextCustom>
                            <input type={"text"} value={configControl.request_email_buttontext} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { request_email_buttontext: e.target.value } }) }} />
                        </InputTextCustom>
                    </ItemSelect>

                </BoxOptions> */}

                {/* <BoxOptions>
                    <ItemSelect>
                        <label>{translation.thema.boxConfigEmail.emailTeste}</label>
                        <InputTextCustom >
                            <input type={"text"} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            <label style={{ cursor: "pointer" }} onClick={() => { senEmail() }} >{translation.thema.boxConfigEmail.enviar}</label>
                        </InputTextCustom>
                    </ItemSelect>
                </BoxOptions> */}



                {/* <ButtonHelp>
                    <img src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() => getDefaultTheme()}>
                        <label>{translation.thema.boxConfigEmail.restaurar}</label>
                    </ButtonReset>
                    <ButtonSave onClick={() => saveTheme()}>
                        {translation.thema.boxConfigEmail.salvar}
                    </ButtonSave>
                </BoxButtons> */}
            {/* </Container> */}
        </Main>

    )
}

export default BarConfigEmailAjustes