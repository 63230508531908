import nuvemshopImg from "../../../../assets/imgs/icons-integrations/nuvemshop.png";
import dropiImg from "../../../../assets/imgs/icons-integrations/dropi.png";
import shopifyImg from "../../../../assets/imgs/icons-integrations/shopify.png";
import siteImg from "../../../../assets/imgs/site.webp";
import hotmartImg from "../../../../assets/imgs/icons-integrations/hotmart.png";
import montinkImg from "../../../../assets/imgs/icons-integrations/montink.png";
import rastreioImg from "../../../../assets/imgs/logo-rastreio.svg";
import landPageImg from "../../../../assets/imgs/icons-integrations/landpage.png";
import lojaIntegradaImg from "../../../../assets/imgs/icons-integrations/loja-integrada.png";
import cartpandaImg from "../../../../assets/imgs/icons-integrations/cartpanda.png";
import wixImg from "../../../../assets/imgs/icons-integrations/wix.png";
import trayImg from "../../../../assets/imgs/icons-integrations/tray.png";
import bagyImg from "../../../../assets/imgs/icons-integrations/icon-bagy.jpeg";
import woocommerceImg from "../../../../assets/imgs/icons-integrations/woocommerce.png";
import sakImg from "../../../../assets/imgs/icons-integrations/sak.svg";
import logoShopeeImg from "../../../../assets/imgs/shopee-logo.png";
import { getTranslation } from "../../../../translations";

// const getActionNuvemshop = () => {
//     switch (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country) {
//         case "BR":
//             return `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`
//         case "AR":
//             return `https://www.tiendanube.com/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`
//         case "MX":
//             return `https://www.tiendanube.com.mx/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`
//         case "CO":
//             return `https://www.nuvemshop.com/co/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`
//         default:
//             return `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`

//     }
// }

const getLinkMercadolivre = () => {
    switch (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country) {
        case "BR":
            return `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
        case "AR":
            return `https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
        case "MX":
            return `https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
        case "CL":
            return `https://auth.mercadolibre.cl/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
        case "CO":
            return `https://auth.mercadolibre.com.co/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`
        default:
            return `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop`

    }
}

const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
export const platforms = {
    nuvemshop: {
        image: nuvemshopImg,
        message: translation.integrations.dataCard.nuvemshop.message,
        title: "Nuvemshop",
        titleColor: "#2C3357",
        action: JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country == "BR" ? `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize` : `https://www.tiendanube.com/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`,
        body: {
            title: translation.integrations.dataCard.nuvemshop.title,
            steps: [
                translation.integrations.dataCard.nuvemshop.steps1,
                translation.integrations.dataCard.nuvemshop.steps2,
                translation.integrations.dataCard.nuvemshop.steps3
            ]
        }

    },
    // shopify: {
    //     image: shopifyImg,
    //     message: "Integre sua conta Shopify e comece a criar avaliações e perguntas na sua loja.",
    //     title: "Shopify",
    //     titleColor: "#5B8A3C",
    //     action: `https://#loja_shopify#.myshopify.com/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_CLIENT_ID}&scope=write_products,read_products,read_orders,read_product_listings,read_collection_listings,write_customers,write_orders,write_draft_orders,write_fulfillments,write_script_tags,write_shipping,read_inventory,read_script_tags&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/shopify&state=gagasgaswqeq&grant_options[]=`,
    //     body: {
    //         title: "Passo a passo",
    //         steps: [
    //             "1. Faça o login em sua conta da shopify que deseja integrar.",
    //             "2. Digite o endereço da loja que deseja integrar:",
    //             "3. Clique no botão Conectar. (você será redirecionado à shopify para autorizar a integração e assim que terminar será redirecionado para o Lily Reviews novamente.)",
    //             "4. Pronto sua loja já está integrada ao Lily Reviews."
    //         ]
    //     }

    // },
    shopify: {
        image: shopifyImg,
        message: translation.integrations.dataCard.shopify.message,
        title: "Shopify",
        titleColor: "#5B8A3C",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.shopify.title,
            steps: [
                translation.integrations.dataCard.shopify.steps1,
                translation.integrations.dataCard.shopify.steps2,
                translation.integrations.dataCard.shopify.steps3,
                translation.integrations.dataCard.shopify.steps4,
                translation.integrations.dataCard.shopify.steps5,
                translation.integrations.dataCard.shopify.steps6
            ]
        }

    },
    mercadoshop: {
        image: "https://ehoradeaprender.com/wp-content/uploads/2023/01/unnamed.png",
        message: translation.integrations.dataCard.mercadoshop.message,
        title: "Mercadoshop",
        titleColor: "#F8D000",
        action: getLinkMercadolivre(),
        //action: JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country == "BR" ? `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=1528487818689704&redirect_uri=${process.env.REACT_APP_BASE_URL}/integracoes/mercadoshop` : ``,
        body: {
            title: translation.integrations.dataCard.mercadoshop.title,
            steps: [
                translation.integrations.dataCard.mercadoshop.steps1,
                translation.integrations.dataCard.mercadoshop.steps2,
                translation.integrations.dataCard.mercadoshop.steps3
            ]
        }

    },
    //dropi
    dropi: {
        image: dropiImg,
        message: translation.integrations.dataCard.dropi.message,
        title: "Dropi",
        titleColor: "#182390",
        //action: `https://magos.dropi.com.br/api/authorize/1000?callback_url=${process.env.REACT_APP_BASE_URL}/integracoes/dropi`,
        action: `https://app.dropi.com.br/api/authorize/1000?callback_url=${process.env.REACT_APP_BASE_URL}/integracoes/dropi`,
        body: {
            title: translation.integrations.dataCard.dropi.title,
            steps: [
                translation.integrations.dataCard.dropi.steps1,
                translation.integrations.dataCard.dropi.steps2,
            ]
        }
    },

    custom: {
        image: "https://celulaweb.com.br/wp-content/uploads/2021/02/icon-site.png",
        message: translation.integrations.dataCard.custom.message,
        title: "Custom",
        titleColor: "#FE852B",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.custom.title,
            steps: [
                translation.integrations.dataCard.custom.steps1,
                translation.integrations.dataCard.custom.steps2,
                translation.integrations.dataCard.custom.steps3,
            ]
        }
    },
    hotmart: {
        image: hotmartImg,
        message: translation.integrations.dataCard.hotmart.message,
        title: "Hotmart",
        titleColor: "#FF4D1A",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.hotmart.title,
            steps: [
                translation.integrations.dataCard.hotmart.steps1,
                translation.integrations.dataCard.hotmart.steps2,
                translation.integrations.dataCard.hotmart.steps3,
                translation.integrations.dataCard.hotmart.steps4,
                translation.integrations.dataCard.hotmart.steps5,
                translation.integrations.dataCard.hotmart.steps6,
                translation.integrations.dataCard.hotmart.steps7,
                translation.integrations.dataCard.hotmart.steps8,
                translation.integrations.dataCard.hotmart.steps9,
                translation.integrations.dataCard.hotmart.steps10,
                translation.integrations.dataCard.hotmart.steps11,
            ]
        }
    },
    montink: {
        image: montinkImg,
        message: translation.integrations.dataCard.montink.message,
        title: "Montink",
        titleColor: "#070E2B",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.montink.title,
            steps: [
                translation.integrations.dataCard.montink.steps1,
                translation.integrations.dataCard.montink.steps2,
                translation.integrations.dataCard.montink.steps3,
            ]
        }
    },

    rastreio: {
        image: rastreioImg,
        message: translation.integrations.dataCard.rastreio.message,
        title: "Rastreio",
        titleColor: "#0083CA",
        action: `#`,
        body: {
            title: "Passo a passo",
            steps: [
                "1. Clique em conectar, para ser direcionado ao app Rastreio",
                "2. Dentro do app Rastreio, preencha os dados e integre.",
                "3. Retorne ao Lily reviews e pronto. você está integrado ",
            ]
        }
    },

    landpage: {
        image: landPageImg,
        message: translation.integrations.dataCard.landpage.message,
        title: "Land Page",
        titleColor: "#05BFDA",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.landpage.title,
            steps: [
                translation.integrations.dataCard.landpage.steps1,
                translation.integrations.dataCard.landpage.steps2,
                translation.integrations.dataCard.landpage.steps3,
            ]
        }
    },

    //teste
    loja_integrada: {
        image: lojaIntegradaImg,
        message: translation.integrations.dataCard.lojaIntegrada.message,
        title: "Loja Integrada",
        titleColor: "#15A5A5",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.lojaIntegrada.title,
            steps: [
                translation.integrations.dataCard.lojaIntegrada.steps1,
                translation.integrations.dataCard.lojaIntegrada.steps2,
                translation.integrations.dataCard.lojaIntegrada.steps3,
                translation.integrations.dataCard.lojaIntegrada.steps4,
            ]
        }
    },

    cartpanda: {
        image: cartpandaImg,
        message: translation.integrations.dataCard.cartpanda.message,
        title: "Cartpanda",
        titleColor: "#000000",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.cartpanda.title,
            steps: [
                translation.integrations.dataCard.cartpanda.steps1,
                translation.integrations.dataCard.cartpanda.steps2,
                translation.integrations.dataCard.cartpanda.steps3,
                translation.integrations.dataCard.cartpanda.steps4,
            ]
        }
    },


    wix: {
        image: wixImg,
        message: translation.integrations.dataCard.wix.message,
        title: "Wix",
        titleColor: "#000000",
        action: `https://www.wix.com/installer/install?appId=${process.env.REACT_APP_WIX_ID}&redirectUrl=${process.env.REACT_APP_URL}/integracoes/wix`,
        body: {
            title: translation.integrations.dataCard.wix.title,
            steps: [
                translation.integrations.dataCard.wix.steps1,
                translation.integrations.dataCard.wix.steps2,
                translation.integrations.dataCard.wix.steps3
            ]
        }
    },

    shopee: {
        image: logoShopeeImg,
        message: translation.integrations.dataCard.shopee.message,
        title: "Shopee",
        titleColor: "#EE4D2D",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.shopee.title,
            steps: [
                translation.integrations.dataCard.shopee.steps1,
                translation.integrations.dataCard.shopee.steps2,
                translation.integrations.dataCard.shopee.steps3
            ]
        }
    },

    tray: {
        image: trayImg,
        message: translation.integrations.dataCard.tray.message,
        title: "Tray",
        titleColor: "#070E2B",
        action: `#`,
        //action: `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`,

        body: {
            title: translation.integrations.dataCard.tray.title,
            steps: [
                translation.integrations.dataCard.tray.steps1,
                translation.integrations.dataCard.tray.steps2,
                translation.integrations.dataCard.tray.steps3,
                translation.integrations.dataCard.tray.steps4,
            ]
        }
    },

    bagy: {
        image: bagyImg,
        message: translation.integrations.dataCard.bagy.message,
        title: "Bagy",
        titleColor: "#FA3D8B",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.bagy.title,
            steps: [
                translation.integrations.dataCard.bagy.steps1,
                translation.integrations.dataCard.bagy.steps2,
                translation.integrations.dataCard.bagy.steps3,
                translation.integrations.dataCard.bagy.steps4,
            ]
        }
    },

    woocommerce: {
        image: woocommerceImg,
        message: "Agora você pode criar avaliações e perguntas na sua loja Woocommerce.",
        title: "Woo Commerce",
        titleColor: "#7F54B3",
        action: `#`,
        body: {
            title: "Passo a passo",
            steps: [
                "1. Digite a url do site que deseja integrar:",
                "2. Clique no botão Conectar",
                "3. Pronto seu site já está integrado ao Lily Reviews.",
            ]
        }
    },

    sak: {
        image: sakImg,
        message: translation.integrations.dataCard.sak.message,
        title: "Sak",
        titleColor: "#00E673",
        action: `#`,
        body: {
            title: translation.integrations.dataCard.sak.title,
            steps: [
                translation.integrations.dataCard.sak.steps1,
                translation.integrations.dataCard.sak.steps2,
                translation.integrations.dataCard.sak.steps3,
                translation.integrations.dataCard.sak.steps4,
            ]
        }
    },

}