import styled from "styled-components";

export const Container = styled.div`
  /* background-color:  yellow;
  display: flex ;
  justify-content: center ;
  flex-wrap: wrap;
  gap: 20px; */

  /* column-count: auto;
  column-width: 300px;
  background-color: red;
  padding-left: 20px !important;
  padding-right: 20px !important;
  column-gap: auto; */
  column-count: 1; /* Número de colunas dinâmico */
  text-align: center;
  column-gap: auto;
  //background-color: red;
  column-width: auto;

  max-width: max-content;
  div{
    margin-bottom: 40px !important;
  }

  @media (min-width: 1200px) {
    column-count: 2;
  }

  @media (min-width: 1450px) {
    column-count: 3;
  }

  @media (min-width: 1700px) {
    column-count: 3;
  }
  
`