import { Tooltip, BoxInfor, BoxCode, BoxMessage, Status, BoxDescription, BoxIntegration, Container, BoxContents, BoxOptions, ButtonAnswered, ButtonHidden, ButtonDelete, BoxActions, Badget, ButtonCustom, OptionCustom, } from "./styled"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { RiScissorsLine } from "react-icons/ri"
import { getTranslation } from "../../../../translations";
import { Back } from "iconsax-react";


const CardCupons = (props) => {

    const dispatch = useDispatch()
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language)

    const updateItem = () => {
        // dispatch({
        //     type: "SAGA_UPDATE_QUESTIONS",
        //     payload: {
        //         active: 1,
        //         id: props.item.id
        //     }
        // })
    }

    return (
        <Container>
            <BoxContents background={props?.background} color={props.item.active == 0 ? "#F1BC00" : "#20D489"}>
                <BoxCode onClick={() => {  }} active={props.item.active == 0 ? false : true}>
                    <p>{props.item.code}</p>
                    <label>{translation.cupons.card.copiarCupom}</label>
                    <RiScissorsLine color={props.item.active == 0 ? "gray" : "#21D487"} size={18} />
                </BoxCode>
                <BoxInfor>
                    <label><strong style={{ color: "gray", marginRight: "5px" }}>{translation.cupons.card.expira}</strong>  {new Date(props.item.expiration_date).toLocaleDateString()}</label>
                    <BoxMessage>
                        <strong style={{ color: "gray" }}>{translation.cupons.card.mensagem}</strong>
                        {props.item.message}
                    </BoxMessage>
                    <BoxDescription>
                        <strong style={{ color: "gray" }}>{translation.cupons.card.descCupom}</strong>
                        {props.item.description}
                    </BoxDescription>

                </BoxInfor>
                <BoxActions>
                    <span onClick={() => { updateItem() }}>
                        <Back size={20} color={"gray"} />
                        <label>{translation.cupons.card.excluirCupom}</label>
                    </span>
                </BoxActions>
            </BoxContents>
        </Container >
    )
}

export default CardCupons
