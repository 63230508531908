import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`

export const BarClose= styled.div`
    display: flex;
    background-color: #fff;
    width: 100%;
    height: 60px;
    position: fixed;
    align-items: center;
    padding: 0px 20px;
`

export const BoxContents= styled.div`
    display: flex;
    margin-top: 60px;
    justify-content: center;
    background-color:  "yellow";
    width: 100%;
`
export const Box= styled.div`
    display: flex;
    width: 900px;
    background-color: red;
    margin-top: 20px;
    min-height: 500px;
`