import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Answer, Container, Infor, Question } from './styled'
import { RiQuestionnaireFill } from "react-icons/ri";
import { MdHeadsetMic } from "react-icons/md";
import { interval } from '../../../../utils/date';
import { getTranslation } from '../../../../translations';

const Card2Question = (props) => {

    const config = useSelector(state => state.reducerControlConfig)



    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Container background={config.background_color_secondary} borderRadius={config.border_card_radius} borderSize={config.border_card_size} borderColor={config.border_card_color}>
            <Question fontColor={config.font_color_primary}>
                <RiQuestionnaireFill size={25} color={"gray"} />
                <label>{props.item.ask}</label>
            </Question>
            <Answer fontColor={config.font_color_primary}>
                <MdHeadsetMic size={25} color={"gray"} />
                <label>
                    {props.item.answer == "" ? translation.thema.cardQuestionPreview.aguardando : props.item.answer}
                </label>
            </Answer>
            <Infor fontColor={config.font_color_primary}>
                <label>
                    {props.item.name}
                    <span>
                        20 {translation.thema.cardQuestionPreview.diasAtras}
                    </span>
                </label>
            </Infor>
        </Container>
    )
}

export default Card2Question