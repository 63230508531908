import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    margin: 0px 0px;
    flex-direction: column;
    position: relative;
    flex-direction: row;
    //background-color: yellow;

`;
export const Box = styled.div`
    display: flex ;
    flex: 1 ;
    flex-direction: column ;

    border: 1px solid #f2f2f2;
    border-radius: 4px;

    //background-color: red;
`;

export const BoxContentsLeft = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    //background-color: yellow ;
    //gap: 5px;
    label{
        font-size: 12px ;  
    }
`;

export const BoxContents = styled.div`
    background-color: white;
    display: flex;
    flex: 1;
    padding: 20px;
    border-radius: 0px 5px 5px 0px;
    text-align: justify;
    border-radius: 10px ;
  

    @media(max-width: 480px) {
        flex-direction: column-reverse;

        div:nth-child(2){
            
            display: flex;
            justify-content: center;
            margin: 20px 0px ;
        }
    }

    p{
        font-size: 14px;
        font-weight: 500; 
        padding: 0px ;
        margin: 0px ;
        display: flex;
        align-items: center;
        gap: 10px;

        a{
            outline: none;
            text-decoration: none;
            color: ${props => props.theme.colors.font.active};

            :hover{
                text-decoration: underline;
            }
        }
    }

    label{
        font-size: 14px;
        display: flex;
        flex: 1;
    }
`;



export const BoxActions = styled.div`
    display: flex ;
    align-items: center ;

    span{
        position: relative ;
        svg{
            margin-left: 20px ;
            cursor: pointer;
            position: relative ;
            
        }
        :hover{

            label{
                visibility: visible;
            }
        }

        label{
            display: flex;
            position: absolute;
            background: #292929cc;
            visibility: hidden;
            border-radius: 5px;
            width: max-content;
            padding: 10px 10px;
            transform: translateX(-100%);
            left: 0;
            margin-top: -75px;
            margin-left: 49px;
            color: white ;
            font-size: 11px !important;

            :after{
                content: '';
                position: absolute;
                top: 100%;
                right: 10px;
                border-top: 8px solid #292929cc;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
    }

`


export const ButtonCustom = styled.div`
    width: 100px ;
    height: 30px ;
    background-color: #54D18C ; // 
    display: flex ;
    cursor: pointer;
    border-radius: 5px ;
    margin: 0px ;
    position: relative ;
    margin-top: 20px ;

    label{
        font-size: 13px ;
        color: white ;
        display: flex ;
        width: 100% ;

        margin: 0px ;
        height: 100% ;
        justify-content: center ;
        align-items: center ;
        cursor: pointer;
    }

`

export const BoxMedias = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 5px;
`

export const Profile = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;

    span{

        label{
            cursor: pointer;   
            :hover{
                text-decoration: underline;
            }
        }
    }
`

export const Avatar = styled.div`
    display: flex;
    
    img{
        width: 55px;
        height: 55px;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid #FFC40088;
        padding: 5px;
    }
`

export const ItemMedia = styled.div`
    display: flex;
    width: 50px;
    height: 50px;
    //background-color: purple;

    img{
        /* width: 50px;
        height: 50px; */
        object-fit: cover;
    }
`

export const IconType = styled.div`
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;

    img{
        width: 20px;
        height: 20px;
        object-fit: cover;
    }

`

export const SubTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label{
        margin: 0px 10px;
        font-size: 12px;
        display: flex;
    }

    span{
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
`;

export const BoxCheck = styled.div`
    //background-color: purple ;
    width: 30px;
    display: flex ;
    justify-content: center;
    padding-top: 3px ;
    //background-color: red ;
    align-items: flex-start ;
`;
