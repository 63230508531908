import Modal from 'react-modal';
import { BoxOptions } from '../box-config-email/styled';
import { BoxButtons, ButtonHelp, ButtonReset, ButtonSave, ItemSelect, SelectCustom } from '../bar-config-email-ajustes/styled';
import { useSelector } from 'react-redux';
import { getTranslation } from '../../../../translations';
import { Container } from '../bar-config-product-ajustes/styled';
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BodyItem, BoxInfor, Carrossel, Close, ItemActive, ItemReview, List, ListItem, Title, TitleCircle, TitleInfor, TitleList } from './styled';
import { GrFormClose } from 'react-icons/gr';

import IconSlider from "../../../../assets/imgs/icon-slider-home.svg";
import { toast } from 'react-toastify';
import { AiFillStar } from 'react-icons/ai';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
        maxWidth: "580px",
        maxHeight: "90%"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalConfigGoogle = (props) => {


    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const dispatch = useDispatch()

    const [isActive, setIsActive] = useState(JSON.parse(localStorage.getItem("depoimentos@login")).integration?.is_script_google == 1 ? true : false)


    const save = () => {
        dispatch({
            type: "SAGA_CHANGE_STATUS_GOOGLE",
            payload: { status: !isActive, platform: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform },
        })
    }

    const reducerChangeStatusGoogle = useSelector(state => state.reducerChangeStatusGoogle)

    useEffect(() => {
        if (reducerChangeStatusGoogle.loading == null && reducerChangeStatusGoogle.error == null && reducerChangeStatusGoogle.data.length == 0) {

        } else {
            const id = "changeStatusGoogle"
            if (reducerChangeStatusGoogle.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (reducerChangeStatusGoogle.error) toast.update(id, { render: "Erro ao tentar enviar.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.products.card.atualizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    let copy = JSON.parse(localStorage.getItem("depoimentos@login"))
                    copy.integration = reducerChangeStatusGoogle.data.data
                    localStorage.setItem("depoimentos@login", JSON.stringify(copy))
                    setIsActive(reducerChangeStatusGoogle.data.data.is_script_google == 1 ? true : false)
                }
            }
        }
    }, [reducerChangeStatusGoogle])


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props?.setVisibleModal(false)}
        >
            <Container style={{ padding: "40px", gap: "20px" }}>
                <Close onClick={() => { props.setVisibleModal(false) }}>
                    <GrFormClose size={25} color={"#5e6278"} />
                </Close>
                <Title>
                    <strong>
                        Google
                    </strong>
                </Title>

                <Carrossel>
                    <span>
                        <div>
                            <img width={20} src={IconSlider} />
                            <label>{"Script do google"}</label>
                        </div>
                        <ItemActive onClick={() => { save() }} active={isActive}>
                            <span />
                        </ItemActive>
                    </span>
                    <label style={{ maxWidth: "500px", borderRadius: "8px", backgroundColor: "#ff500022", fontSize: "12px", padding: "20px" }}>
                        {translation.thema.modalConfigGoogle.importante}
                    </label>
                </Carrossel>

                <List>
                    <ListItem opacity={true}>
                        <TitleList>
                            <TitleCircle></TitleCircle>
                            <TitleInfor>
                                <p>meuconcorrente.com</p>
                                <label>{"meuconcorrente.com > blog"}</label>
                            </TitleInfor>
                        </TitleList>
                        <BodyItem>
                            <p>Concorrente sem Lily Reviews e sem estrelinhas do google</p>
                            <label>
                                Atendimento ao cliente eficiente: Tive uma dúvida sobre o meu pedido e entrei em contato com o suporte ao cliente. Eles foram muito prestativos e resolveram minha questão rapidamente.
                            </label>
                        </BodyItem>
                    </ListItem>

                    <ListItem active={true}>
                        <TitleList>
                            <TitleCircle></TitleCircle>
                            <TitleInfor>
                                <p>minhaloja.com</p>
                                <label>{"minhaloja.com > blog"}</label>
                            </TitleInfor>
                        </TitleList>
                        <BodyItem>
                            <p>Sua loja lindona com estrelinhas do google</p>
                            <label>
                                Veja um exemplo de como as estrelinhas podem aparecer abaixo do seu site nos resultados de pesquisa do Google. Isso aumenta o destaque do seu link e as chances das pessoas clicarem nele.
                            </label>
                            <ItemReview>
                                <span>
                                    <AiFillStar size={15} color={"orange"} />
                                    <AiFillStar size={15} color={"orange"} />
                                    <AiFillStar size={15} color={"orange"} />
                                    <AiFillStar size={15} color={"orange"} />
                                    <AiFillStar size={15} color={"orange"} />
                                </span>
                                <label>
                                    Avaliação: 50 - 1000 comentários
                                </label>
                            </ItemReview>
                        </BodyItem>
                    </ListItem>

                    {/* <ListItem>
                        <Title>
                            <TitleCircle></TitleCircle>
                            <TitleInfor>
                                <p>meuconcorrente.com</p>
                                <label>meuconcorrente.com</label>
                            </TitleInfor>
                        </Title>
                        <BodyItem>
                            <p>Meu concorrente não usa Lily</p>
                            <label>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </label>
                        </BodyItem>
                    </ListItem> */}
                </List>

                <iframe
                    src="https://www.youtube.com/embed/uMIKvvrxZ2I"
                    title="Integração Lily Reviews com Nuvemshop"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="true"
                    height={250}
                    width={500}
                />

            </Container>
        </Modal>
    )
}

export default ModalConfigGoogle
