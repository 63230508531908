import { useEffect } from 'react';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getTranslation } from '../../../../translations';

import { Container, Actions, AlertSave } from "./styled";
import IconBossSave from '../../../../assets/imgs/boss-save.svg';

import { useLocation } from "react-router-dom";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#242527",
        padding: "0px",
        margin: "0px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalAlertSave = (props) => {

    const dispatch = useDispatch()
    const location = useLocation();
    const [visible, setVisible] = useState(false)

    const controlSaveProductColor = useSelector(state => state.reducerControlSaveProductColor)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const save = () => {
        if (location.pathname == "/personalizar/tema" || location.pathname == "/personalizar") {
            alert("/personalizar/tema")
        } else if (location.pathname == "/personalizar/email") {
            alert("/personalizar/email")
        } else if (location.pathname == "/personalizar/home") {
            alert("/personalizar/home")
        }
    }

    useEffect(() => {

        if (controlSaveProductColor == false) {
            setVisible(true)
        }
    }, [controlSaveProductColor])


    return (
        <Modal
            isOpen={true}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props?.setVisibleModal(false)}
        >
            {props.children}
        </Modal>
    )
}

export default ModalAlertSave
