
import { useDispatch, useSelector } from 'react-redux';
import { BodyNew, ContainerNew, TitleNew } from "./styled";
import { GrFormClose } from "react-icons/gr";
import imgBackground from "../../../../assets/imgs/preview-background.png";

import { useEffect, useRef, useState } from 'react';
import BoxConfigEmail from '../box-config-email';
import IconCam from "../../../../assets/imgs/icon-camera.svg";
import AlertCloseBoss from '../../../../components/alert-close-boss';
import { getTranslation } from '../../../../translations';
import { reviews } from "../../pages/home/dataPreview";

import CardDropi from "../card-review-dropi-preview";
import CardLily from "../card-review-lily-preview";
import CardTiktok from '../card-review-tiktok-preview';
import Card2Lily from '../card2-review-lily-preview';
import Card3Lily from '../card3-review-lily-preview';
import Card2Dropi from '../card2-review-dropi-preview';
import Card3Dropi from '../card3-review-dropi-preview';
import { BoxMessage } from '../../../questions/pages/home/styled';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


import { Swiper, SwiperSlide } from "swiper/react";

 import { Pagination, Autoplay, Navigation, Scrollbar, A11y, EffectCards, EffectFade, EffectCube, EffectCoverflow, EffectFlip, EffectCreative } from "swiper/modules";
 import 'swiper/css';
 import 'swiper/css/pagination';
 import 'swiper/css/navigation';
import "./styles.css";
import Card1Home from '../card1-home';
import Card01Corousel from '../card-01-corousel';
import Card02Corousel from '../card-02-corousel';

const PreviewHomeCarrosselMulti = (props) => {
  const dispatch = useDispatch()
  const [optionSelected, setOptionSelected] = useState("reviews")
  const [icon, setIcon] = useState({ size: 20, color: "orange" })

  const configControl = useSelector(state => state.reducerControlConfig)
  const reviewsFavorites = useSelector(state => state.reducerReviews)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
  const history = useHistory();

  const [autoplaySwiper, setautoplaySwiper] = useState(false)

  const getCard = (card, item) => {

    switch (card) {
      case "carousel-multi-modelo01":
        return <Card01Corousel item={item} />
      case "carousel-multi-modelo02":
        return <Card02Corousel item={item} />
      // case "carousel-modelo01":
      //   return <Card1Home item={item} />
      // case "dropi":
      //   return <CardDropi item={item} />
      // case "dropi2":
      //   return <Card2Dropi item={item} />
      // case "dropi3":
      //   return <Card3Dropi item={item} />
      default:
        return <Card01Corousel item={item} />
    }
  }


  useEffect(() => {
    if (configControl.homereviews_animation == 1) {
      swiperRef?.current?.swiper?.autoplay?.start()
      //setautoplaySwiper(configControl.homereviews_animation == 1 || configControl.homereviews_animation == "1" ? true : false)
    } else {
      swiperRef?.current?.swiper?.autoplay?.stop()
    }

    if (configControl.homereviews_navigation == 1) {
      swiperRef?.current?.swiper?.navigation?.enable()
      //setautoplaySwiper(configControl.homereviews_animation == 1 || configControl.homereviews_animation == "1" ? true : false)
    } else {
      swiperRef?.current?.swiper?.navigation?.disable()
    }

    if (configControl.homereviews_pagination == 1) {
      swiperRef?.current?.swiper?.pagination?.enable()
      //setautoplaySwiper(configControl.homereviews_animation == 1 || configControl.homereviews_animation == "1" ? true : false)
    } else {
      swiperRef?.current?.swiper?.pagination?.disable()
    }

  }, [configControl])

  const swiperRef = useRef(null);

  useEffect(() => {



    var elements3 = document.getElementsByClassName('swiper-button-next'); // get all elements
    for (var i = 0; i < elements3.length; i++) {
      elements3[i].style.color = configControl.homereviews_arrow_color;
    }

    var elements4 = document.getElementsByClassName('swiper-button-prev'); // get all elements
    for (var i = 0; i < elements4.length; i++) {
      elements4[i].style.color = configControl.homereviews_arrow_color;
    }

    // setTimeout(() => {
    //   var elements5 = document.getElementsByClassName('swiper-pagination-bullet'); // get all elements
    //   for (var i = 0; i < elements5.length; i++) {
    //     elements5[i].style.background = "red"
    //   }
    // }, 1000);

    setTimeout(() => {
      // var elements5 = document.getElementsByClassName('swiper-pagination-total'); // get all elements
      // for (var i = 0; i < elements5.length; i++) {
      //   elements5[i].style.color = configControl.homereviews_pagination_color
      // }

      // var elements6 = document.getElementsByClassName('swiper-pagination-current'); // get all elements
      // for (var i = 0; i < elements6.length; i++) {
      //   elements6[i].style.color = configControl.homereviews_pagination_color
      // }

      var elements7 = document.getElementsByClassName('swiper-pagination-horizontal'); // get all elements
      for (var i = 0; i < elements7.length; i++) {
        elements7[i].style.color = configControl.homereviews_pagination_color
      }
    }, 100);

  }, [configControl])


  const updatePagination = () => {
    // setTimeout(() => {
    //   var elements5 = document.getElementsByClassName('swiper-pagination swiper-pagination-clickable swiper-pagination-fraction swiper-pagination-horizontal'); // get all elements
    //   if (elements5[0] != undefined) {
    //     elements5[0].style.color = configControl.homereviews_pagination_color
    //   }


    // }, 100);

  }

  useEffect(() => {
    document.documentElement.style.setProperty('--swiper-navigation-size', '30px');
  },)

  return (
    <ContainerNew img={imgBackground}>
      <TitleNew
        backgroundColor={configControl.homereviews_background_color}
        fontsize={configControl.homereviews_font_size_title}
        color={configControl.homereviews_color_title}
        font={configControl.homereviews_font_title}
        align={configControl.homereviews_align_title}
      >
        <label>
          {/* {configControl.homereviews_title} */}
          {configControl?.homereviews_title == null || configControl?.homereviews_title == "null" ? "" : configControl?.homereviews_title}
       
        </label>
      </TitleNew>

      <BodyNew backgroundColor={configControl.homereviews_background_color}>
        {configControl.homereviews_loop == 1 ?
          <Swiper
            ref={swiperRef}
            id='swiper-multi'
            modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
            spaceBetween={10}
            slidesPerView={4}
            navigation
            pagination={{ clickable: true, type: 'fraction', }}
            onSwiper={(swiper) => console.log("onswiper")}
            onSlideChange={() => updatePagination()}
            loop={true}
            speed={2000}
            effect={"slide"}
            breakpoints={{
              700: {
                slidesPerView: 1,
              },
              1000: {
                slidesPerView: 1.5,
              },
              1500: {
                slidesPerView: 2.5,
              },
              1700: {
                slidesPerView: 4.5,
              },
            }}

          >
            {
              reviews.map((item) => {
                return (
                  <SwiperSlide>
                    {getCard(configControl.homereviews_card, item)}
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          :
          <Swiper
            ref={swiperRef}
            id='swiper-multi'
            modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
            spaceBetween={10}
            slidesPerView={4}
            navigation
            pagination={{ clickable: true, type: 'fraction', }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            loop={false}
            speed={2000}
            effect={"slide"}
            breakpoints={{
              700: {
                slidesPerView: 1,
              },
              1000: {
                slidesPerView: 1.5,
              },
              1500: {
                slidesPerView: 2.5,
              },
              1700: {
                slidesPerView: 4.5,
              },
            }}

          >
            {
              reviews.map((item) => {
                return (
                  <SwiperSlide>
                    {getCard(configControl.homereviews_card, item)}
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        }

      </BodyNew>

    </ContainerNew >
  )
}

export default PreviewHomeCarrosselMulti