import React, { useEffect, useState } from 'react'
import { BoxButtons, BoxColors, BoxOptions, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Container, Efeito, EstiloWidget, InputCustom, Item, ItemLayout, ItemSelect, Layout, Posicao, SelectCustom, Subtitle, Titulo } from './styled'

import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useDispatch, useSelector } from 'react-redux';

import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';
import { BarSubmenu, BarSubmenuItem, ItemOption, Main, MenuOption, Title } from '../bar-config-product-cores/styled';
import { IoIosArrowBack } from 'react-icons/io';


import IconText from "../../../../assets/imgs/icon-text.svg";
import { useHistory, useLocation } from 'react-router';
import { CounterItemsCircle, InputTextAreaCustom } from '../box-config-tema/styled';
import { CloudChange, Setting2, Text } from 'iconsax-react';


const BarConfigAvaliarConfig = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const selectedMenu = useLocation();
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const [controlVisibleMidias, setControlVisibleMidias] = useState("")

    // const changeMidias = (value) => {
    //     switch (value) {
    //         case "Somente gravar":
    //             dispatch({
    //                 type: "CONFIG_CONTROL_SET", payload: {
    //                     screen_review_config_visible_media_cam: 1,
    //                     screen_review_config_visible_media_upload: 0,
    //                     screen_review_config_visible_media_tiktok: 0
    //                 }
    //             })
    //             break;
    //         case "Somente upload":
    //             dispatch({
    //                 type: "CONFIG_CONTROL_SET", payload: {
    //                     screen_review_config_visible_media_cam: 0,
    //                     screen_review_config_visible_media_upload: 1,
    //                     screen_review_config_visible_media_tiktok: 0
    //                 }
    //             })
    //             break;
    //         case "Somente tiktok":
    //             dispatch({
    //                 type: "CONFIG_CONTROL_SET", payload: {
    //                     screen_review_config_visible_media_cam: 0,
    //                     screen_review_config_visible_media_upload: 0,
    //                     screen_review_config_visible_media_tiktok: 1
    //                 }
    //             })
    //             break;
    //         case "Somente texto":
    //             dispatch({
    //                 type: "CONFIG_CONTROL_SET", payload: {
    //                     screen_review_config_visible_media_cam: 0,
    //                     screen_review_config_visible_media_upload: 0,
    //                     screen_review_config_visible_media_tiktok: 0
    //                 }
    //             })
    //             break;
    //         case "Gravar e Upload":
    //             dispatch({
    //                 type: "CONFIG_CONTROL_SET", payload: {
    //                     screen_review_config_visible_media_cam: 1,
    //                     screen_review_config_visible_media_upload: 1,
    //                     screen_review_config_visible_media_tiktok: 0
    //                 }
    //             })
    //             break;
    //         case "Gravar e TikTok":
    //             dispatch({
    //                 type: "CONFIG_CONTROL_SET", payload: {
    //                     screen_review_config_visible_media_cam: 1,
    //                     screen_review_config_visible_media_upload: 0,
    //                     screen_review_config_visible_media_tiktok: 1
    //                 }
    //             })
    //             break;
    //         case "Upload e TikTok":
    //             dispatch({
    //                 type: "CONFIG_CONTROL_SET", payload: {
    //                     screen_review_config_visible_media_cam: 0,
    //                     screen_review_config_visible_media_upload: 1,
    //                     screen_review_config_visible_media_tiktok: 1
    //                 }
    //             })
    //             break;
    //         case "Gravar, Upload e  TikTok":
    //             dispatch({
    //                 type: "CONFIG_CONTROL_SET", payload: {
    //                     screen_review_config_visible_media_cam: 1,
    //                     screen_review_config_visible_media_upload: 1,
    //                     screen_review_config_visible_media_tiktok: 1
    //                 }
    //             })
    //             break;

    //         default:

    //             break;
    //     }
    // }

    // useEffect(() => {



    //     if (
    //         configControl.screen_review_config_visible_media_cam == 1 &&
    //         configControl.screen_review_config_visible_media_upload == 0 &&
    //         configControl.screen_review_config_visible_media_tiktok == 0
    //     ) {
    //         setControlVisibleMidias("Somente gravar")
    //     } else if (
    //         configControl.screen_review_config_visible_media_cam == 0 &&
    //         configControl.screen_review_config_visible_media_upload == 1 &&
    //         configControl.screen_review_config_visible_media_tiktok == 0
    //     ) {
    //         setControlVisibleMidias("Somente upload")
    //     } else if (
    //         configControl.screen_review_config_visible_media_cam == 0 &&
    //         configControl.screen_review_config_visible_media_upload == 0 &&
    //         configControl.screen_review_config_visible_media_tiktok == 1
    //     ) {
    //         setControlVisibleMidias("Somente tiktok")
    //     } else if (
    //         configControl.screen_review_config_visible_media_cam == 0 &&
    //         configControl.screen_review_config_visible_media_upload == 0 &&
    //         configControl.screen_review_config_visible_media_tiktok == 0
    //     ) {
    //         setControlVisibleMidias("Somente texto")
    //     } else if (
    //         configControl.screen_review_config_visible_media_cam == 1 &&
    //         configControl.screen_review_config_visible_media_upload == 1 &&
    //         configControl.screen_review_config_visible_media_tiktok == 0
    //     ) {
    //         setControlVisibleMidias("Gravar e Upload")
    //     } else if (
    //         configControl.screen_review_config_visible_media_cam == 1 &&
    //         configControl.screen_review_config_visible_media_upload == 0 &&
    //         configControl.screen_review_config_visible_media_tiktok == 1
    //     ) {
    //         setControlVisibleMidias("Gravar e TikTok")
    //     } else if (
    //         configControl.screen_review_config_visible_media_cam == 0 &&
    //         configControl.screen_review_config_visible_media_upload == 1 &&
    //         configControl.screen_review_config_visible_media_tiktok == 1
    //     ) {
    //         setControlVisibleMidias("Upload e TikTok")
    //     } else if (
    //         configControl.screen_review_config_visible_media_cam == 1 &&
    //         configControl.screen_review_config_visible_media_upload == 1 &&
    //         configControl.screen_review_config_visible_media_tiktok == 1
    //     ) {
    //         setControlVisibleMidias("Gravar, Upload e  TikTok")
    //     }
    // }, [configControl])







    const save = () => {

        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                screen_review_config_title_star: configControl.screen_review_config_title_star,
                screen_review_config_title_comment: configControl.screen_review_config_title_comment,
                screen_review_config_title_medias: configControl.screen_review_config_title_medias,
                screen_review_config_title_medias_secondary: configControl.screen_review_config_title_medias_secondary,
                screen_review_config_title_data: configControl.screen_review_config_title_data,
                screen_review_config_title_end: configControl.screen_review_config_title_end,
                screen_review_config_title_end_secondary: configControl.screen_review_config_title_end_secondary,
                screen_review_config_visible_media_tiktok: configControl.screen_review_config_visible_media_tiktok,
                screen_review_config_visible_media_upload: configControl.screen_review_config_visible_media_upload,
                screen_review_config_visible_media_cam: configControl.screen_review_config_visible_media_cam,
                screen_review_config_visible_data_whatsapp: configControl.screen_review_config_visible_data_whatsapp,

                terms_use: configControl.terms_use,
            }
        })

    }

    const getDefault = () => {
        const themaDefault = {
            id: config.data.id,
            screen_review_config_title_star: "COMO VOCÊ AVALIA ESTE ITEM?",
            screen_review_config_title_comment: "CONTE-NOS MAIS !",
            screen_review_config_title_medias: "MOSTRE-O",
            screen_review_config_title_medias_secondary: "Adoraremos ver em ação.",
            screen_review_config_title_data: "SOBRE VOCÊ",
            screen_review_config_title_end: "OBRIGADO !",
            screen_review_config_title_end_secondary: "Sua avaliação foi enviada com sucesso.",
            screen_review_config_visible_media_tiktok: "1",
            screen_review_config_visible_media_upload: "1",
            screen_review_config_visible_media_cam: "1",
            screen_review_config_visible_data_whatsapp: "1",

            terms_use: "Ao enviar eu reconheço os termos e a política de privacidade, e que minha análise será postada publicamente e compartilhada on-line.",
        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })
    }


    return (
        <Main>

            <Container visible={true}>

                <Title>
                    <span onClick={() => {
                        history.push("/personalizar")
                        //isSaveHomeConfig() && history.push("/personalizar")
                    }}>
                        <IoIosArrowBack />
                    </span>
                    <label>
                        {translation.thema.configs.telaAvaliar}
                        {/* {translation.thema.homepage.title} */}
                    </label>
                </Title>

                <MenuOption>
                    <ItemOption active={false} onClick={() => {
                        dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "avaliar/cores" })
                        //isSaveHomeConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/cores" }) 
                    }}>
                        {translation.thema.homepage.cores}
                    </ItemOption>
                    <ItemOption active={true} onClick={() => { dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "avaliar/config" }) }}>
                        {translation.thema.homepage.avancado}
                    </ItemOption>
                </MenuOption>

                {/* <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <CloudChange size="24" color="#161718" />
                                <label style={{ marginLeft: "10px" }}>Modelo</label>
                            </div>
                        </span>
                    </Titulo>
                    <ItemSelect>
                        <SelectCustom name="select_layout_form" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_form: e.target.value } }) }}                        >
                            <option value="modelo1" selected={configControl.layout_form == "modelo1" ? true : false}>{translation.thema.boxConfigTema.padrao}</option>
                            <option value="modelo2" selected={configControl.layout_form == "modelo2" ? true : false}>{translation.thema.boxConfigTema.modelo} 2</option>
                        </SelectCustom>
                    </ItemSelect>
                </BoxOptions> */}

                <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <img width={20} src={IconText} />
                                <label>Títulos</label>
                            </div>
                        </span>
                    </Titulo>
                    <ItemSelect>
                        <label>{translation.thema.configs.telaEstrelas}</label>
                        <InputCustom
                            type={"text"}
                            value={configControl.screen_review_config_title_star}
                            placeholder={"Tela de estrelas"}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_title_star: e.target.value } }) }}
                        />
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.configs.telaComentarios}</label>
                        <InputCustom
                            type={"text"}
                            value={configControl.screen_review_config_title_comment}
                            placeholder={"Tela de comentário"}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_title_comment: e.target.value } }) }}
                        />
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.configs.telaMidias}</label>
                        <InputCustom
                            type={"text"}
                            value={configControl.screen_review_config_title_medias}
                            placeholder={"Tela de mídias"}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_title_medias: e.target.value } }) }}
                        />
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.configs.telaDados}</label>
                        <InputCustom
                            type={"text"}
                            value={configControl.screen_review_config_title_data}
                            placeholder={"Tela de dados"}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_title_data: e.target.value } }) }}
                        />
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.configs.telaAgradecimentos}</label>
                        <InputCustom
                            type={"text"}
                            value={configControl.screen_review_config_title_end}
                            placeholder={"Tela de agradecimento"}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_title_end: e.target.value } }) }}
                        />
                    </ItemSelect>

                </BoxOptions>

                <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <Setting2 size="24" color="#161718" />
                                <label style={{ marginLeft: "10px" }}>{translation.thema.configs.configuracoes}</label>
                            </div>
                        </span>

                    </Titulo>
                    {/* <ItemSelect>
                        <label>Midias:</label>
                        <SelectCustom onChange={(e) => { changeMidias(e.target.value) }}>
                            <option value={"Somente gravar"} selected={controlVisibleMidias == "Somente gravar" ? true : false}>Somente gravar</option>
                            <option value={"Somente upload"} selected={controlVisibleMidias == "Somente upload" ? true : false}>Somente upload</option>
                            <option value={"Somente tiktok"} selected={controlVisibleMidias == "Somente tiktok" ? true : false}>Somente tiktok</option>
                            <option value={"Somente texto"} selected={controlVisibleMidias == "Somente texto" ? true : false}>Somente texto</option>
                            <option value={"Gravar e Upload"} selected={controlVisibleMidias == "Gravar e Upload" ? true : false}>Gravar e Upload</option>
                            <option value={"Gravar e TikTok"} selected={controlVisibleMidias == "Gravar e TikTok" ? true : false}>Gravar e TikTok</option>
                            <option value={"Upload e TikTok"} selected={controlVisibleMidias == "Upload e TikTok" ? true : false}>Upload e TikTok</option>
                            <option value={"Gravar, Upload e  TikTok"} selected={controlVisibleMidias == "Gravar, Upload e  TikTok" ? true : false}>Gravar, Upload e  TikTok</option>
                        </SelectCustom>
                    </ItemSelect> */}

                    <ItemSelect>
                        <label>{translation.thema.configs.opcaoGravar}:</label>
                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_visible_media_cam: e.target.value } }) }}>
                            <option value={"1"} selected={configControl.screen_review_config_visible_media_cam == "1" ? true : false}>Habilitar {translation.thema.configs.gravacao}</option>
                            <option value={"0"} selected={configControl.screen_review_config_visible_media_cam == "0" ? true : false}>{translation.thema.configs.desabilitar} {translation.thema.configs.gravacao}</option>
                        </SelectCustom>
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.configs.opcao} upload:</label>
                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_visible_media_upload: e.target.value } }) }}>
                            <option value={"1"} selected={configControl.screen_review_config_visible_media_upload == "1" ? true : false}>Habilitar upload</option>
                            <option value={"0"} selected={configControl.screen_review_config_visible_media_upload == "0" ? true : false}>{translation.thema.configs.desabilitar} upload</option>
                        </SelectCustom>
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.configs.opcao} TikTok:</label>
                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_visible_media_tiktok: e.target.value } }) }}>
                            <option value={"1"} selected={configControl.screen_review_config_visible_media_tiktok == "1" ? true : false}>Habilitar TikTok</option>
                            <option value={"0"} selected={configControl.screen_review_config_visible_media_tiktok == "0" ? true : false}>{translation.thema.configs.desabilitar} TikTok</option>
                        </SelectCustom>
                    </ItemSelect>

                    <ItemSelect>
                        <label>Whatsapp:</label>
                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_visible_data_whatsapp: e.target.value } }) }}>
                            <option value={"1"} selected={configControl.screen_review_config_visible_data_whatsapp == "1" ? true : false}>Habilitar whatsapp</option>
                            <option value={"0"} selected={configControl.screen_review_config_visible_data_whatsapp == "0" ? true : false}>{translation.thema.configs.desabilitar} whatsapp</option>
                        </SelectCustom>
                    </ItemSelect>
                </BoxOptions>

                <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <Text size="24" color="#161718" />
                                <label style={{ marginLeft: "10px" }}>{translation.thema.configs.termosUso}</label>
                            </div>
                        </span>

                    </Titulo>
                    <ItemSelect style={{ position: "relative" }}>
                        <label>{translation.thema.configs.termosUso}:</label>
                        <InputTextAreaCustom
                            value={configControl.terms_use}
                            rows={6}
                            maxLength="255"
                            placeholder={`${translation.thema.boxConfigTema.modelo}`}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { terms_use: e.target.value } }) }}
                        />
                        <CounterItemsCircle porc={`${Math.round(configControl.terms_use?.length / 255 * 100)}%`} colorBorder={configControl.terms_use?.length < 255 ? "green" : "red"}>
                            <label>{255 - configControl.terms_use?.length}</label>
                        </CounterItemsCircle>
                    </ItemSelect>
                </BoxOptions>


                <ButtonHelp>
                    <img width={20} src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() => getDefault()}>
                        <label>{translation.thema.boxConfigEmail.restaurar}</label>
                    </ButtonReset>
                    <ButtonSave onClick={() => save()}>
                        {translation.thema.boxConfigEmail.salvar}
                    </ButtonSave>
                </BoxButtons>
            </Container>
        </Main >

    )
}

export default BarConfigAvaliarConfig