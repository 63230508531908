const INITIAL_STATE = {isOpen: true}

const ControlMenu = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'HEADER_CONTROL_MENU':
            return state = action.payload
        default:
            return state;
    }
}

export default ControlMenu