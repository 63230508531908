import styled from "styled-components";

export const BoxApps = styled.span`
    background-color: red;
    margin-left: ${props => props.menuOpen ? "200px" : "72px"};
    transition: 0.5s ease-in;
`

export const Container = styled.div`
    width: ${props => props.menuOpen ? "calc(100% - 200px)" : "calc(100% - 72px)"} ;
    //min-height: 75px;
    display: flex;
    align-items: center;
    padding: 5px 30px;
    /* flex-wrap: wrap-reverse; */
    gap: 1rem;
    //padding-top: 60px;
    justify-content: space-between;
    position: relative ;
    //background-color: yellow ;

    //margin: 5px 0px ;
    margin-left: ${props => props.menuOpen ? "200px" : "72px"} ;
    margin-top: 0px ;
    //background-color: yellow ;

    height: 60px ;
    //border-bottom: 1px solid #D3DAE3;
    //margin-bottom: 20px ;

    transition: 0.5s ease-in;
    margin-bottom: 20px;


    @media screen and (max-width: 768px) {
        //margin: 10px !important;
        padding: 0px 10px ;
        //background-color: green ;
        //width: calc(100% - 20px);
        //background-color: red ;
    }

    /* @media screen and (max-width: 550px) {
        //background-color: red ;
        width: ${props => props.menuOpen ? "calc(100% - 72px)" : "calc(100% - 0px)"} ;
        margin-left: ${props => props.menuOpen ? "72px" : "0px"} ;
    } */

    svg {
        flex-shrink: 0;
    }

`;

export const BoxMenu = styled.span`
    display: flex ;
    //background-color: red ;
    cursor: pointer;
`

export const BoxBarApps = styled.div`
    margin-left: 0px;
    //background-color: red ;
    //display: flex;
    //position: fixed ;

    @media screen and (max-width: 768px) {
        margin-left: 0px !important;
    }
`

export const Title = styled.h3`
    display: flex;
    //min-width: 150px;
    text-transform: capitalize;
    font-weight: bold;
    color: gray;

    //background-color: red !important;
    height: "50px";
    //padding: 0px 10px ;

    @media screen and (max-width: 768px) {
        /* justify-content: center; */
        align-items: center;
        width: 100%;
    }

    img{
        position: absolute ;
        @media(min-width: 768px) {
            display: none ;
        }
    }
`;

export const SectionOptions = styled.div`
    font-size: 18px;
    display: flex;
    //flex: 1;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    //background-color: red ;
    svg{
        display: none ;
    }

    @media screen and (max-width: 489px) {
        /* justify-content: center; */
        align-items: center;
    }

    @media screen and (max-width: 768px) {
        /* justify-content: center; */
        
        
        svg{
            display: flex;
        }
    }
`;


export const IconMenu = styled.span`
    display: flex;
    margin-left: 5px;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background-color: ${props => props.selectedMenu && "#D3D3D3"};
    
    :hover{
        background-color: #D3D3D3;
    }
`;

export const UserDropdown = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    
    label{
        margin-right: 10px;
        font-size: 14px;
        cursor: pointer;
    }

    @media screen and (max-width: 440px) {
        .user__name {
            display: none;
        }
    }

    span{
        //background-color: red ;
        span{
            width: 24px ;
            height: 24px ;
            background-color: #FFCC00; ;
            border-radius: 50% ;
            display: flex ;
            align-items: center ;
            justify-content: center ;
            font-size: 14px ;
        }
    }

   
`;

//Dropdown 

export const UserDropdownSubMenu = styled.div`
    z-index: 999999;
    position: absolute;
    margin-top: 35px;
    flex-direction: column;
    display: ${props => props.visible ? "flex" : "none"} ;
    background-color: white;
    box-shadow: 0 0 2em #DCDBDA;
    padding: 20px;
    border-radius: 5px;
    right: 0px;
    top: 0px;
    color: #5e6278;
    //background-color: red;
    
    div:has(div){
        background-color: blue;
    }


    span{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        //background-color: gray ;

       :hover{
            opacity: 0.7;
        }
        label{
            display: flex ;
            margin-left: 0px;
            font-size: 14px;
            white-space: nowrap;
            //color: #5e6278;
            //background-color: green ;
        }
        label:nth-child(2){
            font-size: 16px;
        }
    }
`;

export const UserDropdownSubMenuInfor = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    //background-color: red ;

    label{
        font-size: 12px;
        margin-bottom: 1px;
        white-space: nowrap;
        cursor: auto;
    }

    label:nth-child(1){
        font-size: 16px;
    }
`;

export const SubMenu = styled.div`
    display: none;
    :hover{

    }
`;

export const Nav = styled.nav`
    display: inline-block;
    margin: 2rem auto 0;
    //background: red;
    color: #fff;
    text-align: left;

    ul{
        line-height: 45px;
        font-weight: 700;
        text-transform: uppercase;

        li{
            display: inline-block;
            position: relative;

            &:hover {
                //background-color: purple;

                .nav__submenu {
                display: block;
                }
            }
        }
    }


`;

export const ChaveEmpreender = styled.div`
    
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    //cursor: pointer;
    flex-direction: column;
    
    span{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        align-items: center;

        label{
            margin-left: 5px;
            font-size: 14px;
            cursor: pointer;
            color: ${props => props.selectedMenu ? "#20D489" : "#5e6278"};
            
        }
    }

    span:nth-child(2){

        cursor: pointer;
        label{
            font-size: 14px;
            
        }
    }

    span:nth-child(1){

        cursor: pointer;
        label{
            font-size: 14px;
            color: #20D489;
        }
    }

    
`;

export const Notify = styled.div`
    margin: 0px 10px;
    display: flex;
    align-items: center; 
    justify-content: center !important;
    cursor: pointer;
    position: relative;

    img{
        width: 24px ;
        height: 24px ;
    }
`;

export const SubMenuNotify = styled.span`
    z-index: 1;
    position: absolute;
    flex-direction: column;
    display: ${props => props.visible ? "flex" : "none"} ;
    background-color: white;
    box-shadow: 0 0 2em #DCDBDA;
    padding: 20px;
    border-radius: 5px;
    top: 0px;
    right: 0px;
    margin-top: 35px;
    color: #5e6278;
    cursor: auto;
    
    span{
        display: flex;
        align-items: center;
        :hover{
            opacity: 0.7;
        }
        label{
            margin-left: 0px;
            font-size: 14px;
            cursor: pointer;
            white-space: nowrap
        }
    }
`;

export const QtdNotify = styled.div`
    //background: red;
    position: absolute;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    

    label{
        display: flex;
        font-size: 7px;
        width: 15px;
        height: 15px;
        background-color: #F13F6C;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin: -25px -25px 0px 0px;
        color: white;
        padding: 0px;
    }
`;



export const TooltipMenu = styled.div`
    //background-color: green;
    height: 100% ;
    display: flex ;
    align-items: center ;
    position: relative ;

    :hover{

        label{
            visibility: visible ;
        }
    }

    label{
        display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 350px !important; 
        width: max-content;
        padding: 5px 10px;
        transform: translateX(-100%); 
        margin-top: -70px ;
        margin-left: 35px !important;
        font-size: 12px !important;
        color: white ;

        :after{
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
    
    svg{
        margin-left: 5px;
        position: relative ;
    }
`;


export const Trial = styled.label`
  display: flex;
  background-color: #FFC400;
  padding: 10px 20px;
  color: #5e6278;
  margin-right: 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: -2px;

  span{
    margin-left: 5px;
  }
`