import styled from "styled-components";

export const Container = styled.div`
    background-color: transparent ;
    display: flex;
    //height: 200px ;
    //width: 100% ;
    width: 100%;
    flex-direction: column ;
    padding: 10px 20px ;
    margin-bottom: 5px;
    min-width: 300px;
    border: ${props => `${props.borderSize} solid ${props.borderColor}`}; 
    border-radius: ${props => props.borderRadius}  ;

    @media(max-width: 1200px) {
        width: 100% ;
    }

    :hover{
        border: ${props => "1px solid " + props.colorBorder};
        cursor: pointer;
    }
`
export const BoxMedias = styled.div`
    display: flex ;
    //background-color: red ;
    //height: 50px;
    padding: 20px 0px;
    gap: 10px;
    align-items: center ;

    img{
        width: 90px ;
        height: 90px ;
        //object-fit: cover;
        cursor: pointer;
        border-radius: 5px ;
        background-color: white;
        padding: 5px ;
    }
`

export const Title = styled.div`
    display: flex ;
    //background-color: red ;
    padding-top: 10px;
    label{
        font-size: 18px;
        font-weight: 400;
        color: ${props => props.fontColor};
        span{
            font-size: 9px ;
            margin-left: 5px ;
            font-weight: initial;
        }
    }
`
export const Description = styled.div`
    display: flex ;
    //background-color: red ;
    flex: 1 ;
    padding-top: 10px;
    color: ${props => props.fontColor};
    flex-direction: column;

    label{
        font-size: 14px;
        color: ${props => `${props.fontColor}99`};
    }

    p{
        margin: 0px;
        padding: 0px;
        font-weight: 400;
        font-size: 14px;
    }
`

export const Stars = styled.div`
    display: flex ;
    height: 50px;
    //background-color: red ;
    margin-top: 5px ; 
    justify-content: ${props => props.position == "default" ? "left" : props.position};

    label{
        font-size: 14px;
        //background-color: red ;
        display: flex ;
        align-items: center ;
        margin-right: 10px;
        margin-top: 1px;
        color: ${props => props.fontColor};
        font-weight: 400;
    }

    span{
        display: flex ;
        align-items: center;
        //background-color: red ;
    }
`


export const BadgeCheck = styled.div`
  position: relative;  
  display: flex ;
  //background-color: yellow ;
  align-items: center ;

    svg{
      cursor: initial ;
    }

    :hover{

      label{
        visibility: visible;
        opacity: 0.9;
        //top: 30px;
        z-index: 999;
      }
       
    }

    label{
        display: flex;
        position: absolute;
        background: black;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        bottom: 30px;
        right: 0px;
        left: 32px;
        visibility: hidden;
        transform: translateX(-100%);
        /* display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        transform: translateX(-100%);
        bottom: 0;
        margin-bottom: 30px;
        margin-left: -5px;

        
        font-size: 10px;
        color: white; */
        
        font-size: 10px;
        color: white;
        font-family: ${props => props.theme.font} !important;

        :after{
       
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        
        } 
    }
`

export const InforText = styled.label`
    display: flex;
    margin-bottom: 20px;
    font-weight: 400;
    color: ${props => props.fontColor};
    font-size: 14px;
`

export const InforDate = styled.label`
    display: flex;
    font-size: 12px;
    margin-top: 3px;
    
`