/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
'use client'

import { useState } from 'react'
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { getTranslation } from '../../../../../translations'
import { Container } from './styled'

const sortOptions = [
    { name: 'Most Popular', href: '#' },
    { name: 'Best Rating', href: '#' },
    { name: 'Newest', href: '#' },
]
const filters = [
    {
        id: 'estrelas',
        name: 'Estrelas',
        options: [
            { value: '5', label: '5 Estrelas' },
            { value: '4', label: '4 Estrelas' },
            { value: '3', label: '3 Estrelas' },
            { value: '2', label: '2 Estrelas' },
            { value: '1', label: '1 Estrelas' },
        ],
    },
    {
        id: 'status',
        name: 'Status',
        options: [
            { value: 'clothing-company', label: 'Aprovados' },
            { value: 'fashion-inc', label: 'Pendentes' },
            { value: 'shoes-n-more', label: "Escondidos" },
            { value: 'shoes-n-more', label: "Excluídos" },
        ],
    },
    {
        id: 'color',
        name: 'Favoritos',
        options: [
            { value: 'white', label: 'Favoritos' },
            { value: 'black', label: 'Não favoritos' },
        ],
    },
    {
        id: 'sizes',
        name: 'Mídias',
        options: [
            { value: 's', label: 'Imagens' },
            { value: 'm', label: 'Vídeos' },
            { value: 'l', label: 'Tik Tok' },
        ],
    },
]

export default function Example(props) {
    const [open, setOpen] = useState(false)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    return (
        <Container>
            <div className="mx-auto max-w-3xl px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex items-center justify-between">
                    <PopoverGroup className="hidden sm:flex sm:items-baseline sm:space-x-8">
                        <Popover
                            key={"section.name"}
                            id={`desktop-menu-${"sectionIdx"}`}
                            className="relative inline-block text-left"
                        >
                            <div>
                                <PopoverButton className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                    <span style={{ marginRight: "0px", backgroundColor: "transparent", border: "2px solid transparent", width: '70px', display: "flex", justifyContent: "right" }}>{translation.avaliacoes.Estrelas}</span>
                                    <ChevronDownIcon
                                        aria-hidden="true"
                                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    />
                                </PopoverButton>
                            </div>

                            <PopoverPanel
                                transition
                                className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <form className="space-y-4">
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.star5}
                                            onChange={() => { props.setStar5(!props.star5) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {`5 ${translation.avaliacoes.Estrelas}`}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.star4}
                                            onChange={() => { props.setStar4(!props.star4) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {`4 ${translation.avaliacoes.Estrelas}`}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.star3}
                                            onChange={() => { props.setStar3(!props.star3) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {`3 ${translation.avaliacoes.Estrelas}`}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.star2}
                                            onChange={() => { props.setStar2(!props.star2) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {`2 ${translation.avaliacoes.Estrelas}`}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.star1}
                                            onChange={() => { props.setStar1(!props.star1) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {`1 ${translation.avaliacoes.Estrelas}`}
                                        </label>
                                    </div>
                                </form>
                            </PopoverPanel>
                        </Popover>

                        <Popover
                            key={"section.name"}
                            id={`desktop-menu-${"sectionIdx"}`}
                            className="relative inline-block text-left"
                        >
                            <div>
                                <PopoverButton className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                    <span style={{ marginRight: "0px", backgroundColor: "transparent", border: "2px solid transparent", width: '70px', display: "flex", justifyContent: "right" }}>{"Status"}</span>
                                    <ChevronDownIcon
                                        aria-hidden="true"
                                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    />
                                </PopoverButton>
                            </div>

                            <PopoverPanel
                                transition
                                className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <form className="space-y-4">
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.approved}
                                            onChange={() => { props.setStatusApproved(!props.approved) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {translation.avaliacoes.aprovados}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.pending}
                                            onChange={() => { props.setStatusPending(!props.pending) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {translation.avaliacoes.pendentes}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.hidden}
                                            onChange={() => { props.setStatusHidden(!props.hidden) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {translation.avaliacoes.escondidos}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.new}
                                            onChange={() => { props.setStatusNew(!props.new) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {translation.avaliacoes.novos}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.deleted}
                                            onChange={() => { props.setStatusDeleted(!props.deleted) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {translation.avaliacoes.excluidos}
                                        </label>
                                    </div>

                                </form>
                            </PopoverPanel>
                        </Popover>

                        <Popover
                            key={"section.name"}
                            id={`desktop-menu-${"sectionIdx"}`}
                            className="relative inline-block text-left"
                        >
                            <div>
                                <PopoverButton className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                    <span style={{ marginRight: "0px", backgroundColor: "transparent", border: "2px solid transparent", width: '70px', display: "flex", justifyContent: "right" }}>{"Favoritos"}</span>
                                    <ChevronDownIcon
                                        aria-hidden="true"
                                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    />
                                </PopoverButton>
                            </div>

                            <PopoverPanel
                                transition
                                className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <form className="space-y-4">
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.favorite}
                                            onChange={() => { props.setFavorite(!props.favorite) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {"Favoritos"}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.noFavorite}
                                            onChange={() => { props.setNoFavorite(!props.noFavorite) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {translation.avaliacoes.naoFavoritos}
                                        </label>
                                    </div>
                                </form>
                            </PopoverPanel>
                        </Popover>

                        <Popover
                            key={"section.name"}
                            id={`desktop-menu-${"sectionIdx"}`}
                            className="relative inline-block text-left"
                        >
                            <div>
                                <PopoverButton className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                    <span style={{ marginRight: "0px", backgroundColor: "transparent", border: "2px solid transparent", width: '70px', display: "flex", justifyContent: "right" }}> {translation.avaliacoes.midias}</span>
                                    <ChevronDownIcon
                                        aria-hidden="true"
                                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    />
                                </PopoverButton>
                            </div>

                            <PopoverPanel
                                transition
                                className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <form className="space-y-4">
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.image}
                                            onChange={() => { props.setImage(!props.image) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {translation.avaliacoes.imagens}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.video}
                                            onChange={() => { props.setVideo(!props.video) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {translation.avaliacoes.videos}
                                        </label>
                                    </div>
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.tiktok}
                                            onChange={() => { props.setTiktok(!props.tiktok) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {"Tik Tok"}
                                        </label>
                                    </div>
                                </form>
                            </PopoverPanel>
                        </Popover>
                        {/* {filters.map((section, sectionIdx) => (
                            <Popover
                                key={section.name}
                                id={`desktop-menu-${sectionIdx}`}
                                className="relative inline-block text-left"
                            >
                                <div>
                                    <PopoverButton className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                        <span style={{ marginRight: "0px", backgroundColor: "transparent", border: "2px solid transparent", width: '70px', display: "flex", justifyContent: "right" }}>{section.name}</span>
                                        {sectionIdx === 0 ? (
                                            <span style={{ display: "none" }} className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                                                1
                                            </span>
                                        ) : null}
                                        <ChevronDownIcon
                                            aria-hidden="true"
                                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                        />
                                    </PopoverButton>
                                </div>

                                <PopoverPanel
                                    transition
                                    className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <form className="space-y-4">
                                        {section.options.map((option, optionIdx) => (
                                            <div key={option.value} className="flex items-center">
                                                <input
                                                    defaultValue={option.value}
                                                    id={`filter-${section.id}-${optionIdx}`}
                                                    name={`${section.id}[]`}
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"

                                                />
                                                <label
                                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                                    className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                                >
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </form>
                                </PopoverPanel>
                            </Popover>
                        ))} */}
                    </PopoverGroup>
                </div>

            </div>
        </Container>
    )
}
