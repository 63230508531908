import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 140px;
    //margin: 15px 0px;
    flex-direction: column; 
    position: relative;
    border: 1px solid #f2f2f2;
    border-radius: 4px;

    @media(max-width: 768px) {
        height: max-content;
    }


`;

export const BoxContents = styled.div`
    background-color: ${props => typeof props.background == "undefined" ? "white" : props.background};
    display: flex;
    flex: 1;
    //padding: 10px 10px 10px 10px;
    border-radius: 10px;
    text-align: justify;
    //border-left: 2px solid ${props => props.color};
    align-items: center;
    //flex-direction: column;
    padding: 20px ;
    //background-color: yellow ;
    height: 200px;

    @media(max-width: 768px) {
        flex-direction: column;
        //background-color: chocolate;
    }

`;

export const Img = styled.img`
    max-width: 200px;
    border-radius: 5px;
`;

export const SubTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label{
        margin: 0px 10px;
        font-size: 14px;
    }

    span{
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
`;

export const BoxContact = styled.span`
    //background-color: red;

    label{
        font-size: 14px;
        margin-left: 0px;

        a{
            margin-left: 5px;
            outline: none;
            text-decoration: none;
            color: ${props => props.theme.colors.font.active};
            font-weight: 500;
   
            :hover{
                text-decoration: underline;
            }
        }
    }
`;

export const BoxOptions = styled.div`
    display: flex;
    height: 50px;
    align-items: center;


`;

export const ButtonAnswered = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 90px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.active ? "#19B674" : "#E4FFF4"};
    color: ${props => props.active ? "white" : "#21D487"};
    //cursor: ${props => props.active ? "pointer" : "no-drop"};
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`;

export const ButtonDelete = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 90px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    background-color: #F13F6C;
    color: white;
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`;

export const ButtonHidden = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 90px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.active ? "#0098DA" : "#F1FAFF"};
    color: ${props => props.active ? "white" : "#0098DA"};
    //cursor: ${props => props.active ? "pointer" : "no-drop"};
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }

`;

export const BoxContentsLeft = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

// export const Status = styled.div`
//     background-color: ${props => props.color};
//     position: absolute;
//     right: 0px;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 10px;
//     margin: 5px;
// `;


export const BoxCode = styled.div`
    
    height: 75px;
    display: flex;
    align-items: center;
    //background-color: red ;
    cursor: pointer;
    position: relative ;

    :hover{
        label{
            visibility: visible ;
        }
    }
    

    p{
        height: 40px;
        min-width: 120px;
        border-radius: 5px;
        //border: 1px dashed #138062;
        border: ${props => props.active ? "1px dashed #21D487" : "1px dashed gray"};
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: #ECFAE5; #f0ad4e
        //background-color: ${props => props.active ? "#E4FFF4" : "#f2b8a055"} ; 
        background-color: ${props => props.active ? "#E4FFF4" : "#f1f1f1"} ; 
        color: ${props => props.active ? "#21D487" : "gray"} ;
        padding: 0px 5px;
        text-transform: uppercase;
        margin: 0px ;
    }

    label{
        display: flex;
        position: absolute;
        background: #292929cc;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important;
        width: max-content;
        padding: 10px;
        color: white ;
        font-size: 11px !important;
        margin-top: -120px;
        margin-left: 0px;

        :after{
            content: '';
            position: absolute;
            top: 100%;
            left: 10px;
            border-top: 8px solid #292929cc;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }

    svg{

        position: absolute ;
        top: 8px ;
        left: 10px ;
    }
`;

export const BoxMessage = styled.label`
    
    overflow: hidden ;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: 14px ;
    strong{
        margin-right: 10px;
    }

    @media(max-width: 768px) {
        //overflow: hidden ;
        display: flex;
        flex-wrap: wrap ;
    }
`;

export const BoxDescription = styled.label`
    overflow: hidden ;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: 14px ;
    margin-bottom: -5px ;

    @media(max-width: 768px) {
        //overflow: hidden ;
        display: flex;
        flex-wrap: wrap ;
    }
    
    strong{
        margin-right: 10px;
    }
    
`;

export const BoxIntegration = styled.div`
    background-color: #2C3357;
    display: flex;
    justify-content: center;
    color: white;
    height: 30px;
    align-items: center;
    margin: 5px 0px;
`;


export const BoxInfor = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    flex: 1 ;
    font-size: 14px;
`;


export const Status = styled.div`
    background-color: ${props => props.color};
    position: absolute;
    right: 0px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 5px;
    cursor: help;

    :hover div{
        visibility: visible;
        opacity: 0.9;
        top: 30px;
        z-index: 999;
    }
`;

export const Tooltip = styled.div`

        display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 5px 10px;
        transform: translateX(-100%);
        left: 0;
        margin-top: -70px;
        margin-left: 35px;

        label{
            font-size: 12px;
            color: white;
        }
        :after{
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    
`;

export const BoxActions = styled.div`
    display: flex ;
    //background-color: red ;
    align-items: center ;

    @media(max-width: 768px) {
        margin-top: 20px ;
    }

    span{
        //display: flex ;
        position: relative ;
        //background-color: yellow;


        svg{
            margin-left: 20px ;
            cursor: pointer;
            position: relative ;
            
        }
        :hover{

            label{
                visibility: visible;
            }
        }

        label{
            display: flex;
            position: absolute;
            background: #292929cc;
            visibility: hidden;
            border-radius: 5px;
            //max-width: 300px !important; 
            width: max-content;
            padding: 10px 10px;
            transform: translateX(-100%);
            left: 0;
            margin-top: -75px;
            margin-left: 49px;
            color: white ;
            font-size: 11px !important;

            :after{
                content: '';
                position: absolute;
                top: 100%;
                right: 10px;
                border-top: 8px solid #292929cc;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
    }

    

    img{
        height: 20px ;
    }
`

export const Badget = styled.div`
    background-color: ${props => props.color.background};
    display: flex ;
    position: absolute ;
    right: 10px ;
    margin-top: 10px;
    border-radius: 12.5px ;
    height: 25px;
    align-items: center ;
    justify-content: center;
    padding: 5px 10px;

    label{
        font-size: 11px ;
        margin-left: 5px;
        //background-color: purple ;
        color: ${props => props.color.color} ;
    }
`


export const ButtonCustom = styled.div`
    width: 100px ;
    height: 30px ;
    background-color: ${props => props.color.background} ; //#19B674 ; //
    display: flex ;
    cursor: pointer;
    border-radius: 5px ;
    margin: 0px ;
    position: relative ;
    margin-top: 20px ;
    //border: 2px solid #19B674 ;

    /* :hover{
        background-color: #19B674 ;

        label{
            color: white ;
        }
    } */

    label{
        font-size: 13px ;
        color: ${props => props.color.color} ;
        /* color: #19B674 ; */
        display: flex ;
        width: 100% ;
        //background-color: yellow ;
        margin: 0px ;
        height: 100% ;
        justify-content: center ;
        align-items: center ;
        cursor: pointer;
    }

`


export const OptionCustom = styled.div`
    background-color: ${props => props.active ? "#54D18C" : "#c1c1c1"};
    height: 20px ;
    width: 40px ;
    display: flex ;
    align-items: center ;
    padding: 0px 5px ;
    border-radius: 15px ;
    cursor: pointer;
    flex-direction: ${props => props.active ? "row" : "row-reverse"}  ;
    position: relative ;
    :hover{
        label{
            visibility: visible;
        }
    }

    div{
        width: 12px ;
        height: 12px;
        background-color: white ;
        border-radius: 50% ;
    }

    label{
        display: flex;
        position: absolute;
        background: #292929cc;
        visibility: hidden;
        border-radius: 5px;
        //max-width: 300px !important; 
        width: max-content;
        padding: 10px 10px;
        transform: translateX(-100%);
        left: 0;
        margin-top: -85px;
        margin-left: 40px;
        color: white ;
        font-size: 11px !important;

        :after{
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid #292929cc;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
`