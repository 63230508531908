import { Box, Container, BoxContents, BoxContentsLeft, BoxActions, ButtonCustom, BoxMedias, ItemMedia, IconType, SubTitle, Profile, Avatar, BoxCheck, } from "./styled"
import { AiFillEye, AiFillClockCircle, AiFillEyeInvisible, AiOutlineEdit, AiFillStar } from "react-icons/ai";
import { IoMdTrash } from "react-icons/io";
import { getTranslation } from "../../../../translations";
import { useState } from "react";
import { useEffect } from "react";
import { interval } from "../../../../utils/date";
import { GoLinkExternal } from "react-icons/go";
import { useDispatch } from "react-redux";
import { Back } from "iconsax-react";

const CardDepoimentos = (props) => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const [selectCard, setSelectCard] = useState(false)

    const dispatch = useDispatch()




    const getIconType = () => {
        switch ("google") {
            case "google":
                return (
                    <IconType>
                        <img src={"https://imagepng.org/wp-content/uploads/2019/08/google-icon-1.png"} />
                    </IconType>
                )

            default:
                return null
        }
    }

    useEffect(() => { handleStatusCard(props.checkedAll) }, [props.checkedAll])
    const handleStatusCard = (checked) => {
        // if (checked) {
        //     dispatch({
        //         type: "COLLECT_CONTROL_SELECT_SET",
        //         payload: { id: props.item.id }
        //     })
        // } else {
        //     dispatch({
        //         type: "COLLECT_CONTROL_SELECT_REMOVE",
        //         payload: { id: props.item.id }
        //     })
        // }
        // setSelectCard(checked)
    }

    const updateItem = () => {

        dispatch({
            type: "SAGA_UPDATE_COLLECT",
            payload: {
                data: { active: true },
                ids: [props.item.id]
            }
        })
    }

    return (
        <Container>
            {getIconType()}

            {/* <BoxCheck >
                <input type="checkbox" checked={selectCard} onChange={(e) => {
                    handleStatusCard(e.target.checked)
                }} />
            </BoxCheck> */}

            <Box>
                <BoxContents>
                    <BoxContentsLeft>

                        <Profile>
                            <Avatar>
                                <img src={props?.item?.client_image_url} />
                            </Avatar>
                            <span style={{ display: "flex", flexDirection: "column" }}>
                                <p style={{ fontSize: "16px" }}>{props?.item?.client_name} <AiFillClockCircle size={15} color={"gray"} />
                                    <label style={{ fontSize: "12px", textDecoration: "none", cursor: "default" }}>
                                        {interval(props?.item?.date_review)} {translation.reviews.card.atras}
                                    </label>
                                </p>
                                <label onClick={() => { window.open(props?.item?.href, '_blank'); }} style={{ fontSize: "12px", display: "flex", alignItems: "center" }}>
                                    {translation.depoimentos.verPublicacao}
                                    <GoLinkExternal onClick={() => { window.open(props?.item?.href, '_blank'); }} style={{ marginLeft: "5px", cursor: "pointer" }} size={14} />
                                </label>
                            </span>

                        </Profile>

                        <SubTitle>
                            <span>
                                <AiFillStar size={15} color={"orange"} />
                                <AiFillStar size={15} color={props?.item?.rating > 1 ? "orange" : "gray"} />
                                <AiFillStar size={15} color={props?.item?.rating > 2 ? "orange" : "gray"} />
                                <AiFillStar size={15} color={props?.item?.rating > 3 ? "orange" : "gray"} />
                                <AiFillStar size={15} color={props?.item?.rating > 4 ? "orange" : "gray"} />
                            </span>
                        </SubTitle>

                        <label>
                            {props?.item?.message}
                        </label>
                    </BoxContentsLeft>
                    <BoxActions>
                        <span>
                            <Back size={20} color={"#808080"} onClick={() => { updateItem() }} />
                            <label>{"Deseja restaurar esse depoimento?"}</label>
                        </span>
                    </BoxActions>
                </BoxContents>

            </Box>


        </Container >
    )
}

export default CardDepoimentos
