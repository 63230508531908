import { useState, useEffect } from 'react'
import Modal from 'react-modal';
import Button from '../../../../components/Button/index'
import Alert from '../../../../components/Alert/index'
import imgCartao from "../../../../assets/imgs/iugu/imgcartao.png";
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { CloseIcon, Content, ModalHeader } from './styled'
import { useSelector } from 'react-redux';
import { usePagamento } from '../../hooks/usePagamento';
import { getTranslation } from '../../../../translations';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '31px',
        border: 'none',
        borderRadius: '10px',
        width: '450px',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const IuguModal = () => {
    const { apenasCaptura, handleToggleIugu, modalIuguIsOpen, isLoading, adicionarCartao } = usePagamento();

    const { data: { plans: planos, currentPlan } } = useSelector(state => state.reducerPayments)

    const [btnCartaoDisabled, setBtnCartaoDisabled] = useState(false);
    const [errosPagamento, setErrosPagamento] = useState([]);
    const [planoSelecionadoId, setPlanoSelecionadoId] = useState(currentPlan?.id ?? 0);
    const [planoSelecionado, setPlanoSelecionado] = useState({ name: 'Carregando...' });
    const formIuguRef = useRef();

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        let exists = true;

        if (Array.isArray(planos) && planos.length > 0) {
            let novoPlanoId = currentPlan?.id ?? 1;
            let novoPlano = planos.find((plano) => plano.id == novoPlanoId)

            if (exists) {
                setPlanoSelecionadoId(novoPlanoId);
                setPlanoSelecionado(novoPlano);
            }
        }

        return () => {
            exists = false;
        }
    }, [currentPlan?.id, planos])

    function closeModal() {
        handleToggleIugu(false);
    }

    useEffect(() => {

        let scriptIugu;
        const scriptFormatter = document.createElement("script")
        scriptFormatter.type = "text/javascript"
        scriptFormatter.onload = function () {
            scriptIugu = document.createElement("script")
            scriptIugu.type = "text/javascript"
            scriptIugu.onload = function () {
                window.Iugu.setAccountID("A8DCF31F956F4393B7CD103831EE2632");
                //window.Iugu.setTestMode(true);
            }
            scriptIugu.src = "https://js.iugu.com/v2";
            document.body.appendChild(scriptIugu);
        }
        scriptFormatter.src = "https://cdnjs.cloudflare.com/ajax/libs/formatter.js/0.1.5/formatter.min.js";
        document.body.appendChild(scriptFormatter);

        return () => {
            scriptFormatter?.remove();
            scriptIugu?.remove();
        }
    }, [])

    function handleSubmit(event) {
        event.preventDefault();

        if (isLoading) return false;

        setBtnCartaoDisabled(true);

        let tokenResponseHandler = async function (data) {
            const id = Date.now();
            toast.loading(translation.assinatura.iuguModal.aguarde, { toastId: id });

            if (data.errors) {
                let erros = [];
                if (data.errors.last_name == 'is_invalid') {
                    erros.unshift(translation.assinatura.iuguModal.erroLastname);
                }
                if (data.errors.first_name == 'is_invalid') {
                    erros.unshift(translation.assinatura.iuguModal.erroFirstname);
                }
                if (data.errors.expiration == 'is_invalid') {
                    erros.unshift(translation.assinatura.iuguModal.erroExpiration);
                }
                if (data.errors.verification_value == 'is_invalid') {
                    erros.unshift(translation.assinatura.iuguModal.erroVerificationvalue);
                }
                if (data.errors.number == 'is_invalid') {
                    erros.unshift(translation.assinatura.iuguModal.erroNumber);
                }
                if (data.errors.credit_card == "unauthorized") {
                    erros.unshift(translation.assinatura.iuguModal.erroCreditcard);
                }
                if (data.errors.record_invalid) {
                    erros.unshift(translation.assinatura.iuguModal.erroRecordinvalid);
                }
                toast.update(id, { render: translation.assinatura.iuguModal.ocorreramErros, type: "error", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true });
                setBtnCartaoDisabled(false);
                setErrosPagamento(erros);
            } else {
                await adicionarCartao(data.id, planoSelecionadoId, { apenasCaptura: apenasCaptura, idToast: id });
                setBtnCartaoDisabled(false);
            }
        }

        try {
            window.Iugu.createPaymentToken(formIuguRef.current, tokenResponseHandler);
        } catch (e) {
            closeModal();
            // $('#atualizarDados').modal('hide');
            // $('#erroPagamento').modal('show');
        }
    }

    return (


        <Modal
            isOpen={modalIuguIsOpen}
            onAfterOpen={() => { window.Iugu.setup(); }}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
        >

            <CloseIcon onClick={closeModal}><RiCloseLine /></CloseIcon>
            <ModalHeader>
                <strong>{translation.assinatura.iuguModal.atualizarDados}</strong>
            </ModalHeader>
            <Content>
                <form id="payment-form" ref={formIuguRef} onSubmit={handleSubmit}>
                    <div className="pagamentoIugu text-center">
                        <p id="infoPlanoCartao" style={{ fontSize: '13px', marginBottom: '8px' }}>
                            {translation.assinatura.iuguModal.planoSelecionado} <strong id="txtPlanoSel">{planoSelecionado?.name ?? translation.assinatura.iuguModal.carregando}</strong>
                        </p>

                        <div className="usable-creditcard-form pr-2 pl-2 pt-2">

                            <div className="input-group nmb_a">
                                <div className="icon ccic-brand"></div>
                                <input autoComplete="off" className="credit_card_number" data-iugu="number" placeholder={translation.assinatura.iuguModal.numeroCartao} type="text" defaultValue="" />
                            </div>
                            <div className="input-group nmb_c">
                                <div className="icon ccic-name"></div>
                                <input className="credit_card_name" data-iugu="full_name" placeholder={translation.assinatura.iuguModal.titularCartao} type="text" defaultValue="" />
                            </div>
                            <div className="input-group nmb_d">
                                <div className="icon ccic-exp"></div>
                                <input autoComplete="off" className="credit_card_expiration" data-iugu="expiration" placeholder={translation.assinatura.iuguModal.mmAa} type="text" defaultValue="" />
                            </div>
                            <div className="input-group nmb_b">
                                <div className="icon ccic-cvv"></div>
                                <input autoComplete="off" className="credit_card_cvv" data-iugu="verification_value" placeholder={translation.assinatura.iuguModal.cvv} type="text" defaultValue="" />
                            </div>

                        </div>
                        <div className="footer pt-2" style={{ textAlign: 'center', marginTop: '16px', marginBottom: '8px' }}>
                            <img src={imgCartao} alt="Visa, Master, Diners. Amex" />
                        </div>

                        {
                            errosPagamento.length > 0 ?
                                <Alert background="red">
                                    {errosPagamento.map((erro, index) => (
                                        <p key={index}>{erro}</p>
                                    ))}
                                </Alert>
                                :
                                ''
                        }

                    </div>
                    <div className="text-center pb-3 pl-2 pr-2 pt-3">
                        <Button type="submit" width="100%" marginTop="16px" background="#ffcc00" color="#5e0a07" style={{ pointerEvents: btnCartaoDisabled ? 'none' : 'auto' }}>{translation.assinatura.iuguModal.salvar}</Button>
                    </div>
                </form>
            </Content>
        </Modal>

    );
}

export default IuguModal