import { useEffect } from "react";
import LoadingLogo from "../../../../components/Loading-logo";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getTranslation } from "../../../../translations";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";

const PositionMuralScript = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory()
  const dispatch = useDispatch()
  const statusUpdateConfig = useSelector(state => state.reducerUpdateConfigHomePosition)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
  const reducerScriptIntegration = useSelector(state => state.reducerScriptIntegration)

  useEffect(() => {
    if (urlParams.get("xpath") && urlParams.get("location")) {
      dispatch({
        type: "SAGA_SCRIPT_INTEGRATION", payload: {
          xpath: urlParams.get("xpath"),
          location: urlParams.get("location")
        }
      })
    } else {
      toast.error("problemas")
    }
  }, [])



  useEffect(() => {
    if (reducerScriptIntegration.loading == false && reducerScriptIntegration.error == false && reducerScriptIntegration.data.length == 0) {
    } else {
      if (reducerScriptIntegration.loading == false) {
        if (reducerScriptIntegration.error) {
          toast.error("Error")
          history.push("/personalizar/tema")
        }
        else {
          toast.success("Posicionado")
          history.push("/personalizar/tema")
        }
      }

    }
  }, [reducerScriptIntegration])


  return (
    <div style={{ display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <LoadingLogoLily />
    </div>
  )
}

export default PositionMuralScript