import React from 'react'
import LayoutNew from '../../../../components/tailwind/layout-new'
import TableQuestions from '../../../questions/components/tailwind/table'
import { useParams } from 'react-router-dom';
import Navbar from '../../components/tailwind/navbar';

const Questions = () => {

    const { id } = useParams();

    return (
        <LayoutNew>
            {/* <TableReviews /> */}
            <TableQuestions productId={id} navbar={<Navbar />} />
        </LayoutNew>
    )
}

export default Questions