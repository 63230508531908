import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    //background-color: purple;
    margin-top: 40px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
`
export const Content = styled.label`
    display: flex;
    //background-color: green;
    max-width: max-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //gap: 8px;
` 

export const Title = styled.label`
    display: flex;
    //background-color: red;
    max-width: max-content;
    font-size: 14px;
    color: #111827;
    margin-top: 8px;
    font-weight: 600;
` 

export const Infor = styled.label`
    display: flex;
    //background-color: yellow;
    max-width: max-content;
    font-size: 14px;
    color: #6B7280;
    margin-bottom: 8px;
    font-weight: 400;
` 