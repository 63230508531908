import React from 'react'
import { BarDivider, Container } from './styled'
import { IoIosHelpCircleOutline } from "react-icons/io";
import { GoLinkExternal } from "react-icons/go";
import { useSelector } from 'react-redux';
import { getTranslation } from '../../translations';


const HelpClick = (props) => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Container>
            <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                <IoIosHelpCircleOutline size={24} color='#292D32' />
                <label style={{ color: "#101828" }} >{translation.alert.duvidas}</label>
            </div>
            <BarDivider />
            <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                <label style={{ color: "#006DA3" }}>{props.text}</label>
                <GoLinkExternal size={20} color='#292D32' />
            </div>


        </Container>
    )
}

export default HelpClick