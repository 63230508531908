import styled from "styled-components";

export const BarFilters = styled.div`
    display: flex;
    background-color: #fff;
    margin-top: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    padding: 20px 20px;
    gap: 20px;
    flex-direction: column;
    border-radius: 4px;
    //padding-bottom: 24px;

    //background-color: red;
    
`

export const BarFiltersLeft = styled.div`
    display: flex;
    //background-color: red;
    align-items: center;
    width: 100%;
`

export const BarFiltersIcon = styled.div`
    display: flex;
    gap: 20px;

    //background-color: greenyellow;

    span{
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        cursor: pointer;
    }
`

export const Search = styled.div`
    display: flex;
    //ackground-color: yellow;
    align-items: center;
    width: 100%;

    span{
        background-color: #f9fafb;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px 0px 0px 4px;
    }

    input{
        display: flex;
        background-color: #f9fafb;
        height: 40px;
        outline: none;
        border: none;
        width: 100%;
        border-radius: 0px 4px 4px 0px;
        font-size: 14px;
        ::placeholder{
            font-size: 14px;
        }
    }
`

export const BarFiltersIconOrder = styled.div`
    display: flex;
    //background-color: yellow;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    position: relative;

    label{
        font-size: 14px;
        color: #374151;
        cursor: pointer;
    }

    span{
        display: ${props => props.visible ? "flex": "none"};
        background-color: #fff;
        position: absolute;
        flex-direction: column;
        width: max-content;
        right: 0px;
        top: 36px;
        height: max-content;
        z-index: 9999999;
        box-shadow: 0 0 2em #DCDBDA;
        //border: 1px solid #c1c1c122;

        label{
            height: 36px;
            display: flex;
            align-items: center;
            padding: 0px 20px;
            justify-content: flex-start;
            width: 100%;
            font-size: 14px;

            :hover{
                background-color: #F9FAFB;
            }
        }
    }
`

export const BarFiltersContents = styled.div`
    display: ${props => props.visible ? "flex": "none"};
    background-color: #80808088;
    height: 300px;

`