import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 120px ;
    margin: 0px 0px;
    position: relative;
    //background-color: green ;
    flex-direction: row ;
    border-radius: 4px ;


    :nth-last-child(2){
        //border-bottom: 1px solid #C1C1C1;
    }

    @media(max-width: 768px) {
        height: max-content ;
        //padding: 10px ;
    }

`;

export const BoxContents = styled.div`
    //background-color: red;
    display: flex;
    flex: 1;
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
    text-align: justify;
    //border-left: 2px solid ${props => props.color};
   

    @media(max-width: 480px) {
        flex-direction: column-reverse;
        //background-color: chocolate;

        div:nth-child(2){
            
            display: flex;
            justify-content: center;
        }
    }

    p{
        font-size: 18px;
        font-weight: 500; 

        a{
            outline: none;
            text-decoration: none;
            color: ${props => props.theme.colors.font.active};

            :hover{
                text-decoration: underline;
            }
        }
    }

    label{
        font-size: 14px;
        display: flex;
        flex: 1;
    }
`;

export const Img = styled.img`
    max-width: 200px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;

    @media(max-width: 380px) {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const BoxImgVideo = styled.div`
   
    
`;

export const SubTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label{
        margin: 0px 10px;
        font-size: 14px;
        display: flex;
    }

    span{
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
`;

export const BoxContact = styled.span`
    //background-color: red;

    label{
        font-size: 14px;

        a{
            margin-left: 5px;
            outline: none;
            text-decoration: none;
            color: ${props => props.theme.colors.font.active};
            font-weight: 500;
   
            :hover{
                text-decoration: underline;
            }
        }
    }
`;

export const BoxOptions = styled.div`
    display: flex;
    height: 50px;
    align-items: center;


`;

export const ButtonApproved = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 100px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.active ? "#19B674" : "#E4FFF4"};
    color: ${props => props.active ? "white" : "#21D487"};
    cursor: ${props => props.active ? "pointer" : "no-drop"};

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`;

export const ButtonAction = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 100px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.background};
    color: ${props => props.color};
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`;

export const ButtonDelete = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 90px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    background-color: #F13F6C;
    color: white;
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`;

export const ButtonHidden = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 100px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.active ? "#0098DA" : "#F1FAFF"};
    color: ${props => props.active ? "white" : "#0098DA"};
    cursor: ${props => props.active ? "pointer" : "no-drop"};

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }

`;

export const BoxContentsLeft = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const Status = styled.div`
    background-color: ${props => props.color};
    position: absolute;
    right: 0px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 5px;
    cursor: help;

    :hover div{
        visibility: visible;
        opacity: 0.9;
        top: 30px;
        z-index: 999;
    }
`;


export const Tooltip = styled.div`

        display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 5px 10px;
        transform: translateX(-100%);
        left: 0;
        margin-top: -90px;
        margin-left: 35px;

        label{
            font-size: 12px;
            color: white;
        }
  

        :after{
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    
`;

export const Box = styled.div`
    //background-color: violet ;
    display: flex ;
    flex: 1 ;
    flex-direction: column ;

`;

export const BoxCheck = styled.div`
    //background-color: purple ;
    width: 30px;
    display: flex ;
    justify-content: center;
    padding-top: 5px ;
    //background-color: red ;
    //border-top: 1px solid #C1C1C1 ;
    //height: 130px ;
    align-items: flex-start ;


`;

export const Section = styled.div`
    background-color: white ;
    display: flex ;
    flex: 1;
    //height: 130px ;
    padding: 0px 20px ;
    //border-top: 1px solid #C1C1C1;
    align-items: center ;
    border-radius: 10px ;
    border: 1px solid #f2f2f2;
    //background-color: purple ;
    
    @media(max-width: 768px) {
        flex-direction:  column ;
        padding: 0px ;
    }

`

export const NameMobile = styled.label`
    display: none ;

    @media(max-width: 768px) {
        display: flex ;
    }
`

export const SectionLeft = styled.div`
    //background-color: red ;
    display: flex ;
    flex: 1 ;

    @media(max-width: 768px) {
        flex-direction:  column ;
        //background-color: red ;
        width: 100% ;
    }
    

    span{
        display: flex ;
        flex-direction: column ;
        

        label{
            font-size: 14px ;
            font-weight: 600 ;
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 1; /** número de linhas que você quer exibir */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @media(max-width: 768px) {
                display: none ;
            }

            :hover{
                text-decoration: underline ;
            }
        }

        div{
            display: flex ;
            margin: 10px 0px;

            @media(max-width: 768px) {
                flex-direction:  column ;
                justify-content: left ;
                //background-color: yellow ;
                gap: 10px;
            }
    

            label{
                display: flex;
                //background-color: purple ;
                margin-right: 30px ;
                font-size: 12px ;
                align-items: center ;
                font-weight: normal ;
                cursor: pointer;

                :hover{
                    text-decoration: underline ;
                }

                svg{
                    margin: 0px 5px ;
                }
            }
        }

        label:nth-child(3){
            font-size: 12px;
            opacity: 0.6 ;
            font-weight: normal ;
            text-decoration: none ;
            cursor: default;
        }
    }


    img{
        width: 60px ;
        height: 60px ;
        object-fit: cover;
        margin-right: 20px ;
        border-radius: 10px ;
        cursor: pointer;
    }


`

export const BoxSvg = styled.div`
    svg{
        width: 50px ;
        height: 50px ;
        margin-right: 25px ;
        margin-left: 5px ;
    }
`

export const SectionOptions = styled.div`
    display: flex ;
    //background-color: red ;

    svg{
        margin-left: 20px ;
        cursor: pointer;
    }

    img{
        height: 20px ;
    }
`

export const BoxActions = styled.div`
    display: flex ;
    //background-color: red ;
    align-items: center ;

    span{
        //display: flex ;
        position: relative ;
        //background-color: yellow;


        svg{
            margin-left: 20px ;
            cursor: pointer;
            position: relative ;
            
        }
        :hover{

            label{
                visibility: visible;
            }
        }

        label{
            display: flex;
            position: absolute;
            background: #292929cc;
            visibility: hidden;
            border-radius: 5px;
            //max-width: 300px !important; 
            width: max-content;
            padding: 10px 10px;
            transform: translateX(-100%);
            left: 0;
            margin-top: -75px;
            margin-left: 49px;
            color: white ;
            font-size: 11px !important;

            :after{
                content: '';
                position: absolute;
                top: 100%;
                right: 10px;
                border-top: 8px solid #292929cc;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
    }

    

    img{
        height: 20px ;
    }
`

export const ImgTooltip = styled.div`
    //background-color: red ;
    position: relative ;

    img{
        cursor: pointer;
    }

    :hover{

        label{
            visibility: visible ;
        }
    }

    label{
        display: flex;
        position: absolute;
        background: #292929cc;
        visibility: hidden;
        border-radius: 5px;
        //max-width: 300px !important; 
        width: max-content;
        padding: 10px 10px;
        transform: translateX(-100%);
        left: 0;
        top: 0 ;
        margin-top: -50px;
        margin-left: 30px;
        color: white ;
        font-size: 11px !important;

        :after{
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid #292929cc;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
`

export const ImportReviews = styled.div`
    display: flex;
    //background-color: green;
    position: relative;
    

    span{
        display: flex;
        //background-color: red;
        flex-direction: row;
        gap: 5px;
        cursor: pointer;

        label{
            cursor: pointer;
            text-decoration: none !important;
        }
    }
`

export const ImportReviewsMenu = styled.div`
    display: ${props => props.visible ? "flex !important" : "none !important"};
    background-color: white;
    box-shadow: 0 0 2em #DCDBDA;
    position: absolute;
    top: 15px;
    //right: 0px;
    //flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    z-index: 9999999;
    width: 250px;
    //height: 200px;
    //background-color: red;
    flex-wrap: wrap;
    justify-content: center;   
    cursor: default;

    p{
        font-size: 16px !important;
        display: flex;
        //background-color: red;
        margin: 0;
        padding: 0;
    }
`

export const ImportReviewsMenuBox = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    cursor: default;


    span{
        display: flex;
        background-color: #f1f1f1;
        flex-direction: column;
        width: 100px;
        height: 100px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer !important;
        

        :hover{
            opacity: 0.7;
            scale: 1.1;
        }

        label{
            cursor: pointer;
            display: flex;
            //background-color: purple;
            width: 100%;
            text-align: center;
            justify-content: center;
            font-size: 18px !important;
        }

        img{
            /* width: 40px;
            height: 40px; */

            border-radius: 0px !important;
            //background-color: yellow;
        }

    }
`

export const NewMenuImportReviews = styled.div`
    display: flex;
    width: 300px;
    height: 400px;
    background-color: red;
    position: absolute;
    display: none;
    visibility: ${props => props.visible ? "visible" : "hidden"};
    left: 0;
    top: 0;
`

export const BoxImport = styled.div`
    display: flex;

`


export const BoxImportItem = styled.div`
    display: flex;

`