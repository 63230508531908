import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.background} ;
    display: flex ;
    //margin: 5px ;
    width: 300px;
    height: 380px ;
    //justify-content: center ;
    flex-direction: column ;
    padding: 15px;
    margin-bottom: 5px ;
    cursor: pointer;
    border: ${props => props.sizeBorder +" solid " + props.colorBorder}; 
    border-radius: ${props => props.radiusBorder} ;

    :hover{
        border: ${props => "1px solid " + props.colorBorder};
        cursor: pointer;
    }

    img{
        /* width: 260px;
        height: 200px; */
        /* border-radius: 5px ; */
        /* object-fit: cover ;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer; */

        //object-fit: cover ;
        width: 100% ;
        height: 100% ;
        max-width: 270px ;
        min-width: 270px ;
        max-height: 200px ;
        border-radius: 10px !important;
        border-radius: 50%;
        background-color: white ;
        padding: 10px ;
    }
`

export const BoxStar = styled.div`
    //background-color: green ;
    //height: 40px ;
    display: flex ;
    align-items: center ;
    justify-content: ${props => props.position == "default" ? "center" : props.position};
    margin-top: 10px ;

    

    span{
        //margin-top: 2px ;
        display: flex ;
       // background-color: red;
    }


`

export const Name = styled.label`
    //background-color: blue ;
    display: flex;
    font-size: 14px;
    font-weight: bold ;
    margin-top: 0px;
    height: 40px ;
    align-items: center ;
    color: ${props => props.fontColor};
    align-items: center ;

    label{
        font-size: 9px ;
        font-weight: initial ;
        //background-color: red ;
        margin-top: 3px ;
        margin-left: 5px;
        
    }
`

export const Text = styled.label`
    //background-color: blueviolet ;
    display: flex;
    flex: 1;
    margin-top: 0px ;
    font-size: 12px;
    color: ${props => props.fontColor};
    text-align: justify ;
    cursor: pointer;
`


export const BadgeCheck = styled.div`
  position: relative;  
  display: flex ;
    //background-color: yellow ;

    svg{
      cursor: initial ;
    }

    :hover{

      label{
        visibility: visible;
        opacity: 0.9;
        //top: 30px;
        z-index: 999;
      }
       
    }

    label{
        display: flex;
        position: absolute;
        background: black;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        bottom: 30px;
        left: 0px;
        visibility: hidden;
        /* display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        //transform: translateX(-100%);
        bottom: 0;
        margin-bottom: 30px;
        margin-left: -5px;

        
        font-size: 10px;
        color: white; */
        
        font-size: 10px;
        color: white;
        font-family: ${props => props.theme.font} !important;

        :after{
       
            content: '';
            position: absolute;
            top: 100%;
            left: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        
        } 
    }
`