import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemTable from '../item-table'
import Pagination from "../pagination";
import { getTranslation } from '../../../../../translations';
import LoadingLogoLilyNew from '../../../../../components/Loading-logo-lily-new';
import { ContainerLoading } from '../../../../reviews/pages/home-new/styled';
import { BarFilters, BarFiltersIcon, BarFiltersLeft, Search } from '../../../../reviews/components/tailwind/table/styled';
import { ArrowDownTrayIcon, CodeBracketIcon, MagnifyingGlassIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableCollect(props) {
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedCollects, setSelectedCollects] = useState([])
    const dispatch = useDispatch()

    const [dataCollects, setDataCollects] = useState([])
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    //const collects = useSelector(state => state.reducercollects)
    const collects = useSelector(state => state.reducerGetCollect)

    useEffect(() => {
        //console.log("collects", collects?.data?.data)
        collects?.data?.data?.length > 0 && setDataCollects(collects.data.data)
        collects?.data?.data == undefined && setDataCollects([])
    }, [collects])

    useLayoutEffect(() => {
        const isIndeterminate = selectedCollects.length > 0 && selectedCollects.length < dataCollects.length
        setChecked(dataCollects.length == 0 ? false : selectedCollects.length === dataCollects.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedCollects])



    function toggleAll() {
        setSelectedCollects(checked || indeterminate ? [] : dataCollects)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    const deleteSelected = () => {
        setTimeout(() => { handleClick() }, 100);
        const dataIds = selectedCollects.map((item) => (item.id))
        props.deleteCollect(dataIds)
    }

    const handleClick = () => {
        if (checkbox.current) {
            checkbox.current.click();
        }
    };

    return (
        collects.loading ?
            <ContainerLoading>
                <LoadingLogoLilyNew />
            </ContainerLoading> :
            <>

                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{translation.sidebar.depoimentos}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.depoimentos}
                            </p>
                        </div>

                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                                onClick={() => { props.setVisibleModalCollectGoogle(true) }}
                                type="button"
                                className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <ArrowDownTrayIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                Importar google
                            </button>
                        </div>

                    </div>




                    <BarFilters style={{ paddingRight: "0px" }}>
                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                            <BarFiltersLeft style={{ marginRight: "20px" }}>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && props.goSearch() }} type='text' placeholder={translation.depoimentos.pesquisarDepoimento} value={props.search} onChange={(e) => { props.setSearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>
                        </span>
                    </BarFilters>

                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {selectedCollects.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            <button
                                                onClick={() => { deleteSelected() }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {translation.avaliacoes.excluir}
                                            </button>
                                        </div>
                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={toggleAll}
                                                    />
                                                </th>
                                                <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.data}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.depoimentos.cliente}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.depoimentos.depoimento}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {translation.avaliacoes.Estrelas}
                                                    </label>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Plataforma
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {dataCollects?.map((item) => (
                                                <ItemTable
                                                    visibleModalImport={props.visibleModalImport}
                                                    setVisibleModalImport={props.setVisibleModalImport}
                                                    setImportShopeecollectselected={props.setImportShopeecollectselected}
                                                    setControlUpdate={props.setControlUpdate}
                                                    goSearch={props.goSearch}
                                                    classNames={classNames}
                                                    setSelectedCollects={setSelectedCollects}
                                                    selectedCollects={selectedCollects}
                                                    deleteCollect={props.deleteCollect}
                                                    item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination setPage={(page) => props.setPage(page)} />
            </>
    )
}
