
import { useDispatch, useSelector } from 'react-redux';
import { BoxContent, BoxImage, Container, Body, Item, Actions, Close, Title, ContainerPreview, ContainerColumn, ContainerTitle, BoxAlerts, BoxSwiper, BoxSlider, BodyNew, ContainerNew, TitleNew } from "./styled";
import { GrFormClose } from "react-icons/gr";
import imgBackground from "../../../../assets/imgs/preview-background.png";

import { useEffect, useRef, useState } from 'react';
import BoxConfigEmail from '../box-config-email';
import IconCam from "../../../../assets/imgs/icon-camera.svg";
import AlertCloseBoss from '../../../../components/alert-close-boss';
import { getTranslation } from '../../../../translations';
import { reviews } from "../../pages/home/dataPreview";

import CardDropi from "../card-review-dropi-preview";
import CardLily from "../card-review-lily-preview";
import CardTiktok from '../card-review-tiktok-preview';
import Card2Lily from '../card2-review-lily-preview';
import Card3Lily from '../card3-review-lily-preview';
import Card2Dropi from '../card2-review-dropi-preview';
import Card3Dropi from '../card3-review-dropi-preview';
import { BoxMessage } from '../../../questions/pages/home/styled';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation, Scrollbar, A11y, EffectCards, EffectFade, EffectCube, EffectCoverflow, EffectFlip, EffectCreative } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./styles.css";
import Card1Home from '../card1-home';

const PreviewHomeCarrossel = (props) => {
  const dispatch = useDispatch()
  const [optionSelected, setOptionSelected] = useState("reviews")
  const [icon, setIcon] = useState({ size: 20, color: "orange" })

  const configControl = useSelector(state => state.reducerControlConfig)
  const reviewsFavorites = useSelector(state => state.reducerReviews)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
  const history = useHistory();







  const getCard = (card, item) => {
    switch (card) {
      case "lily3":
        return <Card3Lily item={item} />
      case "lily2":
        return <Card2Lily item={item} />
      case "lily":
        return <CardLily item={item} />
      case "dropi":
        return <CardDropi item={item} />
      case "dropi2":
        return <Card2Dropi item={item} />
      case "dropi3":
        return <Card3Dropi item={item} />
      case "tiktok":
        return <CardTiktok item={item} />
      case "home1":
        return <Card1Home item={item} />
      default:
        break;
    }
  }



  // useEffect(() => {
  //   dispatch({
  //     type: "SAGA_LIST_REVIEWS", payload: {
  //       rating: "",
  //       status: "all",
  //       search: "",
  //       perPage: 10,
  //       page: 1,
  //       medias: "",
  //       favorite: true
  //     }
  //   })
  // }, [])

  // const selectedMenu = useLocation();

  // const visibleFormHome = () => {
  //   if (selectedMenu.pathname == "/personalizar/home") {
  //     if (
  //       JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "" &&
  //       JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != null &&
  //       typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "undefined"
  //       //&&        reviewsFavorites?.data?.meta?.total > 7
  //     ) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   } else {
  //     return true
  //   }

  // }

  useEffect(() => {
    //document.getElementsByClassName('swiper').style.backgroundColor = "red";
    var elements = document.getElementsByClassName('swiper'); // get all elements

    for (var i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor = configControl.homereviews_background_color;
      //elements[i].style.backgroundColor = "yellow";
      elements[i].style.height = "250px";
    }

    var elements2 = document.getElementsByClassName('swiper-slide '); // get all elements
    for (var i = 0; i < elements2.length; i++) {
      elements2[i].style.backgroundColor = configControl.homereviews_background_color;
    }

    var elements3 = document.getElementsByClassName('swiper-button-next'); // get all elements
    for (var i = 0; i < elements3.length; i++) {
      elements3[i].style.color = configControl.homereviews_arrow_color;
    }

    var elements4 = document.getElementsByClassName('swiper-button-prev'); // get all elements
    for (var i = 0; i < elements4.length; i++) {
      elements4[i].style.color = configControl.homereviews_arrow_color;
    }

    setTimeout(() => {
      var elements7 = document.getElementsByClassName('swiper-pagination-horizontal'); // get all elements
      for (var i = 0; i < elements7.length; i++) {
        elements7[i].style.color = configControl.homereviews_pagination_color
      }
    }, 100);




  }, [configControl])

  const swiperOneRef = useRef(null);

  useEffect(() => {
    if (configControl.homereviews_animation == 1) {
      swiperOneRef?.current?.swiper?.autoplay?.start()
      //setautoplaySwiper(configControl.homereviews_animation == 1 || configControl.homereviews_animation == "1" ? true : false)
    } else {
      swiperOneRef?.current?.swiper?.autoplay?.stop()
    }

    if (configControl.homereviews_navigation == 1) {
      swiperOneRef?.current?.swiper?.navigation?.enable()
      //setautoplaySwiper(configControl.homereviews_animation == 1 || configControl.homereviews_animation == "1" ? true : false)
    } else {
      swiperOneRef?.current?.swiper?.navigation?.disable()
    }

    if (configControl.homereviews_pagination == 1) {
      swiperOneRef?.current?.swiper?.pagination?.enable()
      //setautoplaySwiper(configControl.homereviews_animation == 1 || configControl.homereviews_animation == "1" ? true : false)
    } else {
      swiperOneRef?.current?.swiper?.pagination?.disable()
    }

  }, [configControl])

  useEffect(() => {
    document.documentElement.style.setProperty('--swiper-navigation-size', '20px');
  },)

  return (
    <ContainerNew img={imgBackground}>
      <TitleNew
        backgroundColor={configControl.homereviews_background_color}
        fontsize={configControl.homereviews_font_size_title}
        color={configControl.homereviews_color_title}
        font={configControl.homereviews_font_title}
        align={configControl.homereviews_align_title}
      >
        <label>
          {configControl?.homereviews_title == null || configControl?.homereviews_title == "null" ? "" : configControl?.homereviews_title}
        </label>
      </TitleNew>

      {configControl.homereviews_loop == 1 ?
        <Swiper
          ref={swiperOneRef}
          id='swiper-comum'
          modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
          slidesPerView={1}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
          loop={true}
          navigation
          pagination={{ clickable: true, type: 'fraction', }}
          speed={2000}
          effect={"slide"}
        >

          <SwiperSlide>
            <Card1Home item={reviews[0]} />
          </SwiperSlide>
          <SwiperSlide>
            <Card1Home item={reviews[1]} />
          </SwiperSlide>

        </Swiper> :
        <Swiper
          ref={swiperOneRef}
          id='swiper-comum'
          modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
          slidesPerView={1}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
          loop={false}
          speed={2000}
          navigation
          pagination={{ clickable: true, type: 'fraction', }}
          effect={"slide"}
        >

          <SwiperSlide>
            <Card1Home item={reviews[0]} />
          </SwiperSlide>
          <SwiperSlide>
            <Card1Home item={reviews[1]} />
          </SwiperSlide>

        </Swiper>
        }

    </ContainerNew >



  )
}

export default PreviewHomeCarrossel