import { useSelector } from "react-redux";
import LoadingLogoLilyNew from "../../../../components/Loading-logo-lily-new";
import Layout from "../../../../components/tailwind/layout";
import Pagination from "../../components/tailwind/pagination";
import TableReviews from "../../components/tailwind/table";
import { ContainerLoading } from "./styled";
import { useEffect } from "react";
import ModalEdit from "../../components/tailwind/modal-edit";
import { useState } from "react";
import ModalInforVideo from "../../../../components/modal-infor-video";
import HelpClick from "../../../../components/HelpClick";
import { getTranslation } from "../../../../translations";
import LayoutNew from "../../../../components/tailwind/layout-new";
import PageIntegration from "../../../../components/page-clean";


export default function HomeNew() {
  const questions = useSelector(state => state.reducerQuestions)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  const [visibleModalEdit, setvisibleModalEdit] = useState(false)
  const [visibleModalInforQuestions, setVisibleModalInforQuestions] = useState(false)


  return (
    <LayoutNew>
      <ModalInforVideo visible={visibleModalInforQuestions} setVisibleModal={setVisibleModalInforQuestions}
        video={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/NZkhbdkyY3U" : "https://www.youtube.com/embed/v-1sw3H06fo"}
      />

      {/* <ModalEdit visible={visibleModalEdit} setVisible={setvisibleModalEdit} /> */}
      {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">{translation.sidebar.perguntas}</h1>
              <p className="mt-2 text-sm text-gray-700">
                {translation.title.perguntas}
              </p>
            </div>
          </div>
          <PageIntegration />
        </div> :
        <>
          <TableReviews setVisibleModalEdit={setvisibleModalEdit} />
          {!questions.loading &&
            <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
              <div onClick={() => { setVisibleModalInforQuestions(true) }}>
                <HelpClick text={translation.alert.perguntas} />
              </div>
            </div>}
        </>
      }
    </LayoutNew>
  )
}
