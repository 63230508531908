import React from 'react'
import { Container, ItemData } from './styled'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

const FiltersOrders = (props) => {
    return (
        <Container>
            <ItemData>
                <input id="date" type="date" value={props.dateInicioFilter} onChange={(e) => {
                    props.setDateInicioFilter(e.target.value)
                }} />
            </ItemData>

            <label>a</label>

            <ItemData>
                <input id="dateFim" type="date" value={props.dateFimFilter} onChange={(e) => {
                    props.setDateFimFilter(e.target.value)
                }} />
            </ItemData>

            <button
                onClick={() => { props.goSearch() }}
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-green-50 px-3.5 py-2.5 text-sm font-semibold text-green-700 hover:bg-green-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                <MagnifyingGlassIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                Buscar
            </button>
        </Container>
    )
}

export default FiltersOrders