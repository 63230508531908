import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    //max-height: 200px;
    //height: 200px ;
    margin: 0px 0px;
    flex-direction: column;
    position: relative;
    
    //background-color: yellow ;
    flex-direction: row;
    border: 1px solid #80808022;
    border-radius: 4px;
    
`;
export const Box = styled.div`
    //background-color: violet ;
    display: flex ;
    flex: 1 ;
    flex-direction: column ;

`;

export const BoxCheck = styled.div`
    //background-color: purple ;
    width: 30px;
    display: flex ;
    justify-content: center;
    padding-top: 3px ;
    //background-color: red ;
    align-items: flex-start ;
`;

export const BoxContents = styled.div`
    background-color: white;
    display: flex;
    flex: 1;
    padding: 20px;
    border-radius: 0px 5px 5px 0px;
    text-align: justify;
    //border-left: 2px solid ${props => props.color};
    //background-color: red ;
    border-radius: 10px ;

    @media(max-width: 480px) {
        flex-direction: column-reverse;
        //background-color: chocolate;

        div:nth-child(2){
            
            display: flex;
            justify-content: center;
            margin: 20px 0px ;
        }
    }

    p{
        font-size: 14px;
        font-weight: 500; 
        padding: 0px ;
        margin: 0px ;

        a{
            outline: none;
            text-decoration: none;
            color: ${props => props.theme.colors.font.active};

            :hover{
                text-decoration: underline;
            }
        }
    }

    label{
        font-size: 14px;
        display: flex;
        flex: 1;
    }
`;

export const Img = styled.img`
    //max-width: 200px;
    border-radius: 5px;
    //margin-right: 20px;
    width: 50px ;
    height: 50px ;
    object-fit: cover ;
    border: 1px solid #f1f1f1 ;
    cursor: pointer;

    @media(max-width: 380px) {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const BoxImgVideo = styled.div`
    //background-color: yellow ;
    position: relative ;

    span{
        background-color: #00000011 ;
        width: 50px ;
        height: 50px ;
        display: flex ;
        align-items: center ;
        justify-content: center;
        position: absolute ;
        top: 0 ;

        svg{
            
        }
    }
    
    
`;

export const SubTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label{
        margin: 0px 10px;
        font-size: 12px;
        display: flex;
    }

    span{
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
`;

export const BoxContact = styled.span`
    //background-color: red;

    label{
        font-size: 14px;

        a{
            margin-left: 5px;
            outline: none;
            text-decoration: none;
            color: ${props => props.theme.colors.font.active};
            font-weight: 500;
   
            :hover{
                text-decoration: underline;
            }
        }
    }
`;

export const BoxOptions = styled.div`
    display: flex;
    height: 50px;
    align-items: center;


`;

export const ButtonApproved = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 100px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.active ? "#19B674" : "#E4FFF4"};
    color: ${props => props.active ? "white" : "#21D487"};
    cursor: ${props => props.active ? "pointer" : "no-drop"};

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`;

export const ButtonDelete = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 90px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    background-color: #F13F6C;
    color: white;
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`;

export const ButtonHidden = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 100px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.active ? "#0098DA" : "#F1FAFF"};
    color: ${props => props.active ? "white" : "#0098DA"};
    cursor: ${props => props.active ? "pointer" : "no-drop"};

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }

`;

export const BoxContentsLeft = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    //background-color: yellow ;

    label{
        font-size: 12px ;  
    }
`;

export const Status = styled.div`
    background-color: ${props => props.color};
    position: absolute;
    right: 0px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 5px;
    cursor: help;

    :hover div{
        visibility: visible;
        opacity: 0.9;
        top: 30px;
        z-index: 999;
    }
`;


export const Tooltip = styled.div`

        display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 5px 10px;
        transform: translateX(-100%);
        left: 0;
        margin-top: -90px;
        margin-left: 35px;

        label{
            font-size: 12px;
            color: white;
        }
  

        :after{
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    
`;

export const BoxActions = styled.div`
    display: flex ;
    //background-color: red ;
    align-items: center ;

    span{
        //display: flex ;
        position: relative ;
        //background-color: yellow;


        svg{
            margin-left: 20px ;
            cursor: pointer;
            position: relative ;
            
        }
        :hover{

            label{
                visibility: visible;
            }
        }

        label{
            display: flex;
            position: absolute;
            background: #292929cc;
            visibility: hidden;
            border-radius: 5px;
            //max-width: 300px !important; 
            width: max-content;
            padding: 10px 10px;
            transform: translateX(-100%);
            left: 0;
            margin-top: -75px;
            margin-left: 49px;
            color: white ;
            font-size: 11px !important;

            :after{
                content: '';
                position: absolute;
                top: 100%;
                right: 10px;
                border-top: 8px solid #292929cc;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
    }

`

export const BoxMedias = styled.div`
    //background-color: red;
    display: flex ;
    flex-wrap: wrap ;
    gap: 15px;

    svg {
        border: 1px solid #f1f1f1 ;
        height: 50px ;
        width:  50px;
        padding: 12px ;
        border-radius: 5px ;
        cursor: pointer;
    }
`

export const ButtonCustom = styled.div`
    width: 100px ;
    height: 30px ;
    background-color: #54D18C ; // 
    display: flex ;
    cursor: pointer;
    border-radius: 5px ;
    margin: 0px ;
    position: relative ;
    margin-top: 20px ;

    label{
        font-size: 13px ;
        color: white ;
        display: flex ;
        width: 100% ;
        //background-color: yellow ;
        margin: 0px ;
        height: 100% ;
        justify-content: center ;
        align-items: center ;
        cursor: pointer;
    }

`

export const BoxBadget = styled.span`
    display: flex ;
    //background-color: red ;
    position: absolute ;
    right: 10px ;
    margin-top: 10px;
    gap: 10px;
`

export const Badget = styled.div`
    background-color: ${props => props.color.background};
    display: flex ;
    
    border-radius: 12.5px ;
    height: 25px;
    align-items: center ;
    justify-content: center;
    padding: 5px 10px;

    label{
        font-size: 11px ;
        margin-left: 5px;
        //background-color: purple ;
        color: ${props => props.color.color} ;
    }
`

export const Badget2 = styled.div`
    background-color: #E4FFF4;
    display: flex ;
    position: absolute ;
    left: 90px;
    margin-top: -3px;
    border-radius: 12.5px ;
    height: 25px;
    align-items: center ;
    justify-content: center;
    padding: 5px 10px;
    //width: 100px ;


    label{
        font-size: 11px ;
        margin-left: 5px;
        //background-color: purple ;
        color: #21D487 ;
        margin-right: -1px ;
    }

    svg{
        //background-color: violet ;
    }
`