
const INITIAL_STATE = {screen: "options"}
//const INITIAL_STATE = {screen: "shopee-save"}


const ControlScreenActive = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUCTS_CONTROL_SCREEN_ACTIVE':
            return state = action.payload
        default:
            return state;
    }
}

export default ControlScreenActive