import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    //background-color:  yellow;
`;

export const ContainerLoading = styled.div`
    display: flex;
    min-height: calc(100vh - 70px);
    flex-direction: column;
    //background-color:  red;
    align-items: center;
    justify-content: center;
    margin-top: -70px;
    
`;

export const ActionsDropdown = styled.div`
    display: flex;
    width: ${props => props.perPage == true ? "190px" : "120px"};
    height: 35px;
    //background-color:  red;
    border-radius: 5px ;
    margin-left: 20px;
    align-items: center ;
    justify-content: space-evenly ;
    border: 2px solid #f0f0f0;
    position: relative ;

    cursor: pointer;

    label{
        cursor: pointer;
        font-size: 12px ;
    }

    div{
        display: flex ;
        position: absolute ;
        width: ${props => props.perPage == true ? "190px" : "max-content"};
        display: ${props => props.visible ? "flex" : "none"} ;
        top: 0px ;
        right: 0px ;
        margin-top: ${props => props.perPage == true ? "32px" : "30px"};
        z-index: 9999999;
        flex-direction: column ;
        background-color: white;
        box-shadow: 0 0 2em #DCDBDA;
        padding: 10px ;
        gap: 5px;


        span{
            display: flex ;
            width: 100% ;
            justify-content: ${props => props.perPage == true ? "right" : "left"} ;
            cursor: pointer;

            :hover{
                opacity: 0.7 ;
            }

            label{
                margin-left: 5px ;
                font-size: 11px ;
                cursor: pointer;
                //background-color: red;
            }
        }
    }
`;

export const Filters = styled.div`
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 30px;
    flex-wrap: wrap;
    //background-color: yellow;

    select{
        outline: none;
        border-radius: 5px;
        height: 40px;
        padding: 0px 10px;
        cursor: pointer;
        border: none;
        background-color: ${props => props.theme.colors.layout.secondary};
        color: #A1A5B6; 

        @media(max-width: 480px) {
            margin-top: 10px;
            flex: 1;
        }
   }

   button{
       height: 40px;
       width: 100px;
       outline: none;
       border: none;
       background-color: ${props => props.theme.colors.layout.secondary};
       border-radius: 5px;
       color: gray;
       cursor: pointer;
       border: 1px solid #d3d3d3;
   }
`;

export const ActionsSelect = styled.div`
    //background-color: yellow;
    margin-top: 15px;
    height: 50px;
    display: flex;
    align-items: center;
    display: flex ;

    span{
        width: 30px;
        //background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const ActionsSelectButton = styled.div`
    //background-color: red;
    display: flex;
    flex: 1 ;
    justify-content: flex-end ;
    padding-right: 20px;


    span{

        //display: flex ;
        position: relative ;
        //background-color: yellow;
        margin-left: 20px ;
        width: 20px ;
      
        svg{
            //margin-left: 20px ;
            cursor: pointer;
            position: relative ;
            //background-color: purple ;
            
        }
        :hover{

            label{
                visibility: visible;
            }
        }

        label{
            display: flex;
            position: absolute;
            background: #292929cc;
            visibility: hidden;
            border-radius: 5px;
            //max-width: 300px !important; 
            width: max-content;
            padding: 10px 10px;
            transform: translateX(-100%);
            left: 0;
            margin-top: -80px;
            margin-left: 28px;
            color: white ;
            font-size: 11px !important;

            :after{
                content: '';
                position: absolute;
                top: 100%;
                right: 10px;
                border-top: 8px solid #292929cc;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
    }
`;

export const ButtonCustom = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 100px;
    height: 30px;
    justify-content: center !important;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.background};
    color: ${props => props.color};

    svg{
        margin-left: 10px;
    }
`;

export const Infor = styled.div`
    min-height: 50px;
    display: flex;
    align-items: flex-start;
    //background-color: chocolate;
    height: 100%;
    justify-content: center;


    label{
        margin-right: 10px; 
        margin-top: 75px;

        strong{
            cursor: pointer;
            :hover{
                text-decoration: underline;
            }
        }
    }
    button{
        width: 100px;
        height: 40px;
        background-color:  #FBF9F6;
        outline: none;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        color: black;
        border: 1px solid gray;

        div{
            background-color: #FBF9F6;
            width: 200px;
            position: absolute;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            z-index: 9999;
            padding-left: 5px;
            border: 1px solid gray;
            border-radius: 5px;

            label{
                //background-color: peru;
                text-align: left;
                width: 100%;
                cursor: pointer;
                height: 30px;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: black;
                
                
                :hover{
                    opacity: 0.5;
                }
            }
        }
    }

    
`;

export const ListCard = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    gap: 20px;
`;

export const SectionLeft = styled.div`
    display: flex;
    margin: 10px 0px;
`;

export const SectionRight = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
`;

export const Search = styled.div`
    background-color: ${props => props.theme.colors.layout.secondary};
    display: flex;
    height: 40px;
    //border: 1px solid gray;
    border-radius: 20px;
    padding: 10px;
    align-items: center;

    @media(max-width: 480px) {
        //margin-top: 10px;
        flex: 1;
    }
    
    span{
        cursor: pointer;
    }
    input{
        display: flex;
        height: 100%;
        width: 250px;
        outline: none;
        border: none;
        background-color: transparent;
        color: #A1A5B6;//;

        ::placeholder{
            color: #A1A5B6;
        }
    }
`;

export const BoxLoading = styled.div`

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-top: 50px ;

    span{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;

export const Check = styled.div`
    display: flex;
    margin-right: 20px;
    align-items: center;
    color: gray;
    span{
        display: flex;
        width: 20px;
        height: 20px;
        background-color: #FBF9F6;
        justify-content: center;
        align-items: center;
        border: 2px solid gray;
        margin-right: 5px ;
        border-radius: 4px;
    }
`;

export const DropDown = styled.div`
    display: flex;
    background-color: orange;

    span{
        display: none;
        flex-direction: column;
    }
`;


export const Paginate = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PaginateNav = styled.div`
    display: flex;
    height: 40px;
    margin-bottom: 5px;
    align-items: center;

    span{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    label{
        margin: 0px 10px;
    }
`;

export const PaginateInfor = styled.div`
    display: flex;
    //background-color: saddlebrown;
`;


export const FiltersOptionsClick = styled.div`
    //background-color: gray ;
    display: flex ;
    margin-top: 15px ;
    height: 50px ;
    align-items: center ;
    gap: 10px;
    margin-bottom: -15px ;
    //justify-content: center;
`

export const OptionFilter = styled.label`
    display: flex ;
    background-color: ${props => props.active ? "#EFEFEF" : "transparent"} ;
    //background-color: #EFEFEF ;
    height: 35px ;
    align-items: center ;
    justify-content: center ;
    padding: 0px 15px ;
    border-radius: 17.5px ;
    cursor: pointer;
    font-size: 13px ;
    border: ${props => props.active ? "none" : "1px solid #EFEFEF"} ;
    //border: 1px solid #EFEFEF;
`


export const BoxInforInitial = styled.div`
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px ;
`;

export const BoxInforInitialLeft = styled.div`
    display: flex;
    //background-color: #808080;
    flex-direction: column ;
    flex: 1;

    label{

    }
    label:nth-child(1){
        font-weight: 600;
        font-size: 28px;
        color: #5E6278;
    }

    label:nth-child(2){
        font-weight: 400;
        font-size: 16px;
        color: #5E6278;
    }
`;

export const BoxInforInitialRight = styled.div`
    display: flex;
    
    width: 582px;
    align-items: center ;
    justify-content: center ;
    
    label{
        background-color: #FFC400;
        display: flex ;
        padding: 8px ;
        border-radius: 4px ;
        font-size: 12px ;
        font-weight: 400;
        font-size: 14px;
        color: #2b2b2b;
        cursor: pointer;
    }

`;

export const ContainerInfor = styled.div`
    //background-color: red;
    display: flex ;
    flex-direction: column ;
`

export const BoxMessage = styled.div`
    margin-top: 100px ;
    //background-color: yellow;
    display: flex ;
    flex-direction: column ;

    label{
        //background-color: red ;
    }
`

export const SelectCustom = styled.select`
    display: flex;
    //background-color: #F5F3F0;
    height: 35px;
    //width: 100% !important;
    //font-weight: 600;
    color: #353535;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 10px 0px 5px;
    outline: none;
    font-size: 12px;
    cursor: pointer;
    margin-left: 20px;
    border: 2px solid #f0f0f0;
`