import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background: linear-gradient(180deg, #fff48e 8.33%, #ffd100);
    align-items: center;
    z-index: 999999999999999999999999999999999999999999;
`;

export const Title = styled.div`
    display: flex;
    
`

export const Options = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    
`

export const ItemOption = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #ffffff;
    cursor: pointer;
    width: 300px;
    padding: 10px 10px ;
    border-bottom: 1px solid #eee;

    img{
        width: 35px;
    }

    label{
        font-weight: 600;
        color: #212529;
        cursor: pointer;
    }

    :hover{
        background-color: #eee;
    }

    :first-child{
        border-radius: 4px 4px 0px 0px;
    }

    :last-child{
        border-radius: 0px 0px 4px 4px;
        border-bottom: none;
    }
`
