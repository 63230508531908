import styled from "styled-components";


export const Container = styled.form`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    //padding: 25px;
    border-radius: 5px;
    overflow: auto;
    //height: calc(500%) !important;
    margin-left: 0px ;
    flex:1 ;
    align-items: center ;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
    background-size: 60%;
    //justify-content: center;
    padding-top: 40px;

    @media screen and (max-width: 450px) {
        width: 300px;
    }
`;

export const Main = styled.div`
    display: flex;
    background-color: ${props => props.backgroundColor} ;
    width: 700px;
    height: 500px;
    padding: 20px;
    border-radius: 8px;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`

export const Title = styled.label`
    display: flex;
    //background-color: yellow;
    //height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    font-size: 22px;
    font-weight: bold;
    color: ${props => props.color};

`

export const ItemOption = styled.label`
    display: flex;
    background-color: transparent;
    height: 50px;
    padding: 0px 50px;
    width: 350px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    border: 1px solid ${props => props.colorBorder};

    :hover{
        background-color: ${props => props.colorBorder};
        
        label{
            color: ${props => props.colorHover};
        }

        svg{
            //color: ${props => props.colorHover} !important;
        }

    }
    
    label{
        display: flex;
        margin-left: 10px;
        cursor: pointer;
        color: ${props => props.color};
    }
`

export const ControlScreen = styled.div`
    display: flex;
    //background-color: red;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    label:nth-child(1){
        justify-content: start;
    }

    label:nth-child(3){
        justify-content: end;
    }

    label{
        display: flex;
        width: 100px;
        color: ${props => props.color};
        //background-color: red;
        cursor: pointer;
    }
`

export const ButtonCustom = styled.label`
    display: flex;
    width: 300px;
    background-color: transparent;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.borderColor};
    border-radius: 4px;
    cursor: pointer;
    color: ${props => props.color};

    :hover{
        background-color: ${props => props.borderColor};
        color: ${props => props.colorHover};
    }

`

export const BoxMessage = styled.label`
    display: flex;
    flex: 1;
    flex-direction: column;
    //background-color: purple;
    width: 100%;
    gap: 20px;
    align-items: center;
    justify-content: center;
`

export const Steps = styled.label`
    display: flex;
    //width: 300px;
    //background-color: yellow;
    align-items: center;
    gap: 10px;
    justify-content: center;
    flex: 1;

    div{
        display: flex;
        min-width: 60px;
        max-height: 2px;
        min-height: 2px;
        background-color: ${props => props.backgroundColor};
        border-radius: 2px;
    }

    span{
        display: flex;
        min-width: 10px;
        min-height: 10px;
        border-radius: 50%;
        background-color: ${props => props.backgroundColor};
        
    }
`

export const BoxMedias = styled.label`
    display: flex;
    flex: 1;
    //background-color: yellow;
    width: 100%;
    justify-content: space-around;
`
export const ItemBoxMedia = styled.label`
    display: flex;
    flex-direction: column;
    border: 3px dashed ${props => props.colorBorder};
    width: 200px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    color: ${props => props.color};
    //background-color: yellow;
    padding-top: 50px;

`
export const ButtomItemBoxMedia = styled.label`
    display: flex;
    width: 150px;
    height: 40px;
    background-color: ${props => props.backgroundColor};;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color:${props => props.colorHover};
    cursor: pointer;

`

export const BoxForm = styled.label`
    display: flex;
    flex: 1;
    //background-color: yellow;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    width: 400px;
    align-items: center;
`

export const InputCustomForm = styled.label`
    display: flex;
    background-color: transparent;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid ${props => props.colorBorder};
    color: ${props => props.color};

    input{
        display: flex;
        width: 100%;
        padding: 0px 10px;
        font-size: 14px;
        background-color: transparent;
        outline: none;
        border: none;
        color: ${props => props.color};
    }

`

export const InputCustomWhatsappForm = styled.label`
    display: flex;
    background-color: transparent;
    width: 100%;
    height: 50px;
    align-items: center;
    border: 1px solid ${props => props.colorBorder};

    input{
        display: flex;
        width: 100%;
        padding: 0px 10px;
        font-size: 14px;
        background-color: transparent;
        outline: none;
        border: none;
    }

`

export const TermoUso = styled.label`
    display: flex;
    font-size: 14px;
    text-align: justify;
    color: ${props => props.color};
`



export const BoxWhats = styled.span`
  background-color: transparent;
  //margin-bottom: 40px;
  position: relative ;
  width: 100%;
  //align-items: center;
  //border: 1px solid ${props => props.colorBorder};
  //color:${props => props.color};;
`

export const BoxInfor = styled.div`
    display: flex;
    //flex: 1;
    background-color: ${props => props.backgroundColor}11;
    width: 400px;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;

    p{
        display: flex;
        margin: 0px;
        padding: 0px;
        color: ${props => props.color};
    }

    label{
        display: flex;
        font-size: 14px;
        color: ${props => props.color};
    }
`

export const ButtonClose = styled.div`
    display: flex;
    background-color: ${props => props.colorBorder};
    height: 45px;
    width: 300px;
    border-radius: 4px;
    align-items: center;
    padding: 0px 10px 0px 20px;
    justify-content: space-between;
    color: ${props => props.colorHover};

    
`

export const BoxCupom = styled.div`
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: 2px ${props => props.colorBorder} dashed;
    //padding: 10px 50px;
    align-items: center;
    width: 100%;
    height: 50px;
    gap: 10px;

    label{
        font-size: 18px;
        letter-spacing: 3px;
    }

`