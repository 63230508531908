import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux'

export default function ButtonSinc() {

    const dispatch = useDispatch()

    return (
        <button
            onClick={() => { dispatch({ type: "SAGA_SEARCH_PLATFORM_ALL_PRODUCTS", payload: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform }) }}
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-green-50 px-2.5 py-1.5 text-sm font-semibold text-green-700 hover:bg-green-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-100"
        >
            <ArrowPathIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
            Sincronizar
        </button>
    )
}
