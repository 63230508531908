import { useEffect } from "react";

const ModalDelete = (props) => {

    const closeModal = (event) => {
        if (event.data.id == "closeModalWrite") {
            props.setVisibleModal(false)
            props.goSearch()
        }
    };

    useEffect(() => {

        window.addEventListener('message', closeModal);
        return () => {
            window.removeEventListener('message', closeModal);
        };

    }, [])




    return (

        props.visible &&
        <iframe
            id='teste-app=lily'
            style={{ border: "none", position: "fixed", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "999999", display: "block" }}
            allow='camera;microphone'
            width="100%"
            height="100%"
            src={`https://avali.ar/create/review/${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.user_token}/${props.review.product_id}`} />

    )
}

export default ModalDelete
