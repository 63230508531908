import { useEffect } from 'react'
import { Container } from './styled'

const Card = (props) => {

    useEffect(() => {
        const script2 = document.getElementById("script-support");
        if (script2) {
            //script2.parentNode.removeChild(script2);

            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es") {
                window['sakw_token'] = '63d2dcb4bdc15';
                window['sakw_idioma'] = 'es-mx';
            }else{
                window['sakw_token'] = '619678452e2db';
                window['sakw_idioma'] = 'pt-br';
            }
        } else {
            const script = document.createElement('script');
            script.src = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/63d2dcb4bdc15.js" : "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/619678452e2db.js"
            script.id = "script-support";
            script.async = true;
            document.body.appendChild(script);
        }


    }, [])

    return (
        <Container background={props.background} color={props.color}>
            <span>{props.headerText}</span>
            <strong>{props.mainText}</strong>
            <a href={props.btnAction} target={props.headerText !== 'Atendimento'} rel="noreferrer">{props.btnText}</a>
        </Container>
    );
}

export default Card