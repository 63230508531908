
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE, DELETE_CUSTOM, GET_SHOPEE } from "../../../../services/api";
import { toast } from "react-toastify";

export function* listProducts(action) {

    if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform) {

        yield put({ type: "PRODUCTS_REQUEST", })
        //yield delay(5000);
        const result = yield call(GET, { endpoint: `/products/integration/${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform}?search=${action.payload.search}&perPage=${action.payload.perPage}&page=${action.payload.page}` })

        if (result === false) {
            yield put({ type: "PRODUCTS_ERROR" })
        } else {
            yield put({ type: "PRODUCTS_SUCCESS", payload: result.data.data })
        }
    } else {
        yield put({ type: "PRODUCTS_REQUEST", })
        yield put({ type: "PRODUCTS_SUCCESS", payload: [] })
    }

}

export function* listProductsImport(action) {

    if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform) {

        yield put({ type: "PRODUCTS_IMPORT_REQUEST", })
        //yield delay(5000);
        const result = yield call(GET, { endpoint: `/products/integration/${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform}?search=${action.payload.search}&perPage=${action.payload.perPage}&page=${action.payload.page}` })

        if (result === false) {
            yield put({ type: "PRODUCTS_IMPORT_ERROR" })
        } else {
            yield put({ type: "PRODUCTS_IMPORT_SUCCESS", payload: result.data.data })
        }
    } else {
        yield put({ type: "PRODUCTS_IMPORT_REQUEST", })
        yield put({ type: "PRODUCTS_IMPORT_UCCESS", payload: [] })
    }

}

export function* importShopee(action) {



    yield put({ type: "IMPORT_SHOPEE_REQUEST", })
    const result = yield call(GET, { endpoint: `/importreviews/shopee?url=${action.payload.url}&limit=${action.payload.limit}&midias=${action.payload.midias}&midiasOne=${action.payload.midiasOne}` })
    if (result === false) {
        yield put({ type: "IMPORT_SHOPEE_ERROR" })
    } else {
        yield put({ type: "IMPORT_SHOPEE_SUCCESS", payload: result.data.data })
    }

    //yield put({ type: "PRODUCTS_CONTROL_IMPORT_SHOPEE_MORE", payload: { isEnd: false } })

}

export function* importShopeeMore(action) {


    yield put({ type: "IMPORT_SHOPEE_MORE_REQUEST", })
    const result = yield call(GET, { endpoint: `/importreviews/shopee?url=${action.payload.url}&limit=${action.payload.limit}&midias=${action.payload.midias}&midiasOne=${action.payload.midiasOne}&offset=${action.payload.offset}` })
    if (result === false) {
        yield put({ type: "IMPORT_SHOPEE_MORE_ERROR" })
    } else {
        if (result.data.description == "" && result.data?.data?.length == 0) {
            yield put({ type: "PRODUCTS_CONTROL_IMPORT_SHOPEE_MORE", payload: { isEnd: true } })
            yield put({ type: "IMPORT_SHOPEE_MORE_SUCCESS", payload: result.data.data })
        } else {
            yield put({ type: "IMPORT_SHOPEE_MORE_SUCCESS", payload: result.data.data })
        }
    }

}

export function* saveImportShopee(action) {


    yield put({ type: "SAVE_IMPORT_SHOPEE_REQUEST", })

    try {
        // action.payload.reviews.forEach(element => {
        //     const result = yield call(POST, { endpoint: `/reviews/import/${action.payload.product_id}`, data: element })
        //     yield put({ type: "CONTROL_SAVE_IMPORT_REVIEWS_UPDATE", payload: { qtdTotal: action.payload.reviews.lenght, qtdSuccess: true } })
        // });
        for (const review of action.payload.reviews) {
            const result = yield call(POST, { endpoint: `/reviews/import/${action.payload.product_id}`, data: { review: review } })
            yield put({ type: "CONTROL_SAVE_IMPORT_REVIEWS_UPDATE", payload: { qtdTotal: action.payload.reviews.length, qtdSuccess: true } })
            //yield delay(3000)
        }
        yield put({ type: "CONTROL_SAVE_IMPORT_REVIEWS_RESET", })
        yield put({ type: "SAVE_IMPORT_SHOPEE_SUCCESS", })
    } catch (error) {
        yield put({ type: "SAVE_IMPORT_SHOPEE_ERROR", })
    }


    // yield put({ type: "IMPORT_SHOPEE_REQUEST", })
    // const result = yield call(GET, { endpoint: `/importreviews/shopee?url=${"https://shopee.com.br/Caneca-Flork-Eu-Te-Amo-Mas-as-Vezes-D%C3%A1-Vontade-III-325-ml-Porcelana-i.403650392.17806787801?sp_atk=2a0c5624-6430-4577-bf8e-7e83fb5abad4&xptdk=2a0c5624-6430-4577-bf8e-7e83fb5abad4"}&page=${1}` })
    // if (result === false) {
    //     yield put({ type: "IMPORT_SHOPEE_ERROR" })
    // } else {
    //     yield put({ type: "IMPORT_SHOPEE_SUCCESS", payload: result.data.data })
    // }

}

export function* updateProduct(action) {

    yield put({ type: "PRODUCT_UPDATE_REQUEST", })
    //yield delay(2000);
    const result = yield call(PUT, { endpoint: `/products/${action.payload.id}`, data: action.payload })
    if (result === false) {
        yield put({ type: "PRODUCT_UPDATE_ERROR" })
    } else {
        yield put({ type: "PRODUCT_UPDATE_SUCCESS", payload: result.data })

    }
}

export function* updateDataProduct(action) {

    yield put({ type: "PRODUCT_DATA_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/products/platform/${action.payload.id}` })
    if (result === false) {
        yield put({ type: "PRODUCT_DATA_UPDATE_ERROR" })
    } else {

        const result2 = yield call(PUT, { endpoint: `/products/duplicate/${action.payload.id}` })

        if (result2 === false) {
            yield put({ type: "PRODUCT_DATA_UPDATE_ERROR" })
        } else {
            yield put({ type: "PRODUCT_DATA_UPDATE_SUCCESS", payload: result.data })
            yield put({ type: "PRODUCT_DATA_UPDATE_RESET" })
        }

        // yield put({ type: "PRODUCT_DATA_UPDATE_SUCCESS", payload: result.data })

        // const result2 = yield call(GET, { endpoint: `/products/integration/${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform}?search=&perPage=20}&page=1` })
        // if (result2 === false) {
        //     yield put({ type: "PRODUCTS_ERROR" })
        // } else {
        //     yield put({ type: "PRODUCTS_SUCCESS", payload: result2.data.data })
        //     const result2 = yield call(PUT, { endpoint: `/products/duplicate/${action.payload.id}` })
        // }
    }
}

export function* deleteProduct(action) {


    yield put({ type: "PRODUCT_DELETE_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE, { endpoint: `/products/${action.payload.id}` })
    if (result === false) {
        yield put({ type: "PRODUCT_DELETE_ERROR" })
    } else {
        yield put({ type: "PRODUCT_DELETE_SUCCESS", payload: result.data })
    }
}


export function* addProduct(action) {
    //("addProduct action", action)
    yield put({ type: "PRODUCT_ADD_REQUEST", })
    //yield delay(200);
    const result = yield call(POST, { endpoint: `/products`, data: action.payload })
    if (result === false) {
        yield put({ type: "PRODUCT_ADD_ERROR" })
    } else {
        yield put({ type: "PRODUCT_ADD_SUCCESS", payload: result.data })
    }
}

export function* deleteSelectProducts(action) {


    yield put({ type: "PRODUCTS_DELETE_SELECT_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE_CUSTOM, { endpoint: `/products/all`, data: action.payload })
    if (result === false) {
        yield put({ type: "PRODUCTS_DELETE_SELECT_ERROR" })
    } else {
        yield put({ type: "PRODUCTS_DELETE_SELECT_SUCCESS", payload: result.data })
    }
}

export function* searchPlatformAllProducts(action) {

    yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_REQUEST" });
    const result = yield call(GET, { endpoint: `products/pagination/${action.payload}` });
    console.log("result", result)
    if (result === false) { yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_ERROR" }); }
    else { yield put({ type: "SEARCH_PLATFORM_ALL_PRODUCTS_SUCCESS", payload: result.data }); }

    if (result.data.data.maxItems == 0) { return null }

    if (action.payload == "shopify") {
        yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_REQUEST" });
        let nextPage = 1
        let cont = 1;
        do {
            yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_UPDATE", payload: cont });
            const resultItem = yield call(PUT, { endpoint: `products/pagination/${action.payload}`, data: { page: nextPage, perPage: result.data.data.perPage } });
            console.log("resultItem", resultItem)
            nextPage = resultItem?.data?.data?.nextPage
            console.log("nextPage", nextPage)
            if (resultItem == false) {
                yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_ERROR" });
                toast.error("Erro ao tentar atualização.")
                break
            } else {
                yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_SUCCESS", payload: nextPage })
            }
            cont++
        } while (nextPage != null);

    } else {
        yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_REQUEST" });
        for (let index = 0; index < result.data.data.maxPages; index++) {
            yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_UPDATE", payload: index + 1 });
            const resultItem = yield call(PUT, { endpoint: `products/pagination/${action.payload}`, data: { page: index + 1, perPage: result.data.data.perPage } });
            if (resultItem == false) {
                yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_ERROR" });
                toast.error("Erro ao tentar atualização.")
                break
            } else {
                yield put({ type: "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_SUCCESS", payload: result.data.data.maxItems })
            }
        }
    }
}

export function* searchPlatformSelectedProducts(action) {

    yield put({ type: "PRODUCT_SINC_SELECTED_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `products/selected/${action.payload.platform}`, data: { products: action.payload.data } })
    if (result === false) {
        yield put({ type: "PRODUCT_SINC_SELECTED_ERROR" })
    } else {
        yield put({ type: "PRODUCT_SINC_SELECTED_SUCCESS", payload: result.data })
    }
}