import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    //background-color: #FFFFFF;
    background-color: #FBF9F6;
    min-height: 100vh;
    min-width: 100%;
    overflow: hidden;
`

export const Contents = styled.div`
    display: flex;
    //background-color: yellow;
    width: calc(100% - 300px);
    min-height: calc(100vh + 0px);
    flex-direction: column;
    height: 100%;
    //background-color: purple;
`

export const Header = styled.div`
    display: flex;
    //background-color: purple;
    height: 70px;
    min-width: 100%;
`