import { Box, BoxCheck, Tooltip, BoxImgVideo, Container, BoxContents, BoxContact, BoxOptions, BoxContentsLeft, SubTitle, Status, Img, ButtonApproved, ButtonHidden, ButtonDelete, BoxActions, BoxMedias, ButtonCustom, Badget, Badget2, BoxBadget } from "./styled"
import { AiOutlineInfoCircle, AiFillHeart, AiOutlineHeart, AiFillPlayCircle, AiOutlineQuestionCircle, AiOutlineMail, AiOutlineCheckCircle, AiFillCloseCircle, AiFillEdit, AiFillEye, AiFillStar, AiFillClockCircle, AiOutlineCheck, AiFillEyeInvisible, AiOutlineQuestion, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { interval } from "../../../../utils/date";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useState } from "react";
import { FaInfo } from "react-icons/fa";
import { IoLogoInstagram, IoLogoTiktok, IoLogoYoutube, } from "react-icons/io5";
import { IoMdTrash } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineNewReleases } from "react-icons/md";
import { ZiggeoPlayer } from 'react-ziggeo'
import { BiPlay } from "react-icons/bi";
import { FiCheckCircle } from "react-icons/fi";
import { HiOutlineBadgeCheck, HiBadgeCheck } from "react-icons/hi";
import ImgDropi from "../../../../assets/imgs/icons-integrations/icon-dropi.png";
import { getTranslation } from "../../../../translations";
import { Back } from "iconsax-react";


const CardReviews = (props) => {


    const dispatch = useDispatch()
    const statusUpdateReview = useSelector(state => state.reducerUpdateReview)
    const statusDeleteReview = useSelector(state => state.reducerDeleteReview)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const [selectCard, setSelectCard] = useState(false)

    const [visibleModalReviews, setVisibleModalReviews] = useState(false)
    const [visibleModalEditReviews, setVisibleModalEditReviews] = useState(false)

    const [imgVideo, setImgVideo] = useState("")
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const colorBorder = (status) => {
        switch (status) {
            case "pending":
                return "#f0ad4e"
            case "approved":
                return "#20D489" //#5cb85c 
            case "hidden":
                return "#0275d8"
            case "new":
                return "#F13F6C"
            default:
                return "gray"
        }
    }

    const getIconStatus = (status) => {
        switch (status) {
            case "pending":
                return <AiOutlineQuestion size={15} color={"white"} />
            case "approved":
                return <AiOutlineCheck size={15} color={"white"} />
            case "hidden":
                return <AiFillEyeInvisible size={15} color={"white"} />
            case "new":
                return <FaInfo size={15} color={"white"} />
            default:
                return ""
        }
    }

    const getTextStatus = (status) => {
        switch (status) {
            case "pending":
                return translation.reviews.card.avaliacaoPendente
            case "approved":
                return translation.reviews.card.avaliacaoAprovada
            case "hidden":
                return translation.reviews.card.avaliacaoEscondida
            case "new":
                return translation.reviews.card.avaliacaoNova
            default:
                return ""
        }
    }

    useEffect(() => {
        if (!(statusUpdateReview.loading == false && !statusUpdateReview.error == false && statusUpdateReview.data.length == 0)) {
            const id = "statusUpdateReview"
            if (statusUpdateReview.loading) {
                toast.loading(translation.reviews.card.aguarde, { toastId: id })
            } else {
                if (statusUpdateReview.error) toast.update(id, { render: translation.reviews.card.erroEnviar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else toast.update(id, { render: translation.reviews.card.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                //dispatch({ type: "PRODUCTS_CONTROL_UPDATE_LIST_REVIEWS", payload: { status: true } })
            }
        }
    }, [statusUpdateReview])

    useEffect(() => {
        if (!(statusDeleteReview.loading == false && !statusDeleteReview.error == false && statusDeleteReview.data.length == 0)) {
            const id = "statusDeleteReview"
            if (statusDeleteReview.loading) {
                toast.loading(translation.reviews.card.aguarde, { toastId: id })
            } else {
                if (statusDeleteReview.error) toast.update(id, { render: translation.reviews.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else toast.update(id, { render: translation.reviews.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
            }
        }
    }, [statusDeleteReview])


    const updateReview = (status, id) => {

        dispatch({
            type: "SAGA_UPDATE_REVIEWS",
            payload: {
                status: status,
                id: id
            }
        })
    }





    const deleteReview = () => {

        dispatch({
            type: "SAGA_DELETE_REVIEWS",
            payload: {
                id: props.item.id
            }
        })
    }

    const getData = (data) => {

        if (data.type == "video") {
            return (
                <BoxImgVideo>
                    <Img src={imgVideo} />
                    {/* <AiFillPlayCircle size={50} /> */}
                    <div style={{ display: "none" }}>
                        <ZiggeoPlayer
                            apiKey={process.env.REACT_APP_ZIGGEO_APP_TOKEN}
                            video={data.url}
                            theme={'modern'}
                            themecolor={'yellow'}
                            skipinitial={false}
                            onPlaying={handlePlaying}
                            onPaused={handlePaused}
                            onLoaded={handleLoaded}
                            onRef={ref => (setPlayer(ref))}
                            width={200}
                            height={200}
                        />
                    </div>
                </BoxImgVideo>


            )
        } else if (data.type == "image") {
            return <Img src={data.url} />
        }
    }

    const [player, setPlayer] = useState(null);
    useEffect(() => {
        if (player) {
            // DO stuff here
            player.on("attached", function (embedding) {
            }, player);
        }
    }, [player]);

    const handlePlaying = (embedding) => {

    };

    const handlePaused = (embedding) => {

    };

    const handleLoaded = (embedding) => {
        setImgVideo(embedding.thumbnailurl)
    };

    const handleStatusCard = (checked) => {
        // if (checked) {
        //     dispatch({
        //         type: "REVIEWS_CONTROL_SELECT_SET",
        //         payload: { id: props.item.id }
        //     })
        // } else {
        //     dispatch({
        //         type: "REVIEWS_CONTROL_SELECT_REMOVE",
        //         payload: { id: props.item.id }
        //     })
        // }
        // setSelectCard(checked)
    }

    useEffect(() => { handleStatusCard(props.checkedAll) }, [props.checkedAll])

    const getIconMedia = (item) => {
        switch (item.type) {
            case "video":
                return (
                    <div onClick={() => { setVisibleModalReviews(true) }} src={item.url} style={{ width: "50px", height: "50px", display: "flex", position: "relative" }}>
                        <Img src={item.thumbnail_url} />
                        <BiPlay size={20} color={"gray"} style={{ position: "absolute" }} />
                    </div>
                )
            case "image":
                return (
                    <Img onClick={() => { setVisibleModalReviews(true) }} src={item.url} />
                )
            case "tiktok":
                return (
                    <IoLogoTiktok onClick={() => { setVisibleModalReviews(true) }} />
                )
            case "instagram":
                return (
                    <IoLogoInstagram onClick={() => { setVisibleModalReviews(true) }} />
                )
            case "youtube":
                return (
                    <IoLogoYoutube onClick={() => { setVisibleModalReviews(true) }} />
                )

            default:
                break;
        }
    }

    const getMedias = () => {

        return (
            <BoxMedias style={{ marginTop: props.item?.medias?.length > 0 ? "15px" : "0px" }}>
                {props.item.medias.map((item) => {
                    return getIconMedia(item)
                })}
            </BoxMedias>
        )

    }


    const updateItem = () => {
        dispatch({
            type: "SAGA_UPDATE_REVIEWS",
            payload: {
                active: 1,
                id: props.item.id
            }
        })
    }

    return (
        <Container>

            {/* {getBadget()} */}

            {/* {
                props?.noVisibleCheck ?
                    <></> :
                    <BoxCheck >
                        <input type="checkbox" checked={selectCard} onChange={(e) => { handleStatusCard(e.target.checked) }} />
                    </BoxCheck>
            } */}

            <Box>
                <BoxContents
                //color={colorBorder(props.item.status)}
                >
                    <BoxContentsLeft>
                        <SubTitle >
                            <span>
                                <AiFillStar size={15} color={"orange"} />
                                <AiFillStar size={15} color={props?.item?.rating > 1 ? "orange" : "gray"} />
                                <AiFillStar size={15} color={props?.item?.rating > 2 ? "orange" : "gray"} />
                                <AiFillStar size={15} color={props?.item?.rating > 3 ? "orange" : "gray"} />
                                <AiFillStar size={15} color={props?.item?.rating > 4 ? "orange" : "gray"} />
                            </span>
                            <AiFillClockCircle size={15} color={"gray"} />
                            <label>
                                {interval(props?.item?.created_at)} {translation.reviews.card.atras}
                            </label>

                        </SubTitle>

                        <p>{props?.item?.name} {translation.reviews.card.sobre} <a target="_blank" href={props?.item?.canonical_url}>{props?.item?.product_name}</a></p>

                        <label>
                            {props?.item?.message}
                        </label>
                        {getMedias()}
                    </BoxContentsLeft>
                    <BoxActions>
                        <span>
                            <Back size={20} color={"props?.item?.dropi_id" == undefined ? "#F13F6C" : "gray"} onClick={() => { updateItem() }} />
                            <label>{"Deseja restaurar essa avaliação?"}</label>
                        </span>
                    </BoxActions>
                </BoxContents>

            </Box>


        </Container >
    )
}

export default CardReviews
