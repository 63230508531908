import { EllipsisVerticalIcon } from "@heroicons/react/24/outline"
import { BoxIcon, Message, Submenu } from "../../../../reviews/components/tailwind/item-table/styled"
import { useEffect, useState } from "react"
import Toggle from "../../../../../components/tailwind/toggle"
import ModalUpdate from "../../modal-edit";
import { RiScissorsLine } from "react-icons/ri";

import { getTranslation } from "../../../../../translations";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BoxCode } from "./styled";
import ModalMenuItem from "../../modal-menu-item";

const ItemTable = (props) => {

    const [visibleMenu, setvisibleMenu] = useState(false)
    const [heightTable, setHeightTable] = useState("")
    const [visibleModalUpdate, setVisibleModalUpdate] = useState(false)
    const dispatch = useDispatch()
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const statusDeleteCupom = useSelector(state => state.reducerDeleteCupom)

    const [visibleModalMenu, setVisibleModalMenu] = useState(false)

    const closeMenu = () => { setvisibleMenu(false) }
    useEffect(() => {
        if (visibleMenu) {
            window.addEventListener('mouseup', closeMenu);
            return () => { window.removeEventListener('mouseup', closeMenu); };
        }


    }, [visibleMenu])

    useEffect(() => {
        if (visibleMenu) {
            setTimeout(() => {
                verifyScrollTable()
            }, 100);
        } else {
            setTimeout(() => {
                verifyScrollTable2()
            }, 10);
        }

    }, [visibleMenu])

    const verifyScrollTable2 = () => {
        if (document.getElementById('table-cupons-scroll')) {
            var element = document.getElementById('table-cupons-scroll');
            if (element.style.height != heightTable) {
                element.style.height = heightTable; // Restaura a altura original
            }
        }

    }


    const verifyScrollTable = () => {

        console.log("verifyScrollTable")
        var element = document.getElementById('table-cupons-scroll');
        // Verifica se o conteúdo interno é maior que a altura do elemento (ou seja, se há rolagem)
        if (element.scrollHeight > element.clientHeight) {
            setHeightTable(document.getElementById('table-cupons-scroll').style.height)
            var newHeight = element.scrollHeight; // Define a nova altura igual à altura total do conteúdo
            element.style.height = newHeight + 10 + 'px'; // Define a nova altura
        }
    }


    const deleteCupom = () => {
        dispatch({ type: "SAGA_DELETE_CUPOM", payload: { id: props.item.id } })
    }
    const updateStatusCupom = () => {
        dispatch({ type: "SAGA_UPDATE_CUPOM", payload: { id: props.item.id, active: props.item.active == 1 ? false : true } })
    }

    useEffect(() => {
        if (statusDeleteCupom.loading == false && statusDeleteCupom.error == false && statusDeleteCupom.data.length == 0) {
        } else {
            const id = "statusDeleteCupom"
            if (statusDeleteCupom.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusDeleteCupom.error) toast.update(id, { render: translation.reviews.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.reviews.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                }
            }
        }
    }, [statusDeleteCupom])

    const copyCupom = () => {
        navigator.clipboard.writeText(props.item.code)
        toast.success("Cupom copiado.")
    }

    return (
        <>
            <ModalMenuItem
                //setVisibleModalEdit={setVisibleModal}
                item={props.item}
                visibleModalMenu={visibleModalMenu}
                setVisibleModalMenu={setVisibleModalMenu}
                deleteCupom={deleteCupom}
                setVisibleModalUpdate={setVisibleModalUpdate}
            />
            <ModalUpdate visible={visibleModalUpdate} setVisibleModal={setVisibleModalUpdate} item={props.item} />

            <tr key={props.item.id} className={props.selectedCupons.includes(props.item) ? 'bg-gray-50 even:bg-gray-50' : "even:bg-gray-50"}>
                <td className="relative px-7 sm:w-12 sm:px-6">
                    {props.selectedCupons.includes(props.item) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-green-600" />
                    )}
                    <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={props.item.id}
                        checked={props.selectedCupons.includes(props.item)}
                        onChange={(e) =>
                            props.setSelectedCupons(
                                e.target.checked
                                    ? [...props.selectedCupons, props.item]
                                    : props.selectedCupons.filter((p) => p !== props.item),
                            )
                        }
                    />
                </td>
                <td
                    className={props.classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                        props.selectedCupons.includes("person") ? 'text-indigo-600' : 'text-gray-900',
                    )}
                >
                    {new Date(props.item.expiration_date).toLocaleDateString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <BoxCode
                        onClick={() => { copyCupom() }}
                        active={props.item.active == 0 ? false : true}
                    >
                        <p>{props.item.code}</p>
                        <label>{translation.cupons.card.copiarCupom}</label>
                        <RiScissorsLine color={props.item.active == 0 ? "gray" : "#21D487"} size={18} />
                    </BoxCode>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <Message>
                        <label >
                            {props.item.message}
                        </label>
                    </Message>


                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span onClick={() => { updateStatusCupom() }} style={{ display: "flex", justifyContent: "center" }}>
                        <Toggle status={props.item.active == 0 ? false : true} />
                    </span>
                </td>
                <td style={{ display: "flex", justifyContent: "flex-end" }} className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <span style={{ position: "relative" }}>
                        <EllipsisVerticalIcon onClick={() => {
                            setVisibleModalMenu(true)
                        }} style={{ height: "32px", cursor: "pointer" }} />
                        {/* <Submenu isVisible={visibleMenu}>
                            <div onClick={() => {
                                // props.setVisibleModalEdit(true)
                                setVisibleModalUpdate(true)
                            }}>
                                <BoxIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                    </svg>
                                </BoxIcon>
                                <span>
                                    <p>Editar</p>
                                    <label>  {translation.avaliacoes.atualizarDados}</label>
                                </span>
                            </div>
                            <div>
                                <BoxIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </BoxIcon>
                                <span onClick={() => { deleteCupom() }}>
                                    <p>{translation.avaliacoes.excluir}</p>
                                    <label>  {translation.cupons.excluirCupom}</label>
                                </span>
                            </div>
                        </Submenu> */}
                    </span>
                </td>
            </tr>
        </>

    )
}

export default ItemTable