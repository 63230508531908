import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 600px;
    padding: 60px;
    border-radius: 8px;
    gap: 20px;

    @media screen and (max-width: 450px) {
        width: 300px;
    }
`;

export const Title = styled.label`
    display: flex;
    //background-color: red;
    justify-content: center;
    text-align: center;
    font-size: 18px;
`;

export const InputCustom = styled.div`
    display: flex;
    background-color: #554466;
    height: 50px;
    border-radius: 8px;
    background-color: #f1f1f1;
    padding: 0px 20px 0px 0px;
    align-items: center;

    input{
        display: flex;
        width: 100%;
        border: none;
        outline: none;
        padding: 0px 20px;
        background-color: transparent;
        
    }
`;

export const BoxCheck = styled.div`
    display: flex;
    //background-color: violet;
    //gap: 20px;
    justify-content: space-between;
    

`;
export const CheckCustom = styled.div`
    display: flex;
    gap: 5px;
    cursor: pointer;

    span{
        width: 20px;
        height: 20px;
        background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    label{
        display: flex;
        font-size: 12px;
        cursor: pointer;
    }
`;


export const BoxFilters = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const NumberReviews = styled.div`
    display: flex;
    gap: 10px;

`;

export const NumberReviewsLeft = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    label{
        font-size: 12px;
    }

`;

export const NumberReviewsInfor = styled.label`
    display: flex;
    width: 60px;
    height: 40px;
    background-color: red;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

`;

export const DividerReviews = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    label{
        font-size: 12px;
    }

    span{
        display: flex;
        justify-content: space-between;

        div{
            display: flex;
            flex-direction: column;
        }
    }

`;

export const ButtomCustom = styled.div`
    display: flex;
    background-color: red;
    height: 50px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    color: #fff;

`;