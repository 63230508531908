import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 70px;
    border: 1px solid #e1e1e1;
    align-items: center;
    border-radius: 4px;
    padding: 0px 20px;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;

    label{
        cursor: pointer;
    }

    @media(max-width: 1023px) {
        flex-direction: column;
        height: auto;
        gap: 10px;
        padding: 20px;
    }
`

export const BarDivider = styled.div`
    display: flex;
    min-width: 1px;
    height: 20px;
    background-color: #e1e1e1;

    @media(max-width: 1023px) {
        display: none;
    }
    
`