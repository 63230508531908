import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    margin: 0px 0px;
    flex-direction: column;
    position: relative;
    flex-direction: row;
    border: ${props => props.borderSize} solid ${props => props.borderColor};
    border-radius: ${props => props.borderRadius};
    margin-bottom: 20px;
    break-inside: avoid;
    background-color: ${props => props.backgrounColor};
`;
export const Box = styled.div`
    display: flex ;
    flex: 1 ;
    flex-direction: column ;
`;



export const BoxContents = styled.div`
    //background-color: white;
    display: flex;
    flex: 1;
    padding: 20px;
    //border-radius: 0px 5px 5px 0px;
    text-align: justify;
    border-radius: 4px ;
    flex-direction: column;
  

    @media(max-width: 480px) {
        flex-direction: column-reverse;

        div:nth-child(2){
            
            display: flex;
            justify-content: center;
            margin: 20px 0px ;
        }
    }

    p{
        font-size: 14px;
        font-weight: 500; 
        padding: 0px ;
        margin: 0px ;
        display: flex;
        align-items: center;
        gap: 10px;

        a{
            outline: none;
            text-decoration: none;
            color: ${props => props.theme.colors.font.active};

            :hover{
                text-decoration: underline;
            }
        }
    }

    label{
        font-size: 12px;
        display: flex;
        flex: 1;
        text-align: left;
    }
`;


export const BoxMedias = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 5px;
`

export const Profile = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
`

export const BoxDate = styled.div`
    display: flex;
    color: #c2c2c2;
    margin-top: 10px;
`

export const Avatar = styled.div`
    display: flex;
    
    img{
        width: 55px;
        height: 55px;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid #FFC40088;
        padding: 5px;
    }
`

export const ItemMedia = styled.div`
    display: flex;
    width: 50px;
    height: 50px;
    //background-color: purple;

    img{
        /* width: 50px;
        height: 50px; */
        object-fit: cover;
    }
`



export const SubTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label{
        margin: 0px 10px;
        font-size: 12px;
        display: flex;
    }

    span{
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
`;

export const IconType = styled.div`
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;

    img{
        width: 20px;
        height: 20px;
        object-fit: cover;
    }

`