import { BarLeft, ListCards, BoxControlMobile, NewBarLeft, ItemBarLeft, NewBarLeftTitle, EmBreve, } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { useDispatch } from "react-redux";
import { isVisibleScript } from "../../../../utils/displayScript";
import { useHistory, useLocation } from "react-router-dom";
import { getTranslation } from "../../../../translations";
import { Google, Instagram, NoteText, Youtube } from "iconsax-react";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";
import { useState } from "react";
import { TbBrandTiktok } from "react-icons/tb";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import HelpClick from "../../../../components/HelpClick";

import iconReviews from "../../../../assets/imgs/icons-sidebar/reviews.svg";
import iconQuestions from "../../../../assets/imgs/icons-sidebar/questions.svg";
import iconCupons from "../../../../assets/imgs/icons-sidebar/cupons.svg";
import CardReviews from "../../components/card-reviews";
import CardQuestions from "../../components/card-questions";
import CardDepoimentos from "../../components/card-depoimentos";
import CardCupons from "../../components/card-cupons";
import { BoxMessage, Paginate, PaginateInfor, PaginateNav } from "../../../reviews/pages/home/styled";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const Trash = () => {

    const dispatch = useDispatch()
    const selectedMenu = useLocation();
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(40)
    const reviews = useSelector(state => state.reducerReviews)
    const [checkedAll, setCheckedAll] = useState(false)
    const statusUpdateReview = useSelector(state => state.reducerUpdateReview)
    const statusUpdateQuestion = useSelector(state => state.reducerUpdateQuestion)
    const statusUpdatecollect = useSelector(state => state.reducerUpdateCollect)
    const questions = useSelector(state => state.reducerQuestions)
    const depoimentos = useSelector(state => state.reducerGetCollect)
    const cupons = useSelector(state => state.reducerCupons)

    useEffect(() => {
        if (statusUpdateReview.loading == false && statusUpdateReview.error == false && statusUpdateReview.data.length == 0) {
        } else {
            const id = "statusUpdateReview"
            if (statusUpdateReview.loading) {
                toast.loading(translation.reviews.card.aguarde, { toastId: id })
            } else if (statusUpdateReview.error) {
                toast.update(id, { render: translation.reviews.card.erroEnviar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true })
            } else {
                toast.update(id, { render: translation.reviews.card.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                goSearchReviews()
            }
        }
    }, [statusUpdateReview])

    useEffect(() => {
        if (statusUpdateQuestion.loading == false && statusUpdateQuestion.error == false && statusUpdateQuestion.data.length == 0) {
        } else {
            const id = "statusUpdateQuestion"
            if (statusUpdateQuestion.loading) {
                toast.loading(translation.reviews.card.aguarde, { toastId: id })
            } else if (statusUpdateQuestion.error) {
                toast.update(id, { render: translation.reviews.card.erroEnviar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true })
            } else {
                toast.update(id, { render: translation.reviews.card.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                goSearchQuestions()
            }
        }
    }, [statusUpdateQuestion])

    useEffect(() => {
        if (statusUpdatecollect.loading == false && statusUpdatecollect.error == false && statusUpdatecollect.data.length == 0) {
        } else {
            const id = "statusUpdatecollect"
            if (statusUpdatecollect.loading) {
                toast.loading(translation.reviews.card.aguarde, { toastId: id })
            } else if (statusUpdatecollect.error) {
                toast.update(id, { render: translation.reviews.card.erroEnviar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true })
            } else {
                toast.update(id, { render: translation.reviews.card.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                goSearchDepoimentos()
            }
        }
    }, [statusUpdatecollect])



    const routerBar = () => {
        //if (selectedMenu.pathname == "/lixeira") {
        return (
            <NewBarLeft>

                <NewBarLeftTitle>
                    {"Lixeira"}
                </NewBarLeftTitle>

                <ItemBarLeft active={selectedMenu.pathname == "/lixeira" || selectedMenu.pathname == "/lixeira/avaliacoes" ? true : false} onClick={() => { setPage(1); goSearchReviews(); history.push("/lixeira/avaliacoes") }}>
                    <img src={iconReviews} />
                    <label onClick={() => { setPage(1); goSearchReviews(); history.push("/lixeira/avaliacoes") }}>
                        Avaliações
                    </label>
                </ItemBarLeft>

                <ItemBarLeft active={selectedMenu.pathname == "/lixeira/perguntas" ? true : false}
                    onClick={() => { setPage(1); goSearchQuestions(); history.push("/lixeira/perguntas") }}
                >
                    <img src={iconQuestions} />
                    <label
                        onClick={() => { setPage(1); goSearchQuestions(); history.push("/lixeira/perguntas") }}
                    >
                        Perguntas
                    </label>
                    {/* <EmBreve>{translation.depoimentos.emBreve}</EmBreve> */}
                </ItemBarLeft>

                <ItemBarLeft active={selectedMenu.pathname == "/lixeira/depoimentos" ? true : false}
                    onClick={() => { setPage(1); goSearchDepoimentos(); history.push("/lixeira/depoimentos") }}
                >
                    <NoteText size="55" color="#808080" variant="Outline" />
                    <label
                        onClick={() => { setPage(1); goSearchDepoimentos(); history.push("/lixeira/depoimentos") }}
                    >
                        Depoimentos
                    </label>
                    {/* <EmBreve>{translation.depoimentos.emBreve}</EmBreve> */}
                </ItemBarLeft>

                <ItemBarLeft active={selectedMenu.pathname == "/lixeira/cupons" ? true : false}
                    onClick={() => { setPage(1); goSearchCupons(); history.push("/lixeira/cupons") }}
                >
                    <img src={iconCupons} />
                    <label
                        onClick={() => { setPage(1); goSearchCupons(); history.push("/lixeira/cupons") }}
                    >
                        Cupons
                    </label>
                    <EmBreve>{translation.depoimentos.emBreve}</EmBreve>
                </ItemBarLeft>

            </NewBarLeft>
        )
        //}

    }

    const countItens = () => {

        let result = 0
        if (page == reviews?.data?.meta?.last_page) result = reviews?.data?.meta?.total
        else result = page * perPage
        return result
    }

    const countItensQuestions = () => {

        let result = 0
        if (page == questions?.data?.meta?.last_page) result = questions?.data?.meta?.total
        else result = page * perPage
        return result
    }
    const countItensDepoimentos = () => {

        let result = 0
        if (page == depoimentos?.data?.meta?.last_page) result = depoimentos?.data?.meta?.total
        else result = page * perPage
        return result
    }

    const routerContents = () => {

        switch (selectedMenu.pathname) {
            case "/lixeira":

                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>

                        <ListCards>

                            {reviews?.data?.data?.length != 0 ?
                                reviews?.data?.data?.map((item) => {
                                    return (
                                        <CardReviews item={item} />
                                    )
                                }) :
                                <BoxMessage>
                                    <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                    <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                        {"Não encontramos nenhuma avaliação na lixeira."}
                                    </label>
                                </BoxMessage>
                            }
                        </ListCards>

                        <Paginate style={{ padding: "0px 20px" }}>
                            <PaginateNav>
                                <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                    <MdKeyboardArrowLeft size={35} color={reviews.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                </span>
                                <label>{reviews.data?.meta?.current_page}</label>
                                <span onClick={() => { if (page < reviews.data?.meta?.last_page) setPage(page + 1) }}>
                                    <MdKeyboardArrowRight size={35} color={reviews.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                </span>
                            </PaginateNav>
                            <PaginateInfor>
                                <label>{translation.reviews.total} {countItens()} - {reviews.data?.meta?.total}</label>
                            </PaginateInfor>
                        </Paginate>
                    </div>

                )
            case "/lixeira/avaliacoes":
                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <ListCards>

                            {reviews?.data?.data?.length != 0 ?
                                reviews?.data?.data?.map((item) => {
                                    return (
                                        <CardReviews item={item} />
                                    )
                                }) :
                                <BoxMessage>
                                    <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                    <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                        {"Não encontramos nenhuma avaliação na lixeira."}
                                    </label>
                                </BoxMessage>
                            }
                        </ListCards>

                        <Paginate style={{ padding: "0px 20px" }}>
                            <PaginateNav>
                                <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                    <MdKeyboardArrowLeft size={35} color={reviews.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                </span>
                                <label>{reviews.data?.meta?.current_page}</label>
                                <span onClick={() => { if (page < reviews.data?.meta?.last_page) setPage(page + 1) }}>
                                    <MdKeyboardArrowRight size={35} color={reviews.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                </span>
                            </PaginateNav>
                            <PaginateInfor>
                                <label>{translation.reviews.total} {countItens()} - {reviews.data?.meta?.total}</label>
                            </PaginateInfor>
                        </Paginate>
                    </div>
                )
            case "/lixeira/cupons":
                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <ListCards>
                            {cupons?.data?.data?.length != 0 ?
                                cupons?.data?.data?.map((item) => {
                                    return (
                                        <CardCupons item={item} />
                                    )
                                }) :
                                <BoxMessage>
                                    <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                    <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                        {"Não encontramos nenhuma depoimento na lixeira."}
                                    </label>
                                </BoxMessage>
                            }
                        </ListCards>

                        <Paginate style={{ padding: "0px 20px" }}>
                            <PaginateNav>
                                <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                    <MdKeyboardArrowLeft size={35} color={cupons.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                </span>
                                <label>{cupons.data?.meta?.current_page}</label>
                                <span onClick={() => { if (page < cupons.data?.meta?.last_page) setPage(page + 1) }}>
                                    <MdKeyboardArrowRight size={35} color={cupons.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                </span>
                            </PaginateNav>
                            <PaginateInfor>
                                <label>{translation.reviews.total} {countItensDepoimentos()} - {cupons.data?.meta?.total}</label>
                            </PaginateInfor>
                        </Paginate>
                    </div>
                )
            case "/lixeira/depoimentos":
                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>

                        <ListCards>

                            {depoimentos?.data?.data?.length != 0 ?
                                depoimentos?.data?.data?.map((item) => {
                                    return (
                                        <CardDepoimentos item={item} />
                                    )
                                }) :
                                <BoxMessage>
                                    <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                    <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                        {"Não encontramos nenhuma depoimento na lixeira."}
                                    </label>
                                </BoxMessage>
                            }
                        </ListCards>

                        <Paginate style={{ padding: "0px 20px" }}>
                            <PaginateNav>
                                <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                    <MdKeyboardArrowLeft size={35} color={depoimentos.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                </span>
                                <label>{depoimentos.data?.meta?.current_page}</label>
                                <span onClick={() => { if (page < depoimentos.data?.meta?.last_page) setPage(page + 1) }}>
                                    <MdKeyboardArrowRight size={35} color={depoimentos.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                </span>
                            </PaginateNav>
                            <PaginateInfor>
                                <label>{translation.reviews.total} {countItensDepoimentos()} - {depoimentos.data?.meta?.total}</label>
                            </PaginateInfor>
                        </Paginate>
                    </div>

                )
            case "/lixeira/perguntas":
                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <ListCards>
                            {questions?.data?.data?.length != 0 ?
                                questions?.data?.data?.map((item) => {
                                    return (
                                        <CardQuestions item={item} />
                                    )
                                }) :
                                <BoxMessage>
                                    <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                    <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                        {"Não encontramos nenhuma pergunta na lixeira."}
                                    </label>
                                </BoxMessage>}
                        </ListCards>
                        <Paginate style={{ padding: "0px 20px" }}>
                            <PaginateNav>
                                <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                    <MdKeyboardArrowLeft size={35} color={questions.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                </span>
                                <label>{questions.data?.meta?.current_page}</label>
                                <span onClick={() => { if (page < questions.data?.meta?.last_page) setPage(page + 1) }}>
                                    <MdKeyboardArrowRight size={35} color={questions.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                </span>
                            </PaginateNav>
                            <PaginateInfor>
                                <label>{translation.reviews.total} {countItensQuestions()} - {questions.data?.meta?.total}</label>
                            </PaginateInfor>
                        </Paginate>
                    </div>

                )
            default:
                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <ListCards>

                            {reviews?.data?.data?.length != 0 ?
                                reviews?.data?.data?.map((item) => {
                                    return (
                                        <CardReviews item={item} />
                                    )
                                }) :
                                <BoxMessage>
                                    <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                    <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                        {"Não encontramos nenhuma avaliação na lixeira."}
                                    </label>
                                </BoxMessage>
                            }
                        </ListCards>

                        <Paginate style={{ padding: "0px 20px" }}>
                            <PaginateNav>
                                <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                    <MdKeyboardArrowLeft size={35} color={reviews.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                </span>
                                <label>{reviews.data?.meta?.current_page}</label>
                                <span onClick={() => { if (page < reviews.data?.meta?.last_page) setPage(page + 1) }}>
                                    <MdKeyboardArrowRight size={35} color={reviews.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                </span>
                            </PaginateNav>
                            <PaginateInfor>
                                <label>{translation.reviews.total} {countItens()} - {reviews.data?.meta?.total}</label>
                            </PaginateInfor>
                        </Paginate>
                    </div>
                )
        }
    }


    useEffect(() => {

        if (selectedMenu.pathname == "/lixeira" || selectedMenu.pathname == "/lixeira/avaliacoes") {
            goSearchReviews()
        } else if (selectedMenu.pathname == "/lixeira/perguntas") {
            goSearchQuestions()
        } else if (selectedMenu.pathname == "/lixeira/depoimentos") {
            goSearchDepoimentos()
        } else if (selectedMenu.pathname == "/lixeira/cupons") {
            goSearchCupons()
        }
    }, [page])

    const goSearchReviews = () => {
        dispatch({
            type: "SAGA_LIST_REVIEWS", payload: {
                rating: "",
                status: "",
                search: "",
                perPage: perPage,
                page: page,
                active: 0
            }
        })
    }

    const goSearchQuestions = () => {
        dispatch({
            type: "SAGA_LIST_QUESTIONS", payload: {
                status: "",
                search: "",
                perPage: perPage,
                page: page,
                active: 0
            }
        })
    }

    const goSearchDepoimentos = () => {
        dispatch({
            type: "SAGA_LIST_COLLECT", payload: {
                search: "",
                perPage: perPage,
                page: page,
                active: 0
            }
        })
    }

    const goSearchCupons = () => {
        dispatch({
            type: "SAGA_LIST_CUPONS", payload: {
                search: "",
                perPage: perPage,
                page: page
            }
        })
    }

    // useEffect(() => {
    //     switch (selectedMenu.pathname) {
    //         case "/lixeira/perguntas":
    //             goSearchQuestions()
    //             break;

    //         default:
    //             break;
    //     }
    // }, [selectedMenu.pathname])


    return (
        <Body>

            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >
                <Header />
                <Contents padding={"null"}>
                    <BoxControlMobile active={"" != "" ? true : false}>
                        <BarLeft isFull={false} >
                            {routerBar()}
                        </BarLeft>
                        {reviews.loading || questions.loading || depoimentos.loading || cupons.loading ?
                            <div style={{ display: "flex", flex: 1, height: "100%", justifyContent: "center", alignItems: "center" }}>
                                <LoadingLogoLily />
                            </div> :
                            routerContents()}

                    </BoxControlMobile>

                </Contents>
                {/* {
                    !getCollect.loading &&
                    <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                        <div onClick={() => {
                            //setVisibleModalInforReviews(true)
                        }}>
                            <HelpClick text={translation.alert.depoimentos} />
                        </div>
                    </div>
                } */}
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Trash

