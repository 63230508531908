import React from 'react'
import { Container, ContainerInfor, InforResume, Number } from './styled'
import { AiFillStar } from 'react-icons/ai';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ResumoReviewsModel05 = (props) => {

    const iconStar = { size: 20, color: "orange", colorDisable: "purple" };
    const configControl = useSelector(state => state.reducerControlConfig)




    return (
        <Container>

            <InforResume>
                <ContainerInfor porcent={80} color={configControl.primary_color}>
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <span>
                        <div />
                    </span>
                    <label>{80}</label>
                </ContainerInfor>
                <ContainerInfor porcent={10} color={configControl.primary_color}>
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <span>
                        <div />
                    </span>
                    <label>{10}</label>
                </ContainerInfor>
                <ContainerInfor porcent={5} color={configControl.primary_color}>
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <span>
                        <div />
                    </span>
                    <label>{5}</label>
                </ContainerInfor>
                <ContainerInfor porcent={4} color={configControl.primary_color}>
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <span>
                        <div />
                    </span>
                    <label>{4}</label>
                </ContainerInfor>
                <ContainerInfor porcent={1} color={configControl.primary_color}>
                    <AiFillStar size={20} color={configControl.star_color_primary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <AiFillStar size={20} color={configControl.star_color_secondary} />
                    <span>
                        <div />
                    </span>
                    <label>{1}</label>
                </ContainerInfor>
            </InforResume>
            <Number font={configControl.font} background={configControl.primary_color} color={configControl.font_color_secondary}>
                <span>4.7</span>
                <label>Nota baseada em 100 avaliações.</label>
            </Number>
        </Container>
    )
}

export default ResumoReviewsModel05