import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    min-height: 130px;
    width: 100%;
    //background-color: violet;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    //overflow: hidden;
    border: 1px solid #f1f1f1;
    padding: 16px 8px;
    gap: 8px;

`
export const Delete = styled.div`
    display: flex;
    position: absolute;
    background-color: #F04438;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    top: -9px;
    right: -9px;
    cursor: pointer;
`

export const SectionLeft = styled.div`
    display: flex;
    flex-direction: column;
    flex:1 ;
    //background-color: yellow;
    height: 100%;
    gap: 5px;
`

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 130px;
    //background-color: purple;
`

export const Name = styled.div`
    display: flex;
    color: #101828;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    gap: 10px;

    span{
        display: flex;
        //background-color: red;

        input{
            //background-color: yellow;
            width: 80px;
            font-size: 10px;
        }
    }

    

    input{
        display: flex;
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;

        :hover{
            background-color: #f1f1f1;
        }
    }
`

export const Text = styled.div`
    display: flex;
    color: #667085;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    flex: 1;

    textarea{
        display: flex;
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        flex: 1;
        resize: none;

        :hover{
            background-color: #f1f1f1;
        }
    }
`

export const BoxImages = styled.div`
    display: flex;
    //background-color: red;
    //height: 60px;
    overflow: auto;
    gap: 4px;

`

export const BoxImageAdd = styled.label`
    display: flex;
    //background-color: red;
    height: 60px;
    width: 80px;
    justify-content: center;
    align-items: center;
    border: 1px dashed #A6A6A6;
    border-radius: 8px;
    //cursor: pointer;
`

export const ImageReview = styled.div`
    display: flex;
    min-height: 60px;
    min-width: 80px;
    overflow: auto;
    gap: 4px;
    position: relative;

    span{
        display: flex;
        position: absolute;
        min-height: 60px;
        min-width: 40px;
        background-color: rgba(0, 0, 0, 0.50);
        justify-content: center;
        align-items: center;
        border-radius: 8px 0px 0px 8px;

        img{
            cursor: pointer;
        }

    }

    img{
        min-width: 80px !important;
        max-width: 80px;
        min-height: 60px;
        max-height: 60px;
        object-fit: cover;
        border-radius: 8px;
    }
`

export const BoxStars = styled.div`
    display: flex;
    //background-color: violet;
    flex: 1;
    justify-content: center;
    height: 100%;
    gap: 2px;
    align-items: center;
    margin-top: 5px;

    svg{
        cursor: pointer;
    }
`
