
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AlertSave, Box, FormCustom } from './styled';
import PreviewMural from "../../components/preview-mural";
import ModalInforVideo from '../../../../components/modal-infor-video';
import { getTranslation } from '../../../../translations';
import ModalAlertSave from '../modal-alert-save';
import IconBossSave from '../../../../assets/imgs/boss-save.svg';
const FormTema = () => {

    const [visibleModalInforTema, setVisibleModalInforTema] = useState(false)


    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const edit = useSelector(state => state.reducerControlEdit)
    const dispatch = useDispatch()

    const config = useSelector(state => state.reducerGetConfigMural)
    const configControl = useSelector(state => state.reducerControlConfigMural)
    const reducerControlSaveMuralColor = useSelector(state => state.reducerControlSaveMuralColor)
    const reducerControlSaveMuralConfig = useSelector(state => state.reducerControlSaveMuralConfig)



    return (
        <div style={{ width: "100%" }}>
            <ModalInforVideo visible={visibleModalInforTema} setVisibleModal={setVisibleModalInforTema} video={"https://www.youtube.com/embed/1o8_iwGt7NU"} />

            <FormCustom>
                <Box>
                    <PreviewMural /> 


                    {
                        !reducerControlSaveMuralColor &&
                        <ModalAlertSave>
                            <span>
                                <AlertSave>
                                    <img src={IconBossSave} />
                                    <span>
                                        <label>{translation.thema.alertText}</label>
                                        <div>
                                            <label onClick={() => {
                                                dispatch({ type: "SAGA_GET_CONFIG_MURAL" })
                                                dispatch({ type: "CONFIG_CONTROL_SAVE_MURAL_COLOR_SET", payload: true })
                                            }} >
                                                {translation.thema.alertDescartar}
                                            </label>
                                            <span onClick={() => {

                                                dispatch({
                                                    type: "SAGA_UPDATE_CONFIG_MURAL", payload: {
                                                        id: config.data[0]?.id,
                                                        data_config: JSON.stringify(configControl)
                                                    }
                                                })
                                                dispatch({ type: "CONFIG_CONTROL_SAVE_MURAL_COLOR_SET", payload: true })
                                            }}
                                            >
                                                {translation.thema.alertSalvar}
                                            </span>
                                        </div>
                                    </span>
                                </AlertSave>
                            </span>
                        </ModalAlertSave>
                    }


                    {
                        !reducerControlSaveMuralConfig &&
                        <ModalAlertSave>
                            <span>
                                <AlertSave>

                                    <img src={IconBossSave} />
                                    <span>
                                        <label>{translation.thema.alertText}</label>
                                        <div>
                                            <label onClick={() => {
                                                dispatch({ type: "SAGA_GET_CONFIG_MURAL" })
                                                dispatch({ type: "CONFIG_CONTROL_SAVE_MURAL_CONFIG_SET", payload: true })
                                            }} >
                                                {translation.thema.alertDescartar}
                                            </label>
                                            <span onClick={() => {
                                                dispatch({
                                                    type: "SAGA_UPDATE_CONFIG_MURAL", payload: {
                                                        id: config.data[0]?.id,
                                                        data_config: JSON.stringify(configControl)
                                                    }
                                                })
                                                dispatch({ type: "CONFIG_CONTROL_SAVE_MURAL_CONFIG_SET", payload: true })
                                            }}
                                            >
                                                {translation.thema.alertSalvar}
                                            </span>
                                        </div>
                                    </span>
                                </AlertSave>
                            </span>
                        </ModalAlertSave>
                    }
                </Box>

            </FormCustom>
            <div style={{ display: "flex", justifyContent: "center", }}>
                <label
                    style={{ borderRadius: "30px", padding: "15px 25px", border: "1px solid rgb(241, 241, 241)", fontSize: "14px", marginBottom: "20px" }}
                >{translation.thema.formTema.problemas}
                    <strong onClick={() => { window.open("https://api.whatsapp.com/send?phone=558171033488") }} style={{ marginLeft: "5px", cursor: "pointer" }}>{translation.thema.formTema.cliqueAqui}, </strong>
                </label>
            </div>
        </div >

    )

}
export default FormTema