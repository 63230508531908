import styled from "styled-components";

export const BoxFixxed = styled.div`
    display: none;
    position: absolute !important;
    width: 800px;
    justify-content: center;
    align-items: center;
    left: calc(50vw - 250px);
    height: 80px;

    @media(min-width: 1024px) {
        display: flex;
    }
`

export const BoxFixxedMobile = styled.div`
    display: none;
    position: absolute !important;
    width: calc(100% - 20px);
    margin: 0px 10px;
    height: 80px;
    margin-top: 32px;
    //background-color: yellow;
    @media(max-width: 1023px) {
        display: flex;
    }
    
`