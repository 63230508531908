import React, { useState } from 'react'
import { Avatar, Comment, Container, Date, Delete, Icon, Name, Reviews, Title } from './styled'
import { Star1, Trash } from 'iconsax-react'
import { AiFillStar } from 'react-icons/ai'
import { interval } from '../../../../utils/date'
import { GoLinkExternal } from 'react-icons/go'
import { getTranslation } from '../../../../translations'

const CardGoogle = (props) => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
   

    return (
        <Container>
            <Delete onClick={() => { props.removeReviewsGoogle(props.item) }}>
                <Trash size="16" color="#F13F6C" variant="Bold" />
            </Delete>
            <Reviews>
                <AiFillStar size={16} color={props.item.rating > 0 ? "orange" : "gray"} />
                <AiFillStar size={16} color={props.item.rating > 1 ? "orange" : "gray"} />
                <AiFillStar size={16} color={props.item.rating > 2 ? "orange" : "gray"} />
                <AiFillStar size={16} color={props.item.rating > 3 ? "orange" : "gray"} />
                <AiFillStar size={16} color={props.item.rating > 4 ? "orange" : "gray"} />
            </Reviews>
            <Title>
                <Avatar>
                    <img src={props.item.user.thumbnail} />
                </Avatar>
                <Name>
                    <label>{props.item.user.name}</label>
                    <a>
                        {translation.depoimentos.verPublicacao}
                        <GoLinkExternal onClick={() => { window.open(props?.item?.href, '_blank'); }} style={{ marginLeft: "5px", cursor: "pointer" }} size={14} />
                    </a>
                </Name>
                <Icon>
                    <img src={"https://imagepng.org/wp-content/uploads/2019/08/google-icon-1.png"} />
                </Icon>
            </Title>
            <Comment>
                {props?.item?.snippet}
            </Comment>
            <Date>
                {props?.item?.date}
            </Date>
        </Container>
    )
}

export default CardGoogle