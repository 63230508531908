import React, { useEffect, useState } from 'react'
import { BoxButtons, BoxColors, BoxOptions, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Carrossel, Container, Favoritas, Item, ItemActive, ItemSelect, SelectCustom, Subtitle } from './styled'

import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useDispatch, useSelector } from 'react-redux';

import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';

import { BarSubmenu, BarSubmenuItem, ItemOption, Main, MenuOption, Title } from "../bar-config-product-cores/styled";
import { IoIosArrowBack } from 'react-icons/io';

import { useHistory, useLocation } from 'react-router';
import { InputTextColor } from '../bar-config-email-cores/styled';


const BarConfigPerguntarColor = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const [theme, setTheme] = useState("")

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const save = () => {

        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                screen_question_color_primary: configControl.screen_question_color_primary,
                screen_question_color_font: configControl.screen_question_color_font,
                screen_question_color_font_secondary: configControl.screen_question_color_font_secondary,
                screen_question_color_secondary: configControl.screen_question_color_secondary,
            }
        })

    }

    const getDefault = () => {
        const themaDefault = {
            id: config.data.id,
            screen_question_color_primary: "#FFFFFF",
            screen_question_color_font: "#212121",
            screen_question_color_font_secondary: "#FFFFFF",
            screen_question_color_secondary: "#212121"
        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })
    }


    useEffect(() => {

        switch (theme) {
            case "dark":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        screen_question_color_primary: "#000000",
                        screen_question_color_font: "#FFFFFF",
                        screen_question_color_font_secondary: "#212121",
                        screen_question_color_secondary: "#F1F1F1"
                    }
                })
                break;
            case "light":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        screen_question_color_primary: "#FFFFFF",
                        screen_question_color_font: "#212121",
                        screen_question_color_font_secondary: "#FFFFFF",
                        screen_question_color_secondary: "#212121"
                    }
                })
                break;
            default:
                break;
        }
    }, [theme])

    useEffect(() => {
        if (
            configControl.screen_question_color_primary == "#FFFFFF" &&
            configControl.screen_question_color_font == "#212121" &&
            configControl.screen_question_color_font_secondary == "#FFFFFF" &&
            configControl.screen_question_color_secondary == "#212121"

        ) {
            setTheme("light")
        }
        else if (
            configControl.screen_question_color_primary == "#000000" &&
            configControl.screen_question_color_font == "#FFFFFF" &&
            configControl.screen_question_color_font_secondary == "#212121" &&
            configControl.screen_question_color_secondary == "#F1F1F1"

        ) {
            setTheme("dark")
        }
        else {
            setTheme("custom")
        }

    }, [configControl])



    return (
        <Main >
            <Container
                visible={true}
            >

                <Title>
                    <span onClick={() => {
                        history.push("/personalizar")
                        //isSaveHomeColor() && history.push("/personalizar")
                    }}>
                        <IoIosArrowBack />
                    </span>
                    <label>
                        {translation.thema.telaPerguntar}
                        {/* {translation.thema.homepage.title} */}
                    </label>
                </Title>

                <MenuOption>
                    <ItemOption active={true} onClick={() => {
                        dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "perguntar/cores" })
                        //isSaveHomeColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/cores" })
                    }}>
                        {translation.thema.homepage.cores}
                    </ItemOption>
                    <ItemOption active={false} onClick={() => {
                        dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "perguntar/config" })
                        //isSaveHomeColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/config" })
                    }}>
                        {translation.thema.homepage.avancado}
                    </ItemOption>
                </MenuOption>

                <BoxTema>
                    <label>{translation.thema.boxConfigEmail.combinacaoCores}:</label>
                    <SelectCustom onChange={(e) => { setTheme(e.target.value) }}>
                        <option value="light" selected={theme == "light" ? true : false}>Light</option>
                        <option value="dark" selected={theme == "dark" ? true : false}>Dark</option>
                        <option disabled value="custom" selected={theme == "custom" ? true : false}>{translation.thema.boxConfigEmail.personalizado}</option>
                    </SelectCustom>
                </BoxTema>

                <BoxColors>
                    <label>Widget:</label>
                    <Item>
                        <label>{translation.thema.boxConfigTema.fundo}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.screen_question_color_primary}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_color_primary: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.screen_question_color_primary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_color_primary: e.target.value } }) }} />
                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigTema.fundoAux}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.screen_question_color_secondary}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_color_secondary: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.screen_question_color_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_color_secondary: e.target.value } }) }} />

                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigTema.fonte}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.screen_question_color_font}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_color_font: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.screen_question_color_font} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_color_font: e.target.value } }) }} />

                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigTema.fonteAux}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.screen_question_color_font_secondary}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_color_font_secondary: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.screen_question_color_font_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_color_font_secondary: e.target.value } }) }} />
                        </span>
                    </Item>
                </BoxColors>

                <ButtonHelp>
                    <img src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() =>
                        getDefault()
                        //alert("teste")
                    }>
                        <label>{translation.thema.boxConfigEmail.restaurar}</label>
                    </ButtonReset>
                    <ButtonSave onClick={() =>
                        save()}
                    //alert("teste")}
                    >
                        {translation.thema.boxConfigEmail.salvar}
                    </ButtonSave>
                </BoxButtons>

            </Container>
        </Main>

    )
}

export default BarConfigPerguntarColor