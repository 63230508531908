
export const isVisibleScript = (value) => {


    if (value) {
        if (document.getElementById('wa__btn_popup_chat') != null) {
            document.getElementById('wa__btn_popup_chat').style.visibility = "visible";
        }
        if (document.getElementById('productstashSelector') != null) {
            document.getElementById('productstashSelector').style.visibility = "hidden";
        }
    } else {
        if (document.getElementById('wa__btn_popup_chat') != null) {
            document.getElementById('wa__btn_popup_chat').style.visibility = "hidden";
        }
        if (document.getElementById('productstashSelector') != null) {
            document.getElementById('productstashSelector').style.visibility = "hidden";
        }
    }

    // if (
    //     window.document.location.pathname === "/login" ||
    //     window.document.location.pathname === "/cadastro" ||
    //     window.document.location.pathname === "/senha" ||
    //     window.document.location.pathname === "/wix"
    // ) {
    //     document.getElementsByClassName("empreender-tv-main-content")[0].style.display = "none";
    // } else {

    //     window.document.location.pathname === "/senha"
    // ) {
    //     document.getElementsByClassName("empreender-tv-main-content")[0].style.display = "none";
    // }else{

    //     document.getElementsByClassName("empreender-tv-main-content")[0].style.display = "initial";
    // }

}

