import { all, takeLatest } from "redux-saga/effects";

import { logar, concluirLogarPlus, register, password } from "../modules/login/redux/sagas/sagaLogin";
import { integrate, integrationWix, platforms, deleteIntegration } from "../modules/integrations/redux/sagas/sagaIntegration";
import { listReviews, updateReview, deleteReview, deleteSelectReviews, updateSelectReviews, updateReviewFull } from "../modules/reviews/redux/sagas/sagaReviews";
import { listQuestions, updateQuestion, deleteQuestion, addReplies, updateReplies, updateSelectQuestions, deleteSelectQuestions } from "../modules/questions/redux/sagas/sagaQuestion";
import { listCupons, updateCupom, deleteCupom, addCupom, deleteSelectCupons } from "../modules/cupons/redux/sagas/sagaCupom";
import { listPayments } from "../modules/assinatura/redux/sagas/sagaPayment";
import { updateAccount, deleteAccount, } from "../modules/account/redux/sagas/sagaAccount";
import { getInfor } from "../modules/dashboard/redux/sagas/sagaDashboard";
import { getList, deleteCard, debug, dropi, addMonths, addXpatch } from "../modules/admin/redux/sagas/sagaAdmin";
import { updateConfig, updateConfigHome, getConfig, sendEmail, updateConfigHomePosition, changeStatusGoogle, updateConfigMural, getConfigMural, scriptIntegration } from "../modules/thema/redux/sagas/sagaConfig";
import { getInforAccount, listNotify } from "../components/Header/redux/sagas/sagaNotify";
import { searchPlatformSelectedProducts,listProducts, updateProduct, deleteProduct, addProduct, deleteSelectProducts, updateDataProduct, importShopee, saveImportShopee, importShopeeMore, listProductsImport, searchPlatformAllProducts } from "../modules/products/redux/sagas/sagaProduct";
import { getTokenNuvemshop } from "../routers/redux/sagas/sagaToken";

import { listOrderProducts, listOrders, reSendEmailOrders, reSendWhatsOrders, updateEmailOrders } from "../modules/orders/redux/sagas/sagaOrders";

import { listCollects, listBusinessCache, CollectGoogle, addCollect, searchBusinessGoogle, deleteCollect, updateCollect, CollectGooglePaginate } from "../modules/collect/redux/sagas/sagaCollect";



export default function* rootSagas() {

    yield all([
        takeLatest('SAGA_LOGAR_LOGIN', logar),
        takeLatest('SAGA_LOGAR_PLUS_CONCLUIR', concluirLogarPlus),
        takeLatest('SAGA_REGISTER_LOGIN', register),
        takeLatest('SAGA_PASSWORD_LOGIN', password),

        takeLatest('SAGA_INTEGRATE_INTEGRATION', integrate),
        takeLatest('SAGA_INTEGRATE_WIX_INTEGRATION', integrationWix),
        takeLatest('SAGA_GET_PLATFORMS_INTEGRATION', platforms),
        takeLatest('SAGA_DELETE_INTEGRATION', deleteIntegration),

        takeLatest('SAGA_LIST_REVIEWS', listReviews),
        takeLatest('SAGA_UPDATE_REVIEWS', updateReview),
        takeLatest('SAGA_UPDATE_FULL_REVIEWS', updateReviewFull),
        takeLatest('SAGA_DELETE_REVIEWS', deleteReview),
        takeLatest('SAGA_DELETE_SELECT_REVIEWS', deleteSelectReviews),
        takeLatest('SAGA_UPDATE_SELECT_REVIEWS', updateSelectReviews),

        takeLatest('SAGA_LIST_QUESTIONS', listQuestions),
        takeLatest('SAGA_UPDATE_QUESTIONS', updateQuestion),
        takeLatest('SAGA_DELETE_QUESTIONS', deleteQuestion),
        takeLatest('SAGA_ADD_REPLIES', addReplies),
        takeLatest('SAGA_UPDATE_REPLIES', updateReplies),
        takeLatest('SAGA_DELETE_SELECT_QUESTION', deleteSelectQuestions),
        takeLatest('SAGA_UPDATE_SELECT_QUESTION', updateSelectQuestions),

        takeLatest('SAGA_LIST_CUPONS', listCupons),
        takeLatest('SAGA_UPDATE_CUPOM', updateCupom),
        takeLatest('SAGA_DELETE_CUPOM', deleteCupom),
        takeLatest('SAGA_ADD_CUPOM', addCupom),
        takeLatest('SAGA_DELETE_SELECT_CUPONS', deleteSelectCupons),

        takeLatest('SAGA_LIST_PAYMENTS', listPayments),

        takeLatest('SAGA_UPDATE_ACCOUNT', updateAccount),
        takeLatest('SAGA_DELETE_ACCOUNT', deleteAccount),

        takeLatest('SAGA_INFOR_DASHBOARD', getInfor),

        takeLatest('SAGA_LIST_ADMIN', getList),
        takeLatest('SAGA_DELETE_CARD_ADMIN', deleteCard),
        takeLatest('SAGA_DEBUG_ADMIN', debug),
        takeLatest('SAGA_ADD_TIME_ADMIN', addMonths),
        takeLatest('SAGA_DROPI_ADMIN', dropi),
        takeLatest('SAGA_XPATCH_ADMIN', addXpatch),

        takeLatest('SAGA_SCRIPT_INTEGRATION', scriptIntegration),

        takeLatest('SAGA_GET_CONFIG', getConfig),
        takeLatest('SAGA_UPDATE_CONFIG', updateConfig),
        takeLatest('SAGA_UPDATE_CONFIG_HOME', updateConfigHome),
        takeLatest('SAGA_SEND_EMAIL', sendEmail),
        takeLatest('SAGA_UPDATE_CONFIG_HOME_POSITION', updateConfigHomePosition),

        takeLatest('SAGA_GET_CONFIG_MURAL', getConfigMural),
        takeLatest('SAGA_UPDATE_CONFIG_MURAL', updateConfigMural),

        takeLatest('SAGA_CHANGE_STATUS_GOOGLE', changeStatusGoogle),

        takeLatest('SAGA_LIST_NOTIFY', listNotify),

        takeLatest('SAGA_LIST_PRODUCTS', listProducts),
        takeLatest('SAGA_LIST_PRODUCTS_IMPORT', listProductsImport),
        takeLatest('SAGA_UPDATE_PRODUCT', updateProduct),
        takeLatest('SAGA_UPDATE_DATA_PRODUCT', updateDataProduct),
        takeLatest('SAGA_DELETE_PRODUCT', deleteProduct),
        takeLatest('SAGA_ADD_PRODUCT', addProduct),
        takeLatest('SAGA_DELETE_SELECT_PRODUCTS', deleteSelectProducts), 
        takeLatest("SAGA_SEARCH_PLATFORM_ALL_PRODUCTS", searchPlatformAllProducts),
        takeLatest("SAGA_SEARCH_PLATFORM_SELECTED_PRODUCTS", searchPlatformSelectedProducts), 
 
        takeLatest('SAGA_IMPORT_SHOPEE_PRODUCTS', importShopee),
        takeLatest('SAGA_IMPORT_SHOPEE_MORE_PRODUCTS', importShopeeMore),
        takeLatest('SAGA_SAVE_IMPORT_SHOPEE_PRODUCTS', saveImportShopee),

        takeLatest('SAGA_GET_TOKEN_NUVEMSHOP', getTokenNuvemshop),

        takeLatest('SAGA_GET_INFOR_ACCOUNT', getInforAccount),

        takeLatest('SAGA_LIST_ORDERS', listOrders),
        takeLatest('SAGA_LIST_ORDER_PRODUCTS', listOrderProducts),
        takeLatest('SAGA_RESEND_EMAIL_ORDERS', reSendEmailOrders),
        takeLatest('SAGA_RESEND_WHATS_ORDERS', reSendWhatsOrders),
        takeLatest('SAGA_UPDATE_EMAIL_ORDERS', updateEmailOrders),

        takeLatest('SAGA_LIST_COLLECT', listCollects),
        takeLatest('SAGA_SEARCH_BUSINESS_GOOGLE', searchBusinessGoogle),
        takeLatest('SAGA_COLLECT_GOOGLE', CollectGoogle),
        takeLatest('SAGA_ADD_COLLECT', addCollect),
        takeLatest('SAGA_UPDATE_COLLECT', updateCollect),
        takeLatest('SAGA_DELETE_COLLECT', deleteCollect),
        takeLatest('SAGA_LIST_BUSINESS_REF_COLLECT', listBusinessCache),
        takeLatest('SAGA_COLLECT_PAGINATE_GOOGLE', CollectGooglePaginate),

    ])
}