import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    gap: 10px;
    position: relative;
    //background-color: red;
    //min-width: 100%;
`

export const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`
export const Comment = styled.div`
    display: flex;
    font-size: 14px;
`
export const Date = styled.div`
    display: flex;
    font-size: 12px;
`
export const Avatar = styled.div`
    display: flex;

    img{
        width: 55px;
        height: 55px;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid #FFC40088;
        padding: 5px;
    }
`

export const Name = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    //background-color: red;

    label{
        font-weight: 600;
    }

    a{
        font-size: 12px;
    }
`

export const Icon = styled.div`
    display: flex;

    img{
        width: 20px;
        height: 20px;
    }
`

export const Delete = styled.div`
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`
export const Reviews = styled.div`
    display: flex;
    gap: 1px;

`