import { useSelector } from "react-redux";
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE } from "../../../../services/api";

export function* getInfor(action) {

    yield put({ type: "DASHBOARD_REQUEST", })
    //yield delay(5000)
    const result = yield call(GET, { endpoint: `/dashboard/counts` })
    if (result === false) {
        yield put({ type: "DASHBOARD_ERROR" })
    } else {
        yield put({ type: "DASHBOARD_SUCCESS", payload: result.data.data })
    }
}




