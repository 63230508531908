import React, { useState } from 'react'
import { BoxButtons, BoxColors, BoxOptions, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Container, Efeito, EstiloWidget, Item, ItemLayout, ItemSelect, Layout, Posicao, SelectCustom, Subtitle, Titulo } from './styled'

import IconReset from "../../../../assets/imgs/icon-reset.svg";
import IconCamera from "../../../../assets/imgs/icon-camera.svg";
import IconSeta from "../../../../assets/imgs/seta-back.svg";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useDispatch, useSelector } from 'react-redux';
import { TextAreaCustom } from '../form-email/styled';
import { CounterItemsCircle } from '../box-config-tema/styled';
import { BoxLoadingCustom, InputFileCustomTeste, InputTextAreaCustom, InputTextCustom } from '../box-config-email/styled';
import { useEffect } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { FaUpload } from 'react-icons/fa';
import imageCompression from 'browser-image-compression';

import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';
import { BarSubmenu, BarSubmenuItem, ItemOption, Main, MenuOption, Title } from '../bar-config-product-cores/styled';
import { IoIosArrowBack } from 'react-icons/io';


import IconText from "../../../../assets/imgs/icon-text.svg";
import IconColorText from "../../../../assets/imgs/icon-color-text.svg";
import IconLayout from "../../../../assets/imgs/icon-layout.svg";
import IconPosicao from "../../../../assets/imgs/icon-posicao.svg";
import IconEstiloWidget from "../../../../assets/imgs/icon-estilo-widget.svg";
import IconVideoHome from "../../../../assets/imgs/icon-video-home.svg";
import IconEfeito from "../../../../assets/imgs/icon-efeito.svg";
import { Carrossel, Favoritas, ItemActive } from '../bar-config-home-habilitar/styled';

import IconBoxDesktop from "../../../../assets/imgs/icon-box-desktop.svg";
import IconGridDesktop from "../../../../assets/imgs/icon-grid-desktop.svg";
import IconGridMobile from "../../../../assets/imgs/icon-grid-mobile.svg";
import IconLineDesktop from "../../../../assets/imgs/icon-line-desktop.svg";
import IconLineMobile from "../../../../assets/imgs/icon-line-mobile.svg";

import IconScroll from "../../../../assets/imgs/icon-scroll.svg";

import IconLink from "../../../../assets/imgs/icon-link.svg";
import IconSlider from "../../../../assets/imgs/icon-slider-home.svg";
import IconFavorite from "../../../../assets/imgs/icon-coracao.svg";


import IconImgProduct from "../../../../assets/imgs/icons-sidebar/submenu-product.svg";
import IconImgProductInativo from "../../../../assets/imgs/icons-sidebar/submenu-product-inativo.svg";
import IconImgEmail from "../../../../assets/imgs/icons-sidebar/submenu-email-inativo.svg";
import IconImgEmailAtivo from "../../../../assets/imgs/icons-sidebar/submenu-email-ativo.svg";
import IconImgHome from "../../../../assets/imgs/icon-homepage.svg";
import IconImgHomeAtivo from "../../../../assets/imgs/icon-homepage-active.svg";
import IconImgTeste from "../../../../assets/imgs/teste-00.svg";
import { useHistory, useLocation } from 'react-router';
import { Tooltip } from '../bar-config-product-ajustes/styled';
import { InputCustom } from '../bar-config-avaliar-config/styled';

const BarConfigHomeConfig = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const selectedMenu = useLocation();
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);




    const saveTheme = () => {

        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                homereviews: configControl.homereviews ? 1 : 0,
                editor: configControl.editor ? 1 : 0,

                homereviews_title: configControl.homereviews_title,
                homereviews_widget_style: configControl.homereviews_widget_style,
                homereviews_animation: configControl.homereviews_animation,
                homereviews_font_title: configControl.homereviews_font_title,
                homereviews_font_size_title: configControl.homereviews_font_size_title,
                homereviews_align_title: configControl.homereviews_align_title,
                homereviews_card: configControl.homereviews_card,
                homereviews_radius_border_card: configControl.homereviews_radius_border_card,
                homereviews_size_border_card: configControl.homereviews_size_border_card,
                homereviews_visible_verified_card: configControl.homereviews_visible_verified_card,

                homereviews_navigation: configControl.homereviews_navigation,
                homereviews_progress: configControl.homereviews_progress,
                homereviews_pagination: configControl.homereviews_pagination,
                homereviews_loop: configControl.homereviews_loop,

                // homereviews_background_color: configControl.homereviews_background_color,
                // homereviews_arrow_color: configControl.homereviews_arrow_color,
                // homereviews_step_color: configControl.homereviews_arrow_color,
                // homereviews_title: configControl.homereviews_color_title,

            }
        })

    }

    const getDefaultTheme = () => {
        const themaDefault = {
            id: config.data.id,

            homereviews: 0,
            editor: 0,

            homereviews_title: "Avaliações dos clientes",
            homereviews_widget_style: "grid",
            homereviews_animation: 1,
            homereviews_font_title: "Poppins",
            homereviews_font_size_title: "22px",
            homereviews_align_title: "center",
            homereviews_size_border_card: "0px",
            homereviews_visible_verified_card: "1",
            homereviews_radius_border_card: "8px",

            homereviews_navigation: 0,
            homereviews_progress: 0,
            homereviews_pagination: 0,
            homereviews_loop: 1,

            // homereviews_background_color: "#ffffff",
            // homereviews_arrow_color: "#161718",
            // homereviews_step_color: "#161718",
            // homereviews_color_title: "#000000",

        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })

    }

    const isSaveHomeConfig = () => {


        if (
            configControl.homereviews == config.data.homereviews &&
            configControl.homereviews_title == config.data.homereviews_title &&
            configControl.homereviews_animation == config.data.homereviews_animation &&
            configControl.homereviews_widget_style == config.data.homereviews_widget_style &&

            configControl.homereviews_align_title == config.data.homereviews_align_title &&
            configControl.homereviews_font_size_title == config.data.homereviews_font_size_title &&
            configControl.homereviews_font_title == config.data.homereviews_font_title &&

            configControl.homereviews_radius_border_card == config.data.homereviews_radius_border_card &&
            configControl.homereviews_visible_verified_card == config.data.homereviews_visible_verified_card &&
            configControl.homereviews_size_border_card == config.data.homereviews_size_border_card &&

            configControl.homereviews_navigation == config.data.homereviews_navigation &&
            configControl.homereviews_progress == config.data.homereviews_progress &&
            configControl.homereviews_pagination == config.data.homereviews_pagination &&
            configControl.homereviews_loop == config.data.homereviews_loop
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_CONFIG_SET", payload: false })
            return false
        }
    }

    const linkPosition = () => {

        if (JSON.parse(localStorage.getItem("depoimentos@login")).integration?.url != null)
            window.open(`https://${JSON.parse(localStorage.getItem("depoimentos@login")).integration.url}?editor_lily=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}&language=${getLanguage()}`)

    }

    const getLanguage = () => {
        if (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es") {
            return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language + "-mx"
        } else {
            return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
        }
    }

    return (
        <Main>
            {/* <BarSubmenu>
                <BarSubmenuItem
                    active={false}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveHomeConfig() && history.push("/personalizar/tema") }}
                >
                    {selectedMenu.pathname.slice(13) == "/" || selectedMenu.pathname.slice(13) == "" || selectedMenu.pathname.slice(13) == "/tema" ?
                        <img width={24} src={IconImgProduct} /> :
                        <img width={24} src={IconImgProductInativo} />}

                    <Tooltip>
                        Página do produto
                    </Tooltip>

                </BarSubmenuItem>
                <BarSubmenuItem
                    active={false}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveHomeConfig() && history.push("/personalizar/email") }}
                >
                    {selectedMenu.pathname.slice(13) == "/email" ?
                        <img width={24} src={IconImgEmailAtivo} /> :
                        <img width={24} src={IconImgEmail} />}

                    <Tooltip>
                        E-mail
                    </Tooltip>

                </BarSubmenuItem>


                <BarSubmenuItem
                    active={true}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveHomeConfig() && history.push("/personalizar/home") }}
                >
                    {selectedMenu.pathname.slice(13) == "/home" ?
                        <img width={24} src={IconImgHomeAtivo} /> :
                        <img width={24} src={IconImgHome} />}

                    <Tooltip>
                        Página do inicial
                    </Tooltip>
                </BarSubmenuItem>


            </BarSubmenu> */}

            <Container>
                <Title>
                    <span onClick={() => { isSaveHomeConfig() && history.push("/personalizar") }}>
                        <IoIosArrowBack />
                    </span>
                    <label>{translation.thema.homepage.title}</label>
                </Title>

                <MenuOption>
                    <ItemOption active={false} onClick={() => { isSaveHomeConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/cores" }) }}>
                        {translation.thema.homepage.cores}
                    </ItemOption>
                    <ItemOption active={true} onClick={() => { dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/config" }) }}>
                        {translation.thema.homepage.avancado}
                    </ItemOption>

                </MenuOption>

                <BoxOptions>
                    <Carrossel>
                        <span>
                            <div>
                                <img width={20} src={IconSlider} />
                                <label>{translation.thema.boxConfigHome.ativar}</label>
                            </div>
                            <ItemActive onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews: !configControl.homereviews } }) }} active={configControl.homereviews == 1 ? true : false}>
                                <span />
                            </ItemActive>
                        </span>
                        <label>{translation.thema.boxConfigHome.ativarDesc}</label>
                    </Carrossel>
                </BoxOptions>

                {/* <BoxOptions>
                    <EstiloWidget>
                        <span>
                            <div>
                                <img src={IconVideoHome} />
                                <label> {translation.thema.boxConfigHome.animarAvaliacoes}</label>
                            </div>
                            <ItemActive onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_animation: !configControl.homereviews_animation } }) }} active={configControl.homereviews_animation == 1 ? true : false}>
                                <span />
                            </ItemActive>
                        </span>
                        <label>
                            {translation.thema.boxConfigHome.AnimarAvaliacoesDesc}
                        </label>
                    </EstiloWidget>
                </BoxOptions> */}

                <BoxOptions>
                    <Posicao>
                        <div>
                            <div onClick={() => { linkPosition() }}>
                                <label onClick={() => { linkPosition() }} >
                                    {translation.thema.boxConfigHome.btnEditor}
                                </label>
                                <img onClick={() => { linkPosition() }} src={IconLink} />
                            </div>
                        </div>
                        <label>{translation.thema.boxConfigHome.editorDesc}</label>
                    </Posicao>
                </BoxOptions>

                {/* <BoxOptions>
                    <Favoritas>
                        <span>
                            <img width={20} src={IconFavorite} />
                            <label>AVALIAÇÕES FAVORITAS</label>
                        </span>
                        <label>
                            Escolha suas melhores avaliações para mostrar na página inicial da sua loja.
                        </label>
                        <div>
                            <div>
                                <label onClick={() => { history.push("/avaliacoes") }} >Escolher</label>
                                <img src={IconLink} />
                            </div>

                        </div>
                    </Favoritas>
                </BoxOptions> */}

                <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <img width={20} src={IconText} />
                                <label> {translation.thema.boxConfigHome.titulo.toUpperCase()}</label>
                            </div>
                        </span>
                        <input
                            type={"text"}
                            value={configControl.homereviews_title == null || configControl.homereviews_title == "null" ? "" : configControl.homereviews_title}
                            placeholder={"Título do widget"}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_title: e.target.value } }) }}
                        />
                    </Titulo>

                    <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_font_title: e.target.value } }) }}>
                        <option value={"Poppins"} selected={configControl.homereviews_font_title == "Poppins" ? true : false}>Poppins</option>
                        <option value={"Times New Roman"} selected={configControl.homereviews_font_title == "Times New Roman" ? true : false}>Times New Roman</option>
                        <option value={"Arial"} selected={configControl.homereviews_font_title == "Arial" ? true : false}>Arial</option>
                        <option value={"Courier New"} selected={configControl.homereviews_font_title == "Courier New" ? true : false}>Courier New</option>
                        <option value={"Georgia"} selected={configControl.homereviews_font_title == "Georgia" ? true : false}>Georgia</option>
                        <option value={"Oswald"} selected={configControl.homereviews_font_title == "Oswald" ? true : false}>Oswald</option>
                        <option value={"Montserrat"} selected={configControl.homereviews_font_title == "Montserrat" ? true : false}>Montserrat</option>
                        <option value={"Roboto"} selected={configControl.homereviews_font_title == "Roboto" ? true : false} >Roboto</option>
                    </SelectCustom>

                    <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_font_size_title: e.target.value } }) }}>
                        <option value={"16px"} selected={configControl.homereviews_font_size_title == "16px" ? true : false}>16px</option>
                        <option value={"18px"} selected={configControl.homereviews_font_size_title == "18px" ? true : false}>18px</option>
                        <option value={"20px"} selected={configControl.homereviews_font_size_title == "20px" ? true : false}>20px</option>
                        <option value={"22px"} selected={configControl.homereviews_font_size_title == "22px" ? true : false}>22px</option>
                        <option value={"24px"} selected={configControl.homereviews_font_size_title == "24px" ? true : false}>24px</option>
                        <option value={"26px"} selected={configControl.homereviews_font_size_title == "26px" ? true : false}>26px</option>
                        <option value={"28px"} selected={configControl.homereviews_font_size_title == "28px" ? true : false}>28px</option>
                        <option value={"30px"} selected={configControl.homereviews_font_size_title == "30px" ? true : false}>30px</option>
                        <option value={"32px"} selected={configControl.homereviews_font_size_title == "32px" ? true : false}>32px</option>
                        <option value={"34px"} selected={configControl.homereviews_font_size_title == "34px" ? true : false}>34px</option>
                        <option value={"36px"} selected={configControl.homereviews_font_size_title == "36px" ? true : false}>36px</option>
                        <option value={"38px"} selected={configControl.homereviews_font_size_title == "38px" ? true : false}>38px</option>
                        <option value={"40px"} selected={configControl.homereviews_font_size_title == "40px" ? true : false}>40px</option>
                        <option value={"42px"} selected={configControl.homereviews_font_size_title == "42px" ? true : false}>42px</option>
                    </SelectCustom>

                    <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_align_title: e.target.value } }) }}>
                        <option value={"center"} selected={configControl?.homereviews_align_title == "center" ? true : false}>{translation.thema.configs.centralizado}</option>
                        <option value={"left"} selected={configControl?.homereviews_align_title == "left" ? true : false}>{translation.thema.configs.alinhadoEsquerda}</option>
                    </SelectCustom>
                </BoxOptions>

                <BoxOptions>
                    <EstiloWidget>
                        <span>
                            <div>
                                <img src={IconEstiloWidget} />
                                <label> {translation.thema.boxConfigHome.estiloWidget}</label>
                            </div>
                        </span>

                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_widget_style: e.target.value } }) }}>
                            <option value={"grid"} selected={configControl.homereviews_widget_style == "grid" ? true : false}>Modelo 01</option>
                            <option value={"carousel"} selected={configControl.homereviews_widget_style == "carousel" ? true : false}>Modelo 02</option>
                            <option value={"carousel-multi"} selected={configControl.homereviews_widget_style == "carousel-multi" ? true : false}>Modelo 03</option>
                        </SelectCustom>

                        {configControl.homereviews_widget_style == "carousel-multi" || configControl.homereviews_widget_style == "carousel" ?
                            <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_animation: e.target.value } }) }}>
                                <option value={"1"} selected={configControl.homereviews_animation == "1" ? true : false}>{translation.thema.configs.comAnimacao}</option>
                                <option value={"0"} selected={configControl.homereviews_animation == "0" ? true : false}>{translation.thema.configs.semAnimacao}</option>
                            </SelectCustom> : null}

                        {configControl.homereviews_widget_style == "carousel-multi" || configControl.homereviews_widget_style == "carousel" ?
                            <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_navigation: e.target.value } }) }}>
                                <option value={"1"} selected={configControl.homereviews_navigation == "1" ? true : false}>{translation.thema.configs.comNavegacao}</option>
                                <option value={"0"} selected={configControl.homereviews_navigation == "0" ? true : false}>{translation.thema.configs.semNavegacao}</option>
                            </SelectCustom> : null}

                        {configControl.homereviews_widget_style == "carousel-multi" || configControl.homereviews_widget_style == "carousel" ?
                            <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_pagination: e.target.value } }) }}>
                                <option value={"1"} selected={configControl.homereviews_pagination == "1" ? true : false}>{translation.thema.configs.comPaginacao}</option>
                                <option value={"0"} selected={configControl.homereviews_pagination == "0" ? true : false}>{translation.thema.configs.semPaginacao}</option>
                            </SelectCustom> : null}

                        {/* {configControl.homereviews_widget_style == "carousel-multi" || configControl.homereviews_widget_style == "carousel" ?
                            <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_progress: e.target.value } }) }}>
                                <option value={"1"} selected={configControl.homereviews_progress == "1" ? true : false}>Com progresso</option>
                                <option value={"0"} selected={configControl.homereviews_progress == "0" ? true : false}>Sem progresso</option>
                            </SelectCustom> : null} */}

                        {configControl.homereviews_widget_style == "carousel-multi" || configControl.homereviews_widget_style == "carousel" ?
                            <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_loop: e.target.value } }) }}>
                                <option value={"1"} selected={configControl.homereviews_loop == "1" ? true : false}>{translation.thema.configs.comLoop}</option>
                                <option value={"0"} selected={configControl.homereviews_loop == "0" ? true : false}>{translation.thema.configs.semLoop}</option>
                            </SelectCustom> : null}

                        {/* <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_widget_style: e.target.value } }) }}>
                            <option value={"modelo01"} selected={configControl.homereviews_widget_style == "modelo01" ? true : false}>Seta</option>
                            <option value={"modelo02"} selected={configControl.homereviews_widget_style != "modelo01" ? true : false}>Nenhum</option>
                        </SelectCustom>

                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_widget_style: e.target.value } }) }}>
                            <option value={"modelo01"} selected={configControl.homereviews_widget_style == "modelo01" ? true : false}>Paginação</option>
                            <option value={"modelo02"} selected={configControl.homereviews_widget_style != "modelo01" ? true : false}>Nenhum</option>
                        </SelectCustom> */}

                        {/* <ItemSelect>
                            <label style={{fontWeight: "bold", fontSize: "11px"}}>Máximo largura {"(px)"}</label>
                            <InputCustom
                                type={"number"}
                                value={"1024"}
                                placeholder={"Largura do widget"}
                                onChange={(e) => {
                                    //dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_config_title_comment: e.target.value } })
                                }}
                            />
                        </ItemSelect> */}

                    </EstiloWidget>
                </BoxOptions>

                <BoxOptions>
                    <EstiloWidget>
                        <span>
                            <div>
                                <img src={IconEstiloWidget} />
                                <label> {translation.thema.configs.card}</label>
                            </div>
                        </span>

                        {configControl.homereviews_widget_style == "grid" &&
                            <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_card: e.target.value } }) }}>
                                <option value="dropi"
                                    selected={
                                        configControl.homereviews_card != "dropi2" &&
                                            configControl.homereviews_card != "dropi3" &&
                                            configControl.homereviews_card != "lily" &&
                                            configControl.homereviews_card != "lily2" &&
                                            configControl.homereviews_card != "lily3" ?
                                            true : false}>Modelo 01</option>
                                <option value="dropi2" selected={configControl.homereviews_card == "dropi2" ? true : false}>Modelo 02</option>
                                <option value="dropi3" selected={configControl.homereviews_card == "dropi3" ? true : false}>Modelo 03</option>
                                <option value="lily" selected={configControl.homereviews_card == "lily" ? true : false}>Modelo 04</option>
                                <option value="lily2" selected={configControl.homereviews_card == "lily2" ? true : false}>Modelo 05</option>
                                <option value="lily3" selected={configControl.homereviews_card == "lily3" ? true : false}>Modelo 06</option>
                            </SelectCustom>}

                        {configControl.homereviews_widget_style == "carousel" &&
                            <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_card: e.target.value } }) }}>
                                <option value="carousel-modelo01" selected={configControl.homereviews_card == "carousel-modelo01" ? true : false}>Modelo 01</option>
                            </SelectCustom>}

                        {configControl.homereviews_widget_style == "carousel-multi" &&
                            <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_card: e.target.value } }) }}>
                                <option value="carousel-multi-modelo01" selected={configControl.homereviews_card != "carousel-multi-modelo02" ? true : false}>Modelo 01</option>
                                <option value="carousel-multi-modelo02" selected={configControl.homereviews_card == "carousel-multi-modelo02" ? true : false}>Modelo 02</option>
                            </SelectCustom>}

                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_size_border_card: e.target.value } }) }}>
                            <option value="0px" selected={configControl.homereviews_size_border_card == "0px" ? true : false}>{translation.thema.configs.borda} 0px</option>
                            <option value="1px" selected={configControl.homereviews_size_border_card == "1px" ? true : false}>{translation.thema.configs.borda} 1px</option>
                            <option value="2px" selected={configControl.homereviews_size_border_card == "2px" ? true : false}>{translation.thema.configs.borda} 2px</option>
                            <option value="3px" selected={configControl.homereviews_size_border_card == "3px" ? true : false}>{translation.thema.configs.borda} 3px</option>
                            <option value="4px" selected={configControl.homereviews_size_border_card == "4px" ? true : false}>{translation.thema.configs.borda} 4px</option>
                            <option value="5px" selected={configControl.homereviews_size_border_card == "5px" ? true : false}>{translation.thema.configs.borda} 5px</option>
                        </SelectCustom>

                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_radius_border_card: e.target.value } }) }}>
                            <option value="0px" selected={configControl.homereviews_radius_border_card == "0px" ? true : false}>{translation.thema.configs.arredondamento} 0px</option>
                            <option value="1px" selected={configControl.homereviews_radius_border_card == "1px" ? true : false}>{translation.thema.configs.arredondamento} 1px</option>
                            <option value="2px" selected={configControl.homereviews_radius_border_card == "2px" ? true : false}>{translation.thema.configs.arredondamento} 2px</option>
                            <option value="4px" selected={configControl.homereviews_radius_border_card == "4px" ? true : false}>{translation.thema.configs.arredondamento} 4px</option>
                            <option value="6px" selected={configControl.homereviews_radius_border_card == "6px" ? true : false}>{translation.thema.configs.arredondamento} 6px</option>
                            <option value="8px" selected={configControl.homereviews_radius_border_card == "8px" ? true : false}>{translation.thema.configs.arredondamento} 8px</option>
                            <option value="10px" selected={configControl.homereviews_radius_border_card == "10px" ? true : false}>{translation.thema.configs.arredondamento} 10px</option>
                            <option value="12px" selected={configControl.homereviews_radius_border_card == "12px" ? true : false}>{translation.thema.configs.arredondamento} 12px</option>
                            <option value="14px" selected={configControl.homereviews_radius_border_card == "14px" ? true : false}>{translation.thema.configs.arredondamento} 14px</option>
                            <option value="16px" selected={configControl.homereviews_radius_border_card == "16px" ? true : false}>{translation.thema.configs.arredondamento} 16px</option>
                            <option value="18px" selected={configControl.homereviews_radius_border_card == "18px" ? true : false}>{translation.thema.configs.arredondamento} 18px</option>
                            <option value="20px" selected={configControl.homereviews_radius_border_card == "20px" ? true : false}>{translation.thema.configs.arredondamento} 20px</option>
                        </SelectCustom>

                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_visible_verified_card: e.target.value } }) }}>
                            <option value="1" selected={configControl.homereviews_visible_verified_card == "1" ? true : false}>Mostrar compra verificada</option>
                            <option value="0" selected={configControl.homereviews_visible_verified_card == "0" ? true : false}>{translation.thema.configs.nao} mostrar compra verificada</option>
                        </SelectCustom>

                    </EstiloWidget>
                </BoxOptions>

                {/* <BoxOptions>
                    <EstiloWidget>
                        <span>
                            <div>
                                <img src={IconEstiloWidget} />
                                <label>Animação</label>
                            </div>
                        </span>
                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_widget_style: e.target.value } }) }}>
                            <option value={"modelo01"} selected={configControl.homereviews_widget_style == "modelo01" ? true : false}>Modelo 01</option>
                            <option value={"modelo02"} selected={configControl.homereviews_widget_style == "modelo02" ? true : false}>Modelo 02</option>
                            <option value={"modelo03"} selected={configControl.homereviews_widget_style == "modelo03" ? true : false}>Modelo 03</option>
                        </SelectCustom>

                    </EstiloWidget>
                </BoxOptions> */}

                {/* <BoxOptions>
                <Posicao>
                    <span>
                        <img width={20} src={IconPosicao} />
                        <label>POSIÇÃO:</label>
                    </span>
                    <label>Defina em que espaço as avaliações ficarão posicionados na sua página inicial</label>
                    <div>
                        <div>
                            <label>Definir posição</label>
                        </div>
                    </div>
                </Posicao>
            </BoxOptions> */}
                {/* <BoxOptions>
                <Layout>
                    <span>
                        <img width={20} src={IconLayout} />
                        <label>LAYOUT DAS AVALIAÇÕES</label>
                    </span>
                    <div>
                        <ItemLayout onClick={() => { setLayout("grid") }} active={layout == "grid" ? true : false}>
                            <img src={IconGridMobile} />
                            <img src={IconGridDesktop} />
                        </ItemLayout>
                        <ItemLayout onClick={() => { setLayout("box") }} active={layout == "box" ? true : false}>
                            <img src={IconLineMobile} />
                            <img src={IconBoxDesktop} />
                        </ItemLayout>
                        <ItemLayout onClick={() => { setLayout("line") }} active={layout == "line" ? true : false}>
                            <img src={IconLineMobile} />
                            <img src={IconLineDesktop} />
                        </ItemLayout>
                    </div>
                </Layout>
            </BoxOptions> */}

                {/* {
                widget == "grid" ?
                    <BoxOptions>
                        <EstiloWidget>
                            <span>
                                <div>
                                    <img src={IconVideoHome} />
                                    <label>ANIMAÇÃO:</label>
                                </div>
                                <ItemActive onClick={() => { setActiveAnimation(!activeAnimation) }} active={activeAnimation}>
                                    <span />
                                </ItemActive>
                            </span>
                            <label>
                                O grid se movimenta para cima de modo automático
                            </label>
                        </EstiloWidget>
                    </BoxOptions> :
                    <BoxOptions>
                        <EstiloWidget>
                            <span>
                                <div>
                                    <img src={IconScroll} />
                                    <label>SCROLL AUTOMÁTICO:</label>
                                </div>
                                <ItemActive onClick={() => { setActiveScroll(!activeScroll) }} active={activeScroll}>
                                    <span />
                                </ItemActive>
                            </span>

                            {activeScroll ?
                                <label>
                                    Neste modo, as setas não ficam disponíveis para seus clientes
                                </label> :
                                <label>
                                    Neste modo, as setas ficam disponíveis para seus clientes
                                </label>}
                        </EstiloWidget>
                    </BoxOptions>
            } */}


                <ButtonHelp>
                    <img width={20} src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() => getDefaultTheme()}>
                        <label>{translation.thema.boxConfigEmail.restaurar}</label>
                        {/* <img src={IconReset} /> */}
                    </ButtonReset>
                    <ButtonSave onClick={() => saveTheme()}>
                        {translation.thema.boxConfigEmail.salvar}
                    </ButtonSave>
                </BoxButtons>
            </Container>
        </Main >

    )
}

export default BarConfigHomeConfig