
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE } from "../../../../services/api";



export function* listNotify(action) {
  
    yield put({ type: "NOTIFY_LIST_REQUEST", })
    //yield delay(200);
    const result = yield call(GET, { endpoint: `/dashboard/notifications` })

    if (result === false) {
        yield put({ type: "NOTIFY_LIST_ERROR" })
    } else {
        yield put({ type: "NOTIFY_LIST_SUCCESS", payload: result.data })
    }
}


export function* getInforAccount(action) {
    
    yield put({ type: "GET_INFOR_ACCOUNT_REQUEST", })
    //yield delay(200);
    const result = yield call(GET, { endpoint: `/users/${action.payload.id}` })

    if (result === false) {
        yield put({ type: "GET_INFOR_ACCOUNT_ERROR" })
    } else {
        yield put({ type: "GET_INFOR_ACCOUNT_SUCCESS", payload: result.data })
    }
}