import { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { BadgeCheck, BoxMedias, Container, Description, InforDate, InforText, SectionLeft, SectionRigth, Stars, Title, } from './styled'
import { HiBadgeCheck } from "react-icons/hi";
import { getTranslation } from '../../../../translations';
import ImgCard from "../../../../assets/imgs/icon-oculos.svg";


const CardLilyLarge = (props) => {

    const [icon, setIcon] = useState({ size: 20, color: "orange" })
    const config = useSelector(state => state.reducerControlConfig)

    useEffect(() => {
        setIcon({ ...icon, colorActive: config.star_color_primary, colorInative: config.star_color_secondary })
    }, [config])


    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Container background={config.background_color_secondary} borderRadius={config.border_card_radius} borderSize={config.border_card_size} borderColor={config.border_card_color}>
            <Title fontColor={config.font_color_primary}>
                <label>{props.item.name}</label>
                <BadgeCheck>
                    {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={15} color={"#5cb85c"} />}
                    <label>{translation.thema.cardReviewLilyPreview.compraVerificada}</label>
                </BadgeCheck>
            </Title>
            {config.visible_date_review == "1" &&
                <InforDate>
                    01/01/2024
                </InforDate>}
            <Stars position={config.position_star_review} fontColor={config.font_color_primary}>
                <label>{props.item.qtdStars} {translation.thema.previewCardReview.estrelas}</label>
                <span>
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? icon.colorActive : icon.colorInative} />
                    <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? icon.colorActive : icon.colorInative} />
                </span>

            </Stars>
            <Description fontColor={config.font_color_primary}>
                <p>{translation.thema.previewCardReview.avaliacaoProduto}</p>
                <label>{props.item.text}</label>
            </Description>
            <BoxMedias>
                <img src={ImgCard} />
                <img src={ImgCard} />
                <img src={ImgCard} />
                <img src={ImgCard} />
            </BoxMedias>

            {props.item.qtdStars > 3 &&
                <InforText fontColor={config.font_color_primary}>
                    {translation.thema.previewCardReview.usuarioRecomenda}
                </InforText>}

        </Container>
    )
}

export default CardLilyLarge