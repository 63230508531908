import { Tooltip, BoxInfor, BoxCode, BoxMessage, Status, BoxDescription, BoxIntegration, Container, BoxContents, BoxOptions, ButtonAnswered, ButtonHidden, ButtonDelete, BoxActions, Badget, ButtonCustom, OptionCustom, } from "./styled"
import { AiFillEdit, AiFillCloseCircle, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineClose, AiFillStar, AiFillClockCircle, AiOutlineCheck, AiFillEyeInvisible, AiOutlineQuestion, AiOutlineDelete, AiFillCheckCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ModalDelete from "../modal-delete";
import ModalStatus from "../modal-status";
//import ModalUpdate from "../modal-update";
import ModalUpdate from "../modal-edit";
import { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { RiScissorsLine } from "react-icons/ri"

import ConfirmationDelete from "../../../../components/Modal-Confirmation";
import ConfirmationStatus from "../../../../components/Modal-Confirmation";
import { getTranslation } from "../../../../translations";


const Card = (props) => {


    const dispatch = useDispatch()
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [visibleModalStatus, setVisibleModalStatus] = useState(false)
    const [visibleModalUpdate, setVisibleModalUpdate] = useState(false)

    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const [visibleModalConfirmationStatus, setVisibleModalConfirmationStatus] = useState(false)

    const statusDeleteCupom = useSelector(state => state.reducerDeleteCupom)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language)

    useEffect(() => {
        if (!(statusDeleteCupom.loading == false && !statusDeleteCupom.error == false && statusDeleteCupom.data.length == 0)) {
            const id = "statusDeleteCupom"
            if (statusDeleteCupom.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusDeleteCupom.error) toast.update(id, { render: "Erro ao tentar excluir.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Excluído com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalConfirmation(false)
                }
            }
        }
    }, [statusDeleteCupom])

    const deleteCupom = () => {
        dispatch({
            type: "SAGA_DELETE_CUPOM",
            payload: {
                id: props.item.id
            }
        })
    }
    const updateStatusCupom = () => {
        dispatch({
            type: "SAGA_UPDATE_CUPOM",
            payload: {
                id: props.item.id,
                active: props.item.active == 1 ? false : true
            }
        })
    }

    const copyCupom = () => {
        navigator.clipboard.writeText(props.item.code)
        toast.success("Cupom copiado.")
    }

    return (
        <Container>
            {/* <ModalDelete visible={visibleModalDelete} setVisibleModal={setVisibleModalDelete} item={props.item} /> */}
            <ConfirmationDelete
                confirmation={deleteCupom}
                visible={visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={translation.cupons.card.confirmDeleteTitle}
                infor={translation.cupons.card.confirmDeleteInfor} />
            <ConfirmationStatus
                confirmation={updateStatusCupom}
                visible={visibleModalConfirmationStatus}
                setVisibleModal={setVisibleModalConfirmationStatus}
                title={`${props.item.active == 1 ? translation.cupons.card.desativarCupom : translation.cupons.card.ativarCupom}`}
            //infor={"Obs: Ao excluir esse cupom não será possivel recuperar o mesmo."} 
            />
            {/* <ModalStatus visible={visibleModalStatus} setVisibleModal={setVisibleModalStatus} item={props.item} /> */}
            <ModalUpdate visible={visibleModalUpdate} setVisibleModal={setVisibleModalUpdate} item={props.item} />

            {/* <Status color={props.item.active == 0 ? "#F1BC00" : "#20D489"}            >
                {props.item.active ? <AiOutlineCheck size={15} color={"white"} /> : <AiOutlineClose size={15} color={"white"} />}
            </Status> */}

            {/* <Status color={props.item.active == 0 ? "#F1BC00" : "#20D489"}  >
                {props.item.active ? <AiOutlineCheck size={15} color={"white"} /> : <AiOutlineClose size={15} color={"white"} />}
                <Tooltip>
                    <label>{props.item.active ? "Esse cupom está ativo em sua loja." : "Esse cupom está inativo em sua loja."}</label>
                </Tooltip>
            </Status> */}
            <Badget color={{ background: props.item.active == 0 ? "#f1f1f1" : "#E4FFF4", color: props.item.active == 0 ? "gray" : "#21D487" }}>
                {props.item.active == 1 ? <FiCheckCircle size={15} color={"#21D487"} /> : <AiOutlineCloseCircle size={15} color={"gray"} />}
                <label> {props.item.active == 1 ? translation.cupons.card.ativo : translation.cupons.card.Inativo}</label>
            </Badget>
            <BoxContents background={props?.background} color={props.item.active == 0 ? "#F1BC00" : "#20D489"}>
                <BoxCode onClick={() => { copyCupom() }} active={props.item.active == 0 ? false : true}>
                    <p>{props.item.code}</p>
                    <label>{translation.cupons.card.copiarCupom}</label>
                    <RiScissorsLine color={props.item.active == 0 ? "gray" : "#21D487"} size={18} />
                </BoxCode>
                <BoxInfor>
                    <label><strong style={{ color: "gray", marginRight: "5px" }}>{translation.cupons.card.expira}</strong>  {new Date(props.item.expiration_date).toLocaleDateString()}</label>
                    <BoxMessage>
                        <strong style={{ color: "gray" }}>{translation.cupons.card.mensagem}</strong>
                        {props.item.message}
                    </BoxMessage>
                    <BoxDescription>
                        <strong style={{ color: "gray" }}>{translation.cupons.card.descCupom}</strong>
                        {props.item.description}
                    </BoxDescription>

                    {/* <ButtonCustom onClick={() => { setVisibleModalConfirmationStatus(true) }} color={{ background: !props.item.active ? "#54D18C" : "#F13F6C", color: props.item.active ? "white" : "white" }} >
                        <label>{props.item.active == 1 ? "Desativar" : "Ativar"}</label>
                    </ButtonCustom> */}

                </BoxInfor>
                <BoxActions>
                    {/* {props.item.active != 0 ?
                        <AiOutlineCheckCircle onClick={() => { setVisibleModalConfirmationStatus(true) }} size={20} color={"gray"} /> :
                        <AiOutlineCloseCircle onClick={() => { setVisibleModalConfirmationStatus(true) }} size={20} color={"gray"} />}
                     */}

                    <OptionCustom onClick={() => { setVisibleModalConfirmationStatus(true) }} active={props.item.active == 0 ? false : true}>
                        <div />
                        {
                            props.item.active == 1 ?
                            <label>{translation.cupons.card.tooltipDesativar}</label> :
                            <label>{translation.cupons.card.tooltipAtivar}</label>
                        }
                        {/* <label>Deseja {props.item.active == 1 ? translation.cupons.card.desativar : translation.cupons.card.ativar} {translation.cupons.card.esseCupom}</label> */}
                    </OptionCustom> 

                    <span onClick={() => { setVisibleModalUpdate(true) }}>
                        <AiFillEdit size={20} color={"gray"} />
                        <label>{translation.cupons.card.editarCupom}</label>
                    </span>

                    <span onClick={() => { setVisibleModalConfirmation(true) }}>
                        <AiOutlineDelete size={20} color={"gray"} />
                        <label>{translation.cupons.card.excluirCupom}</label>
                    </span>

                </BoxActions>
            </BoxContents>
            {/* <BoxOptions>
                <ButtonAnswered onClick={() => { setVisibleModalConfirmationStatus(true) }}
                    active={props.item.active == 1 ? false : true}
                //disabled={props.item.status == "answered" ? true : false}
                >
                    {props.item.active == 1 ? "Desativar" : "Ativar"}
                    <span>
                        {props.item.active == 0 ? <AiOutlineCheck size={15} color={"white"} /> : <AiOutlineClose size={15} color={"#19B674"} />}
                    </span>
                </ButtonAnswered>

                <ButtonHidden onClick={() => { setVisibleModalUpdate(true) }}
                    active={true}
                //disabled={props.item.status == "hidden" ? true : false}
                >
                    Editar
                    <span>
                        <AiFillEdit size={15} color={"white"} />
                    </span>
                </ButtonHidden>

                <ButtonDelete onClick={() => {
                    //setVisibleModalDelete(true)
                    setVisibleModalConfirmation(true)
                }}
                >
                    Excluir
                    <span>
                        <AiOutlineDelete size={15} color={"white"} />
                    </span>
                </ButtonDelete>
    
            </BoxOptions> */}
        </Container >
    )
}

export default Card
