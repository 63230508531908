import { BarLeft, BoxControlMobile, NewBarLeft, ItemBarLeft, NewBarLeftTitle, BarOptions, EmBreve, Search, SelectCustom, ActionsSelect, ActionsDropdown, List, PaginateList, PaginateNav, PaginateInfor, ItemUpdate } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { useDispatch } from "react-redux";
import { isVisibleScript } from "../../../../utils/displayScript";
import { useHistory, useLocation } from "react-router-dom";
import { getTranslation } from "../../../../translations";
import IconImgHome from "../../../../assets/imgs/icon-homepage.svg";
import { Google, Instagram, MessageQuestion, Star1, Youtube } from "iconsax-react";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";
import Card from "../../components/card";
import ModalCollectGoogle from "../../components/modal-collect-google";
import { useState } from "react";
import { FaTiktok, FaTools } from "react-icons/fa";
import { TbBrandTiktok } from "react-icons/tb";
import { RiToolsLine } from "react-icons/ri";
import ModalConfirmation from "../../../../components/Modal-Confirmation";
import { AiFillEye, AiFillEyeInvisible, AiOutlineReload, AiOutlineSearch } from "react-icons/ai";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import HelpClick from "../../../../components/HelpClick";
import { IoIosArrowDown, IoMdTrash } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Layout from "../../../../components/tailwind/layout";
import LoadingLogoLilyNew from "../../../../components/Loading-logo-lily-new";
import { ContainerLoading } from "../../../reviews/pages/home-new/styled";
import LayoutNew from "../../../../components/tailwind/layout-new";
import TableCollect from "../../components/tailwind/table";
import PageIntegration from "../../../../components/page-clean";
import ModalInforVideo from "../../../../components/modal-infor-video";

const Home = () => {

    const getCollect = useSelector(state => state.reducerGetCollect)

    const dispatch = useDispatch()
    const selectedMenu = useLocation();
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const history = useHistory()

    const [visibleModalCollectGoogle, setVisibleModalCollectGoogle] = useState(false)
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [filterType, setFilterType] = useState("")

    const reducerDeleteCollect = useSelector(state => state.reducerDeleteCollect)
    const [checkedAll, setCheckedAll] = useState(false)
    const collectSelect = useSelector(state => state.reducerControlSelectCollect)

    const [visibleMenuActions, setVisibleMenuActions] = useState(false)

    const [businessUpdate, setBusinessUpdate] = useState(null)

    const reducerGetBusinessRef = useSelector(state => state.reducerGetBusinessRef)

    const [visibleModalInforCollect, setVisibleModalInforCollect] = useState(false)

    useEffect(() => {
        dispatch({ type: "SAGA_LIST_BUSINESS_REF_COLLECT", })
    }, [])


    useEffect(() => {

        if (visibleMenuActions) {
            window.addEventListener('mouseup', closeMenuActions);
            // cleanup this component
            return () => {
                window.removeEventListener('mouseup', closeMenuActions);
            };
        }
    }, [visibleMenuActions])

    const closeMenuActions = (event) => {
        if (visibleMenuActions) setVisibleMenuActions(false)
    };




    const routerBar = () => {
        if (selectedMenu.pathname == "/depoimentos") {
            return (
                <NewBarLeft>

                    <NewBarLeftTitle>
                        {translation.depoimentos.coletarDeepoimentos}
                    </NewBarLeftTitle>

                    <ItemBarLeft onClick={() => { setVisibleModalCollectGoogle(true) }}>
                        <Google size="55" color="#808080" />
                        <label onClick={() => { setVisibleModalCollectGoogle(true) }}>
                            Google
                        </label>
                    </ItemBarLeft>

                    <ItemBarLeft onClick={() => { }}>
                        <Instagram size="55" color="#808080" variant="Outline" />
                        <label onClick={() => { }}>
                            Instagram
                        </label>
                        <EmBreve>{translation.depoimentos.emBreve}</EmBreve>
                    </ItemBarLeft>

                    <ItemBarLeft onClick={() => { }}>
                        <Youtube size="55" color="#808080" variant="Outline" />
                        <label onClick={() => { }}>
                            Youtube
                        </label>
                        <EmBreve>{translation.depoimentos.emBreve}</EmBreve>
                    </ItemBarLeft>

                    <ItemBarLeft onClick={() => { }}>
                        <TbBrandTiktok size="55" color="#808080" />
                        <label onClick={() => { }}>
                            Tik Tok
                        </label>
                        <EmBreve>{translation.depoimentos.emBreve}</EmBreve>
                    </ItemBarLeft>

                </NewBarLeft>
            )
        }
        else {
            return null
        }
    }


    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_COLLECT", payload: {
                search: search,
                perPage: perPage,
                page: page
            }
        })
    }

    useEffect(() => {
        goSearch()
    }, [page])



    const deleteCollect = (arrayIds) => {
        dispatch({ type: "SAGA_DELETE_COLLECT", payload: arrayIds })
    }

    useEffect(() => {
        if (reducerDeleteCollect.loading == false && reducerDeleteCollect.error == false && reducerDeleteCollect?.data?.length == 0) {
        } else {
            const id = "reducerDeleteCollect"
            if (reducerDeleteCollect.loading == true) {
                toast.loading(translation.reviews.aguarde, { toastId: id })
            } else {
                if (reducerDeleteCollect.error == true) {
                    toast.update(id, { render: translation.reviews.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.reviews.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    goSearch()
                }
            }

        }
    }, [reducerDeleteCollect])

    const countItens = () => {

        let result = 0
        if (page == getCollect?.data?.meta?.last_page) result = getCollect?.data?.meta?.total
        else result = page * perPage
        return result
    }

    const handleChecked = (checked) => {
        setCheckedAll(checked)
    }

    return (
        <LayoutNew>
            <ModalCollectGoogle businessUpdate={businessUpdate} visibleModal={visibleModalCollectGoogle} setVisibleModal={setVisibleModalCollectGoogle} goSearch={goSearch} />

            <ModalInforVideo visible={visibleModalInforCollect} setVisibleModal={setVisibleModalInforCollect}
                video={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/biEdj5jCFp8" : "https://www.youtube.com/embed/biEdj5jCFp8"}
            />

            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{translation.sidebar.cupons}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.cupom}
                            </p>
                        </div>
                    </div>
                    <PageIntegration />
                </div> :
                <>
                    <TableCollect
                        search={search}
                        setSearch={setSearch}
                        goSearch={goSearch}
                        setVisibleModal={setVisibleModalCollectGoogle}
                        setVisibleModalCollectGoogle={setVisibleModalCollectGoogle}
                        deleteCollect={deleteCollect}
                        setPage={setPage}
                    />

                    {!getCollect.loading &&
                        <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                            <div onClick={() => {
                                setVisibleModalInforCollect(true)
                            }}>
                                <HelpClick text={translation.alert.depoimentos} />
                            </div>
                        </div>}
                </>
            }
            {/* <ModalCollectGoogle businessUpdate={businessUpdate} visibleModal={visibleModalCollectGoogle} setVisibleModal={setVisibleModalCollectGoogle} goSearch={goSearch} />
            <div style={{ margin: "20px" }} >

                {getCollect?.loading ?
                    <ContainerLoading>
                        <LoadingLogoLilyNew />
                    </ContainerLoading>
                    :
                    <>
                        <BoxControlMobile active={"" != "" ? true : false}>
                            <BarLeft isFull={false} >
                                {routerBar()}
                            </BarLeft>
                            {getCollect?.loading ?
                                <ContainerLoading >
                                    <LoadingLogoLilyNew />
                                </ContainerLoading> :
                                getCollect?.data?.data?.length == 0 ?
                                    <div style={{ display: "flex", paddingTop: "100px", flexDirection: "column", flex: "1", alignItems: "center", }}>
                                        <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                        <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                            {translation.depoimentos.vazio}<br /> {translation.depoimentos.escolhaLado}
                                        </label>
                                    </div> :
                                    <div style={{ display: "flex", flex: "1", marginBottom: "20px", height: "100%", flexDirection: "column", gap: "10px", margin: "0px 20px" }}>
                                        <BarOptions>
                                            <Search >
                                                <input
                                                    type={"text"}
                                                    placeholder={"Buscar"}
                                                    value={search}
                                                    onChange={(e) => { setSearch(e.target.value) }} />
                                                <span onClick={() => { setPage(1); goSearch() }}>
                                                    <AiOutlineSearch style={{ cursor: "pointer" }} size={25} color={"gray"} />
                                                </span>
                                            </Search>

                                            <SelectCustom onChange={(e) => { setFilterType(e.target.value) }} >
                                                <option value="google" selected={filterType == "google" ? true : false} >Google</option>
                                                <option disabled value="instagram" selected={filterType == "instagram" ? true : false} >Instagram</option>
                                                <option disabled value="youtube" selected={filterType == "youtube" ? true : false} >Youtube</option>
                                                <option disabled value="tiktok" selected={filterType == "tiktok" ? true : false} >TIK TOK</option>
                                            </SelectCustom>
                                        </BarOptions>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <ActionsSelect  >
                                                <span>
                                                    <input type="checkbox" checked={checkedAll} onChange={(e) => {
                                                        handleChecked(e.target.checked)
                                                    }} />
                                                </span>
                                                <label style={{ fontSize: "14px" }}>{translation.reviews.selecionarTodos}</label>
                                                <ActionsDropdown visible={visibleMenuActions} onClick={() => {
                                                    setVisibleMenuActions(true)
                                                }}>
                                                    <label>{translation.reviews.acoes}</label>
                                                    <IoIosArrowDown size={15} color={"gray"} />
                                                    <div >
                                                        <span onClick={() => {
                                                            collectSelect.length > 0 ? deleteCollect(collectSelect) : toast.warning(translation.reviews.nenhumItem)
                                                        }}>
                                                            <IoMdTrash size={15} color={"#F13F6C"} />
                                                            <label>{translation.reviews.excluir}</label>
                                                        </span>

                                                    </div>
                                                </ActionsDropdown>
                                            </ActionsSelect>
                                            <span style={{ display: "flex", gap: "20px" }} >
                                                <ItemUpdate onClick={() => { setBusinessUpdate(reducerGetBusinessRef?.data[reducerGetBusinessRef?.data?.length - 1]); setVisibleModalCollectGoogle(true) }}>
                                                    <AiOutlineReload size={16} style={{ marginRight: "10px" }} />
                                                    {reducerGetBusinessRef?.data[reducerGetBusinessRef?.data?.length - 1]?.title}
                                                    <span>{translation.depoimentos.atualizarDadosEmpresa}</span>
                                                </ItemUpdate>
                                            </span>
                                        </div>
                                        <List>
                                            {
                                                getCollect?.data?.data?.map((item) => {
                                                    return (
                                                        <Card item={item} deleteCollect={deleteCollect} checkedAll={checkedAll} />
                                                    )
                                                })
                                            }

                                            {getCollect?.data?.data?.length > 0 &&
                                                <PaginateList>
                                                    <PaginateNav>
                                                        <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                                            <MdKeyboardArrowLeft size={35} color={getCollect.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                                        </span>
                                                        <label>{getCollect.data?.meta?.current_page}</label>
                                                        <span onClick={() => { if (page < getCollect.data?.meta?.last_page) setPage(page + 1) }}>
                                                            <MdKeyboardArrowRight size={35} color={getCollect.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                                        </span>
                                                    </PaginateNav>
                                                    <PaginateInfor>
                                                        <label>{translation.reviews.total} {countItens()} - {getCollect.data?.meta?.total}</label>
                                                    </PaginateInfor>
                                                </PaginateList>}
                                        </List>

                                    </div>
                            }
                        </BoxControlMobile>

                    </>
                }

                {!getCollect.loading &&
                    <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                        <div onClick={() => {
                            setVisibleModalInforReviews(true)
                        }}>
                            <HelpClick text={translation.alert.depoimentos} />
                        </div>
                    </div>}
            </div>
            {isVisibleScript(false)} */}
        </LayoutNew >
    )
}

export default Home

