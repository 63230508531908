import { CheckIcon, EllipsisVerticalIcon, ExclamationCircleIcon, EyeSlashIcon, QuestionMarkCircleIcon, } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { BoxIcon, BoxImage, BoxImageProduct, BoxMediaItem, Message, Submenu } from './styled'
import { IoLogoInstagram, IoLogoTiktok, IoLogoYoutube } from 'react-icons/io5'
import { BiPlay } from 'react-icons/bi'
import Toggle from '../../../../../components/tailwind/toggle'
import { useEffect } from 'react'
import { Whatsapp } from 'iconsax-react'
import SelectIcon from '../select-icon-action-green'
import SelectIconActionYellow from '../select-icon-action-yellow'
import SelectIconActionBlue from '../select-icon-action-blue'
import SelectIconActionRed from '../select-icon-action-red'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import Modal from "../../modal";
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../../translations'
import ModalMenuItem from '../../modal-menu-item'

const ItemTable = (props) => {



    const [visibleModalReviews, setVisibleModalReviews] = useState(false)
    const [visibleModalEditReviews, setVisibleModalEditReviews] = useState(false)
    const [visibleMenu, setvisibleMenu] = useState(false)
    const [fullMessage, setfullMessage] = useState(false)
    const [visibleModalMenu, setVisibleModalMenu] = useState(false)
    const [fullReplyMessage, setfullReplyMessage] = useState(false)
    const dispatch = useDispatch()

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    const [visibleModal, setVisibleModal] = useState(false)


    const closeMenu = () => { setvisibleMenu(false) }
    useEffect(() => {
        if (visibleMenu) {
            window.addEventListener('mouseup', closeMenu);
            return () => { window.removeEventListener('mouseup', closeMenu); };
        }
    }, [visibleMenu])

    const copyItem = (type) => {
        if (type == "email") {
            navigator.clipboard.writeText(props.item.email)
        } else {
            navigator.clipboard.writeText(props.item.whatsapp)
        }
        toast.success("Copiado!")
    }

    const statusReview = (status) => {
        dispatch({ type: "SAGA_UPDATE_QUESTIONS", payload: { status: status, id: props.item.id } })
    }

    const deleteQuestion = () => {
        dispatch({ type: "SAGA_DELETE_QUESTIONS", payload: { id: props.item.id } })
    }

    const getIconMedia = (item) => {
        switch (item.type) {
            case "video":
                return (
                    <span>
                        <img onClick={() => { setVisibleModalReviews(true) }} src={item.thumbnail_url} style={{ cursor: "pointer", width: "32px", height: "32px", objectFit: "cover", borderRadius: "4px" }} />
                        <BiPlay onClick={() => { setVisibleModalReviews(true) }} size={20} color={"gray"} style={{ position: "absolute", cursor: "pointer", top: "6px", left: "6px" }} />
                    </span>
                )
            case "image":
                return (
                    <img onClick={() => { setVisibleModalReviews(true) }} src={item.url} style={{ cursor: "pointer", width: "32px", height: "32px", objectFit: "cover", borderRadius: "4px" }} />
                )
            case "tiktok":
                return (
                    <BoxMediaItem onClick={() => { setVisibleModalReviews(true) }}>
                        <IoLogoTiktok onClick={() => { setVisibleModalReviews(true) }} />
                    </BoxMediaItem>
                )
            case "instagram":
                return (
                    <BoxMediaItem onClick={() => { setVisibleModalReviews(true) }}>
                        <IoLogoInstagram onClick={() => { setVisibleModalReviews(true) }} />
                    </BoxMediaItem>
                )
            case "youtube":
                return (
                    <BoxMediaItem onClick={() => { setVisibleModalReviews(true) }}>
                        <IoLogoYoutube />
                    </BoxMediaItem>
                )

            default:
                break;
        }
    }

    const getBadge = () => {
        switch (props.item.status) {
            case "approved":
                return (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-emerald-100 px-2 py-1 text-xs font-medium text-emerald-700">
                        <svg className="h-1.5 w-1.5 fill-emerald-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx={3} cy={3} r={3} />
                        </svg>
                        {translation.avaliacoes.aprovado}
                    </span>
                )
            case "pending":
                return (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-emerald-100 px-2 py-1 text-xs font-medium text-emerald-800">
                        <svg className="h-1.5 w-1.5 fill-emerald-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx={3} cy={3} r={3} />
                        </svg>
                        {translation.avaliacoes.pendente}
                    </span>
                )
            case "hidden":
                return (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                        <svg className="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx={3} cy={3} r={3} />
                        </svg>
                        Escondido
                    </span>
                )
            case "new":
                return (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                        <svg className="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx={3} cy={3} r={3} />
                        </svg>
                        Novo
                    </span>
                )
            default:
                break;
        }
    }

    const getSelectAction = () => {
        switch (props.item.status) {
            case "answered":
                return (
                    <SelectIcon action={statusReview} item={props.item} />
                )
            case "pending":
                return (
                    <SelectIconActionYellow action={statusReview} item={props.item} />
                )
            case "hidden":
                return (
                    <SelectIconActionBlue action={statusReview} item={props.item} />
                )
            case "new":
                return (
                    <SelectIconActionRed action={statusReview} item={props.item} />
                )
            default:
                break;
        }
    }

    const getSelectActionButton = () => {
        switch (props.item.status) {
            case "answered":
                return (
                    <button
                        type="button"
                        className="inline-flex items-center gap-x-2 rounded-md bg-green-50 px-3.5 py-2.5 text-sm font-semibold text-green-700 hover:bg-green-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-100"
                    >
                        <CheckIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                        {translation.perguntas.respondido}
                    </button>
                )
            case "pending":
                return (
                    <button
                        type="button"
                        className="inline-flex items-center gap-x-2 rounded-md bg-yellow-50 px-3.5 py-2.5 text-sm font-semibold text-yellow-700 hover:bg-yellow-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-100"
                    >
                        <QuestionMarkCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                        {translation.avaliacoes.pendente}
                    </button>
                )
            case "hidden":
                return (
                    <button
                        type="button"
                        className="inline-flex items-center gap-x-2 rounded-md bg-blue-50 px-3.5 py-2.5 text-sm font-semibold text-blue-700  hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                    >
                        <EyeSlashIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                        {translation.avaliacoes.escondido}
                    </button>
                )
            case "new":
                return (
                    <button
                        type="button"
                        className="inline-flex items-center gap-x-2 rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-700 hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-100"
                    >
                        <ExclamationCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                        {translation.avaliacoes.novo}
                    </button>
                )
            default:
                break;
        }
    }

    return (
        <>
            {/* <ModalReviews data={props.item.medias} visibleModal={visibleModalReviews} setVisibleModal={setVisibleModalReviews} />
            <ModalEditReviews data={props.item} visibleModal={visibleModalEditReviews} setVisibleModal={setVisibleModalEditReviews} /> */}
            
            <ModalMenuItem
                item={props.item}
                visibleModalMenu={visibleModalMenu}
                setVisibleModalMenu={setVisibleModalMenu}
                statusReview={statusReview}
                deleteQuestion={deleteQuestion}
                setVisibleModal={setVisibleModal}
                copyItem={copyItem}
            />
            <Modal goSearch={props.goSearch} visible={visibleModal} setVisibleModal={setVisibleModal} item={props.item} />
            <tr key={props.item.email} className={props.selectedQuestions.includes(props.item) ? 'bg-gray-50 even:bg-gray-50' : "even:bg-gray-50"}>
                <td className="relative px-7 sm:w-12 sm:px-6">
                    {props.selectedQuestions.includes(props.item) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-green-600" />
                    )}
                    <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                        value={props.item.email}
                        checked={props.selectedQuestions.includes(props.item)}
                        onChange={(e) =>
                            props.setSelectedQuestions(
                                e.target.checked
                                    ? [...props.selectedQuestions, props.item]
                                    : props.selectedQuestions.filter((p) => p !== props.item)
                            )
                        }
                    />
                </td>
                <td
                    className={props.classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                        props.selectedQuestions.includes(props.item) ? 'text-gray-900' : 'text-gray-900'
                    )}
                >
                    {new Date(props.item.created_at).toLocaleDateString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{props.item.name}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <Message full={fullMessage} onClick={() => { setfullMessage(!fullMessage) }}>
                        <label>{props.item.message}</label>
                    </Message>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <Message full={fullReplyMessage} onClick={() => { setfullReplyMessage(!fullReplyMessage) }}>
                        <label>{props.item.reply_message}</label>
                    </Message>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <BoxImageProduct>
                        <span>
                            <img onClick={() => { window.open(props.item.canonical_url, "_blank") }} src={props.item.image_url} />
                            <label style={{}}>{props.item.product_name}</label>
                        </span>
                    </BoxImageProduct>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span style={{ display: "flex", justifyContent: "center", }}>
                        {/* {getSelectAction()} */}
                        {getSelectActionButton()}
                    </span>
                </td>
                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <span style={{ position: "relative" }}>
                        <EllipsisVerticalIcon onClick={() => { setVisibleModalMenu(true) }} style={{ height: "32px", cursor: "pointer" }} />
                        {/* <Submenu isVisible={visibleMenu}>
                            <div onClick={() => {
                                //props.setVisibleModalEdit(true) 
                                setVisibleModal(true)
                            }}>
                                <BoxIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                    </svg>
                                </BoxIcon>
                                <span>
                                    <p>{translation.perguntas.responder}</p>
                                    <label>Responder ou editar resposta</label>
                                </span>
                            </div>

                            {props.item.status == "hidden" ?
                                <div onClick={() => { statusReview(props.item.reply_message != null && props.item.reply_message != "" ? "answered" : "pending") }}>
                                    <BoxIcon>
                                        <CheckIcon style={{ width: "20px" }} />
                                    </BoxIcon>
                                    <span>
                                        <p>{"Mostrar"}</p>
                                        <label> {translation.avaliacoes.visivelLoja}</label>
                                    </span>
                                </div> :
                                <div onClick={() => { statusReview("hidden") }}>
                                    <BoxIcon>
                                        <EyeSlashIcon style={{ width: "20px" }} />
                                    </BoxIcon>
                                    <span>
                                        <p>{translation.avaliacoes.esconder}</p>
                                        <label> {translation.avaliacoes.naoVisivelLoja}</label>
                                    </span>
                                </div>}

                            <div>
                                <BoxIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </BoxIcon>
                                <span onClick={() => {
                                    deleteQuestion()
                                }}>
                                    <p>{translation.avaliacoes.excluir}</p>
                                    <label>{translation.avaliacoes.moverLixeira}</label>
                                </span>
                            </div>

                            {props.item.whatsapp &&
                                <div onClick={() => { copyItem("whatsapp") }}>
                                    <BoxIcon>
                                        <Whatsapp size={20} />
                                    </BoxIcon>
                                    <span>
                                        <p>{props.item.whatsapp}</p>
                                        <label>Copiar whatsapp</label>
                                    </span>
                                </div>}

                            {props.item.email &&
                                <div>
                                    <BoxIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                        </svg>

                                    </BoxIcon>
                                    <span onClick={() => { copyItem("email") }}>
                                        <p>{props.item.email}</p>
                                        <label>{translation.avaliacoes.copiarEmail}</label>
                                    </span>
                                </div>}
                        </Submenu> */}
                    </span>
                </td>
            </tr>
        </>

    )
}

export default ItemTable