import { useLayoutEffect, useRef, useState } from 'react'
import ItemTable from '../item-table'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LoadingLogoLilyNew from '../../../../../components/Loading-logo-lily-new'
import { ContainerLoading } from '../../../pages/home-new/styled'
import Pagination from '../pagination'
import { toast } from 'react-toastify'
import { getTranslation } from '../../../../../translations'
import { BarFilters, BarFiltersContents, BarFiltersIcon, BarFiltersIconOrder, BarFiltersLeft, Search } from './styled'
import { AdjustmentsHorizontalIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'

import FiltersReviews from "../filters-reviews";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableReviews(props) {

    const [page, setpage] = useState(1)
    const [perPage, setperPage] = useState(10)
    const [search, setsearch] = useState("")

    const [filterStar5, setfilterStar5] = useState(true)
    const [filterStar4, setfilterStar4] = useState(true)
    const [filterStar3, setfilterStar3] = useState(true)
    const [filterStar2, setfilterStar2] = useState(true)
    const [filterStar1, setfilterStar1] = useState(true)

    const [filterStatusApproved, setfilterStatusApproved] = useState(true)
    const [filterStatusHidden, setfilterStatusHidden] = useState(true)
    const [filterStatusPending, setfilterStatusPending] = useState(true)
    const [filterStatusNew, setfilterStatusNew] = useState(true)
    const [filterStatusDeleted, setfilterStatusDeleted] = useState(false)

    const [filterFavorite, setfilterFavorite] = useState(true)
    const [filterNoFavorite, setfilterNoFavorite] = useState(true)

    const [filterMediasImage, setfilterMediasImage] = useState(true)
    const [filterMediasVideo, setfilterMediasVideo] = useState(true)
    const [filterMediasTiktok, setfilterMediasTiktok] = useState(true)

    const [visibleMenuOrder, setvisibleMenuOrder] = useState(false)
    const [visibleFilters, setvisibleFilters] = useState(false)

    const handleClick = () => {
        if (checkbox.current) {
            checkbox.current.click();
        }
    };

    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedReviews, setSelectedReviews] = useState([])
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const reviews = useSelector(state => state.reducerReviews)
    const dispatch = useDispatch()

    const [dataReviews, setDataReviews] = useState([])

    const statusDeleteReview = useSelector(state => state.reducerDeleteReview)
    const statusUpdateReview = useSelector(state => state.reducerUpdateReview)
    const statusDeleteSelectReviews = useSelector(state => state.reducerDeleteSelectReviews)
    const statusUpdateSelectReviews = useSelector(state => state.reducerUpdateSelectReviews)

    useEffect(() => {
        if (statusDeleteReview.loading == false && statusDeleteReview.error == false && statusDeleteReview.data.length == 0) {
        } else {
            const id = "statusDeleteReview"
            if (statusDeleteReview.loading) { toast.loading(translation.reviews.card.aguarde, { toastId: id }) } else {
                if (statusDeleteReview.error) toast.update(id, { render: translation.reviews.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.reviews.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    goSearch()
                }
            }
        }
    }, [statusDeleteReview])

    useEffect(() => {
        if (statusUpdateReview.loading == false && statusUpdateReview.error == false && statusUpdateReview.data.length == 0) {
        } else {
            const id = "statusUpdateReview"
            if (statusUpdateReview.loading) { toast.loading(translation.reviews.card.aguarde, { toastId: id }) } else {
                if (statusUpdateReview.error) toast.update(id, { render: translation.reviews.card.erroEnviar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.avaliacoes.atualizado, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    goSearch()
                }
            }
        }
    }, [statusUpdateReview])


    useEffect(() => {
        console.log("teste useLayoutEffect")
        const isIndeterminate = selectedReviews.length > 0 && selectedReviews.length < dataReviews.length
        if (dataReviews.length != 0) setChecked(selectedReviews.length === dataReviews.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedReviews])


    function toggleAll() {
        setSelectedReviews(checked || indeterminate ? [] : dataReviews)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    // useEffect(() => {
    //     goSearch()
    // }, [])

    useEffect(() => {
        setDataReviews(reviews?.data?.data)
    }, [reviews])

    useEffect(() => {
        goSearch()
    }, [
        page,
        filterStar5, filterStar4, filterStar3, filterStar2, filterStar1,
        filterMediasImage, filterMediasVideo, filterMediasTiktok,
        filterNoFavorite, filterFavorite,
        filterStatusApproved, filterStatusDeleted, filterStatusHidden, filterStatusPending
    ])


    const getStatusFavorites = () => {
        if (filterFavorite && filterNoFavorite) {
            return ""
        } else if (!filterFavorite && !filterNoFavorite) {
            return ""
        } else if (filterFavorite) {
            return "true"
        } else if (filterNoFavorite) {
            return "false"
        } else {
            return ""
        }
    }



    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_REVIEWS", payload: {
                rating: `${filterStar5 ? "5," : ""}${filterStar4 ? "4," : ""}${filterStar3 ? "3," : ""}${filterStar2 ? "2," : ""}${filterStar1 ? "1" : ""}`,
                status: `${filterStatusNew ? "new," : ""}${filterStatusApproved ? "approved," : ""}${filterStatusHidden ? "hidden," : ""}${filterStatusPending ? "pending" : ""}`,
                search: search,
                perPage: perPage,
                medias: filterMediasImage && filterMediasTiktok && filterMediasVideo ? "" : `${filterMediasImage ? "image," : ""}${filterMediasVideo ? "video," : ""}${filterMediasTiktok ? "tiktok," : ""}`,
                favorite: (filterFavorite && filterNoFavorite) || (!filterFavorite && !filterNoFavorite) ? "" : filterFavorite ? true : false,
                active: filterStatusDeleted ? 0 : 1,
                page: page,
                productId: props.productId
            }
        })
        //setVisibleMenuPerPage(false)
    }

    useEffect(() => {
        console.log("selectedReviews", selectedReviews)
    }, [selectedReviews])


    const deleteAll = () => {
        const dataDelete = selectedReviews.map((item) => item.id)
        dispatch({ type: "SAGA_DELETE_SELECT_REVIEWS", payload: { itens: dataDelete } })
    }

    useEffect(() => {
        if (statusDeleteSelectReviews.loading == false && statusDeleteSelectReviews.error == false && statusDeleteSelectReviews.data.length == 0) {
        } else {
            const id = "statusDeleteSelectReviews"
            if (statusDeleteSelectReviews.loading) {
                toast.loading(translation.reviews.aguarde, { toastId: id })
            } else {
                if (statusDeleteSelectReviews.error) {
                    toast.update(id, { render: translation.reviews.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.reviews.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    goSearch()
                    setTimeout(() => { handleClick() }, 1000);

                }
            }
        }
    }, [statusDeleteSelectReviews])


    const favoriteAll = (status) => {
        let arrayModify = []
        for (let iterator of selectedReviews) { arrayModify.push({ id: iterator.id, favorite: status }) }
        dispatch({ type: "SAGA_UPDATE_SELECT_REVIEWS", payload: { itens: arrayModify, } })
    }


    const verifiedAll = (status) => {
        let arrayModify = []
        for (let iterator of selectedReviews) { arrayModify.push({ id: iterator.id, verified: status }) }
        dispatch({ type: "SAGA_UPDATE_SELECT_REVIEWS", payload: { itens: arrayModify, } })
    }

    const statusAll = (status) => {
        let arrayModify = []
        for (let iterator of selectedReviews) { arrayModify.push({ id: iterator.id, status: status }) }
        dispatch({ type: "SAGA_UPDATE_SELECT_REVIEWS", payload: { itens: arrayModify, } })
    }

    useEffect(() => {
        if (!statusUpdateSelectReviews.loading && !statusUpdateSelectReviews.error && statusUpdateSelectReviews.data.length == 0) {
        } else {
            const id = "statusUpdateSelectReviews"
            if (statusUpdateSelectReviews.loading) {
                toast.loading(translation.reviews.aguarde, { toastId: id })
            } else {
                if (statusUpdateSelectReviews.error) {
                    toast.update(id, { render: translation.reviews.erroOperacao, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.reviews.operacaoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    goSearch()
                    setTimeout(() => { handleClick() }, 1000);
                }
            }
        }
    }, [statusUpdateSelectReviews])

    const closeMenuOrder = () => { setvisibleMenuOrder(false) }
    useEffect(() => {
        if (visibleMenuOrder) {
            window.addEventListener('mouseup', closeMenuOrder);
            return () => { window.removeEventListener('mouseup', closeMenuOrder); };
        }
    }, [visibleMenuOrder])

    const closeMenuFilters = () => { setvisibleFilters(false) }
    useEffect(() => {
        if (visibleFilters) {
            window.addEventListener('mouseup', closeMenuFilters);
            return () => { window.removeEventListener('mouseup', closeMenuFilters); };
        }
    }, [visibleFilters])


    return (
        reviews.loading ?
            <ContainerLoading>
                <LoadingLogoLilyNew />
            </ContainerLoading> :
            <>
                <div className="px-4 sm:px-6 lg:px-8">
                    {props.navbar && props.navbar}
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900"> {translation.sidebar.avaliacoes}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.avaliacoes}
                            </p>
                        </div>
                    </div>
                    <BarFilters>
                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                            <BarFiltersLeft>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && goSearch() }} type='text' placeholder={translation.avaliacoes.pesquisarNomes} value={search} onChange={(e) => { setsearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>

                            <FiltersReviews
                                star5={filterStar5} setStar5={setfilterStar5}
                                star4={filterStar4} setStar4={setfilterStar4}
                                star3={filterStar3} setStar3={setfilterStar3}
                                star2={filterStar2} setStar2={setfilterStar2}
                                star1={filterStar1} setStar1={setfilterStar1}

                                approved={filterStatusApproved} setStatusApproved={setfilterStatusApproved}
                                hidden={filterStatusHidden} setStatusHidden={setfilterStatusHidden}
                                deleted={filterStatusDeleted} setStatusDeleted={setfilterStatusDeleted}
                                pending={filterStatusPending} setStatusPending={setfilterStatusPending}
                                new={filterStatusNew} setStatusNew={setfilterStatusNew}

                                favorite={filterFavorite} setFavorite={setfilterFavorite}
                                noFavorite={filterNoFavorite} setNoFavorite={setfilterNoFavorite}

                                image={filterMediasImage} setImage={setfilterMediasImage}
                                video={filterMediasVideo} setVideo={setfilterMediasVideo}
                                tiktok={filterMediasTiktok} setTiktok={setfilterMediasTiktok}
                            />

                        </span>
                    </BarFilters>
                    {/* <div style={{minHeight: "24px", backgroundColor: "#fff", display: "flex"}} /> */}
                    {/* <FiltersReviews /> */}
                    <div className="mt-0 flow-root ">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {selectedReviews.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            <button
                                                onClick={() => { deleteAll() }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {translation.avaliacoes.excluir}
                                            </button>
                                            <button
                                                onClick={() => { favoriteAll(true) }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {translation.avaliacoes.favoritar}
                                            </button>
                                            <button
                                                onClick={() => { favoriteAll(false) }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {translation.avaliacoes.removerFavorito}
                                            </button>
                                            <button
                                                onClick={() => { verifiedAll(true) }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                Verificar
                                            </button>
                                            <button
                                                onClick={() => { verifiedAll(false) }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {translation.avaliacoes.removerVerificado}
                                            </button>
                                            <button
                                                onClick={() => { statusAll("approved") }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {translation.avaliacoes.aprovar}
                                            </button>
                                            <button
                                                onClick={() => { statusAll("hidden") }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {translation.avaliacoes.esconder}
                                            </button>
                                        </div>
                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={() => toggleAll()}
                                                    />
                                                </th>
                                                <th scope="col" className="min-w-[6rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.data}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.nome}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.comentario}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.Estrelas}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.midias}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.produto}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Verificado
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.favoritado}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", justifyContent: "center" }}>status</label>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {dataReviews?.map((item, index) => (
                                                <ItemTable index={index} classNames={classNames} selectedReviews={selectedReviews} setSelectedReviews={setSelectedReviews} item={item} setVisibleModalEdit={props.setVisibleModalEdit} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination setPage={setpage} />
            </>

    )
}
