import styled from "styled-components";


export const Screen = styled.div`
    display: flex;
    height: 100% ;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    gap: 20px;
    max-height: 90vh;
`
export const Title = styled.label`
    display: flex;
    color: #101828;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`

export const LogoToPlatform = styled.div`
    display: flex;
    gap: 32px;
    position: relative;

    div{
        height: 100px ;
        width: 100px;
        background: #FFF6D9;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            width: 46px;
        }
    }

    span{
        display: flex;
        //background-color: red;
        flex-direction: column;
        justify-content: center;
    }

    
`

export const Form = styled.div`
    display: flex;
    //background-color: red;
    flex-direction: column;
    width: 100%;
`

export const BoxActions = styled.div`
    display: flex;
    //background-color: red;
    width: 100%;
    gap: 12px;
`

export const ItemBoxAction = styled.div`
    display: flex;
    height: 44px;
    flex: 1;
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: ${props => props.fontColor};
    cursor: pointer;
    

`


export const InputCustom = styled.div`
    display: flex;
    background-color: #fff;
    border: 1px solid #E7EAEE;
    height: 44px !important;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    padding: 0px 14px;

    img{
        width: 16px;
        height: 16px;
        
    }

    input{
        outline: none;
        border: none;
        background-color: transparent;
        display: flex;
        flex: 1;
        height: 100%;
        font-size: 13px;
        

        ::placeholder{
            color: rgba(102, 112, 133, 0.50);
            font-size: 13px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    svg{
        animation: spin 1s linear infinite;

        @keyframes spin {
            100% {
            transform: rotate(360deg);
            }
        }
    }
`

export const Results = styled.div`
    display: flex;
    flex-direction: column;
    //gap: 10px;
    //background-color: yellow;

    div{
        border-bottom: none;
    }

    div:not(:last-child){
        border-bottom: 1px solid #dcdcdc;
    }
    
`

export const ItemResult = styled.div`
    display: flex;
    padding: 10px 20px;
    background-color: #fff;
    flex-direction: column;
    font-size: 12px;
    //border-radius: 4px;
    cursor: pointer;

    :hover{
        background-color: #80808011;
    }
    
    label{
            cursor: pointer;
        }

    div{
        display: flex;
        justify-content: space-between;
        border: none !important;
        cursor: pointer;

        label{
            cursor: pointer;
        }

        label:nth-child(2){
            background-color: #FFCC00;
            padding: 3px 10px;
            font-size: 12px;
            border-radius: 4px;
            color: #5e0a07;
        }
    }
`

export const BoxLoading = styled.div`
    display: flex;
    position: relative;
    //background-color: red;


    label{
        color: #101828;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        //background-color: yellow;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        top: calc(50% - 20px);
        left: calc(50% - 75px);
        //width: 100%;
    }

    
   svg{
    animation: spin 1s linear infinite;
    

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
   }
`

export const BoxListItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 500px;
    overflow-y: auto;

    width: 100%;

    //padding-right: 20px;
`

export const TextEnd = styled.label`
    display: flex;
    color: #667085;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px; /* 200% */
`

export const Close = styled.label`
    display: flex;
    //background-color: red;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    svg{
        width: 16px;
        height: 16px;
    }
`

export const Logo = styled.div`
    display: flex;
    height: 100px ;
    width: 100px;
    background: #FFF6D9;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: relative;

    img{
        width: 46px;
    }
`
export const IconSuccess = styled.div`
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 4px;
    justify-content: center;
    align-items: center;
`
export const More = styled.div`
    display: flex;
    width: 100%;
    //background-color: red;
    justify-content: center;
    //padding: 10px 0px;
    //height: 50px;

    label{
        font-size: 12px;
        background-color: #f1f1f1;
        padding: 5px 20px;
        border-radius: 4px;
        cursor: pointer;
    }
`