
import Modal from 'react-modal';
// import { useDispatch, useSelector } from 'react-redux';
// import { IoIosClose } from "react-icons/io";

// import { Container, Title, FormCustom, Close, ButtonCustom, InputCustomText, BoxInput } from "./styled";
// import { useState } from 'react';
// import { useEffect } from 'react';
// import { toast } from 'react-toastify';
//import Loading from '../../../../components/Loading';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalSearchPlatform = (props) => {


    // const dispatch = useDispatch()
    // const [order, setOrder] = useState("")

    // const reducerSearchPlatform = useSelector((state) => state.reducerSearchPlatform);

    // useEffect(() => {
    //     if (reducerSearchPlatform.loading == null && reducerSearchPlatform.error == null && reducerSearchPlatform.data?.length == 0) {

    //     } else {
    //         if (reducerSearchPlatform.error == true) {
    //             toast.error("Problemas ao tentar sincronizar produto.");
    //             //props.setVisibleModal(false)
    //         } else if (reducerSearchPlatform.data?.data?.description?.length > 0) {
    //             toast.success("Produto sincronizado com sucesso.");
    //             props.setVisibleModal(false)
    //             setOrder("")
    //             props.goSearch()
    //         }
    //     }
    // }, [reducerSearchPlatform])

    // const searchOrder = () => {
    //     dispatch({ type: "SAGA_SEARCH_PLATFORM", payload: { order: order }, });
    // }


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <label>modal search</label>
            {/* <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <Title><strong>Sincronizar pedido</strong></Title>
                <FormCustom>
                    <BoxInput>
                        <label>N° pedido:</label>
                        <InputCustomText>
                            <input style={{ padding: "0px 10px" }} type={"text"} placeholder={"1234567890"} value={order} onChange={(e) => { setOrder(e.target.value) }} />
                        </InputCustomText>
                    </BoxInput>
                </FormCustom>
                <ButtonCustom onClick={() => { searchOrder() }}>
                    {reducerSearchPlatform.loading ?
                        <Loading size={"24"} color={"#fff"} /> :
                        "Buscar"}
                </ButtonCustom>
            </Container> */}
        </Modal>
    )
}

export default ModalSearchPlatform
