
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from "react-icons/io";

import { Container, Close, BoxImage, BoxInfor, ItemMenu, BoxMenu } from "./styled";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ArrowDownOnSquareIcon, ArrowDownTrayIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, CodeBracketIcon, EnvelopeIcon, PaperClipIcon, PencilSquareIcon, StarIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useHistory } from 'react-router-dom';
import { Whatsapp } from 'iconsax-react';
import { FaWhatsapp } from "react-icons/fa";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalMenuItem = (props) => {

    const dispatch = useDispatch()
    const language = useSelector((state) => state.reducerLanguage);
    const history = useHistory()

    const sincProduct = () => {
        dispatch({
            type: "SAGA_UPDATE_DATA_PRODUCT",
            payload: {
                integration: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration,
                product: props.item.products,

            }
        })
    }

    return (
        <Modal
            isOpen={props.visibleModalMenu}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModalMenu(false) }}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModalMenu(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <BoxMenu>

                    <Item
                        image={<ArrowPathIcon style={{ width: "32px" }} />}
                        action={() => {

                            props.sincProduct();
                            props.setVisibleModalMenu(false)
                            //history.push(`produtos/${props.item.products.product_id}/variacoes`); props.setVisibleModal(false)
                        }}
                        item={props.item}
                        title={"Sincronizar"}
                        desc={"Sincronizar dados desse produto."} />

                    <Item
                        image={<ArrowDownTrayIcon style={{ width: "32px" }} />}
                        action={() => {
                            props.setImportShopeeProductSelected(props.item);
                            props.setVisibleModalImport(true)
                            props.setVisibleModalMenu(false)
                        }}
                        item={props.item}
                        title={"Importar avaliação"}
                        desc={"Importar avaliações para esse produto."} />

                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "mercadoshop" &&
                        <Item
                            image={<StarIcon style={{ width: "32px" }} />}
                            action={() => {
                                props.setVisibleModalAddReview(true);
                                props.setVisibleModalMenu(false)
                            }}
                            item={props.item}
                            title={"Criar avaliação"}
                            desc={"Criar avaliação para esse produto."} />}

                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?

                        <Item
                            image={<PencilSquareIcon style={{ width: "32px" }} />}
                            action={() => {
                                props.setVisibleModalUpdate();
                                props.setVisibleModalMenu(false)
                            }}
                            item={props.item}
                            title={"Editar"}
                            desc={"Editar esse produto."} /> : null}

                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                        <Item
                            image={<TrashIcon style={{ width: "32px" }} />}
                            action={() => {
                                props.deleteProduct();
                                props.setVisibleModalMenu(false)
                            }}
                            item={props.item}
                            title={"Excluír"}
                            desc={"Excluír esse produto."} /> : null}

                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                        <Item
                            image={<CodeBracketIcon style={{ width: "32px" }} />}
                            action={() => {
                                props.setVisibleModalCode(true);
                                props.setVisibleModalMenu(false)
                            }}
                            item={props.item}
                            title={"Código"}
                            desc={"Copiar código desse produto."} /> : null}

                </BoxMenu>
            </Container>
        </Modal>
    )
}

export default ModalMenuItem


const Item = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        //console.log("props_", props)
    }, [props])


    const [hover, setHover] = useState(false)


    return (
        <ItemMenu onClick={() => { props.action() }} hover={hover} onMouseLeave={() => { setHover(false) }} onMouseEnter={() => { setHover(true) }}>
            <BoxImage hover={hover}>
                {props.image}
            </BoxImage>
            <BoxInfor>
                <label>{props.title}</label>
                <p>{props.desc}</p>
            </BoxInfor>
        </ItemMenu>
    )
}

