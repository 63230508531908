
import { useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";

const WixIntegration = () => {

  

    const location = useLocation();
    const history = useHistory()

    const dispatch = useDispatch()

    // if (location.pathname == "/wix") {
    //     localStorage.setItem('depoimentos@wix', new URLSearchParams(window.location.search).get("token"))
    // }


    useEffect(() => {

        let tokenCode = new URLSearchParams(window.location.search).get("token")
        let instanceCode = new URLSearchParams(window.location.search).get("instance")

        if (tokenCode) {
            window.location = `https://www.wix.com/installer/install?token=${typeof tokenCode == "undefined" || tokenCode == null ? "" : tokenCode}&appId=${process.env.REACT_APP_WIX_ID}&redirectUrl=${process.env.REACT_APP_URL}/integracoes/wix`
        } else if (instanceCode) {
            let dataParsed = JSON.parse(Buffer.from(instanceCode.split('.')[1], 'base64').toString());
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.more_user_data == dataParsed.instanceId) {
                history.push("/")
            } else {
                dispatch({
                    type: "SAGA_INTEGRATE_WIX_INTEGRATION",
                    payload: {
                        instanceId: dataParsed.instanceId
                    }
                })
            }
        }


    }, [])

    return (
        <div>
        </div>
    )
}

export default WixIntegration
