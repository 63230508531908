import styled from "styled-components";

export const Message = styled.div`
    //width: 500px;
    display: flex;
    white-space: normal;
    width: 100%;
    //max-width: 500px;
    //background-color: red;

    label{
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* Definimos quantas linhas queremos */
        display: ${props => props.full ? "flex" : "-webkit-box"} ;
        overflow: hidden;
        cursor: pointer;
        //width: 300px;
        //background-color: yellow;
        :hover{
            text-decoration: underline;
        }
        
    }
`

export const BoxImage = styled.div`
    
    display: flex;
    //background-color: yellow;
    align-items: center;
    justify-content: center;
    

    label{
        display: flex;
        font-size: 9px;
        position: absolute;
        right: -21px;
        top: -21px;
        background-color: #f2f2f2;
        margin: 0px;
        padding: 0px;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }

    span{
        display: flex;
        //background-color: red;
        position: relative;
        
        img{
            display: flex;
            width: 32px;
            height: 32px;
            border: 1px solid #f2f2f2;
        }
    }
   
`

export const Submenu = styled.div`
    display: ${props => props.isVisible ? "flex" : "none"};
    background-color: #fff;
    position: absolute;
    right: 0px;
    padding: 10px;
    border-radius: 4px;
    z-index: 999999999;
    flex-direction: column;
    box-shadow: 0 0 2em #DCDBDA;


    div{
        display: flex;
        gap: 10px;
        height: 60px;
        padding: 10px;
        cursor: pointer;
        align-items: center;
        border-radius: 4px;

        :hover{

            span:nth-child(1){
                background-color: #fff;
            }
        }


        span{
            display: flex;
            flex-direction: column;
            height: 40px;
            //background-color: red;
            align-items: start;
            justify-content: space-between;

            label{
                cursor: pointer;
                font-size: 12px;
                //background-color: purple;
            }

            p{
                margin: 0px;
                padding: 0px;
                font-size: 14px;
                color: #111827;
                //background-color: yellow;
            }
        }


        :hover{
            background-color: #F9FAFB;
        }
    }
`

export const BoxIcon = styled.span`
    display: flex;
    flex-direction: column;
    background-color: #F9FAFB;
    //background-color: red;
    align-items: center !important;
    justify-content: center !important;
    width: 40px;
    height: 40px;
    border-radius: 4px;

`

export const BoxMediaItem = styled.span`
    display: flex;
    //background-color: red;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    cursor: pointer;

    svg{
        width: 20px;
        height: 20px;
    }
`

export const BoxImageProduct = styled.span`
    display: flex;
    justify-content: center;
    

    span{
        //background-color: red;
        position: relative;

        :hover{
            label{
                display: flex;
            }
        }

        img{
            border: 1px solid #f1f1f1;
            cursor: pointer;
            width: 32px;
            height: 32px; 
            object-fit: cover; 
            border-radius: 4px ;
            
        }

        label{
            display: none;
            position: absolute;
            background-color: #222222;
            padding: 10px 20px;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            z-index: 9999;
            top: -55px;
            right: 0;

            :after{
                content: '';
                position: absolute;
                top: 100%;
                right: 10px;
                border-top: 8px solid #222222;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
    }

    

    
`

export const MediasReview = styled.div`
    display: flex;
    //gap: 10px;
    //width: 500px;
    cursor: pointer;

    span{
        display: flex;
        margin-left: -20px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #FFF;
        border: 1px solid #f1f1f1;

        :first-child{
            margin-left: 0px;
        }

        img{
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    label{
        //background-color: red;
        display: flex;
        align-items: center;
        margin-left: 4px;
        cursor: pointer;
    }
`