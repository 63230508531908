import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color:  ${props => props.backgroundColor};
    padding: 20px;
    border-radius: 5px;
`

export const AbaCustom = styled.div`
    display: flex;
    background-color: #f5f5f5;
    margin: 20px 20px 20px 20px;
    height: 80px;
    align-items: center;
    padding: 0px 10px;
    border-radius: 4px;
`

export const AbaItem = styled.div`
    display: flex;
    background-color:  ${props => props.active ? props.background : "transparent"};
    flex: 1;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.5s;

    label{
        font-size: 26px;
        cursor: pointer;
    }
`

export const BoxData = styled.div`
    display: flex;
`

export const BoxInfor = styled.div`
    display: flex;
    min-width: 300px;
    background-color: ${props => props.background};
    padding: 20px;
    height: max-content;
    flex-direction: column;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 4px;
`

export const Table = styled.div`
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    

    label:nth-child(1){
        width: 35px;
        //background-color: red;
        text-align: end;
    }

    label:nth-child(3){
        //background-color: yellow;
        width: 10px;
        text-align: center;
    }
`

export const Row = styled.div`
    display: flex;
    flex: 1;
    //background-color: yellow;
    align-items: center;

    label:nth-child(1){
        align-items: center;
        display: flex;
        justify-content: end;
        width: 40px;
        gap: 7px;
    }

    span{
        display: flex;
        //background-color: orange;
        flex: 1;
        height: 6px;
        margin: 0px 10px;
        background:  #d7d7d7;
        position: relative;

        div{
            width: ${props => `${props.porcent}%`};
            min-height: 5px;
            background-color: ${props => props.color};
            position: absolute;
            border-radius: 4px;
        }
    }
`

export const Reviews = styled.label`
    //display: flex;
    font-size: 14px;
    padding: 10px 0px 20px 0px;
    border-bottom: 1px solid #80808022;

`

export const Rating = styled.label`
    font-size: 50px;
    font-weight: bold;
`

export const Stars = styled.div`
    display: flex;
`

export const BoxList = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
`

export const BarOrder = styled.div`
    display: flex;
    height: 50px;
    //background-color: #ff440022;
    gap: 10px ;
    justify-content: end;
    align-items: center;
    padding: 0px 15px;

    select{
        height: 40px;
        min-width: 150px;
        cursor: pointer;
        padding: 0px 10px;
        border-radius: 4px;
        color: #00000099;
    }
`


export const Btn = styled.label`
    display: flex;
    background-color: ${props => props.background};
    height: 40px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
`


export const BtnAsks = styled.label`
    display: flex;
    background-color: ${props => props.background};
    height: 40px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    width: max-content;
    padding: 0px 30px;
`
