import { BoxCupom, BoxForm, BoxInfor, BoxMedias, BoxMessage, BoxWhats, ButtomItemBoxMedia, ButtonClose, ButtonCustom, Container, ControlScreen, InputCustomForm, InputCustomWhatsappForm, ItemBoxMedia, ItemOption, Main, Steps, TermoUso, Title } from "./styled";
import imgBackground from "../../../../assets/imgs/preview-background.png";
import { AiFillStar } from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";
import { MdOutlineContentCopy } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { FaCloudUploadAlt, FaTiktok } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Autoplay, Navigation, Scrollbar, A11y, EffectCards, EffectFade, EffectCube, EffectCoverflow, EffectFlip, EffectCreative } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./styles.css";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTranslation } from "../../../../translations";

const PreviewPerguntar = (props) => {

  let icon = { size: "24", colorActive: "orange", colorInative: "#80808022" }

  const configControl = useSelector(state => state.reducerControlConfig)

  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);



  const [hoverOption, setHoverOption] = useState(null)



  useEffect(() => {
    document.documentElement.style.setProperty('--swiper-navigation-size', '30px');
  },)


  return (
    <Container id={"main-swiper-avaliar"} img={imgBackground}>

      <Swiper
        id='swiper-avaliar'
        modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
        slidesPerView={1}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        navigation={true}
        pagination={{ type: 'fraction', }}
        effect={"slide"}
      >

        <SwiperSlide>
          <Main backgroundColor={configControl.screen_question_color_primary}>
            <Title color={configControl.screen_question_color_font}>
              {configControl.screen_question_config_title_comment}
            </Title>

            <BoxMessage>
              <textarea
                value={" "}
                rows="12"
                maxLength={255}
                placeholder={"Conte-nos sua experiência..."}
                style={{ fontSize: "14px", color: configControl.screen_question_color_font, border: `1px solid ${configControl.screen_question_color_secondary}`, backgroundColor: "transparent", display: "flex", minWidth: "100%", padding: "20px", borderRadius: "8px" }}
              />
              <ButtonCustom colorHover={configControl.screen_question_color_font_secondary} color={configControl.screen_question_color_secondary} borderColor={configControl.screen_question_color_secondary} >
                {translation.thema.preview.proximo}
              </ButtonCustom>
            </BoxMessage>
          </Main>
        </SwiperSlide>

        <SwiperSlide>
          <Main backgroundColor={configControl.screen_question_color_primary}>
            <Title color={configControl.screen_question_color_font}>
              {configControl.screen_question_config_title_data}
            </Title>

            <BoxForm>
              <InputCustomForm color={configControl.screen_question_color_font} colorBorder={configControl.screen_question_color_secondary}>
                <input type="text" value={"Roberta Oliveira"} placeholder="Nome:" />
              </InputCustomForm>

              {
                configControl.screen_question_config_visible_data_whatsapp == 1 &&
                <BoxWhats color={configControl.screen_question_color_font} colorBorder={configControl.screen_question_color_secondary}>
                  <PhoneInput
                    countryCodeEditable={false}
                    containerStyle={{ marginBottom: "0px" }}
                    inputStyle={{
                      borderRadius: "0px",
                      width: "100%",
                      backgroundColor: 'transparent',
                      outline: "none",
                      height: "45px",
                      paddingLeft: "50px",
                      border: `1px solid ${configControl.screen_question_color_secondary}`,
                      color: `${configControl.screen_question_color_font}`
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                      marginLeft: "0px",
                      padding: "5px",
                      width: "65px",
                    }}
                    country={"br"}
                    value={"5511999999999"}

                  />
                  <span style={{ position: "absolute", top: 8, right: 10 }}>
                    <RiWhatsappFill size={20} color={configControl.screen_question_color_font} />
                  </span>

                </BoxWhats>
              }


              <InputCustomForm color={configControl.screen_question_color_font} colorBorder={configControl.screen_question_color_secondary}>
                <input type="text" value={"robertaoliveira@email.com.br"} placeholder="E-mail:" />
              </InputCustomForm>

              <TermoUso color={configControl.screen_question_color_font}>
                {translation.thema.previewCreateReview.privacidade}
              </TermoUso>
              <ButtonCustom colorHover={configControl.screen_question_color_font_secondary} color={configControl.screen_question_color_font} borderColor={configControl.screen_question_color_secondary} >
                Enviar
              </ButtonCustom>
            </BoxForm>

            <ControlScreen color={configControl.screen_question_color_font}>
              <label>{translation.thema.previewCreateReview.voltar}</label>
              <Steps style={{ visibility: "hidden" }} backgroundColor={configControl.screen_question_color_secondary}>
                <span />
                <div />
                <span />
                <div />
                <span />
                <div />
                <span />
              </Steps>
              <label style={{ visibility: "hidden" }}>Avançar</label>
            </ControlScreen>
          </Main>
        </SwiperSlide>

        <SwiperSlide>
          <Main backgroundColor={configControl.screen_question_color_primary} >
            <Title color={configControl.screen_question_color_font}>
              {configControl.screen_question_config_title_end}
            </Title>

            <BoxInfor color={configControl.screen_question_color_font} backgroundColor={configControl.screen_question_color_secondary}>
              <p> {translation.thema.preview.perguntaSucesso}</p>

            </BoxInfor>

            <div style={{ display: "flex", flex: "1", backgroundColor: "transparent" }} />

            <ButtonClose colorHover={configControl.screen_question_color_font_secondary} colorBorder={configControl.screen_question_color_secondary} color={configControl.screen_question_color_font}>
              Fechar
              <IoIosArrowForward size={24} color={configControl.screen_question_color_font_secondary} />
            </ButtonClose>

          </Main>
        </SwiperSlide>

      </Swiper>


    </Container>
  )
}

export default PreviewPerguntar