
import { useState } from 'react';
import Modal from 'react-modal';
// import { useDispatch, useSelector } from 'react-redux';
// import { IoIosClose } from "react-icons/io";

// import { Container, Title, FormCustom, Close, ButtonCustom, InputCustomText, BoxInput } from "./styled";
// import { useState } from 'react';
// import { useEffect } from 'react';
// import { toast } from 'react-toastify';
// import { MdError } from 'react-icons/md';
// import { AiFillCheckCircle, AiOutlineLoading } from 'react-icons/ai';
// import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
// import { BodyModal, Option } from '../Modal-Search-All-Platform/styled';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalSearchAllPlatformDefault = (props) => {

     const [modalIsOpen, setModalIsOpen] = useState(null);
    // const reducerSearchPlatformAll = useSelector(state => state.reducerSearchPlatformAll)
    // const reducerSearchPlatformItems = useSelector(state => state.reducerSearchPlatformItems)

    // const language = useSelector((state) => state.reducerLanguage);

    // const [qtdTotal, setQtdTotal] = useState(0)
    // const [qtdRequest, setqtdRequest] = useState(0)
    // const [qtdRequestCompleted, setQtdRequestCompleted] = useState(0)

    // const [statusVerify, setStatusVerify] = useState(null)
    // const [statusItems, setStatusItems] = useState(null)

    // const dispatch = useDispatch()

    // useEffect(() => {
    //     if (reducerSearchPlatformAll.loading) {
    //         setModalIsOpen(true)
    //         setStatusVerify("loading")
    //     } else {
    //         if (reducerSearchPlatformAll?.data?.error == false) {
    //             //toast.info("teste")
    //             setStatusVerify("success")
    //             setQtdTotal(reducerSearchPlatformAll?.data?.data?.maxItems)
    //             setqtdRequest(reducerSearchPlatformAll?.data?.data?.maxPages)
    //         } else {
    //             if (reducerSearchPlatformAll?.data?.error) {
    //                 setStatusVerify("error")
    //             }
    //         }
    //     }

    // }, [reducerSearchPlatformAll])

    // useEffect(() => {

    //     if (reducerSearchPlatformItems.requestCompleted != null) {
    //         setQtdRequestCompleted(reducerSearchPlatformItems.requestCompleted)
    //     }

    //     if (reducerSearchPlatformItems.loading == null && reducerSearchPlatformItems.error == null && reducerSearchPlatformItems.requestCompleted == null) {
    //         setStatusItems(null)
    //     } else if (reducerSearchPlatformItems.loading) {
    //         setStatusItems("loading")
    //     } else if (reducerSearchPlatformItems.error) {
    //         setStatusItems("error")
    //     } else if (reducerSearchPlatformItems.requestCompleted * 10 >= qtdTotal) {
    //         setStatusItems("success")
    //     }
    // }, [reducerSearchPlatformItems])





    // const getIconVerify = () => {

    //     switch (statusVerify) {
    //         case "error":
    //             return <MdError size={20} color={"red"} />
    //         case "success":
    //             return <AiFillCheckCircle size={20} color={"#21d487"} />
    //         case "loading":
    //             return <AiOutlineLoading size={20} color={"#21d487"} />
    //         case null:
    //             return <RiCheckboxBlankCircleFill size={12} color={"gray"} />
    //         default:
    //             break;
    //     }

    // }

    // const getIconItems = () => {
    //     switch (statusItems) {
    //         case "error":
    //             return <MdError size={20} color={"red"} />
    //         case "success":
    //             return <AiFillCheckCircle size={20} color={"#21d487"} />
    //         case "loading":
    //             return <AiOutlineLoading size={20} color={"#21d487"} />
    //         case null:
    //             return <RiCheckboxBlankCircleFill size={12} color={"gray"} />
    //         default:
    //     }
    // }

    // const closeModal = () => {
    //     setModalIsOpen(false)
    //     dispatch({ type: "SEARCH_PLATFORM_ALL_RESET" })
    //     dispatch({ type: "SEARCH_PLATFORM_ALL_ITEMS_RESET" })
    //     setQtdTotal(0)
    //     setQtdRequestCompleted(0)
    // }



    return (
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            ariaHideApp={false}
        >
            <label>modal all</label>
            {/* <Container>
                <Close onClick={() => { closeModal() }}><IoIosClose size={25} color={"gray"} /></Close>
                <Title><strong>{translations["sales-atualizacao-pedidos"][language.data]}</strong></Title>
                <BodyModal>

                    <Option loading={false}>
                        <label>{translations["sales-acao"][language.data]}</label>
                        <label>{translations["sales-progresso"][language.data]}</label> 
                        <div>Feedback</div>
                    </Option>

                    <Option loading={statusVerify == "loading" ? true : false}>
                        <label>{translations["sales-verificacoes"][language.data]}</label>
                        <label>1 de 1</label>
                        <div>
                            <span>
                                {getIconVerify()}
                            </span>
                        </div>
                    </Option>

                    <Option loading={statusItems == "loading" ? true : false}>
                        <label>{translations["sales-atualizacoes"][language.data]}</label>
                        <label>{qtdRequestCompleted * 10 > qtdTotal ? qtdTotal : qtdRequestCompleted * 10} de {qtdTotal}</label>
                        <div>
                            <span>
                                {getIconItems()}
                            </span>
                        </div>
                    </Option>
                </BodyModal>

            </Container> */}
        </Modal>
    )
}

export default ModalSearchAllPlatformDefault
