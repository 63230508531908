import { useLayoutEffect, useRef, useState } from 'react'
import ItemTable from '../item-table'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LoadingLogoLilyNew from '../../../../../components/Loading-logo-lily-new'
import { ContainerLoading } from '../../../pages/home-new/styled'
import Pagination from '../pagination'
import { toast } from 'react-toastify'
import { getTranslation } from '../../../../../translations'
import { AdjustmentsHorizontalIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { BarFilters, BarFiltersLeft, Search } from '../../../../reviews/components/tailwind/table/styled'
import FilterQuestions from '../filters-questions'





function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableQuestions(props) {

    const [page, setpage] = useState(1)
    const [perPage, setperPage] = useState(10)
    const [search, setsearch] = useState("")

    const [filterStatusAnswered, setfilterStatusAnswered] = useState(true)
    const [filterStatusHidden, setfilterStatusHidden] = useState(true)
    const [filterStatusPending, setfilterStatusPending] = useState(true)
    const [filterStatusNew, setfilterStatusNew] = useState(true)
    const [filterStatusDeleted, setfilterStatusDeleted] = useState(false)

    const [visibleMenuOrder, setvisibleMenuOrder] = useState(false)
    const [visibleFilters, setvisibleFilters] = useState(false)

    const handleClick = () => {
        if (checkbox.current) {
            checkbox.current.click();
        }
    };

    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedQuestions, setSelectedQuestions] = useState([])
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const questions = useSelector(state => state.reducerQuestions)
    const dispatch = useDispatch()
    const [dataQuestions, setDataQuestions] = useState([])
    const statusDeleteSelectReviews = useSelector(state => state.reducerDeleteSelectReviews)
    const statusDeleteSelectQuestions = useSelector(state => state.reducerDeleteSelectQuestions)

    useEffect(() => {
        console.log("teste useLayoutEffect")
        const isIndeterminate = selectedQuestions.length > 0 && selectedQuestions.length < dataQuestions.length
        if (dataQuestions.length != 0) setChecked(selectedQuestions.length === dataQuestions.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedQuestions])


    function toggleAll() {
        setSelectedQuestions(checked || indeterminate ? [] : dataQuestions)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    useEffect(() => {
        goSearch()
    }, [page, filterStatusAnswered, filterStatusDeleted, filterStatusHidden, filterStatusPending, filterStatusDeleted, filterStatusNew
    ])

    useEffect(() => {
        setDataQuestions(questions?.data?.data)
    }, [questions])


    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_QUESTIONS", payload: {
                status: `${filterStatusNew ? "new," : ""}${filterStatusAnswered ? "answered," : ""}${filterStatusHidden ? "hidden," : ""}${filterStatusPending ? "pending" : ""}`,
                search: search,
                perPage: perPage,
                active: filterStatusDeleted ? 0 : 1,
                page: page,
                productId: props.productId
            }
        })
        //setVisibleMenuPerPage(false)
    }

    useEffect(() => {
        console.log("selectedReviews", selectedQuestions)
    }, [selectedQuestions])


    const deleteAll = () => {
        const dataDelete = selectedQuestions.map((item) => item)
        dispatch({ type: "SAGA_DELETE_SELECT_QUESTION", payload: { itens: dataDelete } })
    }

    useEffect(() => {
        if (statusDeleteSelectReviews.loading == false && statusDeleteSelectReviews.error == false && statusDeleteSelectReviews.data.length == 0) {
        } else {
            const id = "statusDeleteSelectReviews"
            if (statusDeleteSelectReviews.loading) {
                toast.loading(translation.reviews.aguarde, { toastId: id })
            } else {
                if (statusDeleteSelectReviews.error) {
                    toast.update(id, { render: translation.reviews.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.reviews.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    goSearch()
                    //setTimeout(() => { handleClick() }, 1000);

                }
            }
        }
    }, [statusDeleteSelectReviews])

    const statusAll = (status) => {
        let arrayModify = []
        for (let iterator of selectedQuestions) { arrayModify.push({ id: iterator.id, status: status }) }
        dispatch({ type: "SAGA_UPDATE_SELECT_REVIEWS", payload: { itens: arrayModify, } })
    }


    const closeMenuOrder = () => { setvisibleMenuOrder(false) }
    useEffect(() => {
        if (visibleMenuOrder) {
            window.addEventListener('mouseup', closeMenuOrder);
            return () => { window.removeEventListener('mouseup', closeMenuOrder); };
        }
    }, [visibleMenuOrder])

    const closeMenuFilters = () => { setvisibleFilters(false) }
    useEffect(() => {
        if (visibleFilters) {
            window.addEventListener('mouseup', closeMenuFilters);
            return () => { window.removeEventListener('mouseup', closeMenuFilters); };
        }
    }, [visibleFilters])


    ///const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const statusUpdateQuestion = useSelector(state => state.reducerUpdateQuestion)
    const statusDeleteQuestion = useSelector(state => state.reducerDeleteQuestion)
    const statusUpdateReplies = useSelector(state => state.reducerUpdateReplies)
    const statusAddReplies = useSelector(state => state.reducerAddReplies)

    useEffect(() => {
        // if (!(statusUpdateQuestion.loading == false && !statusUpdateQuestion.error == false && statusUpdateQuestion.data.length == 0)) {
        //     const id = 0
        //     if (statusUpdateQuestion.loading) {
        //         toast.loading(translation.questions.card.aguarde, { toastId: id })
        //     } else {
        //         if (statusUpdateQuestion.error) toast.update(id, { render: translation.questions.card.erroEnviar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        //         else {
        //             toast.update(id, { render: translation.questions.card.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
        //             //goSearch()
        //         }

        //     }
        // }
        if (!statusUpdateQuestion.loading && !statusUpdateQuestion.error && statusUpdateQuestion.data.length == 0) {
        } else {
            const id = "statusUpdateQuestion"
            if (statusUpdateQuestion.loading) {
                toast.loading(translation.questions.aguarde, { toastId: id })
            } else {
                if (statusUpdateQuestion.error) {
                    toast.update(id, { render: translation.questions.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.questions.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    //setTimeout(() => { handleClick() }, 1000);
                    goSearch()
                }
            }
        }
    }, [statusUpdateQuestion])

    useEffect(() => {
        // if (!(statusDeleteQuestion.loading == false && !statusDeleteQuestion.error == false && statusDeleteQuestion.data.length == 0)) {
        //     const id = "statusDeleteQuestion"
        //     if (statusDeleteQuestion.loading) {
        //         toast.loading(translation.questions.card.aguarde, { toastId: id })
        //     } else {
        //         if (statusDeleteQuestion.error) toast.update(id, { render: translation.questions.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        //         else {
        //             toast.update(id, { render: translation.questions.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
        //             setVisibleModalConfirmation(false)
        //             goSearch()
        //         }
        //     }
        // }
        if (!statusDeleteQuestion.loading && !statusDeleteQuestion.error && statusDeleteQuestion.data.length == 0) {
        } else {
            const id = "statusDeleteQuestion"
            if (statusDeleteQuestion.loading) {
                toast.loading(translation.questions.aguarde, { toastId: id })
            } else {
                if (statusDeleteQuestion.error) {
                    toast.update(id, { render: translation.questions.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.questions.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    //setTimeout(() => { handleClick() }, 1000);
                    goSearch()
                }
            }
        }
    }, [statusDeleteQuestion])

    useEffect(() => {
        // if (!(statusUpdateReplies.loading == false && !statusUpdateReplies.error == false && statusUpdateReplies.data.length == 0)) {
        //     const id = "statusUpdateReplies"
        //     if (statusUpdateReplies.loading) {
        //         toast.loading(translation.questions.modal.aguarde, { toastId: id })
        //     } else {
        //         if (statusUpdateReplies.error) toast.update(id, { render: translation.questions.modal.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        //         else {
        //             toast.update(id, { render: translation.questions.modal.respostaAtualizada, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
        //             //props.setVisibleModal(false)
        //             goSearch()
        //         }
        //     }
        // }
        if (!statusUpdateReplies.loading && !statusUpdateReplies.error && statusUpdateReplies.data.length == 0) {
        } else {
            const id = "statusUpdateReplies"
            if (statusUpdateReplies.loading) {
                toast.loading(translation.questions.aguarde, { toastId: id })
            } else {
                if (statusUpdateReplies.error) {
                    toast.update(id, { render: translation.questions.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.questions.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    //setTimeout(() => { handleClick() }, 1000);
                    goSearch()
                }
            }
        }
    }, [statusUpdateReplies])

    useEffect(() => {
        if (!statusAddReplies.loading && !statusAddReplies.error && statusAddReplies.data.length !== 0) {
            goSearch()
        }
    }, [statusAddReplies])


    useEffect(() => {
        if (!statusDeleteSelectQuestions.loading && !statusDeleteSelectQuestions.error && statusDeleteSelectQuestions.data.length == 0) {
        } else {
            const id = "statusDeleteSelectQuestions"
            if (statusDeleteSelectQuestions.loading) {
                toast.loading(translation.questions.aguarde, { toastId: id })
            } else {
                if (statusDeleteSelectQuestions.error) {
                    toast.update(id, { render: translation.questions.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.questions.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    setTimeout(() => { handleClick() }, 1000);
                    goSearch()
                }
            }
        }
    }, [statusDeleteSelectQuestions])




    return (
        questions.loading ?
            <ContainerLoading>
                <LoadingLogoLilyNew />
            </ContainerLoading> :
            <>
                <div className="px-4 sm:px-6 lg:px-8">
                    {props.navbar && props.navbar}
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900"> {translation.sidebar.perguntas}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.perguntas}
                            </p>
                        </div>
                    </div>
                    <BarFilters >
                        <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <BarFiltersLeft>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && goSearch() }} type='text' placeholder={translation.avaliacoes.pesquisarNomes} value={search} onChange={(e) => { setsearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>
                            <FilterQuestions
                                answered={filterStatusAnswered} setStatusAnswered={setfilterStatusAnswered}
                                hidden={filterStatusHidden} setStatusHidden={setfilterStatusHidden}
                                deleted={filterStatusDeleted} setStatusDeleted={setfilterStatusDeleted}
                                pending={filterStatusPending} setStatusPending={setfilterStatusPending}
                                new={filterStatusNew} setStatusNew={setfilterStatusNew}
                            />
                        </span>
                    </BarFilters>
                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {selectedQuestions.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            <button
                                                onClick={() => { deleteAll() }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {translation.avaliacoes.excluir}
                                            </button>
                                            {/* <button
                                                onClick={() => { statusAll("approved") }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                Mostrar
                                            </button>
                                            <button
                                                onClick={() => { statusAll("hidden") }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                Esconder
                                            </button> */}
                                        </div>
                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={() => toggleAll()}
                                                    />
                                                </th>
                                                <th scope="col" className="min-w-[6rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.data}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.nome}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.perguntas.pergunta}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.perguntas.resposta}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", justifyContent: "center" }}> {translation.avaliacoes.produto}</label>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", justifyContent: "center" }}>status</label>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {dataQuestions?.map((item) => (
                                                <ItemTable goSearch={goSearch} classNames={classNames} selectedQuestions={selectedQuestions} setSelectedQuestions={setSelectedQuestions} item={item} setVisibleModalEdit={props.setVisibleModalEdit} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination setPage={setpage} />
            </>

    )
}
