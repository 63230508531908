import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    //padding: 20px;
    max-height: 80vh ;
    width: 500px;
    background-color: transparent;
`


export const Logo = styled.div`
    display: flex;
    height: 100px ;
    width: 100px;
    background: #FFF6D9;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: relative;

    img{
        width: 46px;
    }
`

export const LogoToPlatform = styled.div`
    display: flex;
    gap: 32px;
    position: relative;

    div{
        height: 100px ;
        width: 100px;
        background: #FFF6D9;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            width: 46px;
        }
    }

    span{
        display: flex;
        //background-color: red;
        flex-direction: column;
        justify-content: center;
    }

    
`

export const IconSuccess = styled.div`
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

export const Screen = styled.div`
    display: flex;
    height: 100% ;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    gap: 20px;
`

export const BoxActions = styled.div`
    display: flex;
    //background-color: red;
    width: 100%;
    gap: 12px;
`

export const ItemBoxAction = styled.div`
    display: flex;
    height: 44px;
    flex: 1;
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: ${props => props.fontColor};
    cursor: pointer;
    

`

export const ItemBoxActionLabel = styled.label`
    display: flex;
    height: 44px;
    flex: 1;
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: ${props => props.fontColor};
    cursor: pointer;
    

`

export const Title = styled.label`
    display: flex;
    color: #101828;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`

export const BoxOptions = styled.div`
    display: grid;
    gap: 20px;
    //background-color: gray;
    grid-template-columns: 1fr 1fr;

`

export const TextDownload = styled.a`
    color: #19B674;

    :hover{
        text-decoration: underline;
    }

`

export const ItemOption = styled.div`
    display: flex;
    width: 130px;
    height: 130px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.active ? "#ffcc00" : "#f1f1f1"};
    cursor: pointer;
    //background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.1) -2.33%, rgba(0, 0, 0, 0) 101.96%, rgba(255, 255, 255, 0.0) 101.96%);
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.1), #dcdcdc88);
    position: relative;

    img{
        width: 50px;
    }

    :hover{
        border: 1px solid #ffcc00;
    }

    label{
        position: absolute;
        bottom: 5px;
    }

`

export const Form = styled.div`
    display: flex;
    //background-color: red;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-height: 400px;
    overflow: auto;
`

export const InputCustom = styled.div`
    display: flex;
    background-color: #fff;
    border: 1px solid #E7EAEE;
    height: 44px !important;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    padding: 0px 14px;

    svg{
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    input{
        outline: none;
        border: none;
        background-color: transparent;
        display: flex;
        flex: 1;
        height: 100%;
        font-size: 13px;
        

        ::placeholder{
            color: rgba(102, 112, 133, 0.50);
            font-size: 13px;
            font-weight: 400;
            line-height: 24px;
        }
    }
`

export const BoxCheck = styled.div`
    display: flex;
    justify-content: space-between;
    
`

export const ItemCheck = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    span{
        background-color: transparent;
        display: flex;
        border-radius: 4px;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        border: 1px solid ${props => props.active ? "#19b674" : "#dcdcdc"};
    }

    label{
        color: #344054;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px; 
        cursor: pointer;
    }
`

export const BoxQtd = styled.div`
    display: flex;
    //background-color: purple;
    //gap: 20px;
    flex-direction: column;

     /* input{
        background: linear-gradient(to right, #82CFD0 0%, #82CFD0 50%, #fff 50%, #fff 100%);
        border: solid 1px #82CFD0;
        border-radius: 8px;
        height: 7px;
        width: 356px;
        outline: none;
        transition: background 450ms ease-in;
        -webkit-appearance: none;
        
    }  */

    label{
        display: flex;
        color: #667085;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px; 
        padding-bottom: -10px;
        //background-color: red;
        margin-bottom: -5px;
    }

    span{

        display: flex;
        //background-color: red;
        width: 100%;
        gap: 20px;

        input{
            display: flex;
            flex: 1;
            accent-color: #19b674;
        }

        label{
            width: 64px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #101828;
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            border-radius: 8px;
            border: 1px solid #E7EAEE;
            padding: 8px;
        }
    }


`

export const BoxLoading = styled.div`
    display: flex;
    position: relative;
    //background-color: red;


    label{
        color: #101828;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        //background-color: yellow;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        top: calc(50% - 20px);
        left: calc(50% - 75px);
        //width: 100%;
    }

    
   svg{
    animation: spin 1s linear infinite;
    

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
   }
`

export const BoxReviews = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 300px;
    overflow: auto;
    padding-right: 10px;
    padding-top: 10px;
    overflow-x: hidden;
`

export const BoxTitleReviews = styled.div`
    display: flex;
   //background-color: yellow;
   width: 100%;
   height: 50px;
   justify-content: space-between;
   align-items: end;
`

export const BoxTitleReviewsLeft = styled.div`
    display: flex;
    flex-direction: column;

    label{
        font-size: 12px;
        color: #667085;
        font-weight: 400;
    }

    div{
        display: flex;
        gap: 10px;
        //background-color: red;
        align-items: center;

        label{
            //background-color: red;
            padding: 6px 14px;
            border-radius: 8px;
            border: 1px solid #E7EAEE;
        }

        span{
            display: flex;
            gap: 2px;
        }
    }

`

export const BoxTitleReviewsAction = styled.div`
    display: flex;
    //background-color: red;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    border-radius: 8px;
    border: 1px solid #E7EAEE;
    cursor: pointer;
    font-size: 12px;
    height: 40px;
    cursor: pointer;
    label{
        cursor: pointer;
    }

`

export const TextEnd = styled.label`
    display: flex;
    color: #667085;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px; /* 200% */
`

export const Close = styled.label`
    display: flex;
    //background-color: red;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    svg{
        width: 16px;
        height: 16px;
    }
`

export const ContainerConfirm = styled.div`
    display: flex;
    background-color: white;
    //position: absolute;
    margin: 0px 20px;
    flex-direction: column;
    width: 350px;
    z-index: 99999999;
    padding: 20px;
    border-radius: 8px;
    gap: 10px;
    border: 2px solid #f1f1f1;
    box-shadow: 0 0 2em #DCDBDA;
    
`

export const BoxIconConfirm = styled.div`
    display: flex;
    
`
export const BoxConfirm= styled.div`
    display: ${props => props.visible ? "flex" : "none"};
    background-color: transparent;
    position: absolute;
    width: calc(100% - 40px);
    min-height: calc(100% - 40px);
    z-index: 9999999;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    
`

export const BoxTextConfirm = styled.div`
    display: flex;
    flex-direction: column;

    p{
        margin: 0px;
        padding: 0px;
    }

    label{
        font-size: 11px;
    }
    
`


export const ButtonMoreReviews = styled.div`
    display: flex;
    //background-color: red;
    align-items: center;
    justify-content: center;


    label{
        display: flex;
        background-color: #f1f1f1;
        padding: 10px 20px;
        margin: 10px 0px;
        border-radius: 8px;
        cursor: pointer;
    }
    
`

export const ListProducts = styled.div`
    display: flex;
    flex-direction: column;
    
`