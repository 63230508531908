import { useEffect } from "react";
import LoadingLogo from "../../../../components/Loading-logo";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getTranslation } from "../../../../translations";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";

const PositionMuralScript = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory()
  const dispatch = useDispatch()
  const statusUpdateConfig = useSelector(state => state.reducerUpdateConfigHomePosition)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
  const reducerUpdateConfigMural = useSelector(state => state.reducerUpdateConfigMural)

  useEffect(() => {

    if (urlParams.get("xpath_mural")) {
      dispatch({
        type: "SAGA_UPDATE_CONFIG_MURAL", payload: {
          xpath_mural: urlParams.get("xpath_mural")
        }
      })
      //dispatch({ type: "SAGA_UPDATE_CONFIG_HOME_POSITION", payload: { xpath_homereviews: urlParams.get("xpath_homereviews") } })
    } else {
      toast.error("problemas")
      //history.push("/personalizar/home")
    }
  }, [])



  useEffect(() => {
    if (reducerUpdateConfigMural.loading == false && reducerUpdateConfigMural.error == false && reducerUpdateConfigMural.data.length == 0) {
    } else {
      if (reducerUpdateConfigMural.loading == false) {
        if (reducerUpdateConfigMural.error) {
          toast.error("Error")
          history.push("/personalizar/mural")
        }
        else {
          toast.success("Posicionado")
          history.push("/personalizar/mural")
        }
      }

    }

  //   useEffect(() => {
  //     const id = "reducerUpdateConfigMural"
  //     if (!(reducerUpdateConfigMural.loading == false && !reducerUpdateConfigMural.error == false && reducerUpdateConfigMural.data.length == 0)) {
  //         if (reducerUpdateConfigMural.loading) {
  //             toast.loading(translation.thema.aguarde, { toastId: id })
  //         } else {
  //             if (reducerUpdateConfigMural.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
  //             else {
  //                 toast.update(id, { render: translation.thema.podeLevar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
  //                 dispatch({ type: "SAGA_GET_CONFIG_MURAL", })
  //             }
  //         }
  //     } else if (reducerUpdateConfigMural.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
  // }, [reducerUpdateConfigMural])

    //         const id = "statusUpdateConfig"
    //         if (!(statusUpdateConfig.loading == false && !statusUpdateConfig.error == false && statusUpdateConfig.data.length == 0)) {
    //             if (statusUpdateConfig.loading) {
    //                 toast.loading(translation.thema.aguarde, { toastId: id })
    //             } else {
    //                 if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //                 else {
    //                     toast.update(id, { render: translation.thema.podeLevar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
    //                     history.push("/personalizar/home")
    //                 }
    //             }
    //         } else if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
  }, [reducerUpdateConfigMural])


  return (
    <div style={{ display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <LoadingLogoLily />
    </div>
  )
}

export default PositionMuralScript